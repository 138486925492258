import axios, { AxiosRequestConfig } from 'axios';
import { DuplicateSitter } from '../types/DuplicateSitter';

const URL = '/v2/duplicate-sitters';

class DuplicateSitterService {
  static transferSitter(data: DuplicateSitter, config?: AxiosRequestConfig) {
    return axios.post(URL, data, config);
  }
}

export { DuplicateSitterService };
