import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Press, PressService } from 'us-web-services';
import PressEditForm from './PressEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

function PressEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [press, setPress] = useState<Press>({ title: '' });

  useEffect(() => {
    const pressId = Number.parseInt(match.params.id, 10);

    if (pressId) {
      const getData = async () => {
        const response = (await PressService.get(pressId)).data;

        if (response.data.id) {
          setPress(response.data);
        } else {
          setPress({ title: '' });
        }

        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/press');
  };

  const saveSuccess = () => {
    toast.success(`${press.title} updated successfully`);
    navigateToList();
  };

  const savePress = async () => {
    try {
      if (press.id) {
        await PressService.patch(press.id, press);
      } else {
        await PressService.create(press);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {press.title}
      </Typography>
      {loaded ? <PressEditForm press={press} setPress={setPress} /> : 'Loading'}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={savePress}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(PressEdit);
