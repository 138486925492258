import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { WebConfigurations } from '../types';

const URL = '/v2/public/web-configurations';

class WebConfigurationsService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<WebConfigurations>> {
    return axios.get(`${URL}/${id}`, config);
  }
}

export { WebConfigurationsService };
