import axios, { AxiosRequestConfig } from 'axios';
import { CompanyDomain } from '../../types/company/CompanyDomain';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/company-domains';

class CompanyDomainService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyDomain>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyDomain>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: CompanyDomain,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyDomain>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: CompanyDomain,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyDomain>> {
    return axios.post(`${URL}`, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CompanyDomainService };
