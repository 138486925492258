import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { SitterPlan } from '../types/SitterPlan';

const URL = '/v2/sitter-plans';

class SitterPlanService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<SitterPlan>> {
    return axios.get(`${URL}`, config);
  }

  static create(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterPlan>> {
    return axios.post(URL, data, config);
  }
}

export { SitterPlanService };
