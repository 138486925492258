import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { EmailNotificationType } from '../types/EmailNotificationType';

const URL = '/v2/sitter-search-notification-types';

class SitterSearchNotificationTypeService {
  static get(config?: AxiosRequestConfig): Promise<PagedResponse<EmailNotificationType>> {
    return axios.get(URL, config);
  }
}

export { SitterSearchNotificationTypeService };
