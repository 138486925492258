import axios, { AxiosRequestConfig } from 'axios';
import { MasterServiceAreaTier } from '../../types/master_service_area/MasterServiceAreaTier';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/master-service-area-tiers';

class MasterServiceAreaTierService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaTier>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceAreaTier>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: MasterServiceAreaTier,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaTier>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: MasterServiceAreaTier,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaTier>> {
    return axios.post(URL, data, config);
  }
}

export { MasterServiceAreaTierService };
