import React from 'react';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppCore from './AppCore';

let theme = createTheme({
  typography: {
    fontSize: 13,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      a: {
        textDecoration: 'none',
      },
      'a:hover': {
        color: '#3a0089',
        textDecoration: 'underline',
      },
    },
  },
  palette: {
    primary: {
      light: '#e0f7f9',
      main: '#00bbcd',
      dark: '#06515f',
    },
    secondary: {
      light: '#eee7fc',
      main: '#6105c5',
      dark: '#3a0089',
    },
    error: {
      light: '#f7e9e9',
      main: '#f25037',
      dark: '#b63729',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.common.black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          '&:hover': {
            color: theme.palette.common.white,
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          margin: '0 16px',
          minWidth: 0,
          [theme.breakpoints.up('md')]: {
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
          ':active': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          a: {
            textDecoration: 'none',
          },
          'a:active': {
            color: theme.palette.primary.main,
          },
          'a:hover': {
            textDecoration: 'underline',
          },
        },
        head: {
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 14,
        },
      },
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const App = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID || ''}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppCore />
      </ThemeProvider>
    </StyledEngineProvider>
  </GoogleOAuthProvider>
);

export default App;
