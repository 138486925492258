import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { OrderService } from 'us-web-services';
import useUser from '../../store/useUser';
import DateUtil from '../../util/DateUtil';
import { Table } from '../common/Table';

function MembershipTransactions() {
  const userState = useUser()[0];

  const params = () => [
    {
      key: 'userId',
      value: userState.user.id,
    },
    {
      key: 'reasons',
      value: 'subscription_setup',
    },
    {
      key: 'reasons',
      value: 'subscription_installment',
    },
  ];

  const COLUMNS = useMemo(
    () => [
      {
        id: 'membershipPayments',
        header: 'Membership Payments',
        columns: [
          {
            header: 'Created Date',
            accessorFn: rowData =>
              DateUtil.getDateSlashesString(moment(rowData.created)),
          },
          {
            header: 'Total',
            accessorFn: rowData => rowData.totalIn,
          },
          {
            header: 'Discount',
            accessorFn: rowData => rowData.discount,
          },
          {
            header: 'Status',
            accessorKey: 'status',
          },
          {
            header: 'Order ID',
            accessorKey: 'id',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Table
      columns={COLUMNS}
      retrieveData={OrderService.getByFilter}
      params={params}
      alwaysApplyParams
    />
  );
}

export default withRouter(MembershipTransactions);
