import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FusionAuthUserData } from '../types/FusionAuthUserData';

const URL = '/v2/admin-fusionauth-user-data';

class AdminFusionAuthUserDataService {
  static refresh(
    data: FusionAuthUserData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.post(URL, data, config);
  }
}

export { AdminFusionAuthUserDataService };
