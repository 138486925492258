import axios, { AxiosRequestConfig } from 'axios';
import { LocalGroup } from '../types/LocalGroup';
import { DataResponse, PagedResponse } from '../types/api';

const API_URL = '/v2/local-groups';

class LocalGroupService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<LocalGroup>> {
    return axios.get(`${API_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<LocalGroup>> {
    return axios.get(API_URL, config);
  }

  static patch(
    id: number,
    data: LocalGroup,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<LocalGroup>> {
    return axios.patch(`${API_URL}/${id}`, data, config);
  }

  static create(
    data: LocalGroup,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<LocalGroup>> {
    return axios.post(API_URL, data, config);
  }
}

export { LocalGroupService };
