import React from 'react';
import useStore from './useStore';

import * as actions from './actions/company';

const initialState = {
  company: {},
};

const useCompany = useStore(React, initialState, actions);

export default useCompany;
