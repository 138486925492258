import React, { useState } from 'react';
import {
  Button,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import toast from 'react-hot-toast';

import { ParentPlanService } from 'us-web-services';
import ModalStyles from '../../../styles/ModalStyles';
import DisplayService from '../../../util/DisplayService';

interface Props {
  refreshPlans: () => void;
  userId: number;
}

function AddParentPlanModal(props: Props) {
  const { refreshPlans, userId } = props;
  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);
  const [planId, setPlanId] = useState(7);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    toast.success('Plan added');
    handleClose();
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error adding the plan.',
    );

    toast.error(displayedError.message);
    handleClose();
  };

  const addParentPlan = () =>
    new Promise((resolve, reject) => {
      const newPlanData = {
        planId,
        parentId: userId,
        userId,
      };

      ParentPlanService.create(newPlanData).then(resolve).catch(reject);
    });

  const addNewPlan = async () => {
    try {
      await addParentPlan();
      refreshPlans();
      handleSuccess();
    } catch (e) {
      refreshPlans();
      showError(e);
    }
  };

  const updatePlanId = event => {
    setPlanId(event.target.value);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        id='openModal'
        variant='contained'
        color='primary'
        onClick={showModal}
      >
        Add Plan
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='background-check-modal-title'>Add Plan</h2>
            <FormLabel component='legend'>Select Plan to Add</FormLabel>
            <RadioGroup
              aria-label='Plan'
              name='plan'
              value={`${planId}`}
              onChange={updatePlanId}
            >
              <FormControlLabel
                value='19'
                control={<Radio />}
                label='One Month Access Pass'
              />
              <FormControlLabel
                value='7'
                control={<Radio />}
                label='Monthly Plan 19.95'
              />
              <FormControlLabel
                value='22'
                control={<Radio />}
                label='Monthly Plan 34.95'
              />
              <FormControlLabel
                value='23'
                control={<Radio />}
                label='Quarterly Plan'
              />
              <FormControlLabel
                value='5'
                control={<Radio />}
                label='Yearly Plan'
              />
            </RadioGroup>
            <br /> <br />
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Close
            </Button>{' '}
            <Button
              id='save'
              variant='contained'
              color='primary'
              onClick={addNewPlan}
            >
              Add
            </Button>{' '}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddParentPlanModal;
