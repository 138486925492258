import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { SitterRateSuggestion } from '../types';

const URL = '/v2/sitter-rate-suggestion';

class SitterRateSuggestionService {
  static get(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterRateSuggestion>> {
    return axios.get(URL, config);
  }
}

export { SitterRateSuggestionService };
