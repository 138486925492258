import React from 'react';
import { Container, CssBaseline, Grid } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { AuthenticationService, UserService } from 'us-web-services';
import { GoogleRecaptcha } from './common';
import useAuthentication from '../store/useAuthentication';
import { ReactComponent as Logo } from '../img/logo_color.svg';

export const Login = (props: RouteComponentProps) => {
  const authenticationActions = useAuthentication()[1];
  const { history, location } = props;

  const authenticate = async token => {
    authenticationActions.authentication.set({});
    const authenticationResponse = await AuthenticationService.admin({
      token,
    });
    const authenticationData = authenticationResponse.data.data;

    authenticationActions.authentication.setToken(authenticationData.token);
    if (authenticationData.refreshToken) {
      authenticationActions.authentication.setRefreshToken(
        authenticationData.refreshToken.token,
      );
    }

    const userConfig = {
      params: {
        fields: 'superUser',
      },
    };

    try {
      const userResponse = await UserService.get(authenticationData.id, userConfig);
      const userData = userResponse.data.data;

      if (userData.superUser) {
        authenticationActions.authentication.setUser(userData);
        if (location.pathname === '/') {
          history.push('/users/lookup');
        }
      } else {
        throw new Error('Access Denied');
      }
    } catch (e) {
      authenticationActions.authentication.set({});
      toast.error('Login failure');
    }
  };

  const afterSignIn = async credentialResponse => {
    try {
      await authenticate(credentialResponse.credential);
    } catch (e) {
      if (e.response?.data?.errors?.length) {
        toast.error(e.response.data.errors[0].message);
      } else {
        toast.error('Authentication failure.');
      }
    }
  };

  const onGoogleError = (): void => {
    toast.error('Google Login failed.');
  };

  const onGoogleLogin = async credentialResponse => {
    await afterSignIn(credentialResponse);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <GoogleRecaptcha />
      <CssBaseline />
      <div>
        <Grid
          container
          alignItems='center'
          direction='column'
          justifyContent='center'
          style={{ marginBottom: 30 }}
        >
          <Logo />
        </Grid>
        <GoogleLogin
          onSuccess={onGoogleLogin}
          onError={onGoogleError}
          shape='pill'
          size='large'
          theme='outline'
          width={210}
          use_fedcm_for_prompt
        />
      </div>
    </Container>
  );
};

export default withRouter(Login);
