import { Paper } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Company, CompanyDomainService } from 'us-web-services';
import { CreateModal, Table, TableInstance } from 'src/components/common/Table';
import PageStyles from 'src/styles/PageStyles';

interface Props {
  company: Company;
}

function CompanyDomains(props: Props) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { company } = props;
  const { classes } = PageStyles();

  const tableRef = React.useRef<TableInstance>();

  const refreshDomains = () => tableRef.current?.fetchData();

  const addDomain = async rowData => {
    const domainData = {
      companyId: company.id,
      name: rowData.name,
    };

    CompanyDomainService.create(domainData).then(refreshDomains);
  };

  const deleteDomain = async rowData => {
    CompanyDomainService.delete(rowData.id).then(refreshDomains);
  };

  const updateDomain = async ({ row, values }) => {
    await CompanyDomainService.patch(row.original.id, {
      id: row.original.id,
      name: values.name,
    });
    refreshDomains();
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'domains',
        header: 'Domains',
        columns: [
          {
            header: 'Domain',
            accessorKey: 'name',
            size: 400,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Paper className={classes.paper}>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={CompanyDomainService.getByFilter}
        params={() => [
          {
            key: 'companyId',
            value: company.id.toString(),
          },
        ]}
        alwaysApplyParams
        addAction={() => setCreateModalOpen(true)}
        deleteAction={deleteDomain}
        editAction={updateDomain}
        enableInlineEditing
        hideSearchBar
      />
      <CreateModal
        columns={COLUMNS}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={addDomain}
        title='Add Domain'
        buttonText='Add'
      />
    </Paper>
  );
}

export default CompanyDomains;
