import { useEffect } from 'react';

import { Company, Parent, Sitter, User } from 'us-web-services';

function setState(store, newState, afterUpdateCallback) {
  store.state = {
    ...store.state,
    ...newState,
  };
  store.listeners.forEach(listener => {
    listener.run(store.state);
  });
  afterUpdateCallback && afterUpdateCallback(); // eslint-disable-line
}

function useCustom(store, React, mapState, mapActions) {
  const [, originalHook] = React.useState(Object.create(null));
  const state = mapState ? mapState(store.state) : store.state;
  const actions = React.useMemo(
    () => (mapActions ? mapActions(store.actions) : store.actions),
    [mapActions, store.actions],
  );

  useEffect(() => {
    const newListener = {
      oldState: {},
      run: originalHook,
    };

    const updateMappedState = newState => {
      const mappedState = mapState(newState);

      if (mappedState !== newListener.oldState) {
        newListener.oldState = mappedState;
        originalHook(mappedState);
      }
    };

    if (mapState) {
      newListener.run = updateMappedState({});
    }

    store.listeners.push(newListener);
    newListener.run(store.state);

    return () => {
      store.listeners = store.listeners.filter(
        listener => listener !== newListener,
      );
    };
  }, []);

  return [state, actions];
}

function associateActions(store, actions) {
  const associatedActions = {};

  Object.keys(actions).forEach(key => {
    if (typeof actions[key] === 'function') {
      associatedActions[key] = actions[key].bind(null, store);
    }
    if (typeof actions[key] === 'object') {
      associatedActions[key] = associateActions(store, actions[key]);
    }
  });

  return associatedActions;
}

const useStore = (
  React: typeof import('react'),
  initialState: {
    authentication?: {
      isAuthenticated: boolean;
      user: User;
      token: string;
      refreshToken: string;
    };
    company?: Company;
    user?: User;
    profile?: Parent | Sitter;
  },
  actions: {
    authentication?: typeof import('./actions/authentication');
    default?: any;
    set?: (store: any, company: Company) => void;
    setUser?: (store: any, user: User) => void;
    setProfile?: (store: any, profile: Parent | Sitter) => void;
  },
  initializer?: (arg0: {
    state: any;
    listeners: any[];
    actions: any;
    setState: any;
  }) => void,
) => {
  const store = {
    state: initialState,
    listeners: [],
    actions: undefined,
    setState: undefined,
  };

  store.setState = setState.bind(null, store);
  store.actions = associateActions(store, actions);
  if (initializer) initializer(store);

  return useCustom.bind(null, store, React);
};

export default useStore;
