import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';

import { Location, LocationService, School } from 'us-web-services';

interface Props {
  school: School;
  setSchool: (school: School) => void;
}

export default function SchoolEditForm(props: Props) {
  const { school, setSchool } = props;

  const [name, setName] = useState(school.name);
  const [alias, setAlias] = useState(school.alias);
  const [location, setLocation] = useState<Location>(school.location);
  const [locationInput, setLocationInput] = useState(null);

  const getLocations = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (locationInput) {
      contentConfig.params.name = locationInput;
    }

    const locationsResponse = (await LocationService.getByFilter(contentConfig)).data;
    const locationsData = locationsResponse.data;
    const locationOptions = [];

    locationsData.map(locationData =>
      locationOptions.push({
        id: locationData.id,
        name: locationData.name,
        value: locationData.id,
        label: `${locationData.name}, ${locationData.state.code}`,
        state: locationData.state,
        region: locationData.region,
      }),
    );

    return locationOptions;
  };

  const updateName = event => {
    setName(event.target.value);
    school.name = event.target.value;
    setSchool(school);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
    school.alias = event.target.value;
    setSchool(school);
  };

  const updateLocationInput = newValue => {
    setLocationInput(newValue);
  };

  const updateLocation = value => {
    setLocation(value);
    school.location = value;
    setSchool(school);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          onChange={updateName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='alias'
          name='alias'
          label='Alias'
          fullWidth
          value={alias}
          onChange={updateAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          Location
        </Typography>
        <AsyncSelect
          id='location'
          required
          loadOptions={getLocations}
          defaultOptions
          defaultInputValue={`${location.name}, ${location.state.code}`}
          onInputChange={updateLocationInput}
          onChange={updateLocation}
        />
      </Grid>
    </Grid>
  );
}
