import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { DuplicateSitterService, User, UserService } from 'us-web-services';
import ConfirmDialog from '../common/ConfirmDialog';
import DisplayService from '../../util/DisplayService';
import PageStyles from '../../styles/PageStyles';

function DuplicateSitters() {
  const { classes } = PageStyles();

  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [transferFromId, setTransferFromId] = useState(0);
  const [transferToId, setTransferToId] = useState(0);
  const [zendeskTicket, setZendeskTicket] = useState('');

  const [transferFromUser, setTransferFromUser] = useState<User>({});
  const [transferToUser, setTransferToUser] = useState<User>({});

  const updateTransferFrom = event => {
    setTransferFromId(event.target.value);
  };

  const updateTransferTo = event => {
    setTransferToId(event.target.value);
  };

  const updateZendeskTicket = event => {
    setZendeskTicket(event.target.value);
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an unexpected error fetching the user.',
    );

    toast.error(displayedError.message);
  };

  const clear = () => {
    setTransferFromId(0);
    setTransferToId(0);
    setTransferToUser({});
    setTransferFromUser({});
    setZendeskTicket('');
  };

  const fetchSitters = async () => {
    UserService.get(transferFromId)
      .then(response => {
        const user = response.data.data;

        if (user.sitter) {
          setTransferFromUser(response.data.data);
        } else {
          toast.error(`User ${transferFromId} is not a sitter`);
          setTransferFromUser({});
        }
      })
      .catch(showError);

    UserService.get(transferToId)
      .then(response => {
        const user = response.data.data;

        if (user.sitter) {
          setTransferToUser(response.data.data);
        } else {
          toast.error(`User ${transferToId} is not a sitter`);
          setTransferToUser({});
        }
      })
      .catch(showError);
  };

  const openDialog = () => {
    setOpenTransferDialog(true);
  };

  const transferSitter = () => {
    const data = {
      transferFrom: transferFromId,
      transferTo: transferToId,
      zendeskTicket,
    };

    DuplicateSitterService.transferSitter(data)
      .then(() => {
        toast.success(
          `Transfer from ${transferFromId} to ${transferToId} completed`,
        );
        setOpenTransferDialog(false);
      })
      .catch(showError);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant='h6' gutterBottom>Resolve duplicate sitters</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id='transferFromId'
              label='Transfer From'
              fullWidth
              value={transferFromId}
              onChange={updateTransferFrom}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id='transferToId'
              label='Transfer To'
              fullWidth
              value={transferToId}
              onChange={updateTransferTo}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id='zendeskTicket'
              label='Zendesk Ticket'
              fullWidth
              value={zendeskTicket}
              onChange={updateZendeskTicket}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant='contained'
            color='secondary'
            className={classes.button}
            onClick={clear}
          >
            Clear
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!transferFromId || !transferToId}
            onClick={fetchSitters}
            className={classes.button}
          >
            Fetch Sitters
          </Button>
        </div>
      </Paper>
      {transferFromUser.id && transferToUser.id ? (
        <Paper className={classes.paper}>
          <Typography variant='h6' gutterBottom>Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              Transfer from: <br />
              User ID: {transferFromUser.id} <br />
              User Name: {transferFromUser.name}
            </Grid>
            <Grid item xs={12} sm={6}>
              Transfer to: <br />
              User ID: {transferToUser.id} <br />
              User Name: {transferToUser.name}
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={openDialog}
            >
              Transfer Sitter
            </Button>
          </div>
          <ConfirmDialog
            confirmAction={transferSitter}
            open={openTransferDialog}
            setOpen={setOpenTransferDialog}
            title='Confirm Transfer Sitter'
            text={`Are you sure you want to transfer ${transferFromUser.id} to ${transferToUser.id}?
              Remember to add a voucher to ${transferToUser.id} so they aren't charged for the membership.`}
          />
        </Paper>
      ) : (
        ''
      )}
    </>
  );
}

export default withRouter(DuplicateSitters);
