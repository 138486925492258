import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PageStyles from '../../../../styles/PageStyles';

function VoucherDeactivateConfirm(props: Props) {
  const { classes } = PageStyles();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleClose = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div>
      <Button
        variant='outlined'
        onClick={handleClickOpen}
        className={classes.button}
      >
        Deactivate
      </Button>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confirm Voucher Deactivation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to deactivate this voucher? This will also
            permanently deactivate all user vouchers for this voucher as well.
            You cannot undo this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onConfirm} color='primary'>
            Confirm
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VoucherDeactivateConfirm;

interface Props {
  onConfirm: () => void;
}
