import React, { useState } from 'react';
import {
  Button,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { DatePicker } from '@mui/x-date-pickers';
import FormLabel from '@mui/material/FormLabel';

import { BackgroundCheck, BackgroundCheckService, CheckrRequestService } from 'us-web-services';
import { BackgroundCheckConstants } from '../../constants';
import ModalStyles from '../../styles/ModalStyles';
import { DateUtil } from '../../util';

interface Props {
  backgroundCheck: BackgroundCheck;
  setBackgroundCheck(backgroundCheck: BackgroundCheck): void;
  userId: number;
}

function EditBackgroundCheck(props: Props) {
  const { backgroundCheck, setBackgroundCheck, userId } = props;

  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(backgroundCheck.status);
  const [candidateId, setCandidateId] = useState(
    backgroundCheck?.checkrRequest ? backgroundCheck.checkrRequest.candidateId : '',
  );
  const [completedDate, setCompletedDate] = useState(
    backgroundCheck.completed ? moment(backgroundCheck.completed) : null,
  );
  const [expirationDate, setExpirationDate] = useState(
    backgroundCheck.expiration ? moment(backgroundCheck.expiration) : null,
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateStatus = async event => {
    const statuses = Object.values(BackgroundCheckConstants.STATUSES);

    for (let i = 0; i < statuses.length; i += 1) {
      if (statuses[i].id === parseInt(event.target.value, 10)) {
        setStatus(statuses[i]);
        break;
      }
    }
  };

  const createCheckrRequest = async savedBackgroundCheck => {
    const checkrRequestResponse = (await CheckrRequestService.create({
      userId,
      candidateId,
      reportStatus: 1,
    })).data;

    savedBackgroundCheck.checkrRequest = checkrRequestResponse.data;
    setBackgroundCheck(savedBackgroundCheck);
  };

  const updateBackgroundCheck = async backgroundCheckData => {
    const { checkrRequest } = backgroundCheck;

    try {
      backgroundCheckData.id = backgroundCheck.id;
      const response = (await BackgroundCheckService.update(
        backgroundCheck.id,
        backgroundCheckData,
      )).data;
      const { data } = response;

      data.checkrRequest = checkrRequest;

      if (checkrRequest?.id) {
        const checkrId = checkrRequest.id;
        const checkrRequestResponse = (await CheckrRequestService.update(
          checkrId,
          {
            id: checkrId,
            candidateId,
            reportStatus: 1,
          },
        )).data;

        data.checkrRequest = checkrRequestResponse.data;
      } else if (candidateId) {
        await createCheckrRequest(data);
      }

      setBackgroundCheck(data);
    } catch (error) {
      toast.error('There was an error updating the background check.');
    }
  };

  const createBackgroundCheck = async backgroundCheckData => {
    backgroundCheckData.sitterId = userId;

    try {
      const response = (await BackgroundCheckService.create(backgroundCheckData)).data;

      if (candidateId) {
        await createCheckrRequest(response.data);
      }

      setBackgroundCheck(response.data);
    } catch (error) {
      toast.error('There was an error creating the background check.');
    }
  };

  const saveBackgroundCheck = async () => {
    const backgroundCheckData = {
      expiration: expirationDate ? expirationDate.format(DateUtil.midnightDateFormat) : undefined,
      completed: completedDate ? completedDate.format(DateUtil.midnightDateFormat) : undefined,
      status,
    };

    if (backgroundCheck.id) {
      await updateBackgroundCheck(backgroundCheckData);
    } else {
      await createBackgroundCheck(backgroundCheckData);
    }
    handleClose();
  };

  const updateCandidateId = event => {
    setCandidateId(event.target.value);
  };

  const getTitle = () =>
    backgroundCheck.id ? 'Edit Background Check' : 'Add Background Check';

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        id='openModal'
        variant='contained'
        color='primary'
        onClick={showModal}
      >
        {getTitle()}
      </Button>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='background-check-modal-title'>{getTitle()}</h2>
            <FormLabel>Status</FormLabel>
            <br />
            <RadioGroup
              aria-label='Status'
              name='status'
              value={`${status ? status.id : ''}`}
              onChange={updateStatus}
            >
              {Object.values(BackgroundCheckConstants.STATUSES).map(
                backgroundCheckStatus => (
                  <FormControlLabel
                    key={backgroundCheckStatus.id}
                    value={`${backgroundCheckStatus.id}`}
                    control={<Radio />}
                    label={backgroundCheckStatus.label}
                  />
                ),
              )}
            </RadioGroup>
            <br />
            <FormLabel component='legend'>Completed Date</FormLabel>
            <br />
            <DatePicker
              value={completedDate}
              onChange={setCompletedDate}
              inputFormat={DateUtil.dateReadable}
              renderInput={pickerProps => <TextField {...pickerProps} />}
            />
            <br /> <br />
            <FormLabel component='legend'>Expiration Date</FormLabel>
            <br />
            <DatePicker
              value={expirationDate}
              onChange={setExpirationDate}
              inputFormat={DateUtil.dateReadable}
              renderInput={pickerProps => <TextField {...pickerProps} />}
            />
            <br /> <br />
            <FormLabel component='legend'>Candidate ID</FormLabel>
            <br />
            <TextField
              id='candidateId'
              name='candidateId'
              label='Candidate ID'
              fullWidth
              value={candidateId}
              onChange={updateCandidateId}
            />
            <br /> <br />
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Close
            </Button>{' '}
            <Button
              id='save'
              variant='contained'
              color='primary'
              onClick={saveBackgroundCheck}
            >
              Save
            </Button>{' '}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default EditBackgroundCheck;
