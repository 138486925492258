import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MasterServiceAreaPlanPriceService } from 'us-web-services';
import DisplayService from '../../util/DisplayService';

import { Table, TableInstance } from '../common/Table';

interface Props extends RouteComponentProps {
  masterServiceAreaId?: number;
}

function MasterServiceAreaPlanPrices(props: Props) {
  const { history, masterServiceAreaId } = props;
  const tableRef = React.useRef<TableInstance>();

  const onDeleteError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error removing the plan price.',
    );

    toast.error(displayedError.message);
  };

  const onDeleteSuccess = () => {
    toast.success('Plan price removed successfully');
    tableRef.current?.fetchData();
  };

  const deleteRowPlanPrice = async rowPlanPrice => {
    MasterServiceAreaPlanPriceService.delete(rowPlanPrice.id)
      .then(onDeleteSuccess)
      .catch(onDeleteError);
  };

  const addPlanPrice = () => {
    history.push(`/master-service-areas/${masterServiceAreaId}/price-create`);
  };

  const editPlanPrice = planPrice => {
    history.push(
      `/master-service-areas/${masterServiceAreaId}/prices/${planPrice.id}`,
    );
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'planPrices',
        header: 'Plan Prices',
        columns: [
          {
            header: 'Plan name',
            accessorKey: 'plan.id',
            accessorFn: row => `${row.plan.id} - ${row.plan.name}`,
          },
          {
            header: 'Frequency',
            accessorFn: row => row.plan.frequencyid,
          },
          {
            header: 'Recurring',
            accessorFn: row => row.plan.recurring,
          },
          {
            header: 'Setup Fee',
            accessorKey: 'setupFee',
          },
          {
            header: 'Installment Fee',
            accessorKey: 'installmentFee',
          },
          {
            header: 'Label',
            accessorKey: 'label',
          },
          {
            header: 'Description',
            accessorKey: 'description',
          },
          {
            header: 'Testing',
            accessorFn: row => (row.masterServiceAreaPriceTest ? 'Yes' : 'No'),
          },
          {
            header: 'Control Setup Fee',
            accessorFn: row => row.masterServiceAreaPriceTest?.controlSetupFee,
          },
          {
            header: 'Control Installment Fee',
            accessorFn: row =>
              row.masterServiceAreaPriceTest?.controlInstallmentFee,
          },
        ],
      },
    ],
    [],
  );

  const tableParams = () => [
    {
      key: 'masterServiceAreaId',
      value: masterServiceAreaId.toString(),
    },
  ];

  return (
    <Table
      ref={tableRef}
      columns={COLUMNS}
      retrieveData={MasterServiceAreaPlanPriceService.getByFilter}
      params={tableParams}
      alwaysApplyParams
      editAction={editPlanPrice}
      deleteAction={deleteRowPlanPrice}
      addAction={addPlanPrice}
    />
  );
}

export default withRouter(MasterServiceAreaPlanPrices);
