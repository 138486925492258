import axios, { AxiosRequestConfig } from 'axios';

import { DataResponse } from '../types/api';
import { SitterPaymentSettings } from '../types';

const URL = '/v2/sitter-payment-settings';

class SitterPaymentSettingsService {
  static create(config?: AxiosRequestConfig): Promise<DataResponse<SitterPaymentSettings>> {
    return axios.post(URL, config);
  }

  static findById(id: number, config?: AxiosRequestConfig): Promise<DataResponse<SitterPaymentSettings>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static patch(
    id: number,
    data: SitterPaymentSettings,
    config?: AxiosRequestConfig): Promise<DataResponse<SitterPaymentSettings>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { SitterPaymentSettingsService };
