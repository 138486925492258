import { AvailabilityStatus } from './AvailabilityStatus';
import { JobPreference } from './JobPreference';
import { Language } from './Language';
import { Profile } from './Profile';
import { Relationship } from './Relationship';
import { SitterExperience } from './SitterExperience';
import { SitterRate } from './SitterRate';
import { SpecialExperience } from './SpecialExperience';
import { SpecialService } from './SpecialService';
import { SpecialSituation } from './SpecialSituation';
import { TeachingSkill } from './TeachingSkill';

export interface Sitter extends Profile {
  about?: string;
  acceptsCreditCards?: boolean;
  availabilityStatus?: AvailabilityStatus;
  backgroundCheck?: boolean;
  certifications?: Array<any>;
  experiences?: SitterExperience;
  hiddenReason?: number;
  homeSittingExperience?: string;
  jobPreferences?: JobPreference[];
  languages?: Language[];
  petSittingExperience?: string;
  rates?: SitterRate[];
  rating?: number;
  relationship?: Relationship;
  repeatFamilies?: number;
  responseTime?: number;
  reviewCount?: number;
  seniorSittingExperience?: string;
  specialExperiences?: SpecialExperience[];
  specialServices?: SpecialService[];
  specialSituations?: SpecialSituation[];
  teachingSkills?: TeachingSkill[];
  /** @deprecated @see vimeoId */
  videoId?: string;
  vimeoId?: string;
  willSitCat?: boolean;
  willSitDog?: boolean;
  willSitHome?: boolean;
  willSitOtherPet?: boolean;
  willSitSenior?: boolean;
}

export enum WillSit {
  cat = 'Cat',
  dog = 'Dog',
  home = 'Home',
  otherPets = 'OtherPet',
  senior = 'Senior',
};

export type WillSitKey = keyof typeof WillSit;
