import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Fade, Modal } from '@mui/material';
import ModalStyles from '../../styles/ModalStyles';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  url: string;
}

function VideoModal(props: Props) {
  const { open, setOpen, url } = props;

  const showControls = true;
  const { classes } = ModalStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby='video-modal'
      aria-describedby='View sitter video'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id='video-modal'>Sitter Video</h2>
          <ReactPlayer url={url} controls={showControls} />
        </div>
      </Fade>
    </Modal>
  );
}

export default VideoModal;
