import axios, { AxiosRequestConfig } from 'axios';
import { MailchimpMetadata } from '../types/MailchimpMetadata';
import { DataResponse } from '../types/api';

const URL = '/v2/mailchimp-metadata';

class MailchimpMetadataService {
  static create(
    data: MailchimpMetadata,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MailchimpMetadata>> {
    return axios.post(URL, data, config);
  }
}

export { MailchimpMetadataService };
