import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { Location } from '../types/Location';

const LOCATION_URL = '/v2/locations';

class LocationService {
  static findById(
    id: any,
    config: AxiosRequestConfig
  ): Promise<DataResponse<Location>> {
    return axios.get(`${LOCATION_URL}/${id}`, config);
  }

  static getByFilter(
    config: AxiosRequestConfig
  ): Promise<PagedResponse<Location>> {
    return axios.get(`${LOCATION_URL}`, config);
  }
}

export { LocationService };
