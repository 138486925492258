import React, { useState } from 'react';
import { Button } from '@mui/material';
import SitterReviews from './SitterReviews';
import useUser from '../../store/useUser';
import ParentReviews from './ParentReviews';

function ReviewLookup() {
  const userState = useUser()[0];
  const [parentReviews, setParentReviews] = useState(false);

  return (
    <>
      <div style={{ margin: '40px 16px' }}>
        <div style={{ marginBottom: 20 }}>
          <Button
            size='small'
            variant={!parentReviews ? 'outlined' : 'contained'}
            color='secondary'
            onClick={() => setParentReviews(false)}
            style={{ marginRight: 10 }}
          >
            {userState.user.parent ? 'Reviews of Sitters' : "Sitter's Reviews"}
          </Button>
          <Button
            size='small'
            variant={parentReviews ? 'outlined' : 'contained'}
            color='secondary'
            onClick={() => setParentReviews(true)}
            style={{ marginRight: 10 }}
          >
            {userState.user.parent ? "Parent's Reviews" : 'Reviews of Parents'}
          </Button>
        </div>
      </div>
      {parentReviews ? <ParentReviews /> : <SitterReviews />}
    </>
  );
}

export default ReviewLookup;
