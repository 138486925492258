import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { CheckrPayoutMethod } from '../../types/checkrpay/CheckrPayoutMethod';

const URL = '/v2/checkr-payout-methods';

class CheckrPayoutMethodService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CheckrPayoutMethod>> {
    return axios.get(URL, config);
  }
  static create(
    data: CheckrPayoutMethod,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CheckrPayoutMethod>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CheckrPayoutMethodService };
