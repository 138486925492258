import axios, { AxiosRequestConfig } from 'axios';
import { ParentAffiliation } from '../types/ParentAffiliation';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/parent-affiliations';

class ParentAffiliationService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ParentAffiliation>> {
    return axios.get(`${URL}`, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static create(
    data: ParentAffiliation,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentAffiliation>> {
    return axios.post(URL, data, config);
  }

  static patch(
    id: number,
    data: ParentAffiliation,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentAffiliation>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { ParentAffiliationService };
