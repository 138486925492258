import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { VoucherCodeService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function VoucherLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'vouchers',
        header: 'Vouchers',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Code',
            accessorKey: 'code',
          },
          {
            header: 'Value',
            accessorFn: row =>
              row.percentDiscount ? `${row.value}%` : `$${row.value}`,
          },
          {
            header: 'Is Active',
            accessorFn: row => row.active ? 'True' : 'False',
            enableEditing: false,
          },
          {
            header: 'Description',
            accessorKey: 'description',
          },
        ],
      },
    ],
    [],
  );

  const addVoucher = () => {
    history.push('/voucher-create');
  };

  const editVoucher = voucher => {
    history.push(`/vouchers/${voucher.id}`);
  };

  const params = (globalFilter: string) => [
    {
      key: 'code',
      value: globalFilter,
    },
    {
      key: 'sort',
      value: 'id',
    },
    {
      key: 'id.dir',
      value: 'desc',
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={VoucherCodeService.getByFilter}
        params={params}
        alwaysApplyParams
        editAction={editVoucher}
        addAction={addVoucher}
      />
    </Paper>
  );
}

export default withRouter(VoucherLookup);
