import React from 'react';

class GoogleRecaptcha extends React.Component {
  componentDidMount() {
    const recaptchaApi = document.createElement('script');

    recaptchaApi.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;

    document.body.appendChild(recaptchaApi);
  }

  render() {
    return '';
  }
}

export default GoogleRecaptcha;
