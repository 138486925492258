import React, { useEffect, useState } from 'react';
import {
  Button,
  ClassNameMap,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { RouteComponentProps } from 'react-router-dom';

import { AdminCheckrExternalPayout, AdminCheckrPayExternalPayoutService } from 'us-web-services';
import useAuthentication from '../../store/useAuthentication';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: '5px auto auto 0',
    overflow: 'hidden',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '20px 16px',
    textAlign: 'center' as const,
  },
});

function OrderPayout(props: RouteComponentProps & { classes: ClassNameMap }) {
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const { classes, location } = props;
  const searchParams = queryString.parse(location.search);
  const [showConfirm, setShowConfirm] = useState(false);
  const [payout, setPayout] = useState<AdminCheckrExternalPayout>();
  const [amount, setAmount] = useState<number>();
  const [bookingId, setBookingId] = useState<number>();
  const [orderId, setOrderId] = useState<number>();
  const [payeeId, setPayeeId] = useState<number>();
  const [isSendingPayout, setIsSendingPayout] = useState(false);

  const getSearchBookingId = () => searchParams?.bookingId
    ? Number.parseInt(searchParams.bookingId.toString(), 10)
    : null;

  const getSearchOrderId = () => searchParams?.orderId
    ? Number.parseInt(searchParams.orderId.toString(), 10)
    : null;

  const getSearchPayeeId = () => searchParams?.payeeId
    ? Number.parseInt(searchParams.payeeId.toString(), 10)
    : null;

  useEffect(() => {
    const searchBookingId = getSearchBookingId();
    const searchOrderId = getSearchOrderId();
    const searchPayeeId = getSearchPayeeId();

    if (searchBookingId && typeof bookingId === 'undefined') {
      setBookingId(searchBookingId);
    }

    if (searchOrderId && typeof orderId === 'undefined') {
      setOrderId(searchOrderId);
    }

    if (searchPayeeId && typeof payeeId === 'undefined') {
      setPayeeId(searchPayeeId);
    }
  }, [authenticationState.authentication, searchParams]);

  const closeConfirmDialog = () => {
    setShowConfirm(false);
    setPayout({});
  };

  const confirmPayout = async () => {
    setPayout({
      amount,
      bookingId,
      orderId,
      payeeId,
    });

    setShowConfirm(true);
  };

  const doSendPayout = async () => {
    try {
      setIsSendingPayout(true);
      await AdminCheckrPayExternalPayoutService.create(payout);
      setAmount(0);
      setBookingId(null);
      setOrderId(null);
      setPayeeId(null);
      closeConfirmDialog();
      setIsSendingPayout(false);
      toast.success('Payout sent successfully.');
    } catch (error) {
      closeConfirmDialog();
      setIsSendingPayout(false);
      toast.error('There was an error sending the payout.');
    }
  };

  const isPaymentAdmin = authenticatedUser.roles.filter(role => role.code === 'PAYMENT_ADMIN').length > 0;

  const updateAmount = (event) => {
    const MAX_AMOUNT = 1000;

    if (event.target.value > MAX_AMOUNT) {
      toast.error(`Amount cannot exceed ${MAX_AMOUNT}`);
    } else {
      setAmount(event.target.value);
    }
  };

  const updatePayeeId = (event) => {
    setPayeeId(event.target.value);
  };

  const updateBookingId = (event) => {
    setBookingId(event.target.value);
  };

  const updateOrderId = (event) => {
    setOrderId(event.target.value);
  };

  return (
    <>
      <Typography variant='h5' gutterBottom>
        Send CheckrPay Payout
      </Typography>
      <p>
        Immediately sends a custom CheckrPay Payout to a caregiver. Only use
        in special cases, to reconcile incorrect or missing orders.
      </p>
      <Dialog open={showConfirm} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Payout</DialogTitle>
        <DialogContent>
          Are you sure you want to send a CheckrPay Payout for ${payout?.amount}? This cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={doSendPayout}
            disabled={isSendingPayout}
            color='primary'
            autoFocus
          >
            Send Now
          </Button>
        </DialogActions>
      </Dialog>
      { isPaymentAdmin ?
        <Paper className={classes.paper}>
          <div className={classes.contentWrapper}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='payeeId'
                  label='Payee User ID'
                  onChange={updatePayeeId}
                  required
                  type='number'
                  value={payeeId}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='amount'
                  label='Amount'
                  onChange={updateAmount}
                  required
                  type='number'
                  value={amount}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='bookingId'
                  label='Booking ID'
                  type='number'
                  onChange={updateBookingId}
                  value={bookingId}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id='orderId'
                  label='Order ID'
                  onChange={updateOrderId}
                  type='number'
                  value={orderId}
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={confirmPayout} disabled={!amount || !payeeId || isSendingPayout}>
                  Send Payout
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
        :
        <Typography variant='h6' gutterBottom>
          You do not have permission to use this tool.
        </Typography>
      }
    </>
  );
}

export default withStyles(OrderPayout, styles);
