import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import PageStyles from 'src/styles/PageStyles';
import { States } from '../../constants';
import { Table } from '../common/Table';

function StateLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'states',
        header: 'States',
        columns: [
          {
            header: 'Code',
            accessorKey: 'code',
          },
          {
            header: 'Name',
            accessorKey: 'label',
          },
        ],
      },
    ],
    [],
  );

  const editState = rowData => {
    history.push(`/seo/states/${rowData.code}`);
  };

  const getStates = () => {
    const response = {
      data: {
        data: States,
        meta: States.length,
      },
    };

    return Promise.resolve(response);
  };

  return (
    <Paper className={classes.paper}>
      <Table columns={COLUMNS} retrieveData={getStates} editAction={editState} />
    </Paper>
  );
}

export default withRouter(StateLookup);
