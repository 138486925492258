import React from 'react';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { ClassNameMap, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const styles = (theme, _params, classes) => ({
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    [`&.${classes.textDense}`]: {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
});

interface Props {
  classes?: ClassNameMap;
  icon: JSX.Element;
  text: string;
  url: string;
}

function ExternalNavigationItem(props: Props) {
  const { classes, icon, text, url } = props;

  return (
    <a key={text} href={url} target='_blank' rel='noopener noreferrer'>
      <ListItem
        button
        dense
        className={classNames(classes.item, classes.itemActionable)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
          }}
        >
          {text}
        </ListItemText>
      </ListItem>
    </a>
  );
}

export default withStyles(ExternalNavigationItem, styles);
