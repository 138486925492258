import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { UserVerificationLogService, UserVerificationService, Verification } from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import DateUtil from '../../util/DateUtil';
import IdentityCheckEdit from './IdentityCheckEdit';

interface Props {
  userId: number;
}

function IdentityCheck(props: Props) {
  const { userId } = props;
  const { classes } = CardStyles();
  const [verification, setVerification] = useState<Verification>({});
  const [status, setStatus] = useState('');
  const [verificationLogs, setVerificationLogs] = useState<Verification[]>([]);
  const [loaded, setLoaded] = useState(false);

  const getVerification = useCallback(async () => {
    const verificationResponse = (await UserVerificationService.get(userId)).data;

    setStatus(verificationResponse.data.status);
    setVerification(verificationResponse.data);
    setLoaded(true);
  }, [userId]);

  const getVerificationLogs = useCallback(async () => {
    const logResponse = await UserVerificationLogService.findByFilter(
      `?userId=${userId}&limit=50&sort=updated&updated.dir=desc`,
    );

    setVerificationLogs(logResponse.data.data);
  }, [userId]);

  const getStatusTitle = () => {
    let statusTitle = 'Failed';

    if (status === 'pass') {
      statusTitle = 'Completed';
    } else if (status === 'incomplete') {
      statusTitle = 'Incomplete';
    }

    return statusTitle;
  };

  const onUpdate = () => {
    setStatus(verification.status);
    getStatusTitle();
    getVerificationLogs();
  };

  useEffect(() => {
    getVerification();
    getVerificationLogs();
  }, [getVerification, getVerificationLogs]);

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            Identity Check
          </Typography>
          <Typography variant='subtitle1' color='textPrimary'>
            Current Status: {getStatusTitle()}
            <br />
            Updated:
            {DateUtil.getReadableDateTime(verification.updated)}
            <br />
            Updated by: {verification.updatedBy}
            <br />
            Attempts: {verification.attempts}
            <br />
            {loaded && (
              <IdentityCheckEdit
                userId={userId}
                identityCheck={verification}
                setIdentityCheck={setVerification}
                onUpdate={onUpdate}
              />
            )}
            <br />
          </Typography>
        </CardContent>
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            Identity Check Logs
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Updated by</TableCell>
                <TableCell align='left'>Updated on</TableCell>
                <TableCell align='left'>Status updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {verificationLogs &&
                verificationLogs.map(log => (
                  <TableRow key={log.id}>
                    <TableCell align='left'>{log.updatedAgent}</TableCell>
                    <TableCell align='left'>
                      {DateUtil.getReadableDateTime(log.updated)}
                    </TableCell>
                    <TableCell align='left'>{log.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </div>
    </Card>
  );
}

export default IdentityCheck;
