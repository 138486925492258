import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { MasterServiceAreaTier, MasterServiceAreaTierService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import MasterServiceAreaTierForm from './MasterServiceAreaTierForm';

function MasterServiceAreaTierEdit(props) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [masterServiceAreaTier, setMasterServiceAreaTier] = useState<MasterServiceAreaTier>({});

  useEffect(() => {
    if (match.params.id) {
      const getData = async () => {
        const response = await MasterServiceAreaTierService.get(
          match.params.id,
        );

        if (response.data.data.id) {
          setMasterServiceAreaTier(response.data.data);
        } else {
          setMasterServiceAreaTier({
            id: null,
            name: '',
          });
        }
        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push('/master-service-area-tiers');
  };

  const saveSuccess = () => {
    toast.success('Master Service Area Tier updated successfully');
    navigateToList();
  };

  const saveMasterServiceAreaTier = async () => {
    try {
      if (masterServiceAreaTier.id) {
        await MasterServiceAreaTierService.patch(
          masterServiceAreaTier.id,
          masterServiceAreaTier,
        );
      } else {
        await MasterServiceAreaTierService.create(masterServiceAreaTier);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {masterServiceAreaTier.id ? 'Edit' : 'Add'} Master Service Area Tier
      </Typography>
      {loaded ? (
        <MasterServiceAreaTierForm
          masterServiceAreaTier={masterServiceAreaTier}
          setMasterServiceAreaTier={setMasterServiceAreaTier}
        />
      ) : (
        'Loading'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveMasterServiceAreaTier}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(MasterServiceAreaTierEdit);
