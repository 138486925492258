export default class HostsService {
  static getAppHost() {
    const { host } = window.location;

    if (host.indexOf('admin-') > -1) {
      return host.replace('admin-', '');
    }

    return 'www.urbansitter.com';
  }
}
