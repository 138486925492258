export default class DisplayService {
  static getErrorResponse(error, defaultMessage, showCode = true) {
    const errorResponse =
      error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors[0]
        : { code: 'unknown-error' };

    let message;

    if (errorResponse.code === 'unknown-error') {
      message = defaultMessage;
    } else if (showCode) {
      message = `Error: ${errorResponse.code} - ${errorResponse.message}`;
    } else {
      message = `Error: ${errorResponse.message}`;
    }

    return {
      message,
      appearance: 'error',
      autoDismiss: true,
    };
  }
}
