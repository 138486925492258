import React, { useState } from 'react';
import {
  Button,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import toast from 'react-hot-toast';

import { SitterPlanService } from 'us-web-services';
import ModalStyles from '../../../styles/ModalStyles';
import DisplayService from '../../../util/DisplayService';

interface Props {
  refreshPlans: () => void;
  userId: number;
}

function AddSitterPlanModal(props: Props) {
  const { refreshPlans, userId } = props;
  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);
  const [createBackgroundCheck, setCreateBackgroundCheck] = useState(true);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    toast.success('Plan added');
    handleClose();
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error adding the plan.',
    );

    toast.error(displayedError.message);
    handleClose();
  };

  const addSitterPlan = () =>
    new Promise((resolve, reject) => {
      const newPlanData = {
        planId: 25,
        sitterId: userId,
        createBackgroundCheck,
        userId,
      };

      SitterPlanService.create(newPlanData).then(resolve).catch(reject);
    });

  const addNewPlan = async () => {
    try {
      await addSitterPlan();
      refreshPlans();
      handleSuccess();
    } catch (e) {
      refreshPlans();
      showError(e);
    }
  };

  const updateCreateBackgroundCheck = event => {
    setCreateBackgroundCheck(event.target.value);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        id='openModal'
        variant='contained'
        color='primary'
        onClick={showModal}
      >
        Add Plan
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='background-check-modal-title'>Add Plan</h2>
            <FormLabel component='legend'>Create Checkr Invitation?</FormLabel>
            <RadioGroup
              aria-label='Status'
              name='status'
              value={`${createBackgroundCheck}`}
              onChange={updateCreateBackgroundCheck}
            >
              <FormControlLabel value='true' control={<Radio />} label='Yes' />
              <FormControlLabel value='false' control={<Radio />} label='No' />
            </RadioGroup>
            <br /> <br />
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Close
            </Button>{' '}
            <Button
              id='save'
              variant='contained'
              color='primary'
              onClick={addNewPlan}
            >
              Add
            </Button>{' '}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddSitterPlanModal;
