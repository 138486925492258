import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Company, CompanyService } from 'us-web-services';
import CompanyEditForm from './CompanyEditForm';
import CompanyBenefits from './CompanyBenefits';
import CompanyDomains from './CompanyDomains';
import PageStyles from '../../../styles/PageStyles';
import useCompany from '../../../store/useCompany';
import CompanySafelist from './CompanySafelist';
import CompanyEmployeeToggle from './CompanyEmployeeToggle';
import CompanyTerminate from './CompanyTerminate';
import CompanyCensus from './CompanyCensus/CompanyCensus';
import CompanyPartners from './CompanyPartners';

function CompanyEdit(props: RouteComponentProps<{ id: string }>) {
  const [companyState, companyActions] = useCompany();
  const [company, setCompany] = useState<Company>(companyState.company || {});
  const [isLoading, setIsLoading] = useState(true);

  const { classes } = PageStyles();
  const { match, history } = props;

  useEffect(() => {
    const companyId = Number.parseInt(match.params.id, 10);

    if (
      companyId > 0 &&
      (!company.id || companyId !== company.id)
    ) {
      const getCompany = async () => {
        try {
          const response = await CompanyService.get(companyId);
          const companyData = response.data.data;

          setCompany(companyData);
          setIsLoading(false);
        } catch (error) {
          setCompany({});
          setIsLoading(false);
        }
      };

      getCompany();
    } else {
      setIsLoading(false);
    }
  }, [company.id, setCompany, match.params.id]);

  const saveCompany = async () => {
    if (company.id > 0) {
      const companyData = {
        id: company.id,
        name: company.name,
        displayName: company.displayName,
        alias: company.alias,
        censusKey: company.censusKey,
        useExternalId: company.useExternalId,
        emailValidationEnabled: company.emailValidationEnabled,
      };

      const response = await CompanyService.patch(companyData.id, companyData);

      companyActions.set(response.data);
    } else {
      const companyData = {
        name: company.name,
        displayName: company.displayName,
        alias: company.alias,
        censusKey: company.censusKey,
        useExternalId: company.useExternalId,
        emailValidationEnabled: company.emailValidationEnabled,
      };

      const response = await CompanyService.create(companyData);

      companyActions.set(response.data);
    }

    toast.success('Company updated successfully');

    history.push(`/companies?id=${company.id}`);
  };

  const cancelEdit = async () => {
    history.push(`/companies?id=${company.id}`);
  };

  return (
    <>
      <Paper className={classes.paper}>
        {company.id && !isLoading && (
          <>
            <CompanyEditForm company={company} setCompany={setCompany} />
            <div className={classes.buttons}>
              <Button
                variant='contained'
                color='secondary'
                onClick={cancelEdit}
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={saveCompany}
                className={classes.button}
              >
                Save
              </Button>
            </div>
          </>
        )}
        {!company.id && (
          isLoading ? 'Loading...' : 'Company not found.'
        )}
      </Paper>
      {company.id && !isLoading && (
        <>
          <CompanyDomains company={company} />
          <CompanyBenefits company={company} />
          <CompanySafelist company={company} />
          <CompanyPartners company={company} />
          <CompanyEmployeeToggle company={company} />
          <CompanyCensus company={company} />
          <CompanyTerminate company={company} />
        </>
      )}
    </>
  );
}

export default withRouter(CompanyEdit);
