import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { BackgroundCheck } from '../types/BackgroundCheck';

const BACKGROUND_CHECKS_URL = '/v2/background-checks';
const BACKGROUND_CHECK_URL = '/v2/background-check-url';

class BackgroundCheckService {
  static findByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<BackgroundCheck>> {
    return axios.get(BACKGROUND_CHECKS_URL, config);
  }

  static create(
    data: BackgroundCheck,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BackgroundCheck>> {
    return axios.post(BACKGROUND_CHECKS_URL, data, config);
  }

  static createUrl(
    sitterId: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BackgroundCheck>> {
    return axios.post(BACKGROUND_CHECK_URL, { sitterId }, config);
  }

  static update(
    id: number,
    data: BackgroundCheck,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BackgroundCheck>> {
    return axios.patch(`${BACKGROUND_CHECKS_URL}/${id}`, data, config);
  }
}

export { BackgroundCheckService };
