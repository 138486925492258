import React, { useMemo } from 'react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Table } from 'src/components/common/Table';
import { Paper } from '@mui/material';
import { Company, CompanyBenefitService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import DateUtil from '../../../util/DateUtil';
import DisplayService from '../../../util/DisplayService';

interface Props extends RouteComponentProps {
  company?: Company;
}

function CompanyBenefits(props: Props) {
  const { company, history } = props;

  const { classes } = PageStyles();

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error with the benefit.',
    );

    toast.error(displayedError.message);
  };

  const deleteBenefit = async benefit => {
    CompanyBenefitService.delete(benefit.id).catch(showError);
  };

  const addBenefit = () => {
    history.push(`/company-benefit-create/${company.id}`);
  };

  const editBenefit = rowData => {
    history.push(`/company-benefits/${rowData.id}`);
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'benefits',
        header: 'Benefits',
        columns: [
          {
            header: 'Status',
            accessorFn: row =>
              row?.statusId === 2 ? 'Active' : 'Pending',
          },
          {
            header: 'Start',
            accessorFn: row =>
              DateUtil.getDateSlashesString(moment(row?.start)),
          },
          {
            header: 'End',
            accessorFn: row => DateUtil.getDateSlashesString(moment(row?.end)),
          },
          {
            header: 'Description',
            accessorKey: 'description',
          },
        ],
      },
    ],
    [],
  );

  const params = () => [
    {
      key: 'companyId',
      value: company.id.toString(),
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={CompanyBenefitService.getByFilter}
        params={params}
        alwaysApplyParams
        editAction={editBenefit}
        addAction={addBenefit}
        deleteAction={deleteBenefit}
      />
    </Paper>
  );
}

export default withRouter(CompanyBenefits);
