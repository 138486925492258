import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { AdminSSOTestService } from 'us-web-services';
import toast from 'react-hot-toast';
import PageStyles from '../../styles/PageStyles';
import { HostsService } from '../../util';
import DisplayService from '../../util/DisplayService';

function SSOTest() {
  const { classes } = PageStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const formRef = React.useRef<HTMLFormElement>(null);

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error logging in.',
    );

    toast.error(displayedError.message);
  };

  const callSSO = async (event) => {
    event.preventDefault();
    const data = {
      email,
      password,
    };

    try {
      const response = (await AdminSSOTestService.login(data)).data;
      const authToken = response.data;

      setToken(authToken.token);
      setRefreshToken(authToken.refreshToken.token);

      setTimeout(() => formRef.current.submit());
    } catch (e) {
      showError(e);
    }
  };

  const onEnterSubmit = (event): void => {
    if (event.key === 'Enter') {
      callSSO(event);
    }
  };

  const updateEmail = event => {
    setEmail(event.target.value);
  };

  const updatePassword = event => {
    setPassword(event.target.value);
  };

  const getAction = () => `https://${HostsService.getAppHost()}/sso`;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>SSO Test</Typography>
      <p>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://urbansitter.atlassian.net/wiki/spaces/ENG/pages/2036301825/How+to+use+SSO+Test+Tool'
        >Usage instructions
        </a>
      </p>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id='email'
            name='email'
            label='Email'
            fullWidth
            value={email}
            onChange={updateEmail}
            onKeyPress={onEnterSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id='password'
            name='password'
            label='Password'
            type='password'
            fullWidth
            value={password}
            onChange={updatePassword}
            onKeyPress={onEnterSubmit}
          />
        </Grid>
      </Grid>
      <form method='post' id='sso-form' ref={formRef} onSubmit={callSSO} action={getAction()} className='inline'>
        <input id='token' type='hidden' value={token} name='token' />
        <input id='refreshToken' type='hidden' value={refreshToken} name='refreshToken' />
        <div className={classes.buttons}>
          <Button
            disabled={!email || !password}
            variant='contained'
            color='primary'
            type='submit'
            className={classes.button}
          >
            SSO to App
          </Button>
        </div>
      </form>
    </Paper>
  );
}
export default withRouter(SSOTest);
