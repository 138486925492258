import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { Order } from '../types/order/Order';

const URL = '/v2/orders';

class OrderService {
  static getByFilter(
    config: AxiosRequestConfig
  ): Promise<PagedResponse<Order>> {
    return axios.get(URL, config);
  }
}

export { Order, OrderService };
