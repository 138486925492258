import React, { useState } from 'react';
import { Button } from '@mui/material';
import BookingTransactions from './BookingTransactions';
import MembershipTransactions from './MembershipTransactions';

function TransactionsLookup() {
  const [bookingPayments, setBookingPayments] = useState(true);

  const showBookingPayments = () => {
    setBookingPayments(true);
  };

  const hideBookingPayments = () => {
    setBookingPayments(false);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Button
          size='small'
          variant={bookingPayments ? 'outlined' : 'contained'}
          color='secondary'
          onClick={showBookingPayments}
          style={{ marginRight: 10 }}
        >
          Booking Payments
        </Button>
        <Button
          size='small'
          variant={!bookingPayments ? 'outlined' : 'contained'}
          color='secondary'
          onClick={hideBookingPayments}
          style={{ marginRight: 10 }}
        >
          Membership Payments
        </Button>
      </div>
      {bookingPayments ? <BookingTransactions /> : <MembershipTransactions />}
    </>
  );
}

export default TransactionsLookup;
