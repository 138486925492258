import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { CollegeService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function CollegeLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'colleges',
        header: 'Colleges',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Alias',
            accessorKey: 'alias',
          },
          {
            header: 'URL Alias',
            accessorKey: 'urlAlias',
          },
          {
            header: 'City',
            accessorKey: 'city',
          },
          {
            header: 'State',
            accessorKey: 'state',
          },
        ],
      },
    ],
    [],
  );

  const addCollege = () => {
    history.push('/college-create');
  };

  const editCollege = rowData => {
    history.push(`/colleges/${rowData.id}`);
  };

  const params = (globalFilter: string) => [
    {
      key: 'name',
      value: globalFilter,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={CollegeService.getByFilter}
        params={params}
        editAction={editCollege}
        addAction={addCollege}
      />
    </Paper>
  );
}

export default withRouter(CollegeLookup);
