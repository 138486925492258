import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { PayoutMethod } from '../types/PayoutMethod';

const URL = '/v2/payout-methods';

class PayoutMethodService {
  static findByFilter(
    config: AxiosRequestConfig
  ): Promise<PagedResponse<PayoutMethod>> {
    return axios.get(`${URL}`, config);
  }

  static create(
    data: PayoutMethod,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<PayoutMethod>> {
    return axios.post(`${URL}`, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { PayoutMethodService };
