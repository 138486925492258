import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { AdminTag, AdminTagService } from 'us-web-services';
import TagEditForm from './TagEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

const newTag = {
  code: '',
  label: '',
};

function TagEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [tag, setTag] = useState<AdminTag>(newTag);

  useEffect(() => {
    const tagId = Number.parseInt(match.params.id, 10);

    if (tagId) {
      const getData = async () => {
        const response = (await AdminTagService.get(tagId)).data;

        if (response.data.id) {
          setTag(response.data);
        } else {
          setTag(newTag);
        }

        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/tags');
  };

  const deleteSuccess = () => {
    toast.success(`${tag.code} deleted`);
    navigateToList();
  };

  const updateSuccess = (existingTag: boolean) => {
    toast.success(`${tag.code} ${existingTag ? 'updated' : 'created'}`);
    navigateToList();
  };

  const saveTag = async () => {
    try {
      if (tag.id) {
        await AdminTagService.patch(tag.id, tag);
      } else {
        await AdminTagService.create(tag);
      }
      updateSuccess(!!tag.id);
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  const deleteTag = async () => {
    try {
      await AdminTagService.delete(tag.id);

      deleteSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {tag.code}
      </Typography>
      {loaded ? <TagEditForm tag={tag} setTag={setTag} /> : 'Loading'}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        {tag.id ? (
          <Button
            variant='contained'
            color='error'
            onClick={deleteTag}
            className={classes.button}
          >
            Delete
          </Button>
        ) : (
          ''
        )}
        <Button
          variant='contained'
          color='primary'
          onClick={saveTag}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(TagEdit);
