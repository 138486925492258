import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { MasterServiceArea, MasterServiceAreaService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import MasterServiceAreaForm from './MasterServiceAreaForm';
import MasterServiceAreaZipCodes from './MasterServiceAreaZipCodes';
import MasterServiceAreaPlanPrices from './MasterServiceAreaPlanPrices';

function MasterServiceAreaEdit(props: RouteComponentProps<{ id: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [masterServiceArea, setMasterServiceArea] = useState<MasterServiceArea>(
    {
      id: null,
      name: '',
    },
  );

  useEffect(() => {
    if (match.params.id) {
      const getData = async () => {
        const response = await MasterServiceAreaService.get(Number.parseInt(match.params.id, 10));

        if (response.data.data.id) {
          setMasterServiceArea(response.data.data);
        } else {
          setMasterServiceArea({
            id: null,
            name: '',
          });
        }
        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push('/master-service-areas');
  };

  const saveSuccess = () => {
    toast.success('Master Service Area updated successfully');
    navigateToList();
  };

  const saveMasterServiceArea = async () => {
    try {
      if (masterServiceArea.id) {
        await MasterServiceAreaService.update(
          masterServiceArea.id,
          masterServiceArea,
        );
      } else {
        await MasterServiceAreaService.create(masterServiceArea);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {masterServiceArea.id ? 'Edit' : 'Add'} Master Service Area
      </Typography>
      {loaded ? (
        <MasterServiceAreaForm
          masterServiceArea={masterServiceArea}
          setMasterServiceArea={setMasterServiceArea}
        />
      ) : (
        'Loading'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveMasterServiceArea}
          className={classes.button}
        >
          Save
        </Button>
      </div>
      {masterServiceArea.id && (
        <MasterServiceAreaPlanPrices
          masterServiceAreaId={masterServiceArea.id}
        />
      )}
      {masterServiceArea.id && (
        <MasterServiceAreaZipCodes masterServiceAreaId={masterServiceArea.id} />
      )}
    </Paper>
  );
}

export default withRouter(MasterServiceAreaEdit);
