import React, { useState } from 'react';
import { Button, Paper, Grid, InputLabel, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';

import { LocalGroupService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function LocalGroupMerge(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [localGroupInput, setLocalGroupInput] = useState(null);
  const [selectedLocalGroupInto, setSelectedLocalGroupInto] = useState(null);
  const [selectedLocalGroupFrom, setSelectedLocalGroupFrom] = useState([]);

  const getLocalGroups = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (localGroupInput) {
      contentConfig.params.name = localGroupInput;
    }

    const localGroupResponse = (await LocalGroupService.getByFilter(contentConfig)).data;
    const localGroups = localGroupResponse.data;
    const options = [];

    localGroups.map(data =>
      options.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return options;
  };

  const updateLocalGroupInput = newValue => {
    setLocalGroupInput(newValue);
  };

  const updateLocalGroupFrom = value => {
    setSelectedLocalGroupFrom(value);
  };

  const navigateToList = () => {
    history.push('/local-groups');
  };

  const saveSuccess = (localGroupInto, totalLocalGroups) => {
    toast.success(
      `Successfully merged ${totalLocalGroups} local groups into ${localGroupInto}`,
    );
    navigateToList();
  };

  const mergeLocalGroups = async () => {
    if (!selectedLocalGroupInto) {
      toast.error('Please pick a Local Group to Merge Into');

      return;
    }

    if (!selectedLocalGroupFrom.length) {
      toast.error('Please pick Local Groups to Merge From');

      return;
    }

    const localGroupMergeFromIds = [];

    selectedLocalGroupFrom.forEach(x => {
      localGroupMergeFromIds.push(x.id);
    });

    selectedLocalGroupInto.mergeFrom = localGroupMergeFromIds;

    try {
      await LocalGroupService.patch(
        selectedLocalGroupInto.id,
        selectedLocalGroupInto,
      );
      saveSuccess(selectedLocalGroupInto.name, localGroupMergeFromIds.length);
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Merge Local Groups
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge Into</InputLabel>
          <AsyncSelect
            id='localGroupInto'
            required
            loadOptions={getLocalGroups}
            defaultOptions
            onInputChange={updateLocalGroupInput}
            onChange={setSelectedLocalGroupInto}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge From</InputLabel>
          <AsyncSelect
            isMulti
            id='localGroupFrom'
            required
            loadOptions={getLocalGroups}
            defaultOptions
            onInputChange={updateLocalGroupInput}
            onChange={updateLocalGroupFrom}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeLocalGroups}
          className={classes.button}
        >
          Submit
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(LocalGroupMerge);
