import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  confirmAction: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  text: string;
}

function ConfirmDialog(props: Props) {
  const { confirmAction, open, setOpen, title, text } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ margin: '0px auto 5px auto' }}>
        <Button onClick={confirmAction} color='primary'>
          Yes
        </Button>
        <Button onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
