import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';

import { College } from 'us-web-services';

interface Props {
  college: College;
  setCollege(college: College): void;
}

export default function CollegeEditForm(props: Props) {
  const { college, setCollege } = props;
  const [name, setName] = useState(college.name);
  const [alias, setAlias] = useState(college.alias);
  const [urlAlias, setUrlAlias] = useState(college.urlAlias);
  const [street, setStreet] = useState(college.street);
  const [city, setCity] = useState(college.city);
  const [state, setState] = useState(college.state);
  const [zip, setZip] = useState(college.zip);
  const [latitude, setLatitude] = useState(college.latitude);
  const [longitude, setLongitude] = useState(college.longitude);

  const updateName = event => {
    setName(event.target.value);
    college.name = event.target.value;
    setCollege(college);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
    college.alias = event.target.value;
    setCollege(college);
  };

  const updateUrlAlias = event => {
    setUrlAlias(event.target.value);
    college.urlAlias = event.target.value;
    setCollege(college);
  };

  const updateStreet = event => {
    setStreet(event.target.value);
    college.street = event.target.value;
    setCollege(college);
  };

  const updateCity = event => {
    setCity(event.target.value);
    college.city = event.target.value;
    setCollege(college);
  };

  const updateState = event => {
    setState(event.target.value);
    college.state = event.target.value;
    setCollege(college);
  };

  const updateZip = event => {
    setZip(event.target.value);
    college.zip = event.target.value;
    setCollege(college);
  };

  const updateLatitude = event => {
    setLatitude(event.target.value);
    college.latitude = event.target.value;
    setCollege(college);
  };

  const updateLongitude = event => {
    setLongitude(event.target.value);
    college.longitude = event.target.value;
    setCollege(college);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          onChange={updateName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='alias'
          name='alias'
          label='Alias'
          fullWidth
          value={alias}
          onChange={updateAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='urlAlias'
          name='urlAlias'
          label='URL Alias'
          fullWidth
          value={urlAlias}
          onChange={updateUrlAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='street'
          name='street'
          label='Street'
          fullWidth
          value={street}
          onChange={updateStreet}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='city'
          name='city'
          label='City'
          fullWidth
          value={city}
          onChange={updateCity}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='state'
          name='state'
          label='State'
          fullWidth
          value={state}
          onChange={updateState}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='zip'
          name='zip'
          label='Zip'
          fullWidth
          value={zip}
          onChange={updateZip}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='latitude'
          name='latitude'
          label='Latitude'
          fullWidth
          value={latitude}
          onChange={updateLatitude}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='longitude'
          name='longitude'
          label='Longitude'
          fullWidth
          value={longitude}
          onChange={updateLongitude}
        />
      </Grid>
    </Grid>
  );
}
