import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { UserGroup } from '../types/UserGroup';

const URL = '/v2/user-groups';

class UserGroupService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<UserGroup>> {
    return axios.get(URL, config);
  }

  static create(
    data: UserGroup,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<UserGroup>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig): Promise<void> {
    return axios.delete(`${URL}/${id}`, config);
  }
}
export { UserGroupService };
