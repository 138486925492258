import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Affiliation, AffiliationService, SitterService } from 'us-web-services';
import AffiliationEditForm from './AffiliationEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

function AffiliationEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [affiliation, setAffiliation] = useState<Affiliation>({});
  const [sitterCount, setSitterCount] = useState(0);

  useEffect(() => {
    if (match.params.id) {
      const affiliationId = Number.parseInt(match.params.id, 10);

      const getData = async () => {
        const response = await AffiliationService.get(affiliationId);

        if (response.data.data.id) {
          setAffiliation(response.data.data);
        } else {
          setAffiliation({
            id: null,
          });
        }

        const sitterConfig = {
          params: {
            limit: 1,
            affiliationId: match.params.id,
            userId: adminUser.id,
          },
        };

        const sitterResponse = await SitterService.getByFilter(sitterConfig);

        setSitterCount(sitterResponse.data.meta.size);
      };

      getData();
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/affiliations');
  };

  const saveSuccess = () => {
    toast.success(`${affiliation.name} updated successfully`);
    navigateToList();
  };

  const saveAffiliation = async () => {
    try {
      if (affiliation.id) {
        await AffiliationService.patch(affiliation.id, affiliation);
      } else {
        await AffiliationService.create(affiliation);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {affiliation.name} (sitters: {sitterCount})
      </Typography>
      {affiliation.id ? (
        <AffiliationEditForm
          affiliation={affiliation}
          setAffiliation={setAffiliation}
        />
      ) : (
        'Affiliation not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveAffiliation}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(AffiliationEdit);
