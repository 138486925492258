import React, { useState } from 'react';
import {
  Button,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@mui/material';
import { CronConstants } from '../../constants';
import ModalStyles from '../../styles/ModalStyles';

interface Props {
  setUrl(url: string): void;
  setMethod(method: string): void;
  className: string;
}

function CronTasks(props: Props) {
  const { setUrl, setMethod, className } = props;
  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectTask = async event => {
    setUrl(event.target.value);
    setMethod('POST');
    handleClose();
  };

  return (
    <>
      <Button
        id='openModal'
        variant='contained'
        color='secondary'
        onClick={showModal}
        className={className}
      >
        Cron Tasks
      </Button>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='cron-tasks-modal-title'>Cron Tasks</h2>
            <RadioGroup aria-label='Tasks' name='tasks' onChange={selectTask}>
              {Object.values(CronConstants.TASKS).map(task => (
                <FormControlLabel
                  key={task.uri}
                  value={`${task.uri}`}
                  control={<Radio />}
                  label={task.label}
                />
              ))}
            </RadioGroup>
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Cancel
            </Button>{' '}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default CronTasks;
