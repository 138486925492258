import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../../types/api';
import { MasterServiceAreaZipCode } from '../../types/master_service_area/MasterServiceAreaZipCode';

const URL = '/v2/master-service-area-zip-codes';

class MasterServiceAreaZipCodeService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceAreaZipCode>> {
    return axios.get(URL, config);
  }

  static create(
    data: MasterServiceAreaZipCode,
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceAreaZipCode>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { MasterServiceAreaZipCodeService };
