import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { AuthToken } from '../types/authentication/AuthToken';

const URL = '/v2/public/auth-token';

class AuthTokenService {
  static create(
    data: AuthToken,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AuthToken>> {
    return axios.post(URL, data, config);
  }
}

export { AuthTokenService };
