import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { UserPicture } from '../types/UserPicture';

const URL = '/v2/user-pictures';

class UserPictureService {
  static create(
    data: UserPicture,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<UserPicture>> {
    return axios.post(URL, data, config);
  }
}
export { UserPictureService };
