import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { useDropzone } from 'react-dropzone';

import { Partner, PromoService } from 'us-web-services';

interface Props extends RouteComponentProps {
  mode?: 'create' | 'edit';
  partner: Partner;
  setPartner: (partner: Partner) => void;
}

function PartnerForm(props: Props) {
  const { mode = 'create', partner, setPartner } = props;

  const [active, setActive] = useState(partner.active);
  const [urlAlias, setUrlAlias] = useState(partner.urlAlias);
  const [title, setTitle] = useState(partner.title);
  const [metaDescription, setMetaDescription] = useState(
    partner.metaDescription,
  );
  const [headline, setHeadline] = useState(partner.headline);
  const [intro, setIntro] = useState(partner.intro);
  const [partnerText, setPartnerText] = useState(partner.partnerText);
  const [logoUrl, setLogoUrl] = useState(partner.logoUrl);
  const [logoUploadStatus, setLogoUploadStatus] = useState('');
  const [offerDescription, setOfferDescription] = useState(
    partner.offerDescription,
  );
  const [code, setCode] = useState(partner.code);
  const [announcement, setAnnouncement] = useState(partner.announcement);
  const [legalText, setLegalText] = useState(partner.legalText);

  const updateActive = event => {
    setActive(event.target.checked);
    partner.active = event.target.checked;
    setPartner(partner);
  };
  const updateUrlAlias = event => {
    setUrlAlias(event.target.value);
    partner.urlAlias = event.target.value;
    setPartner(partner);
  };
  const updateTitle = event => {
    setTitle(event.target.value);
    partner.title = event.target.value;
    setPartner(partner);
  };
  const updateMetaDescription = event => {
    setMetaDescription(event.target.value);
    partner.metaDescription = event.target.value;
    setPartner(partner);
  };
  const updateHeadline = event => {
    setHeadline(event.target.value);
    partner.headline = event.target.value;
    setPartner(partner);
  };
  const updateIntro = event => {
    setIntro(event.target.value);
    partner.intro = event.target.value;
    setPartner(partner);
  };
  const updatePartnerText = event => {
    setPartnerText(event.target.value);
    partner.partnerText = event.target.value;
    setPartner(partner);
  };
  const updateOfferDescription = event => {
    setOfferDescription(event.target.value);
    partner.offerDescription = event.target.value;
    setPartner(partner);
  };
  const updateCode = event => {
    setCode(event.target.value);
    partner.code = event.target.value;
    setPartner(partner);
  };
  const updateAnnouncement = event => {
    setAnnouncement(event.target.value);
    partner.announcement = event.target.value;
    setPartner(partner);
  };
  const updateLegalText = event => {
    setLegalText(event.target.value);
    partner.legalText = event.target.value;
    setPartner(partner);
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      const formData = new FormData();

      formData.append('file', acceptedFiles[0]);
      formData.append('promoId', partner.id.toString());

      setLogoUploadStatus('Uploading...');

      try {
        const uploadResponse = await PromoService.uploadLogo(formData);

        setLogoUrl(uploadResponse.data.data.url);
        partner.logoUrl = uploadResponse.data.data.url;
        setPartner(partner);

        setLogoUploadStatus(null);
      } catch (error) {
        setLogoUploadStatus(error.toString());
      }
    },
    [partner, setPartner, setLogoUrl],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const { ref, ...rootProps } = getRootProps();

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              id='active'
              name='active'
              checked={active}
              onChange={updateActive}
            />
          }
          label='Active'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='urlAlias'
          required
          label='URL Alias'
          value={urlAlias}
          onChange={updateUrlAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='title'
          label='Title'
          fullWidth
          value={title}
          onChange={updateTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='metaDescription'
          label='Meta Description'
          fullWidth
          value={metaDescription}
          onChange={updateMetaDescription}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='headline'
          label='Headline'
          fullWidth
          value={headline}
          onChange={updateHeadline}
        />
      </Grid>

      {mode === 'edit' ? (
        <Grid item xs={12}>
          {logoUrl && (
            <CardMedia
              style={{
                width: 50,
                height: 50,
                marginBottom: 10,
              }}
              image={logoUrl}
            />
          )}
          <section
            ref={ref}
            {...rootProps}
            style={{
              width: '50%',
              height: '50%',
              flex: 1,
              textAlign: 'center',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}
          >
            <input {...getInputProps()} />
            <p>Drag and drop the logo here, or click to select the logo</p>
            {logoUploadStatus}
          </section>
        </Grid>
      ) : (
        <Grid item xs={12} style={{ color: '#777' }}>
          <p>
            <b>Partner Logo</b>
          </p>
          <p>Partner logos can only be added while in edit mode.</p>
        </Grid>
      )}

      <Grid item xs={12}>
        <TextField
          id='offerDescription'
          label='Offer Description (HTML)'
          multiline
          minRows='7'
          fullWidth
          value={offerDescription}
          onChange={updateOfferDescription}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField id='code' label='Code' value={code} onChange={updateCode} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='legalText'
          label='Legal Text'
          multiline
          minRows='5'
          fullWidth
          value={legalText}
          onChange={updateLegalText}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ color: 'orange' }}>
          <FontAwesomeIcon
            style={{ fontSize: 14 }}
            icon={faExclamationTriangle}
          />{' '}
          Deprecated
        </div>
        <TextField
          id='intro'
          label='Intro'
          fullWidth
          value={intro}
          onChange={updateIntro}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ color: 'orange' }}>
          <FontAwesomeIcon
            style={{ fontSize: 14 }}
            icon={faExclamationTriangle}
          />{' '}
          Deprecated
        </div>
        <TextField
          id='partnerText'
          label='Partner Text'
          fullWidth
          value={partnerText}
          onChange={updatePartnerText}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ color: 'orange' }}>
          <FontAwesomeIcon
            style={{ fontSize: 14 }}
            icon={faExclamationTriangle}
          />{' '}
          Deprecated
        </div>
        <TextField
          id='announcement'
          label='Announcement'
          multiline
          minRows='4'
          fullWidth
          value={announcement}
          onChange={updateAnnouncement}
        />
      </Grid>
    </>
  );
}

export default withRouter(PartnerForm);
