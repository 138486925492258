import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { SpecialSituation } from '../types/SpecialSituation';

const URL = '/v2/special-situations';

class SpecialSituationsService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<SpecialSituation>> {
    return axios.get(URL, config);
  }
}

export { SpecialSituationsService };
