class CsvParserService {
  // Based on:
  // https://sebhastian.com/javascript-csv-to-array/
  static parse(str: string, delimiter = ',', headerRow = true) {
    const newlineChar = str.indexOf('\r\n') > 0 ? '\r\n' : '\n';

    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    // trim column names
    let headers: string[];

    if (headerRow) {
      headers = str
        .slice(0, str.indexOf(newlineChar))
        .split(delimiter)
        .map(h => h.trim());
    } else {
      const numColumns = str.slice(0, str.indexOf(newlineChar)).split(delimiter).length;

      // Use an incrementing array as the headers
      headers = Array.from(new Array(numColumns), (_x, i) => i.toString());
    }

    let rows;

    if (headerRow) {
      rows = str
        .slice(str.indexOf(newlineChar) + newlineChar.length)
        .split(newlineChar);
    } else {
      rows = str.split(newlineChar);
    }

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(row => {
      const values = row.split(delimiter);

      const el = headers.reduce(
        (object: { [index: string]: any }, header, index) => {
          object[header] = values[index];

          return object;
        }, {}
      );

      return el;
    });

    return arr;
  }
}

export { CsvParserService };
