import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';

import {
  AdminCheckrPayWorkerService,
  CheckrPayWorkerStatuses,
  CheckrPayWorkerStatusIds,
  CreditCard,
  CreditCardService,
  Parent,
  ParentPlanService,
  Sitter,
  SitterPaymentSettings,
  SitterPlanService,
  SitterCheckrPayWorker,
  SitterCheckrPayWorkerService,
  SitterPaymentSettingsService,
  UserPlan,
} from 'us-web-services';
import toast from 'react-hot-toast';
import CardStyles from '../../styles/CardStyles';
import DateUtil from '../../util/DateUtil';
import ConfirmDialog from '../common/ConfirmDialog';
import AddCheckrPay from './AddCheckrPay';
import useAuthentication from '../../store/useAuthentication';
import { RoleConstants } from '../../constants';

interface Props {
  profile: Parent | Sitter;
  userId: number;
  isParent: boolean;
}

function PaymentInfo(props: Props) {
  const { profile, userId, isParent } = props;
  const { classes } = CardStyles();
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const [creditCard, setCreditCard] = useState<CreditCard>({});
  const [checkrPayWorker, setCheckrPayWorker] = useState<SitterCheckrPayWorker>({});
  const [plan, setPlan] = useState<UserPlan>({});
  const [openDeleteCheckrPayDialog, setOpenDeleteCheckrPayDialog] = useState(false);
  const [openRemoveCardDialog, setOpenRemoveCardDialog] = useState(false);
  const [paymentSettings, setPaymentSettings] = useState<SitterPaymentSettings>({
    acceptingPayment: false,
    id: 0,
    paymentDisabled: false,
  });

  const refreshCheckrPay = async () => {
    const config = {
      params: {
        sitterId: userId,
        userId,
      },
    };
    const checkrPayWorkerResponse = (await SitterCheckrPayWorkerService.getByFilter(config)).data;

    setCheckrPayWorker(checkrPayWorkerResponse.data.length ? checkrPayWorkerResponse.data[0] : {});
  };

  useEffect(() => {
    const getCreditCard = async () => {
      const contentConfig = {
        params: {
          userId,
          active: true,
        },
      };
      const creditCardResponse = (await CreditCardService.findByFilter(contentConfig)).data;

      setCreditCard(
        creditCardResponse.data.length
          ? creditCardResponse.data[0]
          : {},
      );
    };

    const getParentPlans = async () => {
      const config = {
        params: {
          active: true,
          parentId: userId,
          userId,
        },
      };

      ParentPlanService.getByFilter(config).then(response => {
        setPlan(response.data.data.length ? response.data.data[0] : {});
      });
    };

    const getPaymentSettings = async () => {
      SitterPaymentSettingsService.findById(userId).then(response => {
        setPaymentSettings(response.data.data);
      });
    };

    const getSitterPlans = async () => {
      const config = {
        params: {
          active: true,
          sitterId: userId,
          userId,
        },
      };

      SitterPlanService.getByFilter(config).then(response => {
        setPlan(response.data.data.length ? response.data.data[0] : {});
      });
    };

    const getCheckrPayWorker = async () => {
      refreshCheckrPay();
    };

    if (isParent) {
      getParentPlans();
    } else {
      getSitterPlans();
      getCheckrPayWorker();
      getPaymentSettings();
    }
    getCreditCard();
  }, [userId, isParent]);

  const isPaymentAdmin = () => adminUser.roles.find((role) => role.code === RoleConstants.PAYMENT_ADMIN.code);

  const togglePaymentSettings = async () => {
    const body = {
      id: paymentSettings.id,
      paymentDisabled: !paymentSettings.paymentDisabled,
    };

    SitterPaymentSettingsService.patch(userId, body).then(response => {
      setPaymentSettings(response.data.data);
      toast.success(`Credit card bookings successfully ${paymentSettings.paymentDisabled ? 'disabled' : 'enabled'}`);
    });
  };

  const confirmCloseCheckrPayAccount = () => {
    setOpenDeleteCheckrPayDialog(true);
  };

  const deleteCheckrPayAccount = async () => {
    await SitterCheckrPayWorkerService.delete(checkrPayWorker.id);

    setCheckrPayWorker({});
    setOpenDeleteCheckrPayDialog(false);
  };

  const refreshCheckrPayStatus = async () => {
    const workerData = {
      sitterId: userId,
    };

    try {
      await AdminCheckrPayWorkerService.refresh(userId, workerData);
      await refreshCheckrPay();
    } catch (e) {
      toast.error('Failed to refresh Checkr Pay account status. Ensure this worker ID is valid in Checkr Pay.');
    }
  };

  const removeCard = async () => {
    await CreditCardService.delete(creditCard.id);
    setCreditCard({});
    setOpenRemoveCardDialog(false);
  };

  const openCardDialog = () => {
    setOpenRemoveCardDialog(true);
  };

  return (
    <>
      <ConfirmDialog
        confirmAction={deleteCheckrPayAccount}
        open={openDeleteCheckrPayDialog}
        setOpen={setOpenDeleteCheckrPayDialog}
        title='Confirm Close Checkr Pay Account'
        text="Are you sure you want to close this caregiver's Checkr Pay account?"
      />
      <ConfirmDialog
        confirmAction={removeCard}
        open={openRemoveCardDialog}
        setOpen={setOpenRemoveCardDialog}
        title='Confirm Remove Payment Method'
        text='Are you sure you want to delete this card?'
      />
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardContent className={classes.content} style={{ minWidth: 300 }}>
            <Typography component='h5' variant='h5'>
              Payment Method
            </Typography>
            {creditCard.id ? (
              <>
                <b>{creditCard.customerId === null ? 'Braintree' : 'Stripe'}</b><br />
                Card Type: <b>{creditCard.type}</b><br />
                Card Number (last 4): <b>{creditCard.number}</b><br />
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={openCardDialog}
                  style={{ marginTop: '10px' }}
                >
                  Remove Card
                </Button>
              </>
            ) : (
              'No credit card linked'
            )}
            <br /><br />
            <Typography component='h5' variant='h5'>
              Payout Method
            </Typography>
            {checkrPayWorker?.id ? (
              <>
                <b>Checkr Pay</b><br />
                Balance: <b>{checkrPayWorker.hasBalance ? 'Yes' : 'No'}</b><br />
                Status: <b>{CheckrPayWorkerStatuses[CheckrPayWorkerStatusIds[checkrPayWorker.statusId]]}</b><br />
                Worker ID: <b>{checkrPayWorker.workerId}</b><br />
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={refreshCheckrPayStatus}
                  style={{ marginBottom: 20, marginTop: 10, marginRight: 10 }}
                >
                  Refresh Account Status
                </Button>
                {checkrPayWorker.statusId !== CheckrPayWorkerStatusIds.CLOSED && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={confirmCloseCheckrPayAccount}
                    style={{ marginBottom: 20, marginTop: 10 }}
                  >
                    Close Account
                  </Button>
                )}
              </>
            ) : null}
            { !checkrPayWorker?.id ? (
              <div style={{ marginBottom: 20, marginTop: 5 }}>
                No Checkr Pay Account

                {isPaymentAdmin() && <AddCheckrPay profile={profile} refreshCheckrPay={refreshCheckrPay} />}
              </div>
            ) : null}
            {!isParent ? (
              <>
                <Typography component='h5' variant='h5'>
                  Credit card bookings
                </Typography>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={togglePaymentSettings}
                  style={{ marginTop: '10px' }}
                >
                  {paymentSettings.paymentDisabled ? 'Enable' : 'Disable'} Credit Card Bookings
                </Button>
              </>
            ) : null}
          </CardContent>
        </div>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            {plan.id ? (
              <>
                <b>{plan.plan.name}</b><br />
                Plan ID: {plan.plan.id}<br />
                Start Date: {DateUtil.getReadableDate(plan.start)}<br />
                End Date: {DateUtil.getReadableDate(plan.end)}<br />
                Price: ${plan.fee}
              </>
            ) : (
              <b>No membership plan found</b>
            )}
            {!isParent ? (
              <>
                <br /><br />Accepting Credit Cards: <b>{(profile as Sitter).acceptsCreditCards ? 'Yes' : 'No'}</b>
              </>
            ) : null}
          </CardContent>
        </div>
      </Card>
    </>
  );
}

export default PaymentInfo;
