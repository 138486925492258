import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@mui/material';
import {
  UserPlan,
  UserPlanService,
  MembershipRenewalService,
} from 'us-web-services';
import { Table, TableInstance } from 'src/components/common/Table';
import useUser from '../../store/useUser';
import DateUtil from '../../util/DateUtil';
import DisplayService from '../../util/DisplayService';
import ConfirmDialog from '../common/ConfirmDialog';
import PlanEdit from '../users/PlanEdit';
import RenewalOrderLookup from './RenewalOrderLookup';

const STATUS = {
  0: 'New',
  1: 'Inactive',
  3: 'Active',
  4: 'Expired',
  5: 'Removed By Support',
  6: 'Eliminated By Other',
  7: 'Eliminated By Script',
};
const STATUS_ID_ACTIVE = 3;
const STATUS_ID_REMOVED = 5;

function PlanMembershipRenewal() {
  const [plan, setPlan] = useState<UserPlan>({});
  const [openEditPlan, setOpenEditPlan] = useState(false);
  const [openMembershipRenewal, setOpenMembershipRenewal] = useState(true);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openUnCancelDialog, setOpenUncancelDialog] = useState(false);
  const tableRef = React.useRef<TableInstance>();
  const userState = useUser()[0];

  const refreshPlans = () => tableRef.current?.fetchData();

  const postUpdate = () => {
    refreshPlans();
    setOpenActivateDialog(false);
    setOpenRemoveDialog(false);
    setOpenCancelDialog(false);
    setOpenUncancelDialog(false);
    setOpenEditPlan(false);
    toast.success('Plan updated');
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the plan.',
    );

    toast.error(displayedError.message);
  };

  const updatePlan = async (planData, data) => {
    UserPlanService.patch(planData.id, data).then(postUpdate).catch(showError);
  };

  const removePlan = async () => {
    const data = {
      id: plan.id,
      statusId: STATUS_ID_REMOVED,
    };

    await updatePlan(plan, data);
  };

  const activatePlan = async () => {
    const data = {
      id: plan.id,
      statusId: STATUS_ID_ACTIVE,
    };

    await updatePlan(plan, data);
  };

  const cancelPlan = async () => {
    const data = {
      id: plan.id,
      cancel: true,
    };

    await updatePlan(plan, data);
  };

  const unCancelPlan = async () => {
    const data = {
      id: plan.id,
      cancel: false,
    };

    await updatePlan(plan, data);
  };

  const renewalSitter = (idPlan) => {
    const data = {
      planId: idPlan,
    };

    MembershipRenewalService.membershipRenewalSitter(data).then(() => {
      setOpenMembershipRenewal(true);
      postUpdate();
    });
  };

  const renewalParent = (idPlan) => {
    const data = {
      planId: idPlan,
    };

    MembershipRenewalService.membershipRenewalParent(data).then(() => {
      setOpenMembershipRenewal(true);
      postUpdate();
    });
  };

  const renewalMembership = (idPlan) => {
    try {
      setOpenMembershipRenewal(false);
      if (userState.user.sitter) {
        renewalSitter(idPlan);
      } else {
        renewalParent(idPlan);
      }
    } catch (e) {
      refreshPlans();
      showError(e);
    }
  };

  const isActive = rowData => rowData.statusId === STATUS_ID_ACTIVE;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'plans',
        header: 'Plans',
        columns: [
          {
            header: 'ID',
            accessorKey: 'id',
            enableEditing: false,
          },
          {
            header: 'UID',
            accessorKey: 'userId',
            enableEditing: false,
          },
          {
            header: 'Plan ID',
            accessorKey: 'planId',
            enableEditing: false,
          },
          {
            header: 'Start Date',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData.start),
            enableEditing: false,
          },
          {
            header: 'End Date',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(moment(rowData?.end)),
            enableEditing: false,
          },
          {
            header: 'Status',
            accessorFn: rowData => STATUS[rowData.statusId],
            enableEditing: false,
          },
          {
            header: 'Fee',
            accessorKey: 'fee',
            enableEditing: false,
          },
          {
            header: 'Freq Rate',
            accessorKey: 'recurringFee',
            enableEditing: false,
          },
          {
            header: 'Set to cancel?',
            accessorFn: rowData => (rowData.cancel ? 'Y' : 'N'),
          },
          {
            header: 'Cancel Date',
            accessorFn: rowData =>
              rowData.cancelAt
                ? DateUtil.getReadableDateTime(rowData.cancelAt)
                : '',
          },
          {
            header: 'Actions',
            Cell: ({ cell }) => (
              <>
                {isActive(cell?.row?.original) ? (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setPlan(cell?.row?.original);
                      setOpenRemoveDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setPlan(cell?.row?.original);
                      setOpenActivateDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Activate
                  </Button>
                )}
                {cell?.row?.original.cancel && isActive(cell?.row?.original) ? (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setPlan(cell?.row?.original);
                      setOpenUncancelDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Un-Cancel
                  </Button>
                ) : (
                  ''
                )}
                {!cell?.row?.original.cancel && isActive(cell?.row?.original) ? (
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setPlan(cell?.row?.original);
                      setOpenCancelDialog(true);
                    }}
                    style={{ margin: 5 }}
                  >
                    Cancel
                  </Button>
                ) : ''}
                <Button
                  size='small'
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setPlan(cell?.row?.original);
                    setOpenEditPlan(true);
                  }}
                  style={{ margin: 5 }}
                >
                  Edit
                </Button>
                <Button
                  size='small'
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setPlan(cell?.row?.original);
                    renewalMembership(cell?.row?.original?.id);
                  }}
                  style={{ margin: 5 }}
                >
                  Membership Renewal
                </Button>
              </>
            ),
          },
        ],
      },
    ],
    [],
  );

  const params = () => [
    {
      key: 'userId',
      value: userState.user.id.toString(),
    },
  ];

  return (
    <>
      <ConfirmDialog
        confirmAction={removePlan}
        open={openRemoveDialog}
        setOpen={setOpenRemoveDialog}
        title='Confirm Plan Removal'
        text='Are you sure you want to remove this plan?'
      />
      <ConfirmDialog
        confirmAction={activatePlan}
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        title='Confirm Plan Activation'
        text='Are you sure you want to re-activate this plan?'
      />
      <ConfirmDialog
        confirmAction={cancelPlan}
        open={openCancelDialog}
        setOpen={setOpenCancelDialog}
        title='Confirm Plan Cancellation'
        text='Are you sure you want to cancel this plan at the end of the period?'
      />
      <ConfirmDialog
        confirmAction={unCancelPlan}
        open={openUnCancelDialog}
        setOpen={setOpenUncancelDialog}
        title='Confirm Plan Un-Cancellation'
        text='Are you sure you want this plan not to cancel at the end of the period?'
      />
      <PlanEdit
        plan={plan}
        updatePlan={updatePlan}
        open={openEditPlan}
        setOpen={setOpenEditPlan}
      />
      <Table
        columns={COLUMNS}
        ref={tableRef}
        retrieveData={UserPlanService.getByFilter}
        params={params}
        alwaysApplyParams
      />
      <RenewalOrderLookup
        open={openMembershipRenewal}
      />
    </>
  );
}

export default withRouter(PlanMembershipRenewal);
