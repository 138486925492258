import React from 'react';

import { User, Parent, Sitter } from 'us-web-services';
import useStore from './useStore';

import * as actions from './actions/user';

interface State {
  user: User;
  profile: Parent | Sitter;
}

const initialState: State = {
  user: {},
  profile: {},
};

const useUser = useStore(React, initialState, actions);

export default useUser;
