import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { MasterServiceAreaTier } from 'us-web-services';

interface Props {
  masterServiceAreaTier: MasterServiceAreaTier;
  setMasterServiceAreaTier(masterServiceAreaTier: MasterServiceAreaTier): void;
}

export default function MasterServiceAreaTierForm(props: Props) {
  const { masterServiceAreaTier, setMasterServiceAreaTier } = props;
  const [name, setName] = useState(masterServiceAreaTier.name);

  const updateName = event => {
    setName(event.target.value);
    masterServiceAreaTier.name = event.target.value;
    setMasterServiceAreaTier(masterServiceAreaTier);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          inputProps={{
            maxLength: 10,
          }}
          onChange={updateName}
        />
      </Grid>
    </Grid>
  );
}
