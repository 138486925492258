import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { CompanyService } from 'us-web-services';
import { Table } from 'src/components/common/Table';
import PageStyles from 'src/styles/PageStyles';
import useCompany from '../../../store/useCompany';

function CompanyLookup(props: RouteComponentProps) {
  const companyActions = useCompany()[1];
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'companies',
        header: 'Companies',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Display Name',
            accessorKey: 'displayName',
          },
          {
            header: 'Alias',
            accessorKey: 'alias',
          },
          {
            header: 'External ID?',
            accessorFn: row => (row.useExternalId ? 'Yes' : 'No'),
            size: 20,
          },
          {
            header: 'Email validation?',
            accessorFn: row => (row.emailValidationEnabled ? 'Yes' : 'No'),
            size: 20,
          },
          {
            header: 'Logo',
            accessorFn: row => row.logoUrl,
            Cell: ({ cell }) => (
              <img alt='' src={cell.getValue()} style={{ width: 50 }} />
            ),
            size: 60,
          },
        ],
      },
    ],
    [],
  );

  const editCompany = rowData => {
    companyActions.set(rowData);
    history.push(`/companies/${rowData.id}`);
  };

  const addCompany = () => {
    companyActions.set({});
    history.push('/company-create');
  };

  const params = (globalFilter: string) => {
    const paramArray = [];

    if (globalFilter) {
      paramArray.push({
        key: 'q',
        value: `name.startsWith('${globalFilter}')`,
      });
    }

    return paramArray;
  };

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={CompanyService.getByFilter}
        params={params}
        editAction={editCompany}
        addAction={addCompany}
      />
    </Paper>
  );
}

export default withRouter(CompanyLookup);
