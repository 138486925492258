import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { CheckrPayoutOrder } from '../../types/checkrpay/CheckrPayoutOrder';

const URL = '/v2/admin-checkrpay-order';

class AdminCheckrPayOrderService {
  static getByFilter(
    params: any,
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CheckrPayoutOrder>> {
    return axios.get(`${URL}${params}`, config);
  }

  static patch(
    checkrPayoutOrder: CheckrPayoutOrder,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CheckrPayoutOrder>> {
    return axios.patch(
      `${URL}/${checkrPayoutOrder.id}`,
      checkrPayoutOrder,
      config
    );
  }
}

export { AdminCheckrPayOrderService, CheckrPayoutOrder };
