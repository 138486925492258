export enum SignupReasonCode {
  one_time = 'one_time',
  part_time = 'part_time',
  full_time = 'full_time',
  not_sure = 'not_sure',
}

export enum SignupReasonId {
  ONE_TIME = 1,
  PART_TIME = 2,
  FULL_TIME = 3,
  NOT_SURE = 4,
}

export interface SignupReason {
  id?: number;
  created?: Date;
  modified?: Date;
  code?: SignupReasonCode;
  label?: string;
}
