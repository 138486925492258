import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../../types/api';
import { SitterCheckrPayWorker } from '../../types/checkrpay/SitterCheckrPayWorker';

const URL = '/v2/checkrpay-workers';

class SitterCheckrPayWorkerService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<SitterCheckrPayWorker>> {
    return axios.get(URL, config);
  }

  static delete(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<SitterCheckrPayWorker> {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { SitterCheckrPayWorkerService };
