import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { UserGroupType } from '../types/UserGroup';

const URL = '/v2/user-group-types';

class UserGroupTypeService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<UserGroupType>> {
    return axios.get(URL, config);
  }
}
export { UserGroupTypeService };
