import React, { useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import toast from 'react-hot-toast';

import { CompanyEmployee, CompanyEmployeeService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import DisplayService from '../../../util/DisplayService';

function EmployeeAdd(props: RouteComponentProps) {
  const { history, location } = props;
  const searchParams = queryString.parse(location.search);
  const [companyId, setCompanyId] = useState(Number.parseInt(searchParams.companyId?.toString(), 10));
  const [userId, setUserId] = useState(Number.parseInt(searchParams.userId?.toString(), 10));
  const [email, setEmail] = useState(searchParams.email?.toString());
  const [externalId, setExternalId] = useState(searchParams.externalId?.toString());
  const { classes } = PageStyles();

  const addEmployee = async () => {
    if (email && externalId) {
      toast.error(
        'Both email and external id cannot be populated at the same time',
      );

      return;
    }

    const employeeData:CompanyEmployee = {
      companyId,
      email: email || null,
      userId,
      externalId: externalId || null,
    };

    try {
      await CompanyEmployeeService.create(employeeData);

      toast.success(
        `Employee successfully added to company ${employeeData.companyId}`,
      );
      history.push('/company-employees');
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error adding the employee.',
      );

      toast.error(displayedError.message);
    }
  };

  const cancel = () => {
    history.push('/company-employees');
  };

  const updateCompanyId = event => {
    setCompanyId(event.target.value);
  };
  const updateEmail = event => {
    setEmail(event.target.value);
  };
  const updateUserId = event => {
    setUserId(event.target.value);
  };
  const updateExternalId = event => {
    setExternalId(event.target.value);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Add Employee Benefits
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id='employee-userId'
            label='User ID'
            variant='outlined'
            fullWidth
            type='number'
            value={userId}
            onChange={updateUserId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='employee-companyId'
            label='Company ID'
            variant='outlined'
            fullWidth
            type='number'
            value={companyId}
            onChange={updateCompanyId}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='employee-email'
            label='Work Email'
            variant='outlined'
            fullWidth
            value={email}
            onChange={updateEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='employee-external-id'
            label='ExternalD'
            variant='outlined'
            fullWidth
            value={externalId}
            onChange={updateExternalId}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons} style={{ marginTop: 10 }}>
        <Button
          id='close'
          variant='contained'
          color='secondary'
          onClick={cancel}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>{' '}
        <Button
          id='add-employee-button'
          variant='contained'
          color='primary'
          onClick={addEmployee}
        >
          Add Employee
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(EmployeeAdd);
