import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { Partner } from '../types/Partner';

const URL = '/v2/partners';

class PartnerService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Partner>> {
    return axios.get(URL, config);
  }
}

export { PartnerService };
