export enum ParentSignupVerticalTypeId {
  'CHILD_CARE' = 1,
  'PET_CARE_DOG' = 2,
  'PET_CARE_CAT' = 3,
  'PET_CARE_OTHER' = 4,
  'SENIOR_CARE' = 5,
  'HOUSEHOLD_SERVICES' = 6,
}

export interface ParentSignupVerticalType {
  id?: number;
  created?: Date;
  modified?: Date;
  parentId?: number;
  signupVerticalType?: {
    id: ParentSignupVerticalTypeId;
    name?: string;
    secondaryLabel?: string;
  };
}
