class ValidationService {
  validEmailRegex = String.raw`^\S+@\S+\.\S+$`;

  validNameRegex = String.raw`^[^0-9]+$`;

  validPhoneRegex = String.raw`^[0-9]{3}-[0-9]{3}-[0-9]{4}$`;

  validMonthRegex = String.raw`^(0?[1-9]|1[012])$`;

  validDayRegex = String.raw`^(0?[1-9]|[12][0-9]|3[01])$`;

  validYearRegex = String.raw`^((19|20)\d{2})$`;

  strengthMeter: { [key: number]: { value: number; type: string } } = {
    10: { value: 10, type: 'strong' },
    9: { value: 9, type: 'strong' },
    8: { value: 8, type: 'strong' },
    7: { value: 7, type: 'good' },
    6: { value: 6, type: 'good' },
    5: { value: 5, type: 'good' },
    4: { value: 4, type: 'medium' },
    3: { value: 3, type: 'medium' },
    2: { value: 2, type: 'weak' },
    1: { value: 1, type: 'weak' },
    0: { value: 0, type: 'empty' },
  };

  public isValidEmail(email: string): boolean {
    const re = new RegExp(this.validEmailRegex);

    return re.test(email);
  }

  public isValidPhone(phone: string): boolean {
    const re = new RegExp(this.validPhoneRegex);

    return re.test(phone);
  }

  public isAlpha(input: string): boolean {
    const re = new RegExp(this.validNameRegex);

    return !!input && re.test(input);
  }

  public isName(input: string): boolean {
    return !!input && this.isAlpha(input) && !this.isValidEmail(input);
  }

  public passwordStrength(password: string): { value: number; type: string } {
    let strength = 0;

    const hasUppercase = /[A-Z]+/.test(password);
    const hasLowercase = /[a-z]+/.test(password);
    const hasDigit = /[0-9]+/.test(password);
    const hasSpecialCharacter = /[\W]+/.test(password);
    const validLength = password.length > 7;

    if (
      hasUppercase &&
      hasLowercase &&
      hasDigit &&
      hasSpecialCharacter &&
      validLength
    ) {
      strength = 10;
    } else if (
      hasUppercase ||
      hasLowercase ||
      hasDigit ||
      hasSpecialCharacter ||
      validLength
    ) {
      strength = 1;
    }

    return this.strengthMeter[strength];
  }
}

export default new ValidationService();
