import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/sitter-parent-review';

class ParentReviewService {
  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(`${URL}`, config);
  }

  static delete(id: any, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static patch(id: any, data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { ParentReviewService };
