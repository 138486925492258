import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, SitterGeneralAvailability } from '../types';

const URL = '/v2/sitter-general-availabilities';

class SitterGeneralAvailabilityService {
  static create(
    data: SitterGeneralAvailability,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterGeneralAvailability>> {
    return axios.post(URL, data, config);
  }
}

export { SitterGeneralAvailabilityService };
