import axios, { AxiosRequestConfig } from 'axios';

import { DataResponse, PagedResponse, ParentLocalGroup } from '../types';

const API_URL = '/v2/parent-local-groups';

class ParentLocalGroupsService {
  static create(
    data: ParentLocalGroup,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentLocalGroup>> {
    return axios.post(API_URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${API_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ParentLocalGroup>> {
    return axios.get(API_URL, config);
  }

  static patch(
    id: any,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentLocalGroup>> {
    return axios.patch(`${API_URL}/${id}`, data, config);
  }
}

export { ParentLocalGroupsService };
