import axios, { AxiosRequestConfig } from 'axios';
import { Login } from '../types/authentication/Login';
import { AuthToken, DataResponse } from '../types';

const URL = '/v2/admin-sso-test';

class AdminSSOTestService {
  static login(
    data: Login,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AuthToken>> {
    return axios.post(URL, data, config);
  }
}

export { AdminSSOTestService };
