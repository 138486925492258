import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { SharedAccount } from '../types/SharedAccount';

const URL = '/v2/shared-accounts';

class SharedAccountService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SharedAccount>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<SharedAccount>> {
    return axios.get(URL, config);
  }

  static create(
    data: SharedAccount,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SharedAccount>> {
    return axios.post(URL, data, config);
  }

  static update(
    id: number,
    data: SharedAccount,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SharedAccount>> {
    return axios.put(`${URL}/${id}`, data, config);
  }

  static patch(
    id: number,
    data: SharedAccount,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SharedAccount>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}
export { SharedAccountService };
