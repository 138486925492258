import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';

import { AdminRefreshCheckrReportService, BackgroundCheck, BackgroundCheckService } from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import { DateUtil, DisplayService } from '../../util';
import EditBackgroundCheck from './EditBackgroundCheck';

interface Props {
  userId: number;
}

function BackgroundCheckComponent(props: Props) {
  const { userId } = props;

  const { classes } = CardStyles();
  const [backgroundCheck, setBackgroundCheck] = useState<BackgroundCheck>();
  const [loaded, setLoaded] = useState(false);

  const getEventLabel = typeId => {
    const events = {
      1: 'Report Created',
      2: 'Invitation Expired',
      3: 'Invitation Completed',
      4: 'Report Completed',
      5: 'Report Suspended',
      6: 'Report Canceled',
    };

    return events[typeId];
  };

  const getReportStatusLabel = statusId => {
    const status = {
      1: 'Pending',
      2: 'Complete',
      3: 'Suspended',
      4: 'Dispute',
      5: 'Canceled',
    };

    return status[statusId];
  };

  const getInvitationStatusLabel = statusId => {
    const status = {
      1: 'Pending',
      2: 'Complete',
      3: 'Expired',
    };

    return status[statusId];
  };

  const getBackgroundCheck = async () => {
    const config = {
      params: {
        sitterId: userId,
      },
    };
    const backgroundCheckResponse = await BackgroundCheckService.findByFilter(
      config,
    );

    setBackgroundCheck(
      backgroundCheckResponse.data.data.length > 0
        ? backgroundCheckResponse.data.data[0]
        : {},
    );
    setLoaded(true);
  };

  const handleRefreshCheckrReport = async () => {
    try {
      await AdminRefreshCheckrReportService.post({ id: userId });

      getBackgroundCheck();
      toast.success('User refreshed in Checkr Report');
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error refreshing user in Checkr Report',
      );

      toast.error(displayedError.message);
    }
  };

  useEffect(() => {
    getBackgroundCheck();
  }, [userId]);

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            Background Check
          </Typography>
          {backgroundCheck?.status && (
            <div>
              Status: {backgroundCheck.status.label}
              <br />
              Created:{' '}
              {DateUtil.getReadableDateTime(backgroundCheck.created)}
              <br />
              Completed:{' '}
              {DateUtil.getReadableDateTime(backgroundCheck.completed)}
              <br />
              Modified:{' '}
              {DateUtil.getReadableDateTime(backgroundCheck.modified)}
              <br />
              Expires:{' '}
              {DateUtil.getReadableDateTime(backgroundCheck.expiration)}
            </div>
          )}
          {backgroundCheck?.checkrRequest?.id && (
            <div>
              <h4 style={{ marginBottom: 2 }}>Checkr Request Details</h4>
              Status:{' '}
              {getReportStatusLabel(
                backgroundCheck.checkrRequest.reportStatusId,
              )}
              <br />
              Includes Cancelled:
              {backgroundCheck.checkrRequest.includeCanceled ? 'Yes' : ''}
              <br />
              Invitation Status:{' '}
              {getInvitationStatusLabel(
                backgroundCheck.checkrRequest.invitationStatusId,
              )}
              <br />
              Candidate ID: {backgroundCheck.checkrRequest.candidateId}
              <br />
              Invitation URL: {backgroundCheck.checkrRequest.invitationUrl}
              <br />
              Created:{' '}
              {DateUtil.getReadableDateTime(backgroundCheck.checkrRequest.created)}
              <br />
              Last Event:{' '}
              {getEventLabel(backgroundCheck.checkrRequest.lastEventTypeId)}
              {backgroundCheck.checkrRequest.lastEventTypeCreated
                ? ` (${DateUtil.getReadableDateTime(backgroundCheck.checkrRequest.lastEventTypeCreated)})`
                : ''}
            </div>
          )}
          {loaded && (
            <div>
              <EditBackgroundCheck
                backgroundCheck={backgroundCheck}
                userId={userId}
                setBackgroundCheck={setBackgroundCheck}
              />
              <Button
                id='close'
                variant='contained'
                color='secondary'
                onClick={handleRefreshCheckrReport}
                style={{ marginTop: 10 }}
              >
                Refresh Checkr Report
              </Button>
            </div>
          )}
        </CardContent>
      </div>
    </Card>
  );
}

export default BackgroundCheckComponent;
