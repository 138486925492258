import React from 'react';
import { Paper, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import PageStyles from '../../../../styles/PageStyles';

function VoucherNotFound() {
  const { classes } = PageStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Voucher not found
      </Typography>
    </Paper>
  );
}

export default withRouter(VoucherNotFound);
