import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  AppBar,
  Button,
  ClassNameMap,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import queryString from 'query-string';
import toast from 'react-hot-toast';

import { CheckrPayoutOrder, AdminCheckrPayOrderService } from 'us-web-services';
import CheckrPayoutOrderCard from './CheckrPayoutOrderCard';
import useAuthentication from '../../store/useAuthentication';
import DateUtil from '../../util/DateUtil';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 0 8px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '20px 16px',
  },
});

function OrderLookup(props: RouteComponentProps & { classes: ClassNameMap }) {
  const authenticationState = useAuthentication()[0];
  const { classes, location } = props;
  const searchParams = queryString.parse(location.search);
  const [order, setOrder] = useState<CheckrPayoutOrder>({});
  const [orderId, setOrderId] = useState<number>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isReversingPayout, setIsReversingPayout] = useState(false);

  const getSearchId = () => searchParams?.id ? Number.parseInt(searchParams.id.toString(), 10) : null;

  useEffect(() => {
    const currentSearchId = getSearchId();

    if (currentSearchId && !orderId) {
      const getOrder = async () => {
        const userId = authenticationState.authentication.user.id;
        const orderResponse = await AdminCheckrPayOrderService.getByFilter(
          `?orderId=${currentSearchId}&userId=${userId}`,
        );
        const orderData = orderResponse.data?.data?.pop();

        setOrder(orderData);
      };

      setOrderId(currentSearchId);
      getOrder();
    }
  }, [authenticationState.authentication, searchParams.id]);

  const getOrderOnEnter = async id => {
    try {
      const newOrderId = Number.parseInt(id, 10);
      const userId = authenticationState.authentication.user.id;
      const orderResponse = await AdminCheckrPayOrderService.getByFilter(
        `?orderId=${newOrderId}&userId=${userId}`,
      );
      const orderData = orderResponse.data?.data?.pop();

      setOrder(orderData);
    } catch (e) {
      toast.error(`Order ID ${id} not found`);
    }
  };

  const closeConfirmDialog = () => {
    setShowConfirm(false);
  };

  const confirmReversePayout = async () => {
    setShowConfirm(true);
  };

  const doReversePayout = async () => {
    try {
      setIsReversingPayout(true);
      order.repayDate = DateUtil.getCurrentDateTimeString();
      await AdminCheckrPayOrderService.patch(order);
      closeConfirmDialog();
      setIsReversingPayout(false);
      toast.success('Payout reversed successfully.');
    } catch (error) {
      order.repayDate = null;
      closeConfirmDialog();
      setIsReversingPayout(false);
      toast.error('There was an error reversing the payout.');
    }
  };

  const searchKeyPress = event => {
    if (event.key === 'Enter') {
      getOrderOnEnter(orderId);
    }
  };

  const updateOrderId = event => {
    setOrderId(event.target.value);
  };

  return (
    <>
      <Dialog open={showConfirm} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Payout Reversal</DialogTitle>
        <DialogContent>
          Are you sure you want to reverse the payout for <b>Order ID {order?.id}</b>? This action is irreversible.
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={doReversePayout}
            disabled={isReversingPayout}
            color='primary'
            autoFocus
          >
            Reverse
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={classes.paper}>
        <AppBar
          className={classes.searchBar}
          position='static'
          color='default'
          elevation={0}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems='center'>
              <SearchIcon className={classes.block} color='inherit' />
              <Grid item xs>
                <TextField
                  fullWidth
                  required
                  id='orderIdSearch'
                  label='Search by Order ID'
                  name='orderIdSearch'
                  onChange={updateOrderId}
                  onKeyPress={searchKeyPress}
                  type='number'
                  value={orderId}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          {order?.id ? (
            <CheckrPayoutOrderCard order={order} reversePayout={confirmReversePayout} />
          ) : (
            <Typography color='textPrimary' align='center'>
              { orderId ? 'Order not found' : 'Search by order_id above. Only supports CheckrPay orders.' }
            </Typography>
          )}
        </div>
      </Paper>
    </>
  );
}

export default withStyles(OrderLookup, styles);
