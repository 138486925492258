import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tooltip,
} from '@mui/material';
import { CompanyEmployee, CompanyEmployeeService } from 'us-web-services';
import { Table, TableInstance } from 'src/components/common/Table';
import PageStyles from 'src/styles/PageStyles';
import DateUtil from '../../../util/DateUtil';

function EmployeeLookup(props: RouteComponentProps) {
  const { history } = props;
  const [showConfirmDisable, setShowConfirmDisable] = React.useState(false);
  const [showConfirmEnable, setShowConfirmEnable] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState<CompanyEmployee>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const tableRef = React.useRef<TableInstance>();

  const { classes } = PageStyles();

  const params = (globalFilter: string) => {
    const paramArray = [];

    if (!+globalFilter) {
      const encodedFilter = globalFilter.replace("'", "\\'");
      const nameString = encodedFilter.split(' ');

      let q = '';

      if (nameString.length > 1) {
        q = `firstName.contains('${nameString[0]}').and(lastName.contains('${nameString[1]}'))`;
      } else {
        q = `firstName.contains('${encodedFilter}')`;
        q += `.or(lastName.contains('${encodedFilter}'))`;
        q += `.or(email.startsWith('${encodedFilter}'))`;
      }

      paramArray.push({
        key: 'q',
        value: q,
      });
    } else {
      paramArray.push({
        key: 'userId',
        value: globalFilter,
      });
    }

    return paramArray;
  };

  const closeConfirmDisableDialog = () => {
    setShowConfirmDisable(false);
  };

  const closeConfirmEnableDialog = () => {
    setShowConfirmEnable(false);
  };

  const refreshEmployees = () => tableRef.current?.fetchData();

  const disableEmployee = async () => {
    setIsSubmitting(true);
    const patchData:CompanyEmployee = {
      id: currentRow.id,
      disabled: DateUtil.getCurrentDateTimeString(),
    };

    CompanyEmployeeService.patch(patchData.id, patchData).then(() => {
      currentRow.disabled = patchData.disabled;
      closeConfirmDisableDialog();
      setIsSubmitting(false);
      refreshEmployees();
    });
  };

  const enableEmployee = async () => {
    setIsSubmitting(true);
    const data:CompanyEmployee = {
      ...currentRow,
      disabled: null,
    };

    CompanyEmployeeService.update(data.id, data).then(() => {
      currentRow.disabled = data.disabled;
      closeConfirmEnableDialog();
      setIsSubmitting(false);
      refreshEmployees();
    });
  };

  const editEmployee = async employee => {
    const data:CompanyEmployee = {
      id: employee.row.id,
      email: employee.values.email,
      externalId: employee.values.externalId,
    };

    if (employee.values.email === employee.row.email && employee.values.externalId === employee.row.externalId) {
      return;
    }

    await CompanyEmployeeService.patch(employee.row.id, data).then(() => {
      toast.success('Employee successfully updated');
    });
  };

  const confirmDisable = (rowData) => {
    setShowConfirmDisable(true);
    setCurrentRow(rowData);
  };

  const confirmEnable = (rowData) => {
    setShowConfirmEnable(true);
    setCurrentRow(rowData);
  };

  const addEmployee = () => {
    history.push('/company-employees/add');
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'employees',
        header: 'Company Employees',
        columns: [
          {
            header: 'Status',
            enableEditing: false,
            size: 80,
            accessorFn: row => row?.disabled ? 'Disabled' : 'Enabled',
            Cell: ({ cell }) => (
              <>
                {!!cell?.row?.original.disabled &&
                  <Tooltip title='Enable employee' placement='top-start'>
                    <Button
                      size='small'
                      variant='contained'
                      color='error'
                      onClick={() => confirmEnable(cell?.row?.original)}
                    >
                      Disabled
                    </Button>
                  </Tooltip>
                }
                {!cell?.row?.original.disabled &&
                  <Tooltip title='Disable employee' placement='top-start'>
                    <Button
                      size='small'
                      variant='contained'
                      color='success'
                      onClick={() => confirmDisable(cell?.row?.original)}
                    >
                      Enabled
                    </Button>
                  </Tooltip>
                }
              </>
            ),
          },
          {
            accessorKey: 'userId',
            header: 'User ID',
            enableEditing: false,
            size: 60,
          },
          {
            accessorKey: 'companyId',
            header: 'Company ID',
            enableEditing: false,
            size: 70,
          },
          {
            accessorKey: 'name',
            header: 'Name',
            enableEditing: false,
          },
          {
            accessorKey: 'email',
            header: 'Work Email',
          },
          {
            accessorKey: 'externalId',
            header: 'External ID',
          },
          {
            accessorKey: 'disabled',
            header: 'Disabled Date',
            enableEditing: false,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirmDisable} onClose={closeConfirmDisableDialog}>
        <DialogTitle>Confirm Employee Deactivation</DialogTitle>
        <DialogContent>
          Are you sure you want to disabled this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDisableDialog} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={disableEmployee}
            disabled={isSubmitting}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmEnable} onClose={closeConfirmEnableDialog}>
        <DialogTitle>Confirm Employee Activation</DialogTitle>
        <DialogContent>
          Are you sure you want to enable this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmEnableDialog} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={enableEmployee}
            disabled={isSubmitting}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={CompanyEmployeeService.findByFilter}
        params={params}
        addAction={addEmployee}
        editAction={editEmployee}
        enableInlineEditing
        alwaysApplyParams
      />
    </Paper>
  );
}

export default withRouter(EmployeeLookup);
