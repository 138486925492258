import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Cookies from 'universal-cookie';

import {
  BookingService,
  Company,
  UserAdminTag,
  UserPIIRemovalService,
  UserService,
  UserState,
} from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import HostsService from '../../util/HostsService';
import useAuthentication from '../../store/useAuthentication';
import { StorageNames } from '../../constants';
import DateUtil from '../../util/DateUtil';

interface Props extends RouteComponentProps {
  lookupUser: UserState;
  userAdminTags: UserAdminTag[];
  userCompany?: Company;
}

function UserCard(props: Props) {
  const { history, lookupUser, userAdminTags, userCompany = {} } = props;

  const [authenticationState] = useAuthentication();
  const { classes } = CardStyles();
  const [duplicatePhoneCount, setDuplicatePhoneCount] = useState(0);
  const [completedBookings, setCompletedBookings] = useState(0);
  const [acceptedBookings, setAcceptedBookings] = useState(0);
  const [pendingBookings, setPendingBookings] = useState(0);
  const role = lookupUser.user.parent ? 'parent' : 'sitter';
  const [userPIIRemovalDate, setUserPIIRemovalDate] = useState(null);

  const setBookingCount = (type, count) => {
    switch (type) {
      case 'completed':
        setCompletedBookings(count);
        break;
      case 'accepted':
        setAcceptedBookings(count);
        break;
      case 'pending':
        setPendingBookings(count);
        break;
      default:
        break;
    }
  };

  const getProfileUrl = () => {
    const base = `https://${HostsService.getAppHost()}/app`;

    return `${base}/${role}/${lookupUser.user.id}`;
  };

  const editUser = () => {
    history.push(`/users/edit/${lookupUser.user.id}`);
  };

  const masquerade = () => {
    const cookies = new Cookies();
    const cookieValue = {
      adminToken: authenticationState.authentication.token,
      host: HostsService.getAppHost(),
      user: lookupUser.user,
      adminUserId: authenticationState.authentication.user.id,
    };

    let domain = '.urbansitter.net';

    if (cookieValue.host.indexOf('.com') > -1) {
      domain = '.urbansitter.com';
    }

    cookies.set(StorageNames.US_MASQUERADE, cookieValue, {
      domain,
      path: '/',
    });

    window.open(`https://${cookieValue.host}`, '_blank');
  };

  useEffect(() => {
    const getBookingCounts = async () => {
      const bookingConfig = { params: {} };
      const bookingTypes = ['completed', 'accepted', 'pending'];
      const roleId = `${role}Id`; // parentId or sitterId depending on user role

      bookingConfig.params[roleId] = lookupUser.user.id;

      const bookingPromises = bookingTypes.map(async filterType => {
        const response = await BookingService.getByFilter({
          ...bookingConfig,
          params: { ...bookingConfig.params, filterType },
        });

        setBookingCount(filterType, response.data.meta.size);
      });

      await Promise.all(bookingPromises);
    };

    const getDuplicatePhoneCount = async () => {
      const config = {
        params: {
          phone: lookupUser.profile.phone,
          limit: 1,
        },
      };
      const response = (await UserService.getByFilter(config)).data;

      setDuplicatePhoneCount(response.meta.size);
    };

    const getUserPIIRemovalDate = async () => {
      UserPIIRemovalService.findById(lookupUser.user.id).then(response =>
        setUserPIIRemovalDate(response?.data),
      );
    };

    getBookingCounts();

    if (lookupUser.profile.phone) {
      getDuplicatePhoneCount();
    } else {
      setDuplicatePhoneCount(0);
    }

    if (!lookupUser.user.active) {
      getUserPIIRemovalDate();
    }
  }, [lookupUser.user.id, lookupUser.profile.phone, role]);

  return (
    <Card className={classes.card}>
      <div className={classes.photo}>
        <CardMedia
          image={lookupUser.user.pictureUrl}
          title={`${lookupUser.user.name}'s photo`}
          component='img'
        />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            <a
              href={getProfileUrl()}
              className={lookupUser.user.active ? '' : classes.error}
              target='_blank'
              rel='noreferrer noopener'
            >
              {lookupUser.user.firstName} {lookupUser.user.lastName}
            </a>{' '}
            {`(uid: ${lookupUser.user.id})`}
          </Typography>
          {lookupUser.user.active ? (
            ''
          ) : (
            <Typography className={classes.error}>
              This user is deactivated
            </Typography>
          )}
          <Typography variant='subtitle1' color='textPrimary'>
            Email: {lookupUser.user.email}<br />
            Location: {lookupUser.profile.address.city ? `${lookupUser.profile.address.city}, ` : ''}
            {lookupUser.profile.address.state}<br />
            Phone: {lookupUser.profile.phone}
            {duplicatePhoneCount > 1 && (` (duplicate count: ${duplicatePhoneCount})`)}<br />
            Registration date:{' '}
            {DateUtil.getReadableDateTime(lookupUser.user.created)}{' '}
            {lookupUser.profile.userStats && (
              <span>
                <br />
                Last accessed:{' '}
                {DateUtil.getReadableDateTime(lookupUser.profile.userStats.lastAccessDate)}
              </span>
            )}
            <br />
            User Role:{' '}
            <b>
              {lookupUser.user.parent ? 'Parent ' : ''}
              {lookupUser.user.sitter ? 'Sitter ' : ''}
              {lookupUser.user.superUser ? 'Super User' : ''}
            </b>
            {userPIIRemovalDate && (
              <div>
                PII Removed:{' '}
                {DateUtil.getReadableDateTime(userPIIRemovalDate.modified)}
              </div>
            )}
          </Typography>
          <CardActions>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              onClick={masquerade}
            >
              Masquerade
            </Button>
            <Button
              size='small'
              variant='outlined'
              color='primary'
              onClick={editUser}
            >
              Edit
            </Button>
          </CardActions>
        </CardContent>
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {lookupUser.user.sitter ? (
            <Typography variant='subtitle1' color='textPrimary'>
              DOB: {DateUtil.getReadableDate(lookupUser.profile.dob)}
            </Typography>
          ) : null}
          <Typography variant='subtitle1' color='textPrimary'>
            Completed Bookings: {completedBookings} <br />
            Accepted Bookings: {acceptedBookings} <br />
            Pending Bookings: {pendingBookings} <br />
            Admin Tags:{' '}
            {!userAdminTags || !userAdminTags.length ? <>None<br /></> : ' '}
            <b>
              {userAdminTags &&
                userAdminTags.map(tag => (
                  <Chip
                    key={tag.id}
                    label={tag.tag.code}
                    style={{ marginRight: 5 }}
                    color='secondary'
                  />
                ))}
            </b>
            {userCompany && (
              <div>
                Active BD Company:{' '}
                <Link to={`/companies/${userCompany.id}`}>{userCompany.name}</Link>
              </div>
            )}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}

export default withRouter(UserCard);
