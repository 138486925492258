export default {
  STATUSES: {
    CLEARED: {
      id: 1,
      label: 'Cleared',
    },
    SUBMITTED: {
      id: 2,
      label: 'Submitted',
    },
    PENDING: {
      id: 3,
      label: 'Pending',
    },
    FAILED: {
      id: 4,
      label: 'Failed',
    },
    UNKNOWN: {
      id: 5,
      label: 'Unknown',
    },
  },
};
