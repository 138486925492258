import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Order,
  OrderService,
} from 'us-web-services';
import useUser from '../../store/useUser';
import PageStyles from '../../styles/PageStyles';

function OrderItemVoucher() {
  const { classes } = PageStyles();
  const userCache = useUser()[0];
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const getOrders = () => {
      const config = {
        params: {
          limit: 10,
          page: 1,
          userId: userCache.user.id,
        },
      };

      OrderService.getByFilter(config).then(result => {
        const tempIterations = [];

        result.data.data.map(item => (
          tempIterations.push(item)
        ));
        setOrders(tempIterations);
      });
    };

    getOrders();
  }, []);

  return (
    <Paper>
      <Card className={classes.paper}>
        <div>
          <CardContent>
            <h3>Items Vouchers</h3>
          </CardContent>
        </div>
        <div>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Order Id</TableCell>
                  <TableCell>User Voucher Id</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders &&
                  orders.map(order => (
                    order?.itemVouchers?.map(item => (
                      <TableRow key={order.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{order.id}</TableCell>
                        <TableCell>{item.userVoucherId}</TableCell>
                        <TableCell>{item.value}</TableCell>
                      </TableRow>
                    ))
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </div>
      </Card>
    </Paper>
  );
}

export default OrderItemVoucher;
