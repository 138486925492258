import axios from 'axios';
import { AuthToken } from './types/authentication/AuthToken';
import { AuthTokenService } from './services';

interface Authentication {
  user: {
    id?: number;
  };
  updateToken(token: AuthToken): void;
}

const US_USER_AUTH_TOKEN = 'US_USER_AUTH_TOKEN';
const US_USER_REFRESH_TOKEN = 'US_USER_REFRESH_TOKEN';

const initBearerToken = () => {
  axios.interceptors.request.use(
    async config => {
      const token = localStorage.getItem(US_USER_AUTH_TOKEN);
      if (
        config.url?.indexOf('/v2/public/auth-token') === -1 &&
        config.headers &&
        token
      ) {
        config.headers.Authorization = `Bearer ${token}`;
      } else if (config.headers) {
        config.headers.Authorization = '';
      }

      return config;
    },
    async error => Promise.reject(error)
  );
};

let failedQueue: any[] = [];

const processServicesQueue = () => {
  failedQueue.forEach(prom => {
    prom.resolve();
  });

  failedQueue = [];
};

let lastRefreshRequest: number;
const requestTimeLimit = 5000;

const canRefresh = (): boolean => (
    lastRefreshRequest === null ||
    lastRefreshRequest === undefined ||
    lastRefreshRequest + requestTimeLimit < Date.now()
  );

const setServicesLastRefreshRequest = (servicesLastRefreshRequest: number) => {
  lastRefreshRequest = servicesLastRefreshRequest;
};

const initResponseInterceptor = (
  authentication: Authentication,
  processAppQueues?: Function
) => {
  axios.interceptors.response.use(
    response => response,
    async error => {
      const refreshToken = localStorage.getItem(US_USER_REFRESH_TOKEN);
      if (error.response?.status === 401 && refreshToken) {
        if (canRefresh()) {
          lastRefreshRequest = Date.now();
          const authToken: AuthToken = {
            userId: authentication.user.id,
            refreshToken: {
              token: refreshToken,
            },
          };

          try {
            const authResponse = await AuthTokenService.create(authToken);
            const responseData = authResponse.data.data;

            authentication.updateToken(responseData);
            processServicesQueue();
            if (processAppQueues) {
              processAppQueues();
            }

            return await axios.request(error.config);
          } catch (e) {
            authentication.updateToken({});

            return Promise.reject(error);
          }
        } else {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              const token = localStorage.getItem(US_USER_AUTH_TOKEN);

              error.config.headers.Authorization = `Bearer ${token}`;
              return axios(error.config);
            })
            .catch(err => Promise.reject(err));
        }
      }

      return Promise.reject(error);
    }
  );
};

export {
  initBearerToken,
  initResponseInterceptor,
  processServicesQueue,
  setServicesLastRefreshRequest,
};
export * from './services';
export * from './types';
