import axios, { AxiosRequestConfig } from 'axios';

import { DataResponse, PagedResponse, ScammerOverride } from '../types';

const OVERRIDE_URL = '/v2/overages';

class ScammerOverrideService {
  static findByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ScammerOverride>> {
    return axios.get(OVERRIDE_URL, config);
  }

  static create(
    data: ScammerOverride,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ScammerOverride>> {
    return axios.post(OVERRIDE_URL, data, config);
  }

  static post(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ScammerOverride>> {
    return axios.post(`${OVERRIDE_URL}/${data.id}`, data, config);
  }
}

export { ScammerOverrideService };
