export default {
  STATUSES: [
    'pending',
    'accepted',
    'sitter_confirmed',
    'declined',
    'expired',
    'sitter_cancel',
    'parent_cancel',
    'sitter_reschedule',
    'parent_reschedule',
    'parent_confirm_payment',
    'closed_duplicate',
    'job_finished',
    'job_uncompleted',
    'processing_charge',
    'charge_failed_hard',
    'processing_deposit',
    'deposit_complete',
    'deposit_failed_hard',
    'closed_completed',
  ],
  GROUP_STATUSES: ['pending', 'fulfilled_us', 'fulfilled_nus', 'parent_cancel'],
  PAYMENT_FREQUENCIES: [
    {
      id: 1,
      label: 'Hourly',
    },
    {
      id: 2,
      label: 'Flat rate',
    },
  ],
  PAYMENT_TYPES: ['cash', 'credit'],
  CANCELLATION_REASONS: [
    {
      value: '',
      label: 'Remove Reason',
    },
    {
      value: 'not_cancelled',
      label: 'Not Canceled',
    },
    {
      value: 'standard_cancel',
      label: 'Standard Cancel',
    },
    {
      value: 'problematic_cancel',
      label: 'Problematic Cancel',
    },
    {
      value: 'sitter_noshow',
      label: 'Sitter No Show',
    },
  ],
  SITTER_CANCEL_REASONS: [
    {
      value: 'illness_emergency',
      label: 'Illness or emergency',
    },
    {
      value: 'parent_canceled',
      label: 'Parent canceled',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
  PARENT_CANCEL_REASONS: [
    {
      value: 'illness_emergency',
      label: 'Illness or emergency',
    },
    {
      value: 'sitter_canceled',
      label: 'Sitter canceled',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
};
