import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { SitterSpecialSituation } from '../types/SitterSpecialSituation';

const URL = '/v2/sitter-special-situations';

class SitterSpecialSituationsService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<SitterSpecialSituation>> {
    return axios.get(URL, config);
  }

  static create(data: SitterSpecialSituation, config?: AxiosRequestConfig): Promise<SitterSpecialSituation> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig): Promise<void> {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { SitterSpecialSituationsService };
