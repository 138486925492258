import axios, { AxiosRequestConfig } from 'axios';

import { PagedResponse, Plan } from '../types';

const URL = '/v2/plans';

class PlanService {
  static getByFilter(config: AxiosRequestConfig): Promise<PagedResponse<Plan>> {
    return axios.get(`${URL}`, config);
  }
}

export { PlanService };
