import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import {
  MasterServiceAreaPlanPrice,
  MasterServiceAreaPlanPriceService,
  MasterServiceAreaPriceTestService,
} from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import MasterServiceAreaPlanPriceForm from './MasterServiceAreaPlanPriceForm';

function MasterServiceAreaPlanPriceEdit(props: RouteComponentProps<{ id: string, masterServiceAreaId: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [masterServiceAreaPlanPrice, setMasterServiceAreaPlanPrice]
    = useState<MasterServiceAreaPlanPrice>({ plan: {} });

  useEffect(() => {
    const masterServiceAreaPlanPriceId = Number.parseInt(match.params.id, 10);

    if (masterServiceAreaPlanPriceId) {
      const getData = async () => {
        const response = (await MasterServiceAreaPlanPriceService.get(masterServiceAreaPlanPriceId)).data;

        if (response.data.id) {
          setMasterServiceAreaPlanPrice(response.data);
        } else {
          setMasterServiceAreaPlanPrice({ plan: {} });
        }

        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push(`/master-service-areas/${match.params.masterServiceAreaId}`);
  };

  const saveSuccess = () => {
    toast.success('Master Service Area Price updated successfully');
    navigateToList();
  };

  const saveMasterServiceAreaPriceTest = async () => {
    masterServiceAreaPlanPrice.masterServiceAreaPriceTest.installmentFee =
      masterServiceAreaPlanPrice.installmentFee;
    masterServiceAreaPlanPrice.masterServiceAreaPriceTest.setupFee =
      masterServiceAreaPlanPrice.setupFee;

    masterServiceAreaPlanPrice.masterServiceAreaPriceTest.masterServiceAreaPlanPriceId = masterServiceAreaPlanPrice.id;

    if (masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id) {
      await MasterServiceAreaPriceTestService.patch(
        masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id,
        masterServiceAreaPlanPrice.masterServiceAreaPriceTest,
      );
    } else {
      await MasterServiceAreaPriceTestService.create(
        masterServiceAreaPlanPrice.masterServiceAreaPriceTest,
      );
    }
  };

  const saveMasterServiceArea = async () => {
    try {
      if (masterServiceAreaPlanPrice.id) {
        await MasterServiceAreaPlanPriceService.patch(
          masterServiceAreaPlanPrice.id,
          masterServiceAreaPlanPrice,
        );
        if (masterServiceAreaPlanPrice.masterServiceAreaPriceTest) {
          await saveMasterServiceAreaPriceTest();
        }
      } else {
        masterServiceAreaPlanPrice.masterServiceArea = {
          id: Number.parseInt(match.params.masterServiceAreaId, 10),
          name: '',
        };
        await MasterServiceAreaPlanPriceService.create(
          masterServiceAreaPlanPrice,
        );
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {masterServiceAreaPlanPrice.id ? 'Edit' : 'Add'} Master Service Area
        Price
      </Typography>
      {loaded ? (
        <MasterServiceAreaPlanPriceForm
          masterServiceAreaPlanPrice={masterServiceAreaPlanPrice}
          setMasterServiceAreaPlanPrice={setMasterServiceAreaPlanPrice}
        />
      ) : (
        'Loading'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveMasterServiceArea}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(MasterServiceAreaPlanPriceEdit);
