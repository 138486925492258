import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { PublicJob } from '../types/PublicJob';

const URL = '/v2/public/jobs';

class PublicJobService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<PublicJob>> {
    return axios.get(URL, config);
  }

  static getByFilterInternal(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<PublicJob>> {
    return axios.get(
      `http://${process.env.US_SERVICES_HOST}:${process.env.US_SERVICES_PORT}${URL}`,
      config
    );
  }
}

export { PublicJobService };
