import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import toast from 'react-hot-toast';

import { CsvParserService, Company } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import DisplayService from '../../../util/DisplayService';

interface Props extends RouteComponentProps {
  company?: Company;
}

function CompanyEmployeeToggle(props: Props) {
  const { company } = props;
  const [file, setFile] = useState(null);
  const [parsed, setParsed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(null);
  const [activateCount, setActivateCount] = useState(null);
  const [deactivateCount, setDeactivateCount] = useState(null);
  const [unknownCount, setUnknownCount] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const fileInput = React.createRef<HTMLInputElement>();
  const { classes } = PageStyles();

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const showError = useCallback(error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error uploading the CSV.',
    );

    displayedError.message = `Error: ${error.response.data.errors[0].message}`;
    toast.error(displayedError.message);
    setUploadSuccessful(false);
  }, []);

  const resetAfterUpload = () => {
    setFile(null);
    setParsed(false);
    setActivateCount(null);
    setDeactivateCount(null);
    setUnknownCount(null);
    setUploadSuccessful(true);
  };

  const showProcessedData = data => {
    let activate = 0;
    let deactivate = 0;
    let unknown = 0;

    data.forEach(r => {
      const action = r.Action;

      if (action === 'activate') {
        activate += 1;
      } else if (action === 'deactivate') {
        deactivate += 1;
      } else {
        unknown += 1;
      }
    });

    setActivateCount(activate);
    setDeactivateCount(deactivate);
    setUnknownCount(unknown);
    setParsed(true);
  };

  const processFile = async () => {
    if (!fileInput.current.files[0]) {
      toast.error('Uploaded file does not exist');

      return;
    }

    setUploadSuccessful(null);
    setFile(fileInput.current.files[0]);
    const reader = new window.FileReader();

    reader.onload = e => {
      const data = CsvParserService.parse(e.target.result.toString());

      showProcessedData(data);
    };

    reader.readAsText(fileInput.current.files[0]);
  };

  const uploadFile = async () => {
    const formData = new FormData();

    formData.append('file', file);
    setShowConfirm(false);
    setUploading(true);

    await axios
      .post(`/v2/company-employee-toggle-bulk/${company.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(resetAfterUpload)
      .catch(showError)
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Ready to upload?</DialogTitle>
        <DialogContent>
          <p>
            Activate: {activateCount}
            <br />
            Deactivate: {deactivateCount}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={uploadFile}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Toggle Employee Activation
      </Typography>
      <p>Upload a .csv file to activate/deactivate employees.</p>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <input
            id='csv-file-employee-toggle'
            type='file'
            ref={fileInput}
            onChange={processFile}
          />
        </Grid>
        <Grid item lg={4}>
          {parsed && (
            <Box>
              Activate {activateCount}{' '}
              {activateCount === 1 ? 'employee' : 'employees'}
            </Box>
          )}
          {parsed && (
            <Box>
              Deactivate {deactivateCount}{' '}
              {deactivateCount === 1 ? 'employee' : 'employees'}
            </Box>
          )}
          {parsed && unknownCount > 0 && (
            <Box
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              {unknownCount} {unknownCount === 1 ? 'error' : 'errors'} during
              parsing
            </Box>
          )}
        </Grid>
        <Grid item lg={4}>
          {parsed && (
            <Button
              color='primary'
              disabled={uploading}
              onClick={openConfirm}
              variant='contained'
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          )}
          {uploadSuccessful === true && (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faCheckCircle}
              />
              <span>Upload successful!</span>
            </div>
          )}
          {uploadSuccessful === false && (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faTimesCircle}
              />
              <span>Error occurred</span>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withRouter(CompanyEmployeeToggle);
