import { makeStyles } from 'tss-react/mui';

const PageStyles = makeStyles()(theme => ({
  paper: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),

    [theme.breakpoints.up(768 + Number(theme.spacing(2)) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up(1200 + Number(theme.spacing(3)) * 2)]: {
      width: 900,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default PageStyles;
