import React, { useState } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';

import { CompanyBenefit, CompanyBenefitService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import CompanyBenefitEditForm from './CompanyBenefitEditForm';
import DisplayService from '../../../util/DisplayService';
import DateUtil from '../../../util/DateUtil';

function CompanyBenefitCreate(props: RouteComponentProps<{ companyId: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [benefit, setBenefit] = useState<CompanyBenefit>({
    start: moment().startOf('year').format(DateUtil.midnightDateFormat),
    end: moment()
      .add(1, 'year')
      .startOf('year')
      .format(DateUtil.midnightDateFormat),
    planId: 7,
    description: '',
  });

  const cancel = () => {
    history.push(`/companies/${match.params.companyId}`);
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error adding the benefit.',
    );

    toast.error(displayedError.message);
  };

  const verifyBenefit = () => {
    if (moment(benefit.start).isAfter(moment(benefit.end))) {
      toast.error('End Date should be after Start date');

      return false;
    }

    return true;
  };

  const addBenefit = async () => {
    const verified = verifyBenefit();

    if (verified) {
      const companyId = Number.parseInt(match.params.companyId, 10);
      const benefitData = {
        companyId,
        planId: benefit.planId,
        description: benefit.description,
        statusId: 1,
        start: benefit.start,
        end: benefit.end,
      };

      try {
        const benefitResponse = (await CompanyBenefitService.create(benefitData)).data;

        setBenefit(benefitResponse.data);
        toast.success('Benefit created');

        history.push(`/company-benefits/${benefitResponse.data.id}`);
      } catch (error) {
        showError(error);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <CompanyBenefitEditForm benefit={benefit} setBenefit={setBenefit} />
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={cancel}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={addBenefit}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(CompanyBenefitCreate);
