import axios, { AxiosRequestConfig } from 'axios';
import { MasterServiceAreaPriceTest } from '../../types/master_service_area/MasterServiceAreaPriceTest';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/master-service-area-price-tests';

class MasterServiceAreaPriceTestService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPriceTest>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceAreaPriceTest>> {
    return axios.get(URL, config);
  }

  static create(
    data: MasterServiceAreaPriceTest,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPriceTest>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static patch(
    id: number,
    data: MasterServiceAreaPriceTest,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPriceTest>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { MasterServiceAreaPriceTestService };
