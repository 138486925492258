import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/admin/reset-passwords';

class ResetPasswordsService {
  static resetPasswords(formData: FormData, config?: AxiosRequestConfig) {
    return axios.post(URL, formData, config);
  }
}

export { ResetPasswordsService };
