import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { FavoriteSitter } from '../types/FavoriteSitter';

const URL = '/v2/parent-favorites';

class FavoriteSitterService {
  static create(
    data: FavoriteSitter,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<FavoriteSitter>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}
export { FavoriteSitterService };
