import React, { useState } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';

import { ZipCode } from 'us-web-services';
import { States } from '../../../constants';

interface Props {
  zipCode: ZipCode;
  setZipCode(zipCode: ZipCode): void;
}

export default function ZipCodeForm(props: Props) {
  const { zipCode, setZipCode } = props;
  const [code, setCode] = useState(zipCode.zipCode);
  const [state, setState] = useState(zipCode.state);
  const [city, setCity] = useState(zipCode.city);
  const [timeZone, setTimeZone] = useState(zipCode.timeZone);
  const [latitude, setLatitude] = useState(zipCode.latitude);
  const [longitude, setLongitude] = useState(zipCode.longitude);

  const updateCode = event => {
    setCode(event.target.value);
    zipCode.zipCode = event.target.value;
    setZipCode(zipCode);
  };

  const updateState = event => {
    setState(event.target.value);
    zipCode.state = event.target.value;
    setZipCode(zipCode);
  };

  const updateCity = event => {
    setCity(event.target.value);
    zipCode.city = event.target.value;
    setZipCode(zipCode);
  };

  const updateTimeZone = event => {
    setTimeZone(event.target.value);
    zipCode.timeZone = event.target.value;
    setZipCode(zipCode);
  };

  const updateLatitude = event => {
    setLatitude(event.target.value);
    zipCode.latitude = event.target.value;
    setZipCode(zipCode);
  };

  const updateLongitude = event => {
    setLongitude(event.target.value);
    zipCode.longitude = event.target.value;
    setZipCode(zipCode);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='code'
          name='code'
          label='Code'
          fullWidth
          value={code}
          onChange={updateCode}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='city'
          name='city'
          label='City'
          fullWidth
          value={city}
          onChange={updateCity}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label='State'
          select
          fullWidth
          value={state}
          onChange={updateState}
        >
          {Object.values(States).map(option => (
            <MenuItem key={option.code} value={option.code}>
              {option.code}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='timeZone'
          name='timeZone'
          label='Time Zone'
          fullWidth
          value={timeZone}
          onChange={updateTimeZone}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='latitude'
          name='latitude'
          label='Latitude'
          fullWidth
          value={latitude}
          onChange={updateLatitude}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='longitude'
          name='longitude'
          label='Longitude'
          fullWidth
          value={longitude}
          onChange={updateLongitude}
        />
      </Grid>
    </Grid>
  );
}
