import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { Include } from '../types/Include';

const URL = '/v2/booking-includes';

class IncludeService {
  static getBookingIncludes(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Include>> {
    return axios.get(URL, config);
  }
}

export { IncludeService };
