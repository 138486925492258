import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, IconButton } from '@mui/material';
import { PlayArrow, ThumbDown, ThumbUp } from '@mui/icons-material';

import { VideoService, VideoModerationService } from 'us-web-services';
import VideoModal from '../videos/VideoModal';
import VideoModerationConfirmModal from '../videos/VideoModerationConfirmModal';
import { Table, TableInstance } from '../common/Table';

function UserVideoModeration(props) {
  const { userId } = props;
  const [url, setUrl] = useState('');
  const [currentId, setCurrentId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmRejectModal, setOpenConfirmRejectModal] = useState(false);
  const tableRef = React.useRef<TableInstance>();

  const openVideoModal = rowData => {
    setUrl(`https://player.vimeo.com/video/${rowData.vimeoId}`);
    setOpenModal(true);
  };

  const refreshModeration = (approved: boolean) => {
    toast.success(`Video ${approved ? 'approved' : 'rejected'}`);
    tableRef.current?.fetchData();
  };

  const approveVideo = async rowData => {
    setCurrentId(rowData.moderationId);
    setOpenConfirmApproveModal(true);
  };

  const rejectVideo = async rowData => {
    setCurrentId(rowData.moderationId);
    setOpenConfirmRejectModal(true);
  };

  const confirmApproval = async () => {
    const patchObject = {
      id: currentId,
      status: 'approved',
    };

    await VideoModerationService.patch(patchObject).then(() => refreshModeration(true));

    setOpenConfirmApproveModal(false);
  };

  const confirmRejection = async () => {
    const patchObject = {
      id: currentId,
      status: 'rejected',
    };

    await VideoModerationService.patch(patchObject).then(() => refreshModeration(false));

    setOpenConfirmRejectModal(false);
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'benefits',
        header: 'Video Moderation',
        columns: [
          {
            header: 'Video ID',
            accessorKey: 'id',
          },
          {
            header: 'Moderation Status',
            accessorKey: 'status',
          },
          {
            header: 'Actions',
            Cell: ({ cell }) => (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => openVideoModal(cell?.row?.original)}
                >
                  <PlayArrow />
                </Button>
                <IconButton
                  aria-label='approve'
                  onClick={() => approveVideo(cell?.row?.original)}
                  size='large'
                >
                  <ThumbUp />
                </IconButton>
                <IconButton
                  aria-label='reject'
                  onClick={() => rejectVideo(cell?.row?.original)}
                  size='large'
                >
                  <ThumbDown />
                </IconButton>
              </>
            ),
          },
        ],
      },
    ],
    [],
  );

  const params = () => [
    {
      key: 'sort',
      value: 'id',
    },
    {
      key: 'id.dir',
      value: 'desc',
    },
    {
      key: 'userId',
      value: userId,
    },
  ];

  return (
    <div style={props.style}>
      <VideoModal url={url} open={openModal} setOpen={setOpenModal} />
      <VideoModerationConfirmModal
        action='approve'
        confirmAction={confirmApproval}
        open={openConfirmApproveModal}
        setOpen={setOpenConfirmApproveModal}
      />
      <VideoModerationConfirmModal
        action='reject'
        confirmAction={confirmRejection}
        open={openConfirmRejectModal}
        setOpen={setOpenConfirmRejectModal}
      />
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={VideoService.getByFilter}
        params={params}
        alwaysApplyParams
      />
    </div>
  );
}

export default UserVideoModeration;
