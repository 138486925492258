import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  connection: string;
  confirmAction: () => Promise<void>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function UserConnectionConfirmModal(props: Props) {
  const { connection, confirmAction, open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Confirm Disconnection</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to disconnect {connection}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmAction} color='primary'>
          Confirm
        </Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserConnectionConfirmModal;
