import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { SitterService } from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import DisplayService from '../../util/DisplayService';

function SitterProfileStatus(props) {
  const { lookupUser, userId } = props;
  const { classes } = CardStyles();
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [hiddenReasons, setHiddenReasons] = useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const getReason = code => hiddenReasons.find(item => item.id === code);

  useEffect(() => {
    const initializeReasons = async () => {
      const hiddenReasonResponse = await SitterService.getHiddenReasons();

      setHiddenReasons(hiddenReasonResponse.data.data);
      setSelectedStatus(lookupUser.profile.hiddenReason);
    };

    if (lookupUser.user.sitter) {
      initializeReasons();
    }
  }, [lookupUser, userId]);

  const closeConfirmDialog = () => {
    setShowConfirm(false);
    setSelectedStatus(lookupUser.profile.hiddenReason);
  };
  const confirmUpdate = event => {
    setSelectedStatus(event.target.value);
    setShowConfirm(true);
  };

  const showError = (error, defaultMessage) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      defaultMessage,
    );

    toast.error(displayedError.message);
  };
  const showSuccess = message => {
    toast.success(message);
  };

  const saveReason = async () => {
    setIsSubmitting(true);
    const params = {
      id: lookupUser.profile.id,
      hiddenReason: selectedStatus,
      userId,
    };

    try {
      await SitterService.patch(params.id, params);
      lookupUser.profile.hiddenReason = selectedStatus;
      closeConfirmDialog();
      setIsSubmitting(false);
      showSuccess('Profile status successfully updated');
    } catch (error) {
      setIsSubmitting(false);
      setSelectedStatus(lookupUser.profile.hiddenReason);
      showError(error, 'Failed to update status');
    }
  };
  const saveAndSendEmail = () => {
    saveReason();
    SitterService.sendApprovalEmail(userId);
  };

  return (
    <>
      {hiddenReasons.length > 0 && lookupUser.profile.id ? (
        <Dialog open={showConfirm} onClose={closeConfirmDialog}>
          <DialogContent>
            <Typography
              component='h5'
              variant='h5'
              style={{ marginBottom: 15 }}
            >
              Change hidden status for {lookupUser.profile.name}?
            </Typography>
            Current hidden status is:{' '}
            <b>{getReason(lookupUser.profile.hiddenReason).type}</b>
            <br />
            Set new status to: <b>{getReason(selectedStatus).type}</b>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmDialog} color='primary'>
              Cancel
            </Button>
            {selectedStatus === 0 ? (
              <Button
                onClick={saveAndSendEmail}
                disabled={isSubmitting}
                color='primary'
                variant='contained'
              >
                Save and Notify User
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={saveReason}
              disabled={isSubmitting}
              color='primary'
              variant='contained'
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ''
      )}
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography
              component='h5'
              variant='h5'
              style={{ marginBottom: 15 }}
            >
              Sitter Profile Status
            </Typography>
            <Select
              id='hidden-status'
              value={selectedStatus}
              onChange={confirmUpdate}
            >
              {hiddenReasons.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.type}
                </MenuItem>
              ))}
            </Select>
          </CardContent>
        </div>
      </Card>
    </>
  );
}

export default SitterProfileStatus;
