import React, { useState } from 'react';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AffiliationService, CityService, LocationRegionsService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function AffiliationCreate(props:RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [selectedCityState, setSelectedCityState] = useState(null);
  const [region, setRegion] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [regionInput, setRegionInput] = useState(null);

  const getCities = async () => {
    const paramList = new URLSearchParams();

    paramList.append('limit', '25');
    paramList.append('page', '1');

    if (cityInput) {
      paramList.append('q', `name.startsWith('${cityInput}')`);
    }

    const config = {
      params: paramList,
    };

    const citiesResponse = await CityService.getByFilter(config);

    const citiesData = citiesResponse.data.data;

    const cityOptions = [];

    citiesData.map(cityData =>
      cityOptions.push({
        id: cityData.id,
        name: cityData.name,
        value: cityData.id,
        label: `${cityData.name}, ${cityData.state}`,
        state: cityData.state,
      }),
    );

    return cityOptions;
  };

  const getRegions = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (regionInput) {
      contentConfig.params.name = regionInput;
    }

    const regionResponse = await LocationRegionsService.getByFilter(
      contentConfig,
    );

    const regionData = regionResponse.data.data;

    const regionOptions = [];

    regionData.map(data =>
      regionOptions.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return regionOptions;
  };

  const updateName = event => {
    setName(event.target.value);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
  };

  const updateCityInput = newValue => {
    setCityInput(newValue);
  };

  const updateRegionInput = newValue => {
    setRegionInput(newValue);
  };

  const navigateToList = () => {
    history.push('/affiliations');
  };

  const saveSuccess = affiliation => {
    toast.success(`${affiliation.name} created successfully`);
    navigateToList();
  };

  const findByName = async affName => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        name: affName,
      },
    };

    const response = await AffiliationService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const findByAlias = async affAlias => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        alias: affAlias,
      },
    };

    const response = await AffiliationService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const showError = errText => {
    toast.error(errText);
  };

  const isInputValid = async () => {
    let isValid = true;

    if (!name) {
      showError('Affiliation name is required');
      isValid = false;
    }

    if (!selectedCityState) {
      showError('Please pick a city and state');
      isValid = false;
    }

    if (!region) {
      showError('Please pick a region');
      isValid = false;
    }

    if (await findByName(name)) {
      showError('Name already exists');
      isValid = false;
    }

    if (await findByAlias(alias)) {
      showError('Alias already exists');
      isValid = false;
    }

    return isValid;
  };

  const createAffiliation = async () => {
    if (await isInputValid()) {
      const affiliation = {
        name,
        alias,
        city: selectedCityState.name,
        state: selectedCityState.state,
        region,
        type: 'group',
        canon_name: alias,
      };

      try {
        await AffiliationService.create(affiliation);
        saveSuccess(affiliation);
      } catch (e) {
        toast.error(e.response.data.errors[0].message);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Add Affiliation
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id='name'
            name='name'
            label='Name'
            required
            fullWidth
            value={name}
            onChange={updateName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='alias'
            name='alias'
            label='Alias'
            fullWidth
            value={alias}
            onChange={updateAlias}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            City and State
          </Typography>
          <AsyncSelect
            id='citystate'
            required
            loadOptions={getCities}
            defaultOptions
            onInputChange={updateCityInput}
            onChange={setSelectedCityState}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            Region
          </Typography>
          <AsyncSelect
            id='region'
            required
            loadOptions={getRegions}
            defaultOptions
            onInputChange={updateRegionInput}
            onChange={setRegion}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={createAffiliation}
          className={classes.button}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(AffiliationCreate);
