import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { CompanyPartner } from '../../types/company/CompanyPartner';

const URL = '/v2/company-partners';

class CompanyPartnerService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyPartner>> {
    return axios.get(URL, config);
  }

  static create(
    data: CompanyPartner,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyPartner>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CompanyPartnerService };
