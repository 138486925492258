import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { AdminNoteService, UserService } from 'us-web-services';
import { useAuthentication } from '../../store';
import PageStyles from '../../styles/PageStyles';

function BatchUsers() {
  const authenticationState = useAuthentication()[0];
  const { classes } = PageStyles();
  const [ids, setIds] = useState([]);
  const [idText, setIdText] = useState('');
  const [noteText, setNoteText] = useState('');
  const [processing, setProcessing] = useState(false);
  const [batchType, setBatchType] = useState(1);

  const BATCH_TYPES = [
    {
      id: 1,
      name: 'Notes',
    },
    {
      id: 2,
      name: 'Block Users',
    },
  ];

  const statusColors = {
    Ready: '#d9edf7',
    Failure: '#f2dede',
    'Invalid ID': '#fcf8e3',
    Success: '#dff0d8',
  };

  const updateBatchType = event => {
    setBatchType(event.target.value);
  };

  const updateIdText = event => {
    setIdText(event.target.value);
  };

  const updateNoteText = event => {
    setNoteText(event.target.value);
  };

  const getStatus = id => {
    if (!Number.isInteger(+id) || +id <= 0) {
      return 'Invalid ID';
    }

    return 'Ready';
  };

  const parseIds = () => {
    const newIds = [];

    idText.split('\n').forEach(id => {
      newIds.push({
        id,
        status: getStatus(id),
      });
    });
    setIds(newIds);
  };

  const setSuccess = id => {
    id.status = 'Success';
  };

  const setFailure = id => {
    id.status = 'Failure';
  };

  const addNote = async id => {
    const adminUserId = authenticationState.authentication.user.id;
    const noteData = {
      user: {
        id: id.id,
      },
      createdBy: {
        id: adminUserId,
      },
      comment: noteText,
    };

    try {
      await AdminNoteService.create(noteData);
      setSuccess(id);
    } catch (e) {
      setFailure(id);
    }
  };

  const addNotes = () => {
    const idsClone = [...ids];
    const promiseArray = [];

    setProcessing(true);

    idsClone.forEach(id => {
      if (id.status === 'Ready') {
        promiseArray.push(addNote(id));
      }
    });

    Promise.all(promiseArray).then(() => {
      setIds(idsClone);
      setProcessing(false);
    });
  };

  const blockUser = async id => {
    const userData = {
      id: id.id,
      active: false,
    };

    try {
      await UserService.patch(id.id, userData);
      setSuccess(id);
    } catch (e) {
      setFailure(id);
    }
  };

  const blockUsers = () => {
    const idsClone = [...ids];
    const promiseArray = [];

    setProcessing(true);

    idsClone.forEach(id => {
      if (id.status === 'Ready') {
        promiseArray.push(blockUser(id));
      }
    });

    Promise.all(promiseArray).then(() => {
      setIds(idsClone);
      setProcessing(false);
    });
  };

  const clearIds = () => {
    setIds([]);
  };

  return (
    <Paper className={classes.paper}>
      <Typography component='h5' variant='h5' style={{ marginBottom: 15 }}>
        Batch User Actions
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            required
            id='batchType'
            name='batchType'
            label='Batch Type'
            select
            fullWidth
            value={batchType}
            onChange={updateBatchType}
          >
            {BATCH_TYPES.map(type => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <br />
      {ids.length === 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h6' gutterBottom>
              Enter uids on separate lines:
            </Typography>
            <TextField
              id='add-ids-field'
              variant='outlined'
              multiline
              minRows='8'
              value={idText}
              onChange={updateIdText}
            />
            <br /> <br />
            <Button
              id='parse-ids-button'
              variant='contained'
              color='primary'
              onClick={parseIds}
            >
              Parse IDs
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ids.map(id => (
                  <TableRow
                    key={id.id}
                    style={{ backgroundColor: statusColors[id.status] }}
                  >
                    <TableCell>{id.id}</TableCell>
                    <TableCell>{id.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          {batchType === 1 ? (
            <Grid item xs={3}>
              <TextField
                id='add-note-field'
                variant='outlined'
                label='Note'
                multiline
                minRows='6'
                style={{ marginRight: 15 }}
                value={noteText}
                onChange={updateNoteText}
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={3}>
            {batchType === 1 ? (
              <Button
                id='add-notes-button'
                variant='contained'
                color='primary'
                disabled={processing}
                onClick={addNotes}
              >
                Add Notes
              </Button>
            ) : (
              ''
            )}
            {batchType === 2 ? (
              <Button
                id='block-users-button'
                variant='contained'
                color='primary'
                disabled={processing}
                onClick={blockUsers}
              >
                Block Users
              </Button>
            ) : (
              ''
            )}
            <br /> <br />
            <Button
              id='clear-ids-button'
              variant='contained'
              color='secondary'
              onClick={clearIds}
            >
              Change IDs
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default withRouter(BatchUsers);
