import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../../types/api';
import { AdminCheckrExternalPayout } from '../../types/checkrpay/AdminCheckrExternalPayout';

const URL = '/v2/admin-checkrpay-external-payout';

class AdminCheckrPayExternalPayoutService {
  static create(
    adminCheckrExternalPayout: AdminCheckrExternalPayout,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminCheckrExternalPayout>> {
    return axios.post(URL, adminCheckrExternalPayout, config);
  }
}

export { AdminCheckrPayExternalPayoutService, AdminCheckrExternalPayout };
