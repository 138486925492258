import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { StateSeoContent, StateSeoContentService } from 'us-web-services';
import StateSeoContentEditForm from './StateSeoContentEditForm';
import PageStyles from '../../styles/PageStyles';

function StateSeoContentEdit(props: RouteComponentProps<{ state: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [stateSeoContent, setStateSeoContent] = useState<StateSeoContent>({});

  useEffect(() => {
    if (match.params.state) {
      const getStateData = async () => {
        const stateSeoContentResponse =
          await StateSeoContentService.getByFilter(
            `?state=${match.params.state}`,
          );

        const stateSeoContentData = stateSeoContentResponse.data.data;

        if (stateSeoContentData.length > 0) {
          setStateSeoContent(stateSeoContentData[0]);
        } else {
          setStateSeoContent({
            state: match.params.state,
          });
        }
      };

      getStateData();
    }
  }, [match.params.state]);

  const navigateToList = () => {
    history.push('/seo/states');
  };

  const saveSuccess = () => {
    toast.success(`${match.params.state} SEO Content updated successfully`);
    navigateToList();
  };

  const saveCityContent = async () => {
    try {
      if (stateSeoContent.id) {
        await StateSeoContentService.patch(
          stateSeoContent.id,
          stateSeoContent,
        );
      } else {
        await StateSeoContentService.create(stateSeoContent);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {match.params.state}
      </Typography>
      {stateSeoContent.state ? (
        <StateSeoContentEditForm
          stateSeoContent={stateSeoContent}
          setStateSeoContent={setStateSeoContent}
        />
      ) : (
        'State not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveCityContent}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(StateSeoContentEdit);
