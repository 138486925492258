import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import DateUtil from '../../../util/DateUtil';
import PageStyles from '../../../styles/PageStyles';
import useViewModel from './VoucherUpdateViewModel';
import VoucherDeactivateConfirm from './components/VoucherDeactivateConfirm';

function VoucherUpdate(props) {
  const { history, match } = props;

  const { uiModel, dispatch, update, deactivateVoucher, sideEffect } =
    useViewModel(match.params.id);

  const { classes } = PageStyles();

  const validateCodeKeypress = e => {
    const re = /[0-9A-Za-z:-]+/g;

    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateNumberKeypress = e => {
    const re = /[0-9]+/g;

    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const showError = (error?: string) => {
    if (error && error.length > 0) {
      toast.error(error);
    }
  };

  useEffect(() => {
    switch (sideEffect.type) {
      case 'error':
        showError(sideEffect.value);
        break;
      case 'route':
        history.push(sideEffect.value);
        break;
      default:
        break;
    }
  }, [sideEffect]);

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {uiModel.pageTitle}
      </Typography>
      {uiModel.pageSubtitle && (
        <Grid item xs={12}>
          <Typography gutterBottom>{uiModel.pageSubtitle}</Typography>
          <br />
        </Grid>
      )}

      <Grid container spacing={3}>
        {!uiModel.edit && (
          <Grid item xs={12}>
            <FormControl>
              <TextField
                required
                id='type-label'
                name='type-label'
                label='Type'
                select
                fullWidth
                value={uiModel.type}
                defaultValue={uiModel.type}
                onChange={event =>
                  dispatch({
                    type: 'type',
                    value: event.target.value,
                  })
                }
              >
                <MenuItem value='general'>General</MenuItem>
                <MenuItem value='gift'>Pre-paid</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            id='code'
            inputProps={{
              maxLength: 200,
            }}
            label='Code (Optional)'
            fullWidth
            value={uiModel.code}
            onChange={event =>
              dispatch({ type: 'code', value: event.target.value })
            }
            onKeyPress={validateCodeKeypress}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='description'
            label='Description'
            fullWidth
            value={uiModel.description}
            onChange={event =>
              dispatch({ type: 'description', value: event.target.value })
            }
          />
        </Grid>

        {!uiModel.edit && (
          <Grid item xs={12}>
            <TextField
              id='number_of_codes'
              label='Number of codes to generate'
              fullWidth
              value={uiModel.numberOfCodes}
              onChange={event =>
                dispatch({ type: 'numberOfCodes', value: event.target.value })
              }
              onKeyPress={validateNumberKeypress}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField
            required
            id='value'
            label={uiModel.percentDiscount ? 'Value (%)' : 'Value ($)'}
            fullWidth
            value={uiModel.value}
            onChange={event =>
              dispatch({ type: 'value', value: event.target.value })
            }
            onKeyPress={validateNumberKeypress}
          />
        </Grid>

        {!uiModel.edit && (
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Discount Type</FormLabel>
              <RadioGroup
                aria-label='discount type'
                name='controlled-radio-buttons-group'
                onChange={event =>
                  dispatch({
                    type: 'percentDiscount',
                    value: event.target.value === 'percent',
                  })
                }
              >
                <FormControlLabel
                  value='dollar'
                  control={<Radio checked={!uiModel.percentDiscount} />}
                  label='$'
                />
                <FormControlLabel
                  value='percent'
                  control={<Radio checked={uiModel.percentDiscount} />}
                  label='%'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            id='max_redemptions'
            label='Maximum Redemptions'
            fullWidth
            value={uiModel.maxRedemptions}
            onChange={event =>
              dispatch({
                type: 'maxRedemptions',
                value: event.target.value,
              })
            }
            onKeyPress={validateNumberKeypress}
          />
          <br />
          How many unique users can redeem this code? (enter 0 for unlimited)
        </Grid>

        {!uiModel.edit && (
          <Grid item xs={6}>
            <TextField
              required
              id='userType'
              name='userType'
              label='User types'
              select
              fullWidth
              value={uiModel.userType}
              defaultValue={uiModel.userType}
              onChange={event =>
                dispatch({
                  type: 'userType',
                  value: parseInt(event.target.value, 10),
                })
              }
            >
              {uiModel.userTypes.map(item => (
                <MenuItem key={item.label} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={uiModel.firstTimeBooking}
                onChange={event =>
                  dispatch({
                    type: 'firstTimeBooking',
                    value: event.target.checked,
                  })
                }
              />
            }
            label='Accept for First Booking only'
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component='fieldset' required>
            <FormLabel component='legend'>Applicable Fee(s)</FormLabel>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={uiModel.babysittingFee}
                    onChange={event =>
                      dispatch({
                        type: 'babysittingFee',
                        value: event.target.checked,
                      })
                    }
                  />
                }
                label='Babysitting Fee'
              />
              {uiModel.babysittingFee && (
                <Box ml={3}>
                  {uiModel.verticalTypes.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={event =>
                            dispatch({
                              type: 'verticalType',
                              value: event.target.checked,
                              id: item.id,
                            })
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={uiModel.setupFee}
                    onChange={event =>
                      dispatch({
                        type: 'setupFee',
                        value: event.target.checked,
                      })
                    }
                  />
                }
                label='Subscription setup fee'
              />
              {uiModel.setupFee && (
                <Box ml={3}>
                  {uiModel.setupFeeFrequencies.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={event =>
                            dispatch({
                              type: 'setupFeeFrequency',
                              value: event.target.checked,
                              id: item.id,
                            })
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={uiModel.installmentFee}
                    onChange={event =>
                      dispatch({
                        type: 'installmentFee',
                        value: event.target.checked,
                      })
                    }
                  />
                }
                label='Subscription installment fee'
              />
              {uiModel.installmentFee && (
                <Box ml={3}>
                  {uiModel.installmentFeeFrequencies.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={event =>
                            dispatch({
                              type: 'installmentFeeFrequency',
                              value: event.target.checked,
                              id: item.id,
                            })
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Box>
              )}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Applicable Partners</FormLabel>

            <FormGroup>
              {uiModel.partners && (
                <Box ml={3}>
                  {uiModel.partners.map(item => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={event =>
                            dispatch({
                              type: 'partners',
                              value: event.target.checked,
                              id: item.id,
                            })
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </Box>
              )}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormLabel component='legend'>Active From</FormLabel>
          <DatePicker
            minDate={moment()}
            value={uiModel.activeFrom}
            onChange={date => {
              dispatch({
                type: 'activeFrom',
                value: date,
              });
            }}
            inputFormat={DateUtil.dateFormatSlashes}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
          <br />
          When this code should activate (leave blank to activate immediately).
        </Grid>

        <Grid item xs={12}>
          <FormLabel component='legend'>Redeem By</FormLabel>
          <DatePicker
            minDate={moment()}
            value={uiModel.redeemBy}
            onChange={date => {
              dispatch({
                type: 'redeemBy',
                value: date,
              });
            }}
            inputFormat={DateUtil.dateFormatSlashes}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
          <br />
          When this code can no longer be redeemed (leave blank for no expiry).
        </Grid>

        <Grid item xs={12}>
          <FormLabel component='legend'>Use By</FormLabel>
          <DatePicker
            minDate={moment()}
            value={uiModel.useBy}
            onChange={date => {
              dispatch({
                type: 'useBy',
                value: date,
              });
            }}
            inputFormat={DateUtil.dateFormatSlashes}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
          <br />
          When this code can no longer be used (leave blank for no expiry).
        </Grid>

        <Grid item xs={12}>
          <TextField
            id='comment'
            label='Comment'
            fullWidth
            value={uiModel.comment}
            onChange={event =>
              dispatch({ type: 'comment', value: event.target.value })
            }
          />
        </Grid>
      </Grid>

      {uiModel.edit ? (
        <div className={classes.buttons}>
          {uiModel.active ? (
            <>
              <VoucherDeactivateConfirm onConfirm={deactivateVoucher} />
              <Button
                variant='contained'
                color='primary'
                onClick={update}
                className={classes.button}
              >
                Update
              </Button>
            </>
          ) : (
            <p>Voucher is deactivated, editing is not allowed.</p>
          )}
        </div>
      ) : (
        <div className={classes.buttons}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => update()}
            className={classes.button}
          >
            Create Voucher Code
          </Button>
        </div>
      )}
    </Paper>
  );
}

export default withRouter(VoucherUpdate);
