import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/admin/refresh-checkr-report';

class AdminRefreshCheckrReportService {
  static post(data: any, config?: AxiosRequestConfig) {
    return axios.post(URL, data, config);
  }
}

export { AdminRefreshCheckrReportService };
