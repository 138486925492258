import axios, { AxiosRequestConfig } from 'axios';

const URL_SITTER = '/v2/admin/membership-renewal-sitter';
const URL_PARENT = '/v2/admin/membership-renewal-parent';

class MembershipRenewalService {
  static membershipRenewalSitter(
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return axios.post(`${URL_SITTER}`, data, config);
  }

  static membershipRenewalParent(
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return axios.post(`${URL_PARENT}`, data, config);
  }
}

export { MembershipRenewalService };
