import React, { useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { now } from 'moment';

import PageStyles from '../../styles/PageStyles';
import CronTasks from './CronTasks';

function ApiProfiler() {
  const { classes } = PageStyles();

  const defaultUrl = localStorage.getItem('US_API_PROFILER_URL')
    ? localStorage.getItem('US_API_PROFILER_URL')
    : '/v2/cache-stats';
  const defaultMethod = localStorage.getItem('US_API_PROFILER_METHOD')
    ? localStorage.getItem('US_API_PROFILER_METHOD')
    : 'GET';

  const [iterations, setIterations] = useState([]);
  const [counter, setCounter] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [minimumTime, setMinimumTime] = useState(9999999);
  const [maximumTime, setMaximumTime] = useState(0);
  const [averageTime, setAverageTime] = useState(0);
  const [method, setMethod] = useState(defaultMethod);
  const [url, setUrl] = useState(defaultUrl);
  const [requestBody, setRequestBody] = useState('');

  const updateTimes = (time, avg) => {
    if (time < minimumTime) {
      setMinimumTime(time);
    }

    if (time > maximumTime) {
      setMaximumTime(time);
    }

    setAverageTime(avg);
  };

  const updateMethod = event => {
    localStorage.setItem('US_API_PROFILER_METHOD', event.target.value);
    setMethod(event.target.value);
  };

  const updateUrl = event => {
    localStorage.setItem('US_API_PROFILER_URL', event.target.value);
    setUrl(event.target.value);
  };

  const updateRequestBody = event => {
    setRequestBody(event.target.value);
  };

  async function makeCall() {
    await axios({
      method,
      url,
      data: requestBody,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return now();
  }

  async function callApi() {
    const start = now();
    const end = await makeCall();
    const time = end - start;
    const tempCounter = counter + 1;
    const tempTotalTime = totalTime + time;

    setCounter(tempCounter);
    setTotalTime(tempTotalTime);
    const avg = tempTotalTime / tempCounter;

    const tempIterations = [...iterations];

    tempIterations.push({
      id: tempCounter,
      start,
      end,
      time,
      totalTime: tempTotalTime,
      avg,
    });
    setIterations(tempIterations);
    updateTimes(time, avg);
  }

  const clearAll = () => {
    setIterations([]);
    setCounter(0);
    setTotalTime(0);
    setMinimumTime(9999999);
    setMaximumTime(0);
    setAverageTime(0);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant='h6' gutterBottom>API Profiler</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              id='method'
              name='method'
              label='Method'
              fullWidth
              value={method}
              onChange={updateMethod}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <TextField
              required
              id='url'
              name='url'
              label='URL'
              fullWidth
              value={url}
              onChange={updateUrl}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='requestBody'
              name='requestBody'
              label='Request Body'
              fullWidth
              value={requestBody}
              onChange={updateRequestBody}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <CronTasks
            setUrl={setUrl}
            setMethod={setMethod}
            className={classes.button}
          />
          <Button
            variant='contained'
            color='secondary'
            onClick={clearAll}
            className={classes.button}
          >
            Clear
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={callApi}
            className={classes.button}
          >
            Call API
          </Button>
        </div>
      </Paper>
      <Card className={classes.paper}>
        <div>
          <CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Min</TableCell>
                  <TableCell>{minimumTime}</TableCell>
                  <TableCell>Max</TableCell>
                  <TableCell>{maximumTime}</TableCell>
                  <TableCell>Avg</TableCell>
                  <TableCell>{averageTime}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </div>
        <div>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Iterations</TableCell>
                  <TableCell>Total Time</TableCell>
                  <TableCell>Avg</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {iterations &&
                  iterations.map(iteration => (
                    <TableRow key={iteration.id}>
                      <TableCell>{iteration.start}</TableCell>
                      <TableCell>{iteration.end}</TableCell>
                      <TableCell>{iteration.time}</TableCell>
                      <TableCell>{iteration.id}</TableCell>
                      <TableCell>{iteration.totalTime}</TableCell>
                      <TableCell>{iteration.avg}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>
        </div>
      </Card>
    </>
  );
}

export default withRouter(ApiProfiler);
