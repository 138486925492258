import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { City, CityService, CitySeoContent, CitySeoContentService } from 'us-web-services';
import CitySeoContentEditForm from './CitySeoContentEditForm';
import PageStyles from '../../styles/PageStyles';

function CitySeoContentEdit(props: RouteComponentProps<{ id: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [city, setCity] = useState<City>({});
  const [citySeoContent, setCitySeoContent] = useState<CitySeoContent>({});

  useEffect(() => {
    const cityId = Number.parseInt(match.params.id, 10);

    if (cityId) {
      const getCityData = async () => {
        const cityResponse = await CityService.findById(cityId);
        const cityResponseData = cityResponse.data.data;

        setCity(cityResponseData);

        const contentFilterParams = {
          params: {
            cityId,
          },
        };

        const citySeoContentResponse = (await CitySeoContentService.getByFilter(contentFilterParams)).data;
        const citySeoContentData = citySeoContentResponse.data;

        if (citySeoContentData.length > 0) {
          setCitySeoContent(citySeoContentData[0]);
        } else {
          setCitySeoContent({
            cityId,
          });
        }
      };

      getCityData();
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push('/seo/cities');
  };

  const saveSuccess = () => {
    toast.success(
      `${city.name}, ${city.state} SEO Content updated successfully`,
    );
    navigateToList();
  };

  const saveCityContent = async () => {
    try {
      if (citySeoContent.id) {
        await CitySeoContentService.patch(
          citySeoContent.id,
          citySeoContent,
        );
      } else {
        await CitySeoContentService.create(citySeoContent);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {city.name}, {city.state}
      </Typography>
      {citySeoContent.cityId ? (
        <CitySeoContentEditForm
          citySeoContent={citySeoContent}
          setCitySeoContent={setCitySeoContent}
        />
      ) : (
        'City not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveCityContent}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(CitySeoContentEdit);
