import React, { useMemo } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SchoolService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import { Table } from '../common/Table';

function SchoolLookup(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'schools',
        header: 'Schools',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Alias',
            accessorKey: 'alias',
          },
          {
            header: 'City',
            accessorFn: row => row?.location?.name,
          },
          {
            header: 'State',
            accessorFn: row => row?.location?.state?.code,
          },
          {
            header: 'Region',
            accessorFn: row => row?.location?.region?.id,
          },
        ],
      },
    ],
    [],
  );

  const editSchool = rowData => {
    history.push(`/schools/${rowData.id}`);
  };

  const addSchool = () => {
    history.push('/school-create');
  };

  const mergeSchools = () => {
    history.push('/schools-merge');
  };

  const params = (globalFilter: string) => [
    {
      key: 'name',
      value: globalFilter,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={SchoolService.getByFilter}
        params={params}
        editAction={editSchool}
        addAction={addSchool}
      />
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeSchools}
          className={classes.button}
        >
          Merge Schools
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(SchoolLookup);
