import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { Job } from '../types/Job';

const URL = '/v2/jobs';

class JobService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<Job>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: Job,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Job>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: Job,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Job>> {
    return axios.post(URL, data, config);
  }
}

export { JobService };
