import { Booking } from './Booking';
import { RecurringDetails } from './Recurring';

export enum JobAvailabilityType {
  occasional = 'Occasionally',
  partTime = 'Part Time',
  fullTime = 'Full Time',
}

export enum JobType {
  ONE_TIME = 'one_time',
  RECURRING = 'recurring',
}

export interface Job {
  id?: number;
  availability?: JobAvailabilityType;
  groupId?: number;
  booking?: Booking;
  created?: Date;
  description?: string;
  distance?: number;
  createdWithin?: number;
  firstResponseTime?: Date;
  lowNumberOfApplicants?: boolean;
  minimumHours?: number;
  moderated?: boolean;
  numberOfApplicants?: number;
  parentLastViewedSitters?: Date;
  recurring?: RecurringDetails;
  scammerKeywordDetected?: string;
  sitterInterest?: any;
  status?: string;
  totalInterestedSitters?: number;
  type?: JobType;
  updated?: Date;
}
