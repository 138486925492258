import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { LocalGroup, LocalGroupService, SitterService } from 'us-web-services';
import LocalGroupEditForm from './LocalGroupEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

function LocalGroupEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [localGroup, setLocalGroup] = useState<LocalGroup>({ name: '' });
  const [sitterCount, setSitterCount] = useState(0);

  useEffect(() => {
    const localGroupId = Number.parseInt(match.params.id, 10);

    if (localGroupId) {
      const getData = async () => {
        const response = (await LocalGroupService.get(localGroupId)).data;

        if (response.data?.id) {
          setLocalGroup(response.data);

          const sitterConfig = {
            params: {
              limit: 1,
              localGroupId,
              userId: adminUser.id,
            },
          };

          const sitterResponse = (await SitterService.getByFilter(sitterConfig)).data;

          setSitterCount(sitterResponse.meta.size);
        } else {
          setLocalGroup({ name: '' });
          setSitterCount(0);
        }
      };

      getData();
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/local-groups');
  };

  const saveSuccess = () => {
    toast.success(`${localGroup.name} updated successfully`);
    navigateToList();
  };

  const saveLocalGroup = async () => {
    try {
      if (localGroup.id) {
        await LocalGroupService.patch(localGroup.id, localGroup);
      } else {
        await LocalGroupService.create(localGroup);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {localGroup.name} (sitters: {sitterCount})
      </Typography>
      {localGroup.id ? (
        <LocalGroupEditForm
          localGroup={localGroup}
          setLocalGroup={setLocalGroup}
        />
      ) : (
        'LocalGroup not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveLocalGroup}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(LocalGroupEdit);
