import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { User } from '../types/User';

const URL = '/v2/users';

class UserService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<User>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<User>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: User,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<User>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}
export { UserService };
