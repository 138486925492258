import React, { useMemo } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AffiliationService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import { Table } from '../common/Table';

function AffiliationLookup(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'affiliations',
        header: 'Affiliations',
        columns: [
          {
            header: 'ID',
            size: 10,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Alias',
            accessorKey: 'alias',
          },
          {
            header: 'City',
            accessorKey: 'city',
          },
          {
            header: 'State',
            accessorKey: 'state',
          },
          {
            header: 'Region',
            // protect against possible nulls
            accessorFn: row => row?.region?.name,
          },
        ],
      },
    ],
    [],
  );

  const editAffiliation = rowData => {
    history.push(`/affiliations/${rowData.id}`);
  };

  const addAffiliation = () => {
    history.push('/affiliation-create');
  };

  const mergeAffiliations = () => {
    history.push('/affiliations-merge');
  };

  const params = (globalFilter: string) => [
    {
      key: 'name',
      value: globalFilter,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={AffiliationService.getByFilter}
        params={params}
        editAction={editAffiliation}
        addAction={addAffiliation}
      />
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeAffiliations}
          className={classes.button}
        >
          Merge Affiliations
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(AffiliationLookup);
