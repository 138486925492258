import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { ZipCode, ZipCodeService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import ZipCodeForm from './ZipCodeForm';

function ZipCodeEdit(props) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [zipCode, setZipCode] = useState<ZipCode>({});

  useEffect(() => {
    if (match.params.id) {
      const getData = async () => {
        const response = (await ZipCodeService.get(match.params.id)).data;

        if (response.data.id) {
          setZipCode(response.data);
        } else {
          setZipCode({});
        }
        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push('/zip-codes');
  };

  const saveSuccess = () => {
    toast.success('Zip Code updated successfully');
    navigateToList();
  };

  const saveZipCode = async () => {
    try {
      if (zipCode.id) {
        await ZipCodeService.patch(zipCode.id, zipCode);
      } else {
        await ZipCodeService.create(zipCode);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {zipCode.id ? 'Edit' : 'Add'} Zip Code
      </Typography>
      {loaded ? (
        <ZipCodeForm zipCode={zipCode} setZipCode={setZipCode} />
      ) : (
        'Loading'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveZipCode}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(ZipCodeEdit);
