import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { SitterProfileStatus } from '../types';

const URL = '/v2/sitter-profile-status';

class SitterProfileStatusService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterProfileStatus>> {
    return axios.get(`${URL}/${id}`, config);
  }
}

export { SitterProfileStatusService };
