import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import { MasterServiceAreaTierService } from 'us-web-services';
import { Table } from 'src/components/common/Table';
import PageStyles from 'src/styles/PageStyles';

function MasterServiceAreaTierLookup(props) {
  const { history } = props;
  const { classes } = PageStyles();

  const addTier = () => {
    history.push('/master-service-area-tier-create');
  };

  const editTier = rowData => {
    history.push(`/master-service-area-tiers/${rowData.id}`);
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'msaTiers',
        header: 'Master Service Area Tiers',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={MasterServiceAreaTierService.getByFilter}
        editAction={editTier}
        addAction={addTier}
      />
    </Paper>
  );
}

export default withRouter(MasterServiceAreaTierLookup);
