import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/user-devices';

class UserDeviceService {
  static findByFilter(config?: AxiosRequestConfig) {
    return axios.get(URL, config);
  }
}

export { UserDeviceService };
