import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { MenuItem } from '@mui/material';

import { SitterReviewService } from 'us-web-services';
import useUser from '../../store/useUser';
import DateUtil from '../../util/DateUtil';
import DisplayService from '../../util/DisplayService';
import { Table, TableInstance } from '../common/Table';

function SitterReviews() {
  const tableRef = React.useRef<TableInstance>();
  const userState = useUser()[0];

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error with the review.',
    );

    toast.error(displayedError.message);
  };

  const params = (_globalFilter: string, sorting: any) => {
    const paramArray = [];

    if (sorting && sorting?.id === 'Created') {
      paramArray.push({
        key: 'sort',
        value: 'date',
      });

      paramArray.push({
        key: 'date.dir',
        value: sorting.desc ? 'desc' : 'asc',
      });
    }

    if (sorting && sorting?.id === 'Rating') {
      paramArray.push({
        key: 'sort',
        value: 'vote',
      });

      paramArray.push({
        key: 'vote.dir',
        value: sorting.desc ? 'desc' : 'asc',
      });
    }

    if (sorting && sorting?.id === 'Updated') {
      paramArray.push({
        key: 'sort',
        value: 'updated',
      });

      paramArray.push({
        key: 'updated.dir',
        value: sorting.desc ? 'desc' : 'asc',
      });
    }

    if (userState.user.parent) {
      paramArray.push({
        key: 'parentId',
        value: userState.user.id,
      });
    } else {
      paramArray.push({
        key: 'sitterId',
        value: userState.user.id,
      });
    }

    return paramArray;
  };

  const refreshReviews = () => tableRef.current?.fetchData();

  const deleteReview = async rowData => {
    SitterReviewService.delete(rowData.id)
      .then(refreshReviews)
      .catch(showError);
  };

  const updateSuccess = () => {
    toast.success('Review updated successfully');
    refreshReviews();
  };

  const updateReview = async rowData => {
    const data = {
      id: rowData.row.id,
      comment: rowData.values.comment,
      ratingPercent: rowData.values.ratingPercent,
      visibility: rowData.values.visibility,
    };

    SitterReviewService.patch(rowData.row.id, data)
      .then(updateSuccess)
      .catch(showError);
  };

  const visibilitySelect = [
    { id: 'public', name: 'Public' },
    { id: 'private', name: 'Private' },
  ];
  const getVisibility = visibilityId =>
    visibilitySelect.find(item => item.id === visibilityId);

  const ratingSelect = [
    { id: 20, value: '1' },
    { id: 40, value: '2' },
    { id: 60, value: '3' },
    { id: 80, value: '4' },
    { id: 100, value: '5' },
  ];

  const getRating = visibilityId =>
    ratingSelect.find(item => item.id === visibilityId);
  const COLUMNS = useMemo(
    () => [
      {
        id: 'parent-reviews',
        header: 'Parent Reviews',
        columns: [
          {
            header: 'Sitter Name',
            accessorFn: row => `${row.sitter.firstName} ${row.sitter.lastName}`,
            Cell: ({ cell }) => (
              <Link to={`/users/lookup?id=${cell?.row?.original?.sitter?.id}`}>
                {cell?.row?.original?.sitter?.firstName}{' '}
                {cell?.row?.original?.sitter?.lastName}
              </Link>
            ),
            enableEditing: false,
            enableSorting: false,
          },
          {
            header: 'Parent Name',
            accessorFn: row => `${row.parent.firstName} ${row.parent.lastName}`,
            Cell: ({ cell }) => (
              <Link to={`/users/lookup?id=${cell?.row?.original?.parent?.id}`}>
                {cell?.row?.original?.parent?.firstName}{' '}
                {cell?.row?.original?.parent?.lastName}
              </Link>
            ),
            enableEditing: false,
            enableSorting: false,
          },
          {
            header: 'Review',
            accessorKey: 'comment',
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            },
            Cell: ({ cell }) => (
              <div
                style={{
                  minWidth: 350,
                  paddingLeft: 10,
                }}
              >
                {cell?.row?.original?.comment}
              </div>
            ),
            enableSorting: false,
          },
          {
            header: 'Visibility',
            accessorKey: 'visibility',
            Cell: ({ cell }) => (
              <>{getVisibility(cell?.row?.original.visibility).name}</>
            ),
            muiTableBodyCellEditTextFieldProps: {
              select: true,
              children: visibilitySelect.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              )),
            },
            enableSorting: false,
          },
          {
            header: 'Rating',
            accessorFn: rowData => rowData.ratingPercent,
            Cell: ({ cell }) => (
              <>{getRating(cell?.row?.original?.ratingPercent).value}</>
            ),
            muiTableBodyCellEditTextFieldProps: {
              select: true,
              children: ratingSelect.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              )),
            },
          },
          {
            header: 'Created',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData.date),
            enableEditing: false,
          },
          {
            header: 'Updated',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData.updated),
            enableEditing: false,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Table
      ref={tableRef}
      columns={COLUMNS}
      params={params}
      retrieveData={SitterReviewService.getByFilter}
      editAction={updateReview}
      deleteAction={deleteReview}
      alwaysApplyParams
      enableInlineEditing
      enableSorting
    />
  );
}

export default SitterReviews;
