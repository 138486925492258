import axios, { AxiosRequestConfig } from 'axios';

import { DataResponse, PagedResponse, Press } from '../types';

const URL = '/v2/press';
const PUBLIC_URL = '/v2/public/press';

class PressService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Press>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getArticles(year: number): Promise<PagedResponse<Press>> {
    const start = `${year}-01-01 00:00:00`;
    const end = `${year}-12-31 23:59:59`;

    const config: AxiosRequestConfig = {
      params: {
        start,
        end,
        limit: 200,
      },
    };

    if (typeof window === 'undefined') {
      config.baseURL = `http://${process.env.US_SERVICES_HOST}:${process.env.US_SERVICES_PORT}`;
    }

    return axios.get(PUBLIC_URL, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Press>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: Press,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Press>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: Press,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Press>> {
    return axios.post(URL, data, config);
  }
}

export { PressService };
