import React, { useState } from 'react';
import { Button, Paper } from '@mui/material';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Company, CompanyService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import CompanyEditForm from './CompanyEditForm';

function CompanyCreate(props: RouteComponentProps) {
  const [company, setCompany] = useState<Company>({ emailValidationEnabled: true } as Company);
  const { classes } = PageStyles();
  const { history } = props;

  const createCompany = async () => {
    try {
      const response = await CompanyService.create(company);

      toast.success('Company created successfully');
      const { data } = response.data;

      history.push(`/companies/${data.id}`);
    } catch (e) {
      toast.error(`Error creating company: ${e.code}`);
    }
  };

  const cancelCreate = async () => {
    history.push('/companies');
  };

  return (
    <Paper className={classes.paper}>
      <CompanyEditForm company={company} setCompany={setCompany} />
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={cancelCreate}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={createCompany}
          className={classes.button}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(CompanyCreate);
