import moment from 'moment';

class DateUtil {
  dateFormat: any;

  dateFormatSlashes: any;

  dateReadable: any;

  dateTimeFormat: any;

  dateTimeReadable: any;

  getCurrentDateTimeString: any;

  getDateMidnightString: any;

  getDateSlashesString: any;

  getDateTimeString: any;

  getReadableDate: any;

  getReadableDateTime: any;

  midnightDateFormat: any;

  constructor() {
    this.midnightDateFormat = 'YYYY-MM-DD 00:00:00';
    this.dateFormat = 'YYYY-MM-DD'; // 2020-02-14
    this.dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'; // 2020-02-14 18:15:00
    this.dateReadable = 'MMMM DD, YYYY'; // February 14, 2020
    this.dateTimeReadable = 'MMMM DD, YYYY h:mm A'; // February 14, 2020 6:15 PM
    this.dateFormatSlashes = 'MM/DD/YYYY'; // 02/14/2020

    this.getReadableDate = date => date ? moment(date).format(this.dateReadable) : '';
    this.getReadableDateTime = dateTime =>
      dateTime ? moment(dateTime).format(this.dateTimeReadable) : '';
    this.getCurrentDateTimeString = () => moment().format(this.dateTimeFormat);
    this.getDateSlashesString = dateMoment =>
      dateMoment.format(this.dateFormatSlashes);
    this.getDateTimeString = dateMoment =>
      dateMoment.format(this.dateTimeFormat);
    this.getDateMidnightString = dateMoment =>
      dateMoment != null ? dateMoment.format(this.midnightDateFormat) : null;
  }
}

export default new DateUtil();
