import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { JobExample } from '../types';

const URL = '/v2/public/job-examples';

class JobExamplesService {
  static getJobExamples(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<JobExample>> {
    return axios.get(URL, config);
  }
}

export { JobExamplesService };
