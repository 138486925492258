import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { LocalGroup } from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import ParentLocalGroupDisplay from './ParentLocalGroupDisplay';

interface Props {
  localGroups: LocalGroup[];
  getUserLocalGroups: () => void;
}

function ParentLocalGroups(props: Props) {
  const { localGroups, getUserLocalGroups } = props;
  const { classes } = CardStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            Local Groups
          </Typography>
          <Grid container>
            {localGroups.map(localGroup => (
              <ParentLocalGroupDisplay
                key={localGroup.id}
                localGroup={localGroup}
                getLocalGroups={getUserLocalGroups}
              />
            ))}
          </Grid>
        </CardContent>
      </div>
    </Card>
  );
}

export default ParentLocalGroups;
