import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Press, PressService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function PressLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'press',
        header: 'Press',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Title',
            accessorKey: 'title',
          },
          {
            header: 'URL',
            accessorKey: 'url',
          },
          {
            header: 'Publisher',
            accessorKey: 'publisher',
          },
          {
            header: 'Published Date',
            accessorKey: 'pubDate',
          },
        ],
      },
    ],
    [],
  );

  const editPress = (rowData: Press) => {
    history.push(`/press/${rowData.id}`);
  };

  const addPress = () => {
    history.push('/press-create');
  };

  const params = (globalFilter: string) => {
    const paramArray = [];

    if (globalFilter) {
      paramArray.push({
        key: 'q',
        value: `title.contains('${globalFilter}')`,
      });
    }

    return paramArray;
  };

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={PressService.getByFilter}
        params={params}
        editAction={editPress}
        addAction={addPress}
      />
    </Paper>
  );
}

export default withRouter(PressLookup);
