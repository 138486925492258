import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';

import {
  AuthToken,
  AuthTokenService,
  initResponseInterceptor,
  initBearerToken,
  processServicesQueue,
  setServicesLastRefreshRequest,
  UserService,
} from 'us-web-services';
import useAuthentication from './store/useAuthentication';
import AppContainer from './AppContainer';
import { StorageNames } from './constants';

axios.interceptors.request.use(config => {
  if (config.url?.indexOf('/v2/public/auth-token') === -1 && localStorage.getItem(StorageNames.US_USER_AUTH_TOKEN)) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      StorageNames.US_USER_AUTH_TOKEN,
    )}`;
  } else if (config.headers) {
    config.headers.Authorization = '';
  }

  return config;
}, async error => Promise.reject(error));

let lastRefreshRequest = null;
const requestTimeLimit = 5000;
let failedQueue = [];

const canRefresh = (): boolean => (lastRefreshRequest === null
    || ((lastRefreshRequest + requestTimeLimit) < Date.now()));

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

const AppCore = () => {
  const [authenticationState, authenticationActions] = useAuthentication();

  initBearerToken();

  const authentication = {
    user: authenticationState.authentication.user,
    updateToken: authenticationActions.authentication.updateToken,
  };

  initResponseInterceptor(authentication, processQueue);

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (
        error?.response.status === 401 &&
        authenticationState.authentication.refreshToken
      ) {
        if (canRefresh()) {
          lastRefreshRequest = Date.now();
          setServicesLastRefreshRequest(lastRefreshRequest);
          const authToken: AuthToken = {
            userId: authenticationState.authentication.user.id,
            refreshToken: {
              token: authenticationState.authentication.refreshToken,
            },
          };

          try {
            const authResponse = (await AuthTokenService.create(authToken)).data;
            const responseData = authResponse.data;

            authenticationActions.authentication.updateToken(responseData);
            processQueue(null);
            setTimeout(() => processServicesQueue(), 500);

            return await axios.request(error.config);
          } catch (e) {
            authenticationActions.authentication.set({});

            return Promise.reject(error);
          }
        } else {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => axios(error.config))
            .catch(err => Promise.reject(err));
        }
      }

      return Promise.reject(error);
    },
  );

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await UserService.get(
          authenticationState.authentication.user.id, { params: { fields: 'superUser' } },
        );

        const userData = response.data.data;

        authenticationActions.authentication.setUser(userData);
      } catch (e) {
        authenticationActions.authentication.set({});
      }
    }

    if (
      authenticationState.authentication &&
      !authenticationState.authentication.isAuthenticated
    ) {
      if (
        authenticationState.authentication.user &&
        authenticationState.authentication.user.id &&
        authenticationState.authentication.user.superUser &&
        authenticationState.authentication.token
      ) {
        fetchUser();
      } else if (
        authenticationState.authentication.user &&
        authenticationState.authentication.user.id
      ) {
        authenticationActions.authentication.set({});
      }
    }
  }, [
    authenticationActions.authentication,
    authenticationState.authentication,
  ]);

  return (
    <BrowserRouter>
      <Toaster />
      <AppContainer />
    </BrowserRouter>
  );
};

export default AppCore;
