import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types';
import { Order } from '../types/order/Order';

const URL = '/v2/admin/order-reimbursement';

class ReimbursementOrderService {
  static reimburse(
    data: Order,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Order>> {
    return axios.post(URL, data, config);
  }
}

export { ReimbursementOrderService };
