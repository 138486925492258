import React from 'react';
import {
  ButtonBase,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Booking } from 'us-web-services';

interface Props extends RouteComponentProps {
  booking: Booking;
}

const useStyles = makeStyles<
void,
'focusVisible' | 'imageBackdrop' | 'imageMarked' | 'imageTitle'
>()((theme, _params, classes) => ({
  card: {
    display: 'flex',
  },
  bookingLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  media: {
    width: 181,
    backgroundSize: 'cover',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    [`&:hover, &.${classes.focusVisible}`]: {
      zIndex: 1,
      [`& .${classes.imageBackdrop}`]: {
        opacity: 0.15,
      },
      [`& .${classes.imageMarked}`]: {
        opacity: 0,
      },
      [`& .${classes.imageTitle}`]: {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)} 
    ${theme.spacing(4)} calc(${theme.spacing(1)} + 6px)`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

function BookingCard(props: Props) {
  const { booking } = props;
  const { classes } = useStyles();

  const SitterLink = React.forwardRef((refProps, ref) => (
    <Link
      // @ts-expect-error innerRef is valid
      innerRef={ref}
      to={`/users/lookup?id=${booking.sitter.id}`}
      {...refProps}
    />
  ));

  const ParentLink = React.forwardRef((refProps, ref) => (
    <Link
      // @ts-expect-error innerRef is valid
      innerRef={ref}
      to={`/users/lookup?id=${booking.parent.id}`}
      {...refProps}
    />
  ));

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent className={classes.content}>
          <Link
            className={classes.bookingLink}
            to={`/bookings/edit/${booking.id}`}
          >
            <Typography component='h5' variant='h5'>
              ID: {booking.id}
              <br />
              Status: {booking.status.code}
              <br />
            </Typography>
            <Typography variant='subtitle1' color='textPrimary'>
              Start date: {moment(booking.start).format('LLL')}
              <br />
              End date: {moment(booking.end).format('LLL')}
              <br />
              Location: {booking.address.city}, {booking.address.state}
              <br />
            </Typography>
          </Link>
          {booking.group.id > 0 ? (
            <Link to={`/booking-groups/edit/${booking.group.id}`}>
              <Typography variant='subtitle1' color='textPrimary'>
                Edit Booking Group
              </Typography>
            </Link>
          ) : (
            ''
          )}
        </CardContent>

        <div className={classes.buttons}>
          <ButtonBase
            focusRipple
            key={booking.sitter.name}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: '50%',
            }}
            component={SitterLink}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${booking.sitter.pictureUrl})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component='span'
                variant='subtitle1'
                color='inherit'
                className={`${booking.sitter.name}'s photo`}
              >
                {booking.sitter.id}
                {' - '}
                {booking.sitter.firstName} {booking.sitter.lastName}
                {' (Sitter)'}
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase>
          <ButtonBase
            focusRipple
            key={booking.parent.name}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: '50%',
            }}
            component={ParentLink}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${booking.parent.pictureUrl})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component='span'
                variant='subtitle1'
                color='inherit'
                className={`${booking.parent.name}'s photo`}
              >
                {booking.parent.id}
                {' - '}
                {booking.parent.firstName} {booking.parent.lastName}
                {' (Parent)'}
                <span className={classes.imageMarked} />
              </Typography>
            </span>
          </ButtonBase>
        </div>
      </CardActionArea>
    </Card>
  );
}

export default withRouter(BookingCard);
