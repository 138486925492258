import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, MenuItem } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import queryString from 'query-string';

import { ParentService, SitterService, UserService } from 'us-web-services';
import Autocomplete from '../Autocomplete';
import useAuthentication from '../../store/useAuthentication';
import useUser from '../../store/useUser';

function UserSuggestions(props: RouteComponentProps) {
  const authenticationState = useAuthentication()[0];
  const userState = useUser()[0];
  const userActions = useUser()[1];
  const { history, location } = props;
  const searchParams = queryString.parse(location.search);
  const [searchByName, setSearchByName] = useState(false);
  const [searchLabel, setSearchLabel] = useState('Search by email or User ID');

  const updateSearchByName = () => {
    const label = !searchByName ? 'name' : 'email';
    const newSearchLabel = `Search by ${label} or User ID`;

    setSearchLabel(newSearchLabel);
    setSearchByName(!searchByName);
  };

  const setUserGetProfile = useCallback(
    async userData => {
      history.push({ search: `?id=${userData.id}` });
      userActions.setUser(userData);
      userActions.setProfile({});
      if (userData.parent) {
        const parentResponse = await ParentService.get(userData.id);

        userActions.setProfile(parentResponse.data.data);
      } else if (userData.sitter) {
        const sitterConfig = {
          params: {
            userId: authenticationState.authentication.user.id,
          },
        };
        const sitterResponse = await SitterService.get(userData.id, sitterConfig);

        userActions.setProfile(sitterResponse.data.data);
      } else {
        userData.address = {};
        userActions.setProfile(userData);
      }
    },
    [userActions, history, authenticationState.authentication],
  );

  const getUser = useCallback(
    async id => {
      userActions.setUser({});
      userActions.setProfile({});
      try {
        const userConfig = { params: { fields: 'superUser' } };
        const userResponse = await UserService.get(id, userConfig);
        const userData = userResponse.data.data;

        setUserGetProfile(userData);
      } catch (e) {
        // failed lookup of invalid user ID
      }
    },
    [setUserGetProfile, userActions],
  );

  useEffect(() => {
    if (
      searchParams.id &&
      userState.user.id !== parseInt(searchParams.id.toString(), 10)
    ) {
      getUser(searchParams.id);
    }
  }, [getUser, userState.user.id, searchParams.id]);

  const getUserSuggestions = async value => {
    if (Number(value)) {
      try {
        const userConfig = { params: { fields: 'superUser' } };
        const userResponse = await UserService.get(value, userConfig);
        const userData = userResponse.data.data;

        return userData.id ? [userData] : [];
      } catch (e) {
        return [];
      }
    }

    let query;

    if (searchByName) {
      const nameString = value.split(' ');

      if (nameString.length > 1) {
        query = `firstName.contains('${nameString[0]}').and(lastName.contains('${nameString[1]}'))`;
      } else {
        query = `firstName.contains('${value}').or(lastName.contains('${value}'))`;
      }
    } else {
      query = `email.startsWith('${value}')`;
    }

    const userResponse = await UserService.getByFilter({ params: { q: query } });

    return userResponse.data.data;
  };

  const getUserSuggestionValue = userSuggestion => userSuggestion.email;

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const text = `${suggestion.name} (${suggestion.email}) (uid: ${suggestion.id})`;
    const matches = match(text, query);
    const parts = parse(text, matches);

    return (
      <MenuItem selected={isHighlighted} component='div'>
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  return (
    <>
      <div
        style={{
          marginTop: 12,
          marginRight: 10,
        }}
      >
        <Button
          size='small'
          variant='outlined'
          color='secondary'
          onClick={updateSearchByName}
        >
          Find By {searchByName ? 'Email' : 'Name'}
        </Button>
      </div>
      <div style={{ width: '75%' }}>
        <Autocomplete
          updateOnSelect={setUserGetProfile}
          label={searchLabel}
          getSuggestions={getUserSuggestions}
          getSuggestionValue={getUserSuggestionValue}
          renderSuggestion={renderSuggestion}
        />
      </div>
    </>
  );
}

export default withRouter(UserSuggestions);
