import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { LoginAccount } from '../types/LoginAccount';

const URL = '/v2/login-accounts';

class LoginAccountService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<LoginAccount>> {
    return axios.get(URL, config);
  }
}

export { LoginAccountService };
