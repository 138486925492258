import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AdminTagService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function TagLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const editTag = rowData => {
    history.push(`/tags/${rowData.id}`);
  };

  const addTag = () => {
    history.push('/tag-create');
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'tag',
        header: 'Tags',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Code',
            accessorKey: 'code',
          },
          {
            header: 'Label',
            accessorKey: 'label',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={AdminTagService.getByFilter}
        editAction={editTag}
        addAction={addTag}
      />
    </Paper>
  );
}

export default withRouter(TagLookup);
