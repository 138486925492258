import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { WinbackCodeService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import DateUtil from 'src/util/DateUtil';
import { Table } from '../common/Table';

function WinbackCodeLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'winbackCodes',
        header: 'Winback Codes',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Code',
            accessorKey: 'code',
          },
          {
            header: 'Description',
            accessorKey: 'description',
          },
          {
            header: 'Start',
            accessorFn: row => DateUtil.getDateSlashesString(moment(row.start)),
          },
          {
            header: 'End',
            accessorFn: row => DateUtil.getDateSlashesString(moment(row.end)),
          },
        ],
      },
    ],
    [],
  );

  const addCode = () => {
    history.push('/winback-code-create');
  };

  const editCode = row => {
    history.push(`/winback-codes/${row.id}`);
  };

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={WinbackCodeService.getByFilter}
        editAction={editCode}
        addAction={addCode}
      />
    </Paper>
  );
}

export default withRouter(WinbackCodeLookup);
