import React from 'react';
import { MenuItem } from '@mui/material';
import suggestionMatch from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Voucher, VoucherCodeService } from 'us-web-services';
import Autocomplete from '../../Autocomplete';

interface Props {
  setVoucher(voucher: Voucher): void;
}

const AddCompanyBenefitVouchers = (props: Props) => {
  const { setVoucher } = props;

  const getSuggestionValue = suggestion => suggestion.code;

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const text = suggestion.percentDiscount
      ? `${suggestion.code} - ${suggestion.value}%`
      : `${suggestion.code} - $${suggestion.value}`;
    const matches = suggestionMatch(text, query);
    const parts = parse(text, matches);

    return (
      <MenuItem selected={isHighlighted} component='div'>
        <div>
          {parts.map(part => (
            <span
              key={part.text}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const getVoucherSuggestions = async code => {
    const voucherParams = {
      code,
      limit: 5,
    };

    const voucherCodeResponse = (await VoucherCodeService.getByFilter({ params: voucherParams })).data;

    return voucherCodeResponse.data;
  };

  return (
    <Autocomplete
      updateOnSelect={setVoucher}
      label='Search by voucher code'
      getSuggestions={getVoucherSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  );
};

export default AddCompanyBenefitVouchers;
