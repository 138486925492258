import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Grid, Paper, Toolbar, Typography } from '@mui/material';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AdminTag,
  AdminTagService,
  Company,
  CompanyEmployeeService,
  CompanyService,
  Parent,
  ParentLocalGroup,
  ParentLocalGroupsService,
  ParentService,
  UserAdminTagService,
  UserState,
} from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

// eslint-disable-next-line import/no-cycle
import {
  BackgroundCheck,
  IdentityCheck,
  ScammerOverride,
  SitterProfileStatus,
  UserAdminNotes,
  UserAdminTags,
  UserCard,
  UserDevices,
  UserNotifications,
  UserVouchers,
} from '.';
import LookupTabs from './LookupTabs';
import Partners from './Partners';
import PaymentInfo from './PaymentInfo';
import UserSocialConnections from './UserSocialConnections';
import UserSuggestions from './UserSuggestions';
import { useAuthentication, useUser } from '../../store';
import ParentLocalGroups from './ParentLocalGroups';
import UserVideoModeration from './UserVideoModeration';
import UserGroupEdit from './UserGroupEdit';
import SharedAccounts from './SharedAccounts';

function UserLookup(props: RouteComponentProps) {
  const authenticationState = useAuthentication()[0];
  const { classes } = PageStyles();
  const userCache = useUser()[0];
  const { location } = props;
  const searchParams = queryString.parse(location.search);
  const [adminTags, setAdminTags] = useState<AdminTag[]>([]);
  const [localGroups, setLocalGroups] = useState<ParentLocalGroup[]>([]);
  const [userAdminTags, setUserAdminTags] = useState<AdminTag[]>([]);
  const [userCompany, setUserCompany] = useState<Company | null>(null);
  const [userParent, setUserParent] = useState<Parent | null>(null);
  const [userState, setUserState] = useState<UserState>({
    user: {},
    profile: {},
  });

  const getAdminTags = async () => {
    const config = {
      params: {
        limit: 50,
        page: 0,
      },
    };
    const adminTagResponse = (await AdminTagService.getByFilter(config)).data;

    if (adminTagResponse.data) {
      setAdminTags(adminTagResponse.data);
    } else {
      setAdminTags([]);
    }
  };

  const getUserLocalGroups = useCallback(async () => {
    if (searchParams.id) {
      const config = {
        params: {
          parentId: searchParams.id,
        },
      };
      const localGroupResponse = (await ParentLocalGroupsService.getByFilter(config)).data;

      if (localGroupResponse.data) {
        setLocalGroups(localGroupResponse.data);
      } else {
        setLocalGroups([]);
      }
    }
  }, [searchParams.id]);

  const getUserAdminTags = useCallback(async () => {
    if (searchParams.id) {
      const tagResponse = await UserAdminTagService.getByFilter(
        `?userId=${searchParams.id}`,
      );

      setUserAdminTags(tagResponse.data.data);
    }
  }, [searchParams.id]);

  const getUserCompany = useCallback(async () => {
    setUserCompany(null);

    if (!searchParams.id) {
      return;
    }

    const resCompanyEmployee = await CompanyEmployeeService.findByFilter({
      params: { userId: searchParams.id },
    });

    const companyEmployeeRecords =
      resCompanyEmployee &&
      resCompanyEmployee.data &&
      resCompanyEmployee.data.data;

    const companyEmployee = companyEmployeeRecords.find(
      employee => employee.disabled === null,
    );

    if (!companyEmployee || !companyEmployee.companyId) {
      return;
    }

    const resCompany = await CompanyService.get(companyEmployee.companyId);
    const company =
      resCompany && resCompany.data && resCompany.data.data
        ? resCompany.data.data
        : null;

    setUserCompany(company);
  }, [searchParams.id]);

  const getUserParent = useCallback(async () => {
    setUserParent(null);

    if (!searchParams.id) {
      return;
    }

    if (!userState.user.id) {
      userState.user.id = parseInt(searchParams.id.toString(), 10);
    }

    const resParent = await ParentService.get(userState.user.id);
    const parent =
      resParent && resParent.data && resParent.data.data
        ? resParent.data.data
        : null;

    setUserParent(parent);
  }, [searchParams.id]);

  useEffect(() => {
    setUserState(userCache);
    if (userCache.user.id && userCache.profile.id) {
      getAdminTags();
      getUserAdminTags();
      getUserCompany();

      if (userCache.user.parent) {
        getUserLocalGroups();
        getUserParent();
      }
    }
  }, [
    getUserAdminTags,
    getUserCompany,
    getUserLocalGroups,
    getUserParent,
    userCache,
    userCache.user.id,
    userCache.profile.id,
    searchParams.id,
  ]);

  const hasSocialConnections = () => !!userState.user.facebookId || !!userState.user.googleUserId;

  return (
    <Paper className={classes.paper}>
      <AppBar position='static' color='transparent' elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                }}
              >
                <UserSuggestions />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div
        style={{
          margin: '40px 16px',
          paddingBottom: '20px',
        }}
      >
        {userState.user.id && userState.profile.id && (
          <>
            <LookupTabs user={userState.user} />
            <UserCard
              lookupUser={userState}
              userAdminTags={userAdminTags}
              userCompany={userCompany}
            />
            {userState.user.parent && <Partners userCompany={userCompany} />}
            <PaymentInfo
              profile={userState.profile}
              userId={userState.user.id}
              isParent={userState.user.parent}
            />
            {userState.user.parent && <IdentityCheck userId={userState.user.id} />}
            {userState.user.sitter && (
              <SitterProfileStatus
                lookupUser={userState}
                userId={userState.user.id}
              />
            )}
            <UserAdminNotes
              adminUserId={authenticationState.authentication.user.id}
              userId={userState.user.id}
            />
            {userParent?.primaryAccountID && (
              <SharedAccounts parent={userParent} />
            )}
            {hasSocialConnections() && <UserSocialConnections user={userState.user} />}
            <UserVouchers userId={userState.user.id} />
            {userState.user.sitter && (
              <>
                <BackgroundCheck userId={userState.user.id} />
                <UserVideoModeration style={{ paddingTop: 10 }} userId={userState.user.id} />
              </>
            )}
            <UserDevices userId={userState.user.id} />
            {adminTags.length > 0 && (
              <UserAdminTags
                lookupUser={userState}
                adminTags={adminTags}
                userAdminTags={userAdminTags}
                getUserAdminTags={getUserAdminTags}
              />
            )}
            {userState.user.parent && localGroups.length > 0 && (
              <ParentLocalGroups
                localGroups={localGroups}
                getUserLocalGroups={getUserLocalGroups}
              />
            )}
            <UserNotifications userId={userState.user.id} />
            <UserGroupEdit userId={userState.user.id} />
            <ScammerOverride userId={userState.user.id} />
          </>
        )}
        {searchParams.id && !userState.user.id && (
          <Typography
            color='textPrimary'
            align='center'
            component='h5'
            variant='h5'
            style={{ padding: 20 }}
          >
            User not found
          </Typography>
        )}
      </div>
    </Paper>
  );
}

export default withRouter(UserLookup);
