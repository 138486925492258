import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';

import { ParentLocalGroup, ParentLocalGroupsService } from 'us-web-services';
import ConfirmDialog from '../common/ConfirmDialog';

interface Props {
  getLocalGroups: () => void;
  localGroup: ParentLocalGroup;
}

function ParentLocalGroupDisplay(props: Props) {
  const { getLocalGroups, localGroup } = props;
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const checked = true;

  const removeGroup = async () => {
    await ParentLocalGroupsService.delete(localGroup.id);
    await getLocalGroups();
  };

  const confirmRemove = () => {
    setOpenRemoveDialog(true);
  };

  return (
    <>
      <Grid item xs={6}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={confirmRemove} />}
          label={localGroup.localGroup.name}
        />
      </Grid>
      <ConfirmDialog
        confirmAction={removeGroup}
        open={openRemoveDialog}
        setOpen={setOpenRemoveDialog}
        title='Confirm Group Removal'
        text={`Are you sure you want to remove the ${localGroup.localGroup.name} group?`}
      />
    </>
  );
}

export default ParentLocalGroupDisplay;
