import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';

import { StateSeoContent } from 'us-web-services';

interface Props {
  stateSeoContent: StateSeoContent;
  setStateSeoContent: (stateSeoContent: StateSeoContent) => void;
}

export default function StateSeoContentEditForm(props: Props) {
  const { stateSeoContent, setStateSeoContent } = props;
  const [nannyPageTitle, setNannyPageTitle] = useState(
    stateSeoContent.nannyPageTitle,
  );
  const [nannyPageText, setNannyPageText] = useState(
    stateSeoContent.nannyPageText,
  );
  const [sitterPageTitle, setSitterPageTitle] = useState(
    stateSeoContent.sitterPageTitle,
  );
  const [sitterPageText, setSitterPageText] = useState(
    stateSeoContent.sitterPageText,
  );

  const updateNannyPageTitle = event => {
    setNannyPageTitle(event.target.value);
    stateSeoContent.nannyPageTitle = event.target.value;
    setStateSeoContent(stateSeoContent);
  };

  const updateNannyPageText = event => {
    setNannyPageText(event.target.value);
    stateSeoContent.nannyPageText = event.target.value;
    setStateSeoContent(stateSeoContent);
  };

  const updateSitterPageTitle = event => {
    setSitterPageTitle(event.target.value);
    stateSeoContent.sitterPageTitle = event.target.value;
    setStateSeoContent(stateSeoContent);
  };

  const updateSitterPageText = event => {
    setSitterPageText(event.target.value);
    stateSeoContent.sitterPageText = event.target.value;
    setStateSeoContent(stateSeoContent);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='nannyPageTitle'
          name='nannyPageTitle'
          label='Nanny Page Title'
          fullWidth
          value={nannyPageTitle}
          onChange={updateNannyPageTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='nannyPageText'
          multiline
          minRows='5'
          margin='normal'
          variant='outlined'
          label='Nanny Page Text'
          fullWidth
          value={nannyPageText}
          onChange={updateNannyPageText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='sitterPageTitle'
          name='sitterPageTitle'
          label='Sitter Page Title'
          fullWidth
          autoComplete='sitterPageTitle'
          value={sitterPageTitle}
          onChange={updateSitterPageTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='sitterPageText'
          label='Sitter Page Text'
          multiline
          minRows='5'
          margin='normal'
          variant='outlined'
          fullWidth
          value={sitterPageText}
          onChange={updateSitterPageText}
        />
      </Grid>
    </Grid>
  );
}
