import React, { useState } from 'react';
import {
  Button,
  Fade,
  Modal,
  TextField,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import {
  AdminCheckrPayWorkerService,
  Parent,
  Sitter,
} from 'us-web-services';
import toast from 'react-hot-toast';
import ModalStyles from '../../styles/ModalStyles';

interface Props {
  profile: Parent | Sitter;
  refreshCheckrPay(): void;
}

function AddCheckrPay(props: Props) {
  const { profile, refreshCheckrPay } = props;
  const successMessage = 'Worker successfully created';

  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);
  const [workerId, setWorkerId] = useState('');
  const [candidateId, setCandidateId] = useState('');

  const updateWorkerId = event => {
    setWorkerId(event.target.value);
  };

  const updateCandidateId = event => {
    setCandidateId(event.target.value);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveCheckrPay = async () => {
    const worker = {
      sitterId: profile.id,
      workerId,
      candidateId,
    };

    try {
      await AdminCheckrPayWorkerService.create(worker);
      toast.success(successMessage);
      handleClose();
      refreshCheckrPay();
    } catch (e) {
      const { response } = e;

      if (response.data.errors) {
        toast.error(response.data.errors[0].message);
      }
    }
  };

  return (
    <div style={{ marginBottom: 20, marginTop: 10 }}>
      <Button
        id='openModal'
        variant='contained'
        color='primary'
        onClick={showModal}
      >
        Create Checkr Pay Account
      </Button>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='background-check-modal-title'>Add a Checkr Pay account to {profile.name} (UID: {profile.id})?</h2>

            <FormLabel component='legend'>Worker ID</FormLabel>
            <br />
            <TextField
              id='workerId'
              label='Worker ID'
              fullWidth
              value={workerId}
              onChange={updateWorkerId}
            />
            <br /> <br />
            <FormLabel component='legend'>Candidate ID</FormLabel>
            <br />
            <TextField
              id='candidateId'
              label='Candidate ID'
              fullWidth
              value={candidateId}
              onChange={updateCandidateId}
            />
            <br /> <br />
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Close
            </Button>{' '}
            <Button
              id='save'
              variant='contained'
              color='primary'
              onClick={saveCheckrPay}
            >
              Save
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddCheckrPay;
