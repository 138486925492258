import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { GiftCard } from '../types/GiftCard';

const API_PUBLIC_URL = '/v2/public/gift-cards';

class GiftCardService {
  static create(
    data: GiftCard,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<GiftCard>> {
    return axios.post(API_PUBLIC_URL, data, config);
  }
}

export { GiftCardService };
