import React, { useState } from 'react';
import { Box, Grid, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Booking } from 'us-web-services';
import { BookingConstants } from '../../constants';
import DateUtil from '../../util/DateUtil';

interface Props {
  booking: Booking;
  setBooking(booking: Booking): void;
}

export default function BookingEditForm(props: Props) {
  const { booking, setBooking } = props;

  const [start, setStart] = useState(booking.start);
  const [end, setEnd] = useState(booking.end);
  const [status, setStatus] = useState(booking.status);
  const [paymentType, setPaymentType] = useState(booking.paymentType);
  const [paymentFrequency, setPaymentFrequency] = useState(
    booking.paymentFrequency.id,
  );
  const [rate, setRate] = useState(booking.rate);
  const [tip, setTip] = useState(booking.tip);
  const [adminCancellationReason, setAdminCancellationReason] = useState(
    booking.adminCancellationReason ?? '',
  );
  const [adminCancellationNote, setAdminCancellationNote] = useState(
    booking.adminCancellationNote ?? '',
  );
  const [rejectedReason, setRejectedReason] = useState(booking.rejectedReason);

  const getPaymentFrequency = (id: any) => {
    switch (id) {
      case 1:
        return {
          id: 1,
          label: 'Hourly',
        };
      case 2:
        return {
          id: 2,
          label: 'Flat rate',
        };
      default:
        return null;
    }
  };

  const getTotal = () => {
    if (paymentFrequency === 1) {
      const hours = (Date.parse(end) - Date.parse(start)) / 3600000;

      return `$${rate * hours + tip}`;
    }

    return `$${rate + tip}`;
  };

  const updateStart = (moment: { set: (arg0: { seconds: number; }) => any; }) => {
    const newStart = DateUtil.getDateTimeString(moment.set({ seconds: 0 }));

    booking.start = newStart;
    setBooking(booking);
    setStart(newStart);
  };

  const updateEnd = (moment: { set: (arg0: { seconds: number; }) => any; }) => {
    const newEnd = DateUtil.getDateTimeString(moment.set({ seconds: 0 }));

    booking.end = newEnd;
    setBooking(booking);
    setEnd(newEnd);
  };

  const updateRejectedReason = (event?: { target: { value: any; }; }) => {
    const newReason = event ? event.target.value : undefined;

    setRejectedReason(newReason);
    booking.rejectedReason = newReason;
    setBooking(booking);
  };

  const updateStatus = (event: { target: { value: any; }; }) => {
    setStatus({ code: event.target.value });
    booking.status = { code: event.target.value };
    setBooking(booking);

    if (booking.status.code !== 'sitter_cancel' && booking.status.code !== 'parent_cancel') {
      updateRejectedReason();
    }
  };

  const updatePaymentType = (event: { target: { value: any; }; }) => {
    setPaymentType(event.target.value);
    booking.paymentType = event.target.value;
    setBooking(booking);
  };

  const updatePaymentFrequency = (event: { target: { value: any; }; }) => {
    setPaymentFrequency(event.target.value);
    booking.paymentFrequency = getPaymentFrequency(event.target.value);
    setBooking(booking);
  };

  const updateRate = (event: { target: { value: string; }; }) => {
    const newRate = parseInt(event.target.value, 10);

    setRate(newRate);
    booking.rate = newRate;
    setBooking(booking);
  };

  const updateTip = (event: { target: { value: string; }; }) => {
    const newTip = parseInt(event.target.value, 10);

    setTip(newTip);
    booking.tip = newTip;
    setBooking(booking);
  };

  const updateAdminCancellationReason = (event: { target: { value: any; }; }) => {
    const newReason = event.target.value;

    setAdminCancellationReason(newReason);
    booking.adminCancellationReason = newReason;
    setBooking(booking);
  };

  const updateAdminCancellationNote = (event: { target: { value: any; }; }) => {
    setAdminCancellationNote(event.target.value);
    booking.adminCancellationNote = event.target.value;
    setBooking(booking);
  };

  const getVerticalName = (id: number) => {
    switch (id) {
      case 1:
        return 'Child care';
      case 2:
        return 'Pet care';
      case 3:
        return 'Senior care';
      case 4:
        return 'Household services';
      default:
        return '';
    }
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Booking Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {booking.type && (
            <Box mt={2}>
              <span>Booking Type: </span>
              <strong>
                {booking.type.charAt(0).toUpperCase() + booking.type.slice(1)}
              </strong>
            </Box>
          )}
          {booking.typeId && (
            <Box>
              <span>Booking Vertical: </span>
              <strong>{getVerticalName(booking.typeId)}</strong>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            inputFormat={DateUtil.dateTimeReadable}
            minutesStep={15}
            value={start}
            onChange={updateStart}
            renderInput={(pickerProps: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...pickerProps}
                required
                fullWidth
                name='start'
                label='Start'
                id='start'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            inputFormat={DateUtil.dateTimeReadable}
            minutesStep={15}
            value={end}
            onChange={updateEnd}
            renderInput={(pickerProps: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...pickerProps}
                fullWidth
                name='end'
                label='End'
                id='end'
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='status'
            name='status'
            label='Status'
            select
            fullWidth
            value={status.code}
            onChange={updateStatus}
          >
            {BookingConstants.STATUSES.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {(status && (status.code === 'sitter_cancel' || status.code === 'parent_cancel')) && (
          <Grid item xs={12}>
            <TextField
              required
              id='reason'
              name='reason'
              label='Cancel Reason'
              select
              fullWidth
              value={rejectedReason}
              onChange={updateRejectedReason}
            >
              {status.code === 'sitter_cancel' &&
                BookingConstants.SITTER_CANCEL_REASONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
              {status.code === 'parent_cancel' &&
                BookingConstants.PARENT_CANCEL_REASONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id='paymentType'
            name='paymentType'
            label='Payment Type'
            select
            fullWidth
            value={paymentType}
            onChange={updatePaymentType}
          >
            {BookingConstants.PAYMENT_TYPES.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id='paymentFrequency'
            name='paymentFrequency'
            label='Frequency'
            select
            fullWidth
            value={paymentFrequency}
            onChange={updatePaymentFrequency}
            InputProps={{
              disabled: booking.type === 'interview',
            }}
          >
            {BookingConstants.PAYMENT_FREQUENCIES.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id='rate'
            name='rate'
            label='Rate'
            fullWidth
            autoComplete='rate'
            value={rate}
            type='number'
            onChange={updateRate}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id='tip'
            name='tip'
            label='Tip'
            fullWidth
            autoComplete='tip'
            value={tip}
            type='number'
            onChange={updateTip}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id='total'
            name='total'
            label='Total'
            fullWidth
            value={getTotal()}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='adminCancellationReason'
            name='adminCancellationReason'
            label='Admin Cancellation Reason'
            select
            fullWidth
            value={adminCancellationReason}
            onChange={updateAdminCancellationReason}
          >
            {BookingConstants.CANCELLATION_REASONS.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='adminCancellationNote'
            name='adminCancellationNote'
            label='Admin Cancellation Note'
            fullWidth
            autoComplete='adminCancellationNote'
            value={adminCancellationNote}
            onChange={updateAdminCancellationNote}
          />
        </Grid>
      </Grid>
    </>
  );
}
