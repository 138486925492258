import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { UserGeocode } from '../types/UserGeocode';

const URL = '/v2/users';

class UserGeocodeService {
  static create(
    id: number,
    data: UserGeocode,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<UserGeocode>> {
    return axios.post(`${URL}/${id}/geocode`, data, config);
  }
}
export { UserGeocodeService };
