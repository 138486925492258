import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../../types';
import { CompanyEmployeeExternalId } from '../../types/company/CompanyEmployeeExternalId';

const URL = '/v2/public/employee-externalids';

class CompanyEmployeeExternalIdService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyEmployeeExternalId>> {
    return axios.get(URL, config);
  }
}

export { CompanyEmployeeExternalIdService };
