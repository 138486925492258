import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { MessageThread } from '../types/MessageThread';

const URL = '/v2/message-threads';

class MessageThreadService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MessageThread>> {
    return axios.get(URL, config);
  }
}
export { MessageThreadService };
