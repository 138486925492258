export default {
  TASKS: [
    {
      uri: '/v2/cron/accepted-booking-reminder',
      label: 'Accepted Booking Reminders',
    },
    {
      uri: '/v2/cron/background-check-sms-reminder',
      label: 'Background Check SMS Reminder',
    },
    {
      uri: '/v2/cron/booking-expiration-reminder',
      label: 'Booking Expiration Reminder',
    },
    {
      uri: '/v2/cron/close-old-recurring-jobs',
      label: 'Close Old Recurring Jobs',
    },
    {
      uri: '/v2/cron/close-past-one-time-jobs',
      label: 'Close Past One-Time Jobs',
    },
    {
      uri: '/v2/cron/update-past-expiration-background-check',
      label: 'Expire Background Checks',
    },
    {
      uri: '/v2/cron/update-parent-expiring-plan',
      label: 'Expiring Parent Plans',
    },
    {
      uri: '/v2/cron/update-sitter-expiring-plan',
      label: 'Expiring Sitter Plans',
    },
    {
      uri: '/v2/cron/parent-plan-renewal-reminder',
      label: 'Parent Plan Reminders',
    },
    {
      uri: '/v2/cron/recurring-job-close-reminder',
      label: 'Recurring Job Auto Close Reminder',
    },
    {
      uri: '/v2/cron/sitter-plan-reminder',
      label: 'Sitter Plan Reminders',
    },
    {
      uri: '/v2/cron/sitter-confirmed-booking-payout',
      label: 'Sitter Confirmed Booking Payouts',
    },
    {
      uri: 'v2/cron/sitter-first-pending-booking-reminder',
      label: 'Sitter First Pending Booking Reminder',
    },
    {
      uri: '/v2/cron/update-benefits',
      label: 'Update Benefits',
    },
    {
      uri: '/v2/cron/update-completed-bookings',
      label: 'Update Completed Bookings',
    },
    {
      uri: '/v2/cron/update-past-pending-bookings',
      label: 'Update Past Pending Bookings',
    },
  ],
};
