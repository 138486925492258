import React, { useCallback } from 'react';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 50,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));

interface Props {
  updateOnSelect: any;
  label: string;
  renderSuggestion: any;
  getSuggestions: any;
  getSuggestionValue: any;
}

function Autocomplete(props: Props) {
  const {
    updateOnSelect,
    label,
    renderSuggestion,
    getSuggestions,
    getSuggestionValue,
  } = props;
  const { classes: styles } = useStyles();
  const [state, setState] = React.useState({
    searchText: '',
  });

  const [suggestions, setSuggestions] = React.useState([]);

  let getAndSetSuggestions = async value => {
    setSuggestions(await getSuggestions(value));
  };

  function renderInputComponent(inputProps) {
    const { classes, key = '', inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        key={key}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    );
  }

  getAndSetSuggestions = useCallback(_.debounce(getAndSetSuggestions, 500), [
    getSuggestions,
  ]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    getAndSetSuggestions(value);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_event, { suggestion }) => {
    updateOnSelect(suggestion);
  };

  const handleChange =
    name =>
      (_event, { newValue }) => {
        setState({
          ...state,
          [name]: newValue,
        });
      };

  const autosuggestProps = {
    renderInputComponent,
    suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    onSuggestionSelected,
    renderSuggestion,
  };

  return (
    <div className={styles.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          // @ts-expect-error classes are valid
          classes: styles,
          id: 'search',
          label,
          value: state.searchText,
          onChange: handleChange('searchText'),
        }}
        theme={{
          container: styles.container,
          suggestionsContainerOpen: styles.suggestionsContainerOpen,
          suggestionsList: styles.suggestionsList,
          suggestion: styles.suggestion,
        }}
        renderSuggestionsContainer={options => {
          const { key, ...rest } = options.containerProps;

          return (
            <Paper key={key} {...rest} square>
              {options.children}
            </Paper>
          );
        }}
      />
    </div>
  );
}

export default Autocomplete;
