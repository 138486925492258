import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { UserVoucher } from '../types/UserVoucher';

const URL = '/v2/user-vouchers';

class UserVoucherService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<UserVoucher>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: UserVoucher,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<UserVoucher>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<UserVoucher>> {
    return axios.post(URL, data, config);
  }
}
export { UserVoucherService };
