import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { ParentSignupReason } from '../types/ParentSignupReason';

const URL = '/v2/parent-signup-user-reasons';

class ParentSignupUserReasonService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupReason>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ParentSignupReason>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: ParentSignupReason,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupReason>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: ParentSignupReason,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupReason>> {
    return axios.post(URL, data, config);
  }
}
export { ParentSignupUserReasonService };
