import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';

import { OrderService } from 'us-web-services';
import useUser from '../../store/useUser';
import DateUtil from '../../util/DateUtil';
import { Table } from '../common/Table';

function BookingTransactions(props: RouteComponentProps) {
  const { history } = props;

  const userState = useUser()[0];

  const COLUMNS = useMemo(
    () => [
      {
        id: 'bookingPayments',
        header: 'Booking Payments',
        columns: [
          {
            header: 'Completed',
            accessorFn: rowData =>
              DateUtil.getDateSlashesString(moment(rowData.created)),
          },
          {
            header: 'Status',
            accessorKey: 'status',
          },
          {
            header: 'Service total',
            accessorFn: rowData =>
              rowData.itemBooking ? rowData.itemBooking.total : '',
          },
          {
            header: 'Tip',
            accessorFn: rowData =>
              rowData.itemBooking ? rowData.itemBooking.tip : '0',
          },
          {
            header: 'Platform fee',
            accessorFn: rowData => rowData.platformFee ?? '0',
          },
          {
            header: 'Discount',
            accessorFn: rowData => rowData.discount ?? '0',
          },
          {
            header: 'Total In',
            accessorFn: rowData => rowData.totalIn,
          },
          {
            header: 'Order ID',
            accessorKey: 'id',
          },
          {
            header: 'Booking ID',
            accessorFn: rowData =>
              rowData.itemBooking ? rowData.itemBooking.bookingId : '',
          },
        ],
      },
    ],
    [],
  );

  const params = () => {
    const coreFilters = [
      {
        key: 'reasons',
        value: 'booking',
      },
      {
        key: 'status',
        value: 'success',
      },
    ];

    if (userState.user.parent) {
      return [
        {
          key: 'userId',
          value: userState.user.id,
        },
      ].concat(coreFilters);
    }

    return [
      {
        key: 'payeeId',
        value: userState.user.id,
      },
    ].concat(coreFilters);
  };

  const editBooking = rowData => {
    if (rowData.itemBooking) {
      history.push(`/bookings/lookup?id=${rowData.itemBooking.bookingId}`);
    }
  };

  return (
    <Table
      columns={COLUMNS}
      retrieveData={OrderService.getByFilter}
      params={params}
      alwaysApplyParams
      editAction={editBooking}
      hideSearchBar
    />
  );
}

export default withRouter(BookingTransactions);
