import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { College, CollegeService, SitterCollegeService } from 'us-web-services';
import StatesConstant from 'src/constants/States.constant';
import CollegeEditForm from './CollegeEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

function CollegeEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [college, setCollege] = useState<College>({});
  const [sitterCount, setSitterCount] = useState(0);

  useEffect(() => {
    const collegeId = Number.parseInt(match.params.id, 10);

    if (collegeId) {
      const getData = async () => {
        const response = (await CollegeService.get(collegeId)).data;

        if (response.data.id) {
          setCollege(response.data);

          const sitterCollegeConfig = {
            params: {
              limit: 1,
              collegeId,
              userId: adminUser.id,
            },
          };

          const sitterCollegeResponse = (await SitterCollegeService.getByFilter(sitterCollegeConfig)).data;

          setSitterCount(sitterCollegeResponse.meta.size);
        } else {
          setCollege({
            id: null,
          });
          setSitterCount(0);
        }
      };

      getData();
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/colleges');
  };

  const saveSuccess = () => {
    toast.success(`${college.name} updated successfully`);
    navigateToList();
  };

  const showError = (errText: string) => {
    toast.error(errText);
  };

  const isInputValid = async (): Promise<boolean> => {
    let isValid = true;

    if (!college.name) {
      showError('College name is required');
      isValid = false;
    }

    if (!StatesConstant.some(s => s.code === college.state)) {
      showError('State is invalid');
      isValid = false;
    }

    const zipRegex = /^\d{5}$/;

    if (!zipRegex.test(college.zip)) {
      showError('Zip is invalid');
      isValid = false;
    }

    if (+college.latitude > 90 || +college.latitude < -90) {
      showError('Latitude is invalid');
      isValid = false;
    }
    if (+college.longitude > 180 || +college.longitude < -180) {
      showError('Longitude is invalid');
      isValid = false;
    }

    return isValid;
  };

  const saveCollege = async () => {
    try {
      if (!(await isInputValid())) {
        return;
      }

      if (college.id) {
        await CollegeService.patch(college.id, college);
      } else {
        await CollegeService.create(college);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {college.name} (sitters: {sitterCount})
      </Typography>
      {college.id ? (
        <CollegeEditForm college={college} setCollege={setCollege} />
      ) : (
        'College not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveCollege}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(CollegeEdit);
