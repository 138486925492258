import { StorageNames } from 'src/constants';
import { AuthToken } from 'us-web-services';

export const setUser = (store, user) => {
  const { authentication } = store.state;

  if (user) {
    localStorage.setItem(StorageNames.US_USER_OBJECT, JSON.stringify(user));
    authentication.user = user;
    authentication.isAuthenticated = true;
  } else {
    localStorage.removeItem(StorageNames.US_USER_OBJECT);
    authentication.user = {};
    authentication.isAuthenticated = false;
  }

  store.setState({ authentication });
};

export const setToken = (store, token) => {
  if (token) {
    localStorage.setItem(StorageNames.US_USER_AUTH_TOKEN, token);
  } else {
    localStorage.removeItem(StorageNames.US_USER_AUTH_TOKEN);
  }

  const { authentication } = store.state;

  authentication.token = token;
  store.setState({ authentication });
};

export const setRefreshToken = (store, token) => {
  if (token) {
    localStorage.setItem(StorageNames.US_USER_REFRESH_TOKEN, token);
  } else {
    localStorage.removeItem(StorageNames.US_USER_REFRESH_TOKEN);
  }

  const { authentication } = store.state;

  authentication.refreshToken = token;
  store.setState({ authentication });
};

export const updateToken = (store, token: AuthToken | null | undefined): void => {
  if (token?.token) {
    localStorage.setItem(StorageNames.US_USER_AUTH_TOKEN, token.token);
  } else {
    localStorage.removeItem(StorageNames.US_USER_AUTH_TOKEN);
  }
  if (token?.refreshToken?.token) {
    localStorage.setItem(StorageNames.US_USER_REFRESH_TOKEN, token.refreshToken?.token);
  } else {
    localStorage.removeItem(StorageNames.US_USER_REFRESH_TOKEN);
  }

  const { authentication } = store.state;

  authentication.token = token.token;
  authentication.refreshToken = token.refreshToken.token;
  store.setState({ authentication });
};

export const set = (store, authentication) => {
  if (authentication.token) {
    localStorage.setItem(StorageNames.US_USER_AUTH_TOKEN, authentication.token);
  } else {
    localStorage.removeItem(StorageNames.US_USER_AUTH_TOKEN);
  }

  if (authentication.user) {
    localStorage.setItem(
      StorageNames.US_USER_OBJECT,
      JSON.stringify(authentication.user),
    );
    authentication.isAuthenticated = true;
  } else {
    localStorage.removeItem(StorageNames.US_USER_OBJECT);
    authentication.isAuthenticated = false;
  }

  store.state.authentication = authentication;
  store.setState({ authentication });
};
