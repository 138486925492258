export interface BackgroundCheckStatus {
  id?: number;
  label?: string;
}

export enum BackgroundCheckStatusIds {
  CLEARED = 1,
  SUBMITTED = 2,
  PENDING = 3,
  FAILED = 4,
  UNKNOWN = 5,
  EXPIRED = 6,
}
