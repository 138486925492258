import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { ParentPlan } from '../types/ParentPlan';

const URL = '/v2/parent-plans';

class ParentPlanService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ParentPlan>> {
    return axios.get(`${URL}`, config);
  }

  static create(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentPlan>> {
    return axios.post(URL, data, config);
  }
}

export { ParentPlanService };
