import axios, { AxiosRequestConfig } from 'axios';
import { AdminTag } from '../types/AdminTag';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/admin-tags';

class AdminTagService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminTag>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<AdminTag>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: AdminTag,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminTag>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: AdminTag,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminTag>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { AdminTagService };
