import React, { useMemo } from 'react';
import toast from 'react-hot-toast';
import { FormLabel, Grid } from '@mui/material';
import { GroupBase, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';

import { MasterServiceAreaZipCodeService, ZipCodeService } from 'us-web-services';
import DisplayService from '../../util/DisplayService';
import { Table, TableInstance } from '../common/Table';

interface Props {
  masterServiceAreaId: number;
}

function MasterServiceAreaZipCodes(props: Props) {
  const { masterServiceAreaId } = props;
  const tableRef = React.useRef<TableInstance>();

  const getZipCodes = (text: string): Promise<OptionsOrGroups<any, GroupBase<any>>> =>
    new Promise(resolve => {
      const config = {
        params: {
          limit: 10,
          page: 1,
          zipCode: text,
        },
      };

      ZipCodeService.getByFilter(config).then(result => {
        resolve(result.data.data);
      });
    });

  const onUpdateError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the zip code.',
    );

    toast.error(displayedError.message);
  };

  const onUpdateSuccess = (deleted: boolean) => {
    toast.success(`Zip code ${deleted ? 'removed' : 'added'} successfully`);
    tableRef.current?.fetchData();
  };

  const deleteRowZipCode = async rowData => {
    MasterServiceAreaZipCodeService.delete(rowData.id)
      .then(() => onUpdateSuccess(true))
      .catch(onUpdateError);
  };

  const onZipSelect = selectedZipCodes => {
    const data = {
      masterServiceAreaId,
      zipCode: {
        id: selectedZipCodes.id,
      },
    };

    MasterServiceAreaZipCodeService.create(data)
      .then(() => onUpdateSuccess(false))
      .catch(onUpdateError);
  };

  const params = () => [
    {
      key: 'masterServiceAreaId',
      value: masterServiceAreaId.toString(),
    },
  ];

  const COLUMNS = useMemo(
    () => [
      {
        id: 'zipCodes',
        header: 'Zip Codes',
        columns: [
          {
            header: 'Zip Code',
            accessorFn: row => row?.zipCode.zipCode,
          },
          {
            header: 'City',
            accessorFn: row => row?.zipCode.city,
          },
          {
            header: 'State',
            accessorFn: row => row?.zipCode.state,
          },
          {
            header: 'Time Zone',
            accessorFn: row => row?.zipCode.timeZone,
          },
          {
            header: 'Latitude',
            accessorFn: row => row?.zipCode.latitude,
          },
          {
            header: 'Longitude',
            accessorFn: row => row?.zipCode.longitude,
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <Grid item xs={12}>
        <FormLabel component='legend' style={{ marginBottom: 5, marginTop: 20 }}>
          Add Zip Code
        </FormLabel>
        <AsyncSelect
          cacheOptions
          defaultOptions
          onChange={onZipSelect}
          styles={{
            // @ts-expect-error CSS overloads are valid here
            menu: provided => ({
              ...provided,
              zIndex: 100,
            }),
          }}
          loadOptions={getZipCodes}
          getOptionValue={data => data.id}
          getOptionLabel={data => data.zipCode}
        />
      </Grid>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={MasterServiceAreaZipCodeService.getByFilter}
        params={params}
        deleteAction={deleteRowZipCode}
        alwaysApplyParams
      />
    </>
  );
}

export default MasterServiceAreaZipCodes;
