import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import toast from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Booking, BookingGroup, BookingService, BookingGroupService } from 'us-web-services';
import BookingCard from './BookingCard';
import BookingGroupEditForm from './BookingGroupEditForm';
import useAuthentication from '../../store/useAuthentication';
import PageStyles from '../../styles/PageStyles';

function BookingGroupEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { match, history } = props;

  const [bookingGroup, setBookingGroup] = useState<BookingGroup>({});
  const [bookings, setBookings] = useState<Booking[]>([]);

  useEffect(() => {
    const currentGroupId = Number.parseInt(match.params.id, 10);

    if (!bookingGroup?.id || bookingGroup.id !== currentGroupId) {
      const getBookings = async () => {
        const bookingFilter = {
          params: {
            groupId: currentGroupId,
            userId: authenticatedUser.id,
          },
        };
        const bookingResponse = await BookingService.getByFilter(bookingFilter);
        const bookingData = bookingResponse.data.data;

        setBookings(bookingData);
      };

      const getBookingGroup = async () => {
        const bookingGroupFilter = {
          params: {
            userId: authenticatedUser.id,
          },
        };
        const bookingGroupResponse = await BookingGroupService.get(currentGroupId, bookingGroupFilter);
        const bookingGroupData = bookingGroupResponse.data.data;

        setBookingGroup(bookingGroupData);
        getBookings();
      };

      getBookingGroup();
    }
  }, [authenticatedUser.id, match.params.id]);

  const saveBookingGroup = async () => {
    const bookingGroupData: BookingGroup = {
      id: bookingGroup.id,
      status: bookingGroup.status,
      numberOfSitters: bookingGroup.numberOfSitters,
    };

    await BookingGroupService.patch(
      bookingGroup.id,
      bookingGroupData,
    );

    toast.success('Booking Group updated successfully');

    history.goBack();
  };

  return (
    <>
      <Paper className={classes.paper}>
        {bookingGroup.id ? (
          <BookingGroupEditForm
            bookingGroup={bookingGroup}
            setBookingGroup={setBookingGroup}
          />
        ) : (
          'Booking Group not found'
        )}
        <div className={classes.buttons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={history.goBack}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={saveBookingGroup}
            className={classes.button}
          >
            Save
          </Button>{' '}
        </div>
      </Paper>
      {bookings.map(booking => (
        <BookingCard key={booking.id} booking={booking} />
      ))}
    </>
  );
}

export default withRouter(BookingGroupEdit);
