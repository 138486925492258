import React, { useState } from 'react';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { BookingGroup } from 'us-web-services';
import { BookingConstants } from '../../constants';

interface Props {
  bookingGroup: BookingGroup;
  setBookingGroup: (bookingGroup: BookingGroup) => void;
}

export default function BookingGroupEditForm(props: Props) {
  const { bookingGroup, setBookingGroup } = props;

  const [status, setStatus] = useState(bookingGroup.status);
  const [numberOfSitters, setNumberOfSitters] = useState(
    bookingGroup.numberOfSitters,
  );

  const updateStatus = event => {
    setStatus(event.target.value);
    bookingGroup.status = event.target.value;
    setBookingGroup(bookingGroup);
  };

  const updateNumberOfSitters = event => {
    setNumberOfSitters(event.target.value);
    bookingGroup.numberOfSitters = event.target.value;
    setBookingGroup(bookingGroup);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Booking Group Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs>
          <TextField
            required
            id='status'
            name='status'
            label='Status'
            select
            fullWidth
            value={status}
            onChange={updateStatus}
          >
            {BookingConstants.GROUP_STATUSES.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs>
          <TextField
            required
            id='numberOfSitters'
            name='numberOfSitters'
            label='Number of Sitters'
            fullWidth
            value={numberOfSitters}
            onChange={updateNumberOfSitters}
          />
        </Grid>
      </Grid>
    </>
  );
}
