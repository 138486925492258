import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { WinbackCode, WinbackCodeService } from 'us-web-services';
import WinbackCodeForm from './WinbackCodeForm';
import PageStyles from '../../styles/PageStyles';

function WinbackCodeEdit(props: RouteComponentProps<{ id: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [loaded, setLoaded] = useState(false);
  const [winbackCode, setWinbackCode] = useState<WinbackCode>({
    code: '',
    description: '',
  });

  useEffect(() => {
    if (match.params.id) {
      const getData = async () => {
        const response = (await WinbackCodeService.get(match.params.id)).data;

        if (response.data.id) {
          setWinbackCode(response.data);
        } else {
          setWinbackCode({
            code: '',
            description: '',
          });
        }
        setLoaded(true);
      };

      getData();
    } else {
      setLoaded(true);
    }
  }, [match.params.id]);

  const navigateToList = () => {
    history.push('/winback-codes');
  };

  const saveSuccess = () => {
    toast.success('Winback code updated successfully');
    navigateToList();
  };

  const saveWinbackCode = async () => {
    try {
      if (winbackCode.id) {
        await WinbackCodeService.patch(winbackCode.id, winbackCode);
      } else {
        await WinbackCodeService.create(winbackCode);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Winback Code
      </Typography>
      {loaded ? (
        <WinbackCodeForm
          winbackCode={winbackCode}
          setWinbackCode={setWinbackCode}
        />
      ) : (
        'Loading'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveWinbackCode}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(WinbackCodeEdit);
