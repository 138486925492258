import axios, { AxiosRequestConfig } from 'axios';
import { MasterServiceArea } from '../../types/master_service_area/MasterServiceArea';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/master-service-areas';

class MasterServiceAreaService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceArea>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceArea>> {
    return axios.get(URL, config);
  }

  static update(
    id: number,
    data: MasterServiceArea,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceArea>> {
    return axios.post(`${URL}/${id}`, data, config);
  }

  static patch(
    id: number,
    data: MasterServiceArea,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceArea>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: MasterServiceArea,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceArea>> {
    return axios.post(URL, data, config);
  }
}

export { MasterServiceAreaService };
