import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Parent, SharedAccount, SharedAccountService } from 'us-web-services';
import CardStyles from 'src/styles/CardStyles';
import toast from 'react-hot-toast';
import { DisplayService } from '../../util';

type Props = {
  parent?: Parent;
};

function SharedAccounts({ parent }: Props) {
  const { classes } = CardStyles();
  const { primaryAccountID, secondaryAccounts } = parent;
  const [sharedAccounts, setSharedAccounts] = useState<Array<SharedAccount>>([]);
  const isPrimary = !!secondaryAccounts?.length;

  const getSharedAccounts = async () => {
    const config = {
      params: {
        primaryUserId: primaryAccountID,
        secondaryUserId: isPrimary ? null : parent.id,
      },
    };

    const response = await SharedAccountService.getByFilter(config);

    setSharedAccounts(response.data.data);
  };

  const disableSharedAccount = async (sharedAccount: SharedAccount) => {
    try {
      await SharedAccountService.delete(sharedAccount.id);

      toast.success('Shared account disabled successfully');
      getSharedAccounts();
    } catch (e) {
      const displayedError = DisplayService.getErrorResponse(
        e,
        'There was an error disabling this shared account',
      );

      toast.error(displayedError.message);
    }
  };

  const enableSharedAccount = async (sharedAccount: SharedAccount) => {
    try {
      sharedAccount.disabled = null;
      await SharedAccountService.update(sharedAccount.id, sharedAccount);

      toast.success('Shared account re-enabled successfully');
      getSharedAccounts();
    } catch (e) {
      const displayedError = DisplayService.getErrorResponse(
        e,
        'There was an error enabling this shared account',
      );

      toast.error(displayedError.message);
    }
  };

  const toggleSharedAccountDisabled = (sharedAccount: SharedAccount) => {
    if (sharedAccount.disabled) {
      enableSharedAccount(sharedAccount);
    } else {
      disableSharedAccount(sharedAccount);
    }
  };

  useEffect(() => {
    getSharedAccounts();
  }, []);

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component='h5'
            variant='h5'
            style={{
              paddingBottom: '10px',
            }}
          >
            Shared Accounts
          </Typography>
          <Typography variant='subtitle1' color='textPrimary'>
            Account Type: <b>{ isPrimary ? 'Primary' : 'Secondary' }</b>
          </Typography>
          {!isPrimary && (
            <Typography variant='subtitle1' color='textPrimary'>
              <span>
                Primary User ID:&nbsp;
                <Link to={`/users/lookup?id=${primaryAccountID}`}>{ primaryAccountID }</Link>
              </span>
              {sharedAccounts.length > 0 && (
                <>
                  <div>
                    {sharedAccounts[0].disabled ? `Disabled: ${sharedAccounts[0].disabled}` : ''}
                  </div>
                  <Button color='secondary' onClick={() => toggleSharedAccountDisabled(sharedAccounts[0])}>
                    {sharedAccounts[0].disabled ? 'Enable' : 'Disable'} Shared Account
                  </Button>
                </>
              )}
            </Typography>
          )}
          {isPrimary && (
            <Typography variant='subtitle1' color='textPrimary'>
              Secondary User IDs: {sharedAccounts.map((account, index) => (
                <>
                  <span key={account.secondaryUserId}>
                    <Link to={`/users/lookup?id=${account.secondaryUserId}`}>{account.secondaryUserId}</Link>
                    {(index < sharedAccounts.length - 1) && ', '}
                  </span>
                  <div>
                    {account.disabled ? `Disabled: ${account.disabled}` : ''}
                    <Button onClick={() => toggleSharedAccountDisabled(account)}>
                      {account.disabled ? 'Enable' : 'Disable'} Shared Account
                    </Button>
                  </div>
                </>
            ))}
            </Typography>
          )}
        </CardContent>
      </div>
    </Card>
  );
}

export default SharedAccounts;
