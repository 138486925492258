import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types';
import { CompanyBenefit } from '../../types/company/CompanyBenefit';

const URL = '/v2/company-benefits';

class CompanyBenefitService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefit>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyBenefit>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: CompanyBenefit,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefit>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: CompanyBenefit,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefit>> {
    return axios.post(`${URL}`, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CompanyBenefitService };
