import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { ScammerOverride as Override, ScammerOverrideService } from 'us-web-services';
import DisplayService from '../../util/DisplayService';
import useAuthentication from '../../store/useAuthentication';

interface Props extends RouteComponentProps {
  userId: number;
}

function ScammerOverride(props: Props) {
  const { userId } = props;

  const authenticationState = useAuthentication()[0];
  const [override, setOverride] = useState<Override>({});

  const getOverage = useCallback(async () => {
    const config = {
      params: {
        userId,
      },
    };
    const overageResponse = (await ScammerOverrideService.findByFilter(config)).data;

    if (overageResponse.data.length) {
      setOverride(overageResponse.data[0]);
    }
  }, [userId]);

  const getButtonText = () => (override.overridden ? 'Disable' : 'Enable');
  const getCurrentStatus = () => (override.overridden ? 'Enabled' : 'Disabled');
  const getCurrentStatusColor = () => (override.overridden ? 'green' : 'red');

  const toggleOverage = async () => {
    const params = {
      id: override.id ?? undefined,
      createdBy: override.createdBy ?? { id: authenticationState.authentication.user.id },
      modifiedBy: { id: authenticationState.authentication.user.id },
      overriddenBy: override.overridden ? null : { id: authenticationState.authentication.user.id },
      user: { id: userId },
    };

    try {
      if (params.id) {
        await ScammerOverrideService.post(params);
      } else {
        await ScammerOverrideService.create(params);
      }
      getOverage();
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error updating the scammer override.',
      );

      toast.error(displayedError.message);
    }
  };

  useEffect(() => {
    getOverage();
  }, [getOverage]);

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Typography component='h5' variant='h5' style={{ marginBottom: 15 }}>
          Scammer Override
        </Typography>
        Enabling this will allow this user to create unlimited
        Bookings/Jobs/Messages within a 24 hour period.
        <br />
        <br />
        Current Status:{' '}
        <span
          style={{
            fontWeight: 600,
            marginRight: 15,
            color: getCurrentStatusColor(),
          }}
        >
          {getCurrentStatus()}
        </span>
        <Button
          id='toggle-override'
          variant='contained'
          color='primary'
          onClick={toggleOverage}
        >
          {getButtonText()}
        </Button>
      </CardContent>
    </Card>
  );
}

export default withRouter(ScammerOverride);
