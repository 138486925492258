import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { PlayArrow, ThumbDown, ThumbUp } from '@mui/icons-material';
import { Button, IconButton, Paper } from '@mui/material';
import toast from 'react-hot-toast';

import { VideoModerationService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import HostsService from '../../util/HostsService';
import { Table, TableInstance } from '../common/Table';
import VideoModal from './VideoModal';
import VideoModerationConfirmModal from './VideoModerationConfirmModal';

function VideoModeration() {
  const tableRef = React.useRef<TableInstance>();
  const { classes } = PageStyles();
  const [url, setUrl] = useState('');
  const [currentId, setCurrentId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmRejectModal, setOpenConfirmRejectModal] = useState(false);

  const openVideoModal = rowData => {
    setUrl(`https://player.vimeo.com/video/${rowData.video.vimeoId}`);
    setOpenModal(true);
  };

  const refreshModeration = (approved: boolean) => {
    toast.success(`Video ${approved ? 'approved' : 'rejected'}`);
    tableRef.current?.fetchData();
  };

  const approveVideo = async rowData => {
    setCurrentId(rowData.id);
    setOpenConfirmApproveModal(true);
  };

  const rejectVideo = async rowData => {
    setCurrentId(rowData.id);
    setOpenConfirmRejectModal(true);
  };

  const confirmApproval = async () => {
    const patchObject = {
      id: currentId,
      status: 'approved',
    };

    await VideoModerationService.patch(patchObject);
    refreshModeration(true);
    setOpenConfirmApproveModal(false);
  };

  const confirmRejection = async () => {
    const patchObject = {
      id: currentId,
      status: 'rejected',
    };

    await VideoModerationService.patch(patchObject);
    refreshModeration(false);
    setOpenConfirmRejectModal(false);
  };

  const getProfileUrl = id => {
    const base = `https://${HostsService.getAppHost()}/app`;

    return `${base}/sitter/${id}`;
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'benefits',
        header: 'Video Moderation',
        columns: [
          {
            header: 'Sitter ID',
            accessorFn: row => row?.video?.sitter?.id,
          },
          {
            header: 'Sitter Name',
            Cell: ({ cell }) => (
              <a
                href={getProfileUrl(cell.row?.original?.video?.sitter?.id)}
                target='_blank'
                rel='noreferrer noopener'
              >
                {cell?.row?.original?.video?.sitter?.firstName}{' '}
                {cell?.row?.original?.video?.sitter?.lastName}
              </a>
            ),
          },
          {
            header: 'Profile Picture',
            Cell: ({ cell }) => (
              <img
                alt=''
                src={cell?.row?.original?.video?.sitter?.pictureUrl}
                style={{ width: 50 }}
              />
            ),
          },
          {
            header: 'Actions',
            Cell: ({ cell }) => (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => openVideoModal(cell?.row?.original)}
                >
                  <PlayArrow />
                </Button>
                <IconButton
                  aria-label='approve'
                  onClick={() => approveVideo(cell?.row?.original)}
                  size='large'
                >
                  <ThumbUp />
                </IconButton>
                <IconButton
                  aria-label='reject'
                  onClick={() => rejectVideo(cell?.row?.original)}
                  size='large'
                >
                  <ThumbDown />
                </IconButton>
              </>
            ),
          },
        ],
      },
    ],
    [],
  );

  const params = () => [
    {
      key: 'sort',
      value: 'id',
    },
    {
      key: 'id.dir',
      value: 'desc',
    },
    {
      key: 'status',
      value: '',
    },
  ];

  return (
    <Paper className={classes.paper}>
      <VideoModal url={url} open={openModal} setOpen={setOpenModal} />
      <VideoModerationConfirmModal
        action='approve'
        confirmAction={confirmApproval}
        open={openConfirmApproveModal}
        setOpen={setOpenConfirmApproveModal}
      />
      <VideoModerationConfirmModal
        action='reject'
        confirmAction={confirmRejection}
        open={openConfirmRejectModal}
        setOpen={setOpenConfirmRejectModal}
      />
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={VideoModerationService.getByFilter}
        params={params}
        alwaysApplyParams
      />
    </Paper>
  );
}

export default withRouter(VideoModeration);
