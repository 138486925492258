import { makeStyles } from 'tss-react/mui';

const CardStyles = makeStyles()(theme => ({
  card: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  content: { flex: '1 0 auto' },
  cover: { width: 151 },
  error: {
    color: theme.palette.error.main,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    width: 181,
    backgroundSize: 'cover',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  photo: {
    width: 160,
    height: 160,
    marginTop: 55,
    marginLeft: 15,
  },
}));

export default CardStyles;
