import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { Sitter } from 'us-web-services';
import DateUtil from '../../util/DateUtil';

interface Props {
  profile: Sitter;
  setProfile(profile: Sitter): void;
}

export default function SitterEditForm(props: Props) {
  const { profile, setProfile } = props;
  const [dob, setDob] = useState(profile.dob ? profile.dob : null);

  const updateDob = moment => {
    const newDob = DateUtil.getDateTimeString(moment);

    setDob(newDob);
    profile.dob = newDob;
    setProfile(profile);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            inputFormat={DateUtil.dateFormatSlashes}
            value={dob}
            onChange={updateDob}
            renderInput={pickerProps => (
              <TextField
                {...pickerProps}
                fullWidth
                name='dob'
                label='Date of Birth'
                id='dob'
              />
            )}
          />
        </Grid>
      </Grid>
      <br />
    </>
  );
}
