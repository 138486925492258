import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

import { MasterServiceAreaPlanPrice, Plan, PlanService } from 'us-web-services';
import useAuthentication from '../../store/useAuthentication';
import DateUtil from '../../util/DateUtil';

const CHARACTER_LIMIT = 150;

interface Props {
  masterServiceAreaPlanPrice: MasterServiceAreaPlanPrice;
  setMasterServiceAreaPlanPrice: (masterServiceAreaPlanPrice: MasterServiceAreaPlanPrice) => void;
}

function MasterServiceAreaPlanPriceForm(props: Props) {
  const { masterServiceAreaPlanPrice, setMasterServiceAreaPlanPrice } = props;

  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const [plans, setPlans] = useState<Plan[]>([]);
  const [planId, setPlanId] = useState(masterServiceAreaPlanPrice.plan.id);
  const [label, setLabel] = useState(masterServiceAreaPlanPrice.label);
  const [description, setDescription] = useState(
    masterServiceAreaPlanPrice.description,
  );
  const [setupFee, setSetupFee] = useState(masterServiceAreaPlanPrice.setupFee);
  const [installmentFee, setInstallmentFee] = useState(
    masterServiceAreaPlanPrice.installmentFee,
  );
  const [controlSetupFee, setControlSetupFee] = useState(
    masterServiceAreaPlanPrice.masterServiceAreaPriceTest
      ? masterServiceAreaPlanPrice.masterServiceAreaPriceTest.controlSetupFee
      : null,
  );

  const { masterServiceAreaPriceTest } = masterServiceAreaPlanPrice;

  const [controlInstallmentFee, setControlInstallmentFee] = useState(
    masterServiceAreaPriceTest
      ? masterServiceAreaPriceTest.controlInstallmentFee
      : null,
  );
  const [enableTest, setEnableTest] = useState(
    !!masterServiceAreaPlanPrice.masterServiceAreaPriceTest,
  );

  const updateDescription = event => {
    if (event.target.value.length <= CHARACTER_LIMIT) {
      setDescription(event.target.value);
      masterServiceAreaPlanPrice.description = event.target.value;
      setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
    }
  };

  const updateLabel = event => {
    if (event.target.value.length <= CHARACTER_LIMIT) {
      setLabel(event.target.value);
      masterServiceAreaPlanPrice.label = event.target.value;
      setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
    }
  };

  const updateInstallmentFee = event => {
    setInstallmentFee(event.target.value);
    masterServiceAreaPlanPrice.installmentFee = event.target.value;
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
  };

  const updateSetupFee = event => {
    setSetupFee(event.target.value);
    masterServiceAreaPlanPrice.setupFee = event.target.value;
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
  };

  const updateControlInstallmentFee = event => {
    setControlInstallmentFee(event.target.value);
    masterServiceAreaPlanPrice.masterServiceAreaPriceTest.controlInstallmentFee =
      event.target.value;
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
  };

  const updateControlSetupFee = event => {
    setControlSetupFee(event.target.value);
    masterServiceAreaPlanPrice.masterServiceAreaPriceTest.controlSetupFee =
      event.target.value;
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
  };

  const updatePlanId = async event => {
    setPlanId(event.target.value ? event.target.value : '');
    masterServiceAreaPlanPrice.plan.id = event.target.value;
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
  };

  const updateEnableTest = () => {
    const result = !enableTest;

    if (result) {
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest = {
        id:
          masterServiceAreaPlanPrice.masterServiceAreaPriceTest &&
          masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id
            ? masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id
            : null,
        controlSetupFee: masterServiceAreaPlanPrice.plan.fee,
        controlInstallmentFee: masterServiceAreaPlanPrice.plan.fee,
      };
      setControlSetupFee(masterServiceAreaPlanPrice.plan.fee);
      setControlInstallmentFee(masterServiceAreaPlanPrice.plan.fee);
    } else if (
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest &&
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id
    ) {
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest.disabled =
        DateUtil.getDateTimeString(moment());
    } else if (
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest &&
      !masterServiceAreaPlanPrice.masterServiceAreaPriceTest.id
    ) {
      masterServiceAreaPlanPrice.masterServiceAreaPriceTest = null;
    }
    setMasterServiceAreaPlanPrice(masterServiceAreaPlanPrice);
    setEnableTest(result);
  };

  const validateNumberKeypress = e => {
    const re = /[0-9]+/g;

    if (!re.test(e.key) && e.key !== '.') {
      e.preventDefault();
    }
  };

  const getCharactersRemaining = text => {
    const number = text ? CHARACTER_LIMIT - text.length : CHARACTER_LIMIT;

    return `${number} characters remaining`;
  };

  const isPriceTestEnabled = () =>
    masterServiceAreaPlanPrice.masterServiceArea.parentPriceTestEnabled ||
    masterServiceAreaPlanPrice.masterServiceArea.sitterPriceTestEnabled;

  useEffect(() => {
    const getPlans = () => {
      const config = {
        params: {
          userId: adminUser.id,
        },
      };

      PlanService.getByFilter(config).then(result => {
        setPlans(result.data.data);
      });
    };

    getPlans();
  }, []);

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          required
          id='plan'
          name='plan'
          label='Plan'
          select
          fullWidth
          value={planId}
          onChange={updatePlanId}
        >
          {plans.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.id} - {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          id='label'
          multiline
          minRows='2'
          label='Label'
          fullWidth
          required
          value={label}
          onChange={updateLabel}
        />
        <br />
        <Typography>{getCharactersRemaining(label)}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          id='description'
          multiline
          minRows='2'
          label='Description'
          fullWidth
          value={description}
          onChange={updateDescription}
        />
        <br />
        <Typography>{getCharactersRemaining(description)}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          required
          id='setupFee'
          label='Setup Fee'
          fullWidth
          value={setupFee}
          onChange={updateSetupFee}
          onKeyPress={validateNumberKeypress}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <TextField
          required
          id='installmentFee'
          label='Installment Fee'
          fullWidth
          value={installmentFee}
          onChange={updateInstallmentFee}
          onKeyPress={validateNumberKeypress}
        />
      </Grid>
      {masterServiceAreaPlanPrice.id && isPriceTestEnabled() && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <FormLabel component='legend'>Enable A/B test?</FormLabel>
          <Checkbox
            checked={enableTest}
            onChange={updateEnableTest}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </Grid>
      )}
      {masterServiceAreaPlanPrice.id && isPriceTestEnabled() && enableTest && (
        <>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              required
              id='controlSetupFee'
              label='Control Setup Fee'
              fullWidth
              value={controlSetupFee}
              onChange={updateControlSetupFee}
              onKeyPress={validateNumberKeypress}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              required
              id='controlInstallmentFee'
              label='Control Installment Fee'
              fullWidth
              value={controlInstallmentFee}
              onChange={updateControlInstallmentFee}
              onKeyPress={validateNumberKeypress}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default MasterServiceAreaPlanPriceForm;
