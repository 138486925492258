import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';

import { AdminTag } from 'us-web-services';

interface Props {
  tag: AdminTag;
  setTag(tag: AdminTag): void;
}

export default function TagEditForm(props: Props) {
  const { tag, setTag } = props;

  const [code, setCode] = useState(tag.code);
  const [label, setLabel] = useState(tag.label);

  const updateCode = event => {
    setCode(event.target.value);
    tag.code = event.target.value;
    setTag(tag);
  };

  const updateLabel = event => {
    setLabel(event.target.value);
    tag.label = event.target.value;
    setTag(tag);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='code'
          name='code'
          label='Code'
          fullWidth
          value={code}
          onChange={updateCode}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='label'
          name='label'
          label='Label'
          fullWidth
          value={label}
          onChange={updateLabel}
        />
      </Grid>
    </Grid>
  );
}
