import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { Feedback } from '../types/Feedback';

const URL = '/v2/nps-answers';

class FeedbackService {
  static post(
    data: Feedback,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Feedback>> {
    return axios.post(URL, data, config);
  }
}
export { FeedbackService };
