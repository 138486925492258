import { MailchimpMergeTag } from './MailchimpMergeTag';

export enum MailchimpTag {
  VIEWED_CART = 'viewed_cart',
}

export interface MailchimpMetadata {
  userId?: number;
  tags?: MailchimpTag;
  mergeTags?: MailchimpMergeTag[];
}
