import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types';

const BASE_URL = '/v2/admin/scammer-notification';

interface ScammingUser {
  id: number;
}

interface NotificationCount {
  count: number;
}

class AdminScammerNotificationService {
  static count(
    data: ScammingUser,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<NotificationCount>> {
    return axios.post(`${BASE_URL}/count`, data, config);
  }

  static send(
    data: ScammingUser,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<NotificationCount>> {
    return axios.post(`${BASE_URL}/send`, data, config);
  }
}

export { AdminScammerNotificationService };
