import axios, { AxiosRequestConfig } from 'axios';

const USER_VERIFICATION_LOG_URL = '/v2/user-verification-logs';

class UserVerificationLogService {
  static findByFilter(params: any, config?: AxiosRequestConfig) {
    return axios.get(`${USER_VERIFICATION_LOG_URL}${params}`, config);
  }
}

export { UserVerificationLogService };
