import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import { AdminTag, UserAdminTag, UserAdminTagService } from 'us-web-services';

interface Props {
  userId: number;
  getUserTags: any;
  tag: AdminTag;
  userAdminTags: UserAdminTag[];
}

function UserAdminTagDisplay(props: Props) {
  const { userId, getUserTags, tag, userAdminTags } = props;

  const hasTag = (): boolean => userAdminTags.some(userTag => userTag.tag.id === tag.id);
  const getUserTag = (): UserAdminTag => userAdminTags.filter(userTag => userTag.tag.id === tag.id).pop();

  const removeTag = async () => {
    const userTag = getUserTag();

    if (userTag.id) {
      await UserAdminTagService.delete(userTag.id);
      await getUserTags();
    }
  };

  const addTag = async () => {
    const userTag = {
      userId,
      tag: {
        id: tag.id,
      },
    };

    await UserAdminTagService.create(userTag);
    await getUserTags();
  };

  const toggleTag = () => (hasTag() ? removeTag() : addTag());

  const getCreatedDate = () => {
    const userTag = getUserTag();

    return userTag
      ? moment(userTag.created).format('LLL')
      : '';
  };

  const addedBy = getUserTag()?.createdBy?.firstName || '';

  return (
    <Grid item xs={6}>
      <FormControlLabel
        control={<Checkbox checked={hasTag()} onChange={toggleTag} />}
        label={tag.code}
      />
      <Box fontStyle='italic' fontSize={12}>
        {hasTag() ? `added ${getCreatedDate()}${addedBy ? ` by ${addedBy}` : ''}` : ''}
      </Box>
    </Grid>
  );
}

export default UserAdminTagDisplay;
