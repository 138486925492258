import React, { useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Partner, PromoService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import DisplayService from '../../util/DisplayService';
import PartnerForm from './PartnerForm';

function PartnerCreate(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();
  const [partner, setPartner] = useState<Partner>({
    id: null,
    active: false,
    urlAlias: '',
    title: '',
    metaDescription: '',
    headline: '',
    introText: '',
    partnerText: '',
    logoUrl: '',
    offerDescription: '',
    code: '',
    announcement: '',
    legalText: '',
  });

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error creating the partner.',
    );

    toast.error(displayedError.message);
  };

  const createPartner = async () => {
    const data = {
      ...partner,
    };

    await PromoService.create(data).catch(showError);
    const message = 'Partner created successfully';

    toast.success(message);
    history.push('/partners');
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Add Partner
      </Typography>
      <Grid container spacing={3}>
        {partner && <PartnerForm partner={partner} setPartner={setPartner} mode='create' />}
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={createPartner}
          className={classes.button}
        >
          Create Partner
        </Button>
      </div>{' '}
    </Paper>
  );
}

export default withRouter(PartnerCreate);
