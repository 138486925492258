import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { AdminTag, UserAdminTag, UserState } from 'us-web-services';
import UserAdminTagDisplay from './UserAdminTagDisplay';
import CardStyles from '../../styles/CardStyles';

interface Props {
  adminTags: AdminTag[];
  getUserAdminTags: () => any;
  lookupUser: UserState;
  userAdminTags: UserAdminTag[];
}

function UserAdminTags(props: Props) {
  const { lookupUser, adminTags, userAdminTags, getUserAdminTags } = props;
  const { classes } = CardStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component='h5' variant='h5'>
            Admin Tags
          </Typography>
          <Grid container>
            {adminTags.length ? adminTags.map(tag => (
              <UserAdminTagDisplay
                key={tag.id}
                userId={lookupUser.user.id}
                tag={tag}
                getUserTags={getUserAdminTags}
                userAdminTags={userAdminTags}
              />
            )) : ''}
          </Grid>
        </CardContent>
      </div>
    </Card>
  );
}

export default UserAdminTags;
