import React, { useState } from 'react';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';

import { LocalGroupService, Location, LocationService, LocationRegionsService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function LocalGroupCreate(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [selectedCityState, setSelectedCityState] = useState(null);
  const [location, setLocation] = useState<Location>({});
  const [locationInput, setLocationInput] = useState(null);
  const [region, setRegion] = useState(null);
  const [regionInput, setRegionInput] = useState(null);

  const getLocations = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (locationInput) {
      contentConfig.params.name = locationInput;
    }

    const locationsResponse = (await LocationService.getByFilter(contentConfig)).data;
    const locationsData = locationsResponse.data;
    const locationOptions = [];

    locationsData.map(locationData =>
      locationOptions.push({
        id: locationData.id,
        name: locationData.name,
        value: locationData.id,
        label: `${locationData.name}, ${locationData.state.code}`,
        state: locationData.state,
        region: locationData.region,
      }),
    );

    return locationOptions;
  };

  const getRegions = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (regionInput) {
      contentConfig.params.name = regionInput;
    }

    const regionResponse = await LocationRegionsService.getByFilter(
      contentConfig,
    );

    const regionData = regionResponse.data.data;

    const regionOptions = [];

    regionData.map(data =>
      regionOptions.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return regionOptions;
  };

  const updateName = event => {
    setName(event.target.value);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
  };

  const updateLocationInput = newValue => {
    setLocationInput(newValue);
  };

  const updateRegionInput = newValue => {
    setRegionInput(newValue);
  };

  const navigateToList = () => {
    history.push('/local-groups');
  };

  const saveSuccess = localGroup => {
    toast.success(`${localGroup.name} created successfully`);
    navigateToList();
  };

  const findByName = async localGroupName => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        name: localGroupName,
      },
    };

    const response = await LocalGroupService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const findByAlias = async localGroupAlias => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        alias: localGroupAlias,
      },
    };

    const response = await LocalGroupService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const showError = errText => {
    toast.error(errText);
  };

  const isInputValid = async () => {
    let isValid = true;

    if (!name) {
      showError('Local Group name is required');
      isValid = false;
    }

    if (!selectedCityState) {
      showError('Please pick a city and state');
      isValid = false;
    }

    if (!region) {
      showError('Please pick a region');
      isValid = false;
    }

    if (await findByName(name)) {
      showError('Name already exists');
      isValid = false;
    }

    if (await findByAlias(alias)) {
      showError('Alias already exists');
      isValid = false;
    }

    return isValid;
  };

  const createLocalGroup = async () => {
    if (await isInputValid()) {
      const localGroup = {
        name,
        alias,
        location: {
          id: location.id,
          state: location.state,
          region: location.region,
        },
        city: {
          id: selectedCityState.id,
          name: selectedCityState.name,
          state: selectedCityState.state,
          region: selectedCityState.location,
        },
        region,
      };

      try {
        await LocalGroupService.create(localGroup);
        saveSuccess(localGroup);
      } catch (e) {
        toast.error(e.response.data.errors[0].message);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Add Local Group
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id='name'
            name='name'
            label='Name'
            required
            fullWidth
            value={name}
            onChange={updateName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='alias'
            name='alias'
            label='Alias'
            fullWidth
            value={alias}
            onChange={updateAlias}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            City and State
          </Typography>
          <AsyncSelect
            id='citystate'
            required
            loadOptions={getLocations}
            defaultOptions
            onInputChange={updateLocationInput}
            onChange={setSelectedCityState}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            Auto Assign Location
          </Typography>
          <AsyncSelect
            id='location'
            required
            loadOptions={getLocations}
            defaultOptions
            onInputChange={updateLocationInput}
            onChange={setLocation}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            Region
          </Typography>
          <AsyncSelect
            id='region'
            required
            loadOptions={getRegions}
            defaultOptions
            onInputChange={updateRegionInput}
            onChange={setRegion}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={createLocalGroup}
          className={classes.button}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(LocalGroupCreate);
