import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types';
import { CompanyBenefitVoucher } from '../../types/company/CompanyBenefitVoucher';

const URL = '/v2/company-benefit-vouchers';

class CompanyBenefitVoucherService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefitVoucher>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyBenefitVoucher>> {
    return axios.get(URL, config);
  }

  static patch(
    id: any,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefitVoucher>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyBenefitVoucher>> {
    return axios.post(`${URL}`, data, config);
  }

  static delete(id: any, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CompanyBenefitVoucherService };
