import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { Company, CompanyService } from 'us-web-services';
import PageStyles from '../../../styles/PageStyles';
import DisplayService from '../../../util/DisplayService';

interface Props extends RouteComponentProps {
  company: Company;
}

function CompanyTerminate(props: Props) {
  const { company } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [terminating, setTerminating] = useState(false);
  const [terminated, setTerminated] = useState(false);

  const { classes } = PageStyles();

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const reset = () => {
    setTerminating(false);
    closeConfirm();
  };

  const showError = useCallback(error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error terminating the company.',
    );

    displayedError.autoDismiss = false;
    toast.error(displayedError.message);
  }, []);

  const terminate = async () => {
    setTerminating(true);
    const companyData = {
      id: company.id,
      partners: [],
    };

    await CompanyService.delete(companyData)
      .then(() => setTerminated(true))
      .catch(showError)
      .finally(reset);
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <p>{company.name} will be terminated.</p>
          <ul>
            <li>Each active employee will be deactivated.</li>
            <li>Their benefits will also be deactivated.</li>
            <li>A note with this action will be added to each employee.</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={terminate}
            disabled={terminating}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Company Termination
      </Typography>
      <p>
        This will deactivate every {company.name} employee and their benefits.
      </p>
      {terminated ? (
        <div style={{ color: 'green' }}>
          <FontAwesomeIcon
            style={{
              fontSize: 16,
              marginRight: '0.75rem',
            }}
            icon={faCheckCircle}
          />
          <span>{company.name} was successfully terminated.</span>
        </div>
      ) : (
        <Button
          onClick={openConfirm}
          style={{ backgroundColor: '#ee4444' }}
          variant='contained'
        >
          {terminating ? 'Terminating...' : 'Terminate'}
        </Button>
      )}
    </Paper>
  );
}

export default withRouter(CompanyTerminate);
