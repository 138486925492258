import React, { useEffect, useState } from 'react';
import {
  AppBar,
  ClassNameMap,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { RouteComponentProps } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import queryString from 'query-string';
import toast from 'react-hot-toast';

import { Booking, BookingService } from 'us-web-services';
import useAuthentication from '../../store/useAuthentication';
import BookingCard from './BookingCard';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 0 8px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function BookingLookup(props: RouteComponentProps & { classes: ClassNameMap }) {
  const authenticationState = useAuthentication()[0];
  const { classes, location } = props;
  const searchParams = queryString.parse(location.search);
  const [booking, setBooking] = useState<Booking>({});
  const [bookingId, setBookingId] = useState<number>();

  const getSearchId = () => searchParams.id ? Number.parseInt(searchParams.id.toString(), 10) : undefined;

  useEffect(() => {
    const currentSearchId = getSearchId();

    setBookingId(currentSearchId);

    if (currentSearchId && !bookingId) {
      const getBooking = async () => {
        try {
          const bookingParams = {
            params: {
              fields: 'adminCancellationReason,adminCancellationNote',
              userId: authenticationState.authentication.user.id,
            },
          };

          const bookingResponse = await BookingService.get(
            currentSearchId,
            bookingParams,
          );
          const bookingData = bookingResponse.data.data;

          setBooking(bookingData);
        } catch (e) {
          toast.error('Booking not found');
        }
      };

      getBooking();
    }
  }, [authenticationState.authentication, searchParams.id]);

  const getBookingOnEnter = async id => {
    try {
      const bookingParams = {
        params: {
          userId: authenticationState.authentication.user.id,
        },
      };

      const bookingResponse = await BookingService.get(id, bookingParams);
      const bookingData = bookingResponse.data.data;

      setBooking(bookingData);
      searchParams.id = id;
    } catch (e) {
      toast.error('Booking not found');
    }
  };

  const updateBookingId = event => {
    setBookingId(Number.parseInt(event.target.value, 10));
  };

  const searchKeyPress = event => {
    if (event.key === 'Enter') {
      getBookingOnEnter(bookingId);
    }
  };

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position='static'
        color='default'
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <SearchIcon className={classes.block} color='inherit' />
            <Grid item xs>
              <TextField
                fullWidth
                required
                id='bookingIdSearch'
                label='Search by Booking ID'
                name='bookingIdSearch'
                onChange={updateBookingId}
                onKeyPress={searchKeyPress}
                type='number'
                value={bookingId}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {booking.id ? (
          <BookingCard booking={booking} />
        ) : (
          <Typography color='textPrimary' align='center'>
            No bookings found
          </Typography>
        )}
      </div>
    </Paper>
  );
}

export default withStyles(BookingLookup, styles);
