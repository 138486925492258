import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { ClassNameMap, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const styles = (theme, _params, classes) => ({
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: theme.palette.common.white,
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: theme.palette.secondary.mid,
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    [`&.${classes.textDense}`]: {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
});

interface Props {
  active: boolean;
  classes?: ClassNameMap;
  icon: JSX.Element;
  text: string;
  url: string;
}

function AppNavigationItem(props: Props) {
  const { classes, url, icon, active, text } = props;

  return (
    <Link key={text} to={url}>
      <ListItem
        button
        dense
        className={classNames(
          classes.item,
          classes.itemActionable,
          active && classes.itemActiveItem,
        )}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
          }}
        >
          {text}
        </ListItemText>
      </ListItem>
    </Link>
  );
}

export default withStyles(AppNavigationItem, styles);
