import axios, { AxiosRequestConfig } from 'axios';
import { CompanyEmployee } from '../../types/company/CompanyEmployee';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/company-employees';

class CompanyEmployeeService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyEmployee>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static findByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CompanyEmployee>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: CompanyEmployee,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyEmployee>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static update(
    id: number,
    data: CompanyEmployee,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyEmployee>> {
    return axios.put(`${URL}/${id}`, data, config);
  }

  static create(
    data: CompanyEmployee,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CompanyEmployee>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { CompanyEmployeeService };
