export interface SitterCheckrPayWorker {
  id?: number;
  workerId?: string;
  sitterId?: number;
  backgroundId?: string;
  statusId?: number;
  hasBalance?: boolean;
}

export enum CheckrPayWorkerStatusIds {
  PENDING = 1,
  MANUAL_REVIEW = 2,
  ACTIVE = 3,
  DENIED = 4,
  PENDING_CLOSURE = 5,
  CLOSED = 6,
  ONBOARDED = 7,
}

export enum CheckrPayWorkerStatuses {
  PENDING = 'Pending',
  MANUAL_REVIEW = 'Manual Review',
  ACTIVE = 'Active',
  DENIED = 'Denied',
  PENDING_CLOSURE = 'Pending Closure',
  CLOSED = 'Closed',
}
