import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CityService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function CityLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'cities',
        header: 'Cities',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'City',
            accessorKey: 'name',
          },
          {
            header: 'State',
            accessorKey: 'state',
          },
        ],
      },
    ],
    [],
  );

  const editCity = rowData => {
    history.push(`/seo/cities/${rowData.id}`);
  };

  const params = (globalFilter: string) => {
    const paramArray = [];

    if (globalFilter) {
      paramArray.push({
        key: 'q',
        value: `name.startsWith('${globalFilter}')`,
      });
    }

    return paramArray;
  };

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={CityService.getByFilter}
        params={params}
        editAction={editCity}
      />
    </Paper>
  );
}

export default withRouter(CityLookup);
