import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MasterServiceAreaService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function MasterServiceAreaLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'msa',
        header: 'Master Service Areas',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Tier',
            // protect against possible nulls
            accessorFn: row => row?.masterServiceAreaTier?.name,
          },
        ],
      },
    ],
    [],
  );

  const addServiceArea = () => {
    history.push('/master-service-area-create');
  };

  const editServiceArea = rowData => {
    history.push(`/master-service-areas/${rowData.id}`);
  };

  const params = (globalFilter: string) => {
    if (Number(globalFilter)) {
      return [
        {
          key: 'zipCode',
          value: globalFilter,
        },
      ];
    }

    return [
      {
        key: 'name',
        value: globalFilter,
      },
    ];
  };

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={MasterServiceAreaService.getByFilter}
        params={params}
        editAction={editServiceArea}
        addAction={addServiceArea}
      />
    </Paper>
  );
}

export default withRouter(MasterServiceAreaLookup);
