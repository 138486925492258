import axios, { AxiosRequestConfig } from 'axios';
import { OutschoolBalance,
  OutschoolCredit,
  PartnerCredit } from '../types/Outschool';
import { DataResponse } from '../types/api';

const URL = '/v2/outschool';

class OutschoolService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<OutschoolBalance>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static create(
    data: OutschoolCredit,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<PartnerCredit>> {
    return axios.post(URL, data, config);
  }
}

export { OutschoolService };
