import axios, { AxiosRequestConfig } from 'axios';
import { BookingGroup } from '../types/BookingGroup';
import { DataResponse } from '../types/api';

const BOOKING_GROUPS_URL = '/v2/booking-groups';

class BookingGroupService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BookingGroup>> {
    return axios.get(`${BOOKING_GROUPS_URL}/${id}`, config);
  }

  static patch(
    id: number,
    data: BookingGroup,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BookingGroup>> {
    return axios.patch(`${BOOKING_GROUPS_URL}/${id}`, data, config);
  }
}

export { BookingGroupService };
