import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { Notification } from '../types/Notification';

const URL = '/v2/notifications';

class NotificationService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Notification>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Notification>> {
    return axios.get(URL, config);
  }

  static patch(
    data: Notification,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Notification>> {
    return axios.patch(URL, data, config);
  }
}
export { NotificationService };
