import React, { useMemo } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LocalGroupService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import { Table } from '../common/Table';

function LocalGroupLookup(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'localGroups',
        header: 'Local Groups',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Name',
            accessorKey: 'name',
          },
          {
            header: 'Alias',
            accessorKey: 'alias',
          },
          {
            header: 'City',
            accessorKey: 'city.name',
          },
          {
            header: 'State',
            accessorKey: 'city.state.code',
          },
          {
            header: 'Region',
            accessorKey: 'region.name',
          },
        ],
      },
    ],
    [],
  );

  const editLocalGroup = rowData => {
    history.push(`/local-groups/${rowData.id}`);
  };

  const addLocalGroup = () => {
    history.push('/local-group-create');
  };

  const mergeLocalGroups = () => {
    history.push('/local-groups-merge');
  };

  const params = (globalFilter: string) => [
    {
      key: 'name',
      value: globalFilter,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={LocalGroupService.getByFilter}
        params={params}
        editAction={editLocalGroup}
        addAction={addLocalGroup}
      />
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeLocalGroups}
          className={classes.button}
        >
          Merge Local Groups
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(LocalGroupLookup);
