import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { SpecialService } from '../types/SpecialService';

const URL = '/v2/special-services';

class SpecialServicesService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<SpecialService>> {
    return axios.get(URL, config);
  }
}

export { SpecialServicesService };
