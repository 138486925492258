import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AdminCheckrPayWorker } from '../types';

const URL = '/v2/admin/checkr-workers';

class AdminCheckrPayWorkerService {
  static create(
    data: AdminCheckrPayWorker,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.post(URL, data, config);
  }

  static refresh(
    sitterId: number,
    data: AdminCheckrPayWorker,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.post(`${URL}/refresh-payout/${sitterId}`, data, config);
  }
}

export { AdminCheckrPayWorkerService };
