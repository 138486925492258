export default {
  STATUSES: {
    COMPLETED: {
      code: 'pass',
      label: 'Completed',
    },
    INCOMPLETE: {
      code: 'incomplete',
      label: 'Incomplete',
    },
    FAILED: {
      code: 'fail',
      label: 'Failed',
    },
  },
};
