import axios, { AxiosRequestConfig } from 'axios';

const USER_ADMIN_TAG_URL = '/v2/user-admin-tags';

class UserAdminTagService {
  static getByFilter(params: any, config?: AxiosRequestConfig) {
    return axios.get(`${USER_ADMIN_TAG_URL}${params}`, config);
  }

  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(`${USER_ADMIN_TAG_URL}`, data, config);
  }

  static delete(id: any, config?: AxiosRequestConfig) {
    return axios.delete(`${USER_ADMIN_TAG_URL}/${id}`, config);
  }
}

export { UserAdminTagService };
