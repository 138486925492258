import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { College } from '../types/College';

const API_URL = '/v2/sitter-colleges';

class SitterCollegeService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<College>> {
    return axios.get(`${API_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<College>> {
    return axios.get(API_URL, config);
  }

  static patch(
    id: number,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<College>> {
    return axios.patch(`${API_URL}/${id}`, data, config);
  }

  static create(
    data: any,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<College>> {
    return axios.post(API_URL, data, config);
  }
}

export { SitterCollegeService };
