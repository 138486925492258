import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useState } from 'react';

interface Props {
  buttonText: string;
  columns: MRT_ColumnDef<any>[];
  onClose: () => void;
  onSubmit: (values: any) => void;
  open: boolean;
  title: string;
}
const CreateModal = ({
  buttonText,
  columns,
  onClose,
  onSubmit,
  open,
  title,
}: Props) => {
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';

      return acc;
    }, {} as any),
  );

  const handleSubmit = () => {
    onSubmit(values);
    onClose();
  };

  const renderColumn = column => {
    const isDropDown = column.muiTableBodyCellEditTextFieldProps?.select;
    const dropDownItems = column.muiTableBodyCellEditTextFieldProps?.children;

    return (
      <TextField
        key={String(column.accessorKey)}
        label={column.header}
        name={String(column.accessorKey)}
        select={isDropDown}
        defaultValue=''
        multiline={column?.muiTableBodyCellEditTextFieldProps?.multiline}
        onChange={e => {
          if (isDropDown) {
            // @ts-expect-error value.id is valid for dropdown element
            setValues({ ...column.accessorKey, id: e.target.value.id });
          } else {
            setValues({ ...values, [e.target.name]: e.target.value });
          }
        }}
      >
        {dropDownItems?.map(item => item)}
      </TextField>
    );
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign='center'>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={e => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              marginTop: 5,
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns[0].columns
              .filter(column => column.enableEditing !== false)
              .map(renderColumn)}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color='secondary' onClick={handleSubmit} variant='contained'>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateModal;
