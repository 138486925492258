import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, SitterStats } from '../types';

const URL = '/v2/sitters';

class SitterStatsService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterStats>> {
    return axios.get(`${URL}/${id}/stats`, config);
  }
}

export { SitterStatsService };
