import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';

import { CitySeoContent } from 'us-web-services';

interface Props {
  citySeoContent: CitySeoContent;
  setCitySeoContent: (citySeoContent: CitySeoContent) => void;
}

export default function CitySeoContentEditForm(props: Props) {
  const { citySeoContent, setCitySeoContent } = props;
  const [nannyPageTitle, setNannyPageTitle] = useState(
    citySeoContent.nannyPageTitle,
  );
  const [nannyPageText, setNannyPageText] = useState(
    citySeoContent.nannyPageText,
  );
  const [sitterPageTitle, setSitterPageTitle] = useState(
    citySeoContent.sitterPageTitle,
  );
  const [sitterPageText, setSitterPageText] = useState(
    citySeoContent.sitterPageText,
  );
  const [jobFeedPriority, setJobFeedPriority] = useState(
    citySeoContent.jobFeedPriority,
  );

  const updateNannyPageTitle = event => {
    setNannyPageTitle(event.target.value);
    citySeoContent.nannyPageTitle = event.target.value;
    setCitySeoContent(citySeoContent);
  };

  const updateNannyPageText = event => {
    setNannyPageText(event.target.value);
    citySeoContent.nannyPageText = event.target.value;
    setCitySeoContent(citySeoContent);
  };

  const updateSitterPageTitle = event => {
    setSitterPageTitle(event.target.value);
    citySeoContent.sitterPageTitle = event.target.value;
    setCitySeoContent(citySeoContent);
  };

  const updateSitterPageText = event => {
    setSitterPageText(event.target.value);
    citySeoContent.sitterPageText = event.target.value;
    setCitySeoContent(citySeoContent);
  };

  const updateJobFeedPriority = event => {
    setJobFeedPriority(event.target.value);
    citySeoContent.jobFeedPriority = event.target.value;
    setCitySeoContent(citySeoContent);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='nannyPageTitle'
          name='nannyPageTitle'
          label='Nanny Page Title'
          fullWidth
          value={nannyPageTitle}
          onChange={updateNannyPageTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='nannyPageText'
          multiline
          minRows='5'
          margin='normal'
          variant='outlined'
          label='Nanny Page Text'
          fullWidth
          value={nannyPageText}
          onChange={updateNannyPageText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='sitterPageTitle'
          name='sitterPageTitle'
          label='Sitter Page Title'
          fullWidth
          autoComplete='sitterPageTitle'
          value={sitterPageTitle}
          onChange={updateSitterPageTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='sitterPageText'
          label='Sitter Page Text'
          multiline
          minRows='5'
          margin='normal'
          variant='outlined'
          fullWidth
          value={sitterPageText}
          onChange={updateSitterPageText}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='jobFeedPriority'
          name='jobFeedPriority'
          label='Job Feed Priority'
          fullWidth
          autoComplete='jobFeedPriority'
          value={jobFeedPriority}
          type='number'
          onChange={updateJobFeedPriority}
        />
      </Grid>
    </Grid>
  );
}
