import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';

import { CompanyBenefit, CompanyBenefitService, CompanyBenefitVoucherService, Voucher } from 'us-web-services';
import { Table, TableInstance } from 'src/components/common/Table';
import PageStyles from '../../../styles/PageStyles';
import AddCompanyBenefitVouchers from './AddCompanyBenefitsVoucher';
import CompanyBenefitEditForm from './CompanyBenefitEditForm';
import DisplayService from '../../../util/DisplayService';

function CompanyBenefitEdit(props: RouteComponentProps<{ benefitId: string }>) {
  const { classes } = PageStyles();
  const { history, match } = props;
  const [voucher, setVoucher] = useState<Voucher>({});
  const [benefit, setBenefit] = useState<CompanyBenefit>({});

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const tableRef = React.useRef<TableInstance>();

  const cancel = () => {
    history.push(`/companies/${benefit.companyId}`);
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error with the benefit.',
    );

    toast.error(displayedError.message);
  };

  const COLUMNS = useMemo(
    () => [
      {
        id: 'vouchers',
        header: 'Vouchers',
        columns: [
          {
            header: 'Code',
            accessorFn: row => {
              const { code } = row.voucher || '';

              return code;
            },
          },
          {
            header: 'Value',
            accessorFn: row => {
              const { percentDiscount, value } = row.voucher || {};

              return <>{percentDiscount ? `${value}%` : `$${value}`}</>;
            },
          },
          {
            header: 'Description',
            // protect against possible nulls
            accessorFn: row => row?.voucher?.description,
          },
        ],
      },
    ],
    [],
  );

  const refreshVouchers = () => tableRef.current?.fetchData();

  const deleteRowVoucher = async rowVoucher => {
    try {
      await CompanyBenefitVoucherService.delete(rowVoucher.id);
    } catch (error) {
      showError(error);
    }

    refreshVouchers();
  };

  useEffect(() => {
    const benefitId = Number.parseInt(match.params.benefitId, 10);

    const getBenefit = async () => {
      if (benefitId > 0) {
        try {
          const benefitResponse = await CompanyBenefitService.get(benefitId);

          setBenefit(benefitResponse.data.data);
        } catch (error) {
          showError(error);
        }
      } else {
        showError('Benefit not found');
      }
    };

    getBenefit();
  }, [match.params.benefitId]);

  const saveVoucherCode = async () => {
    const benefitVoucher = {
      benefitId: benefit.id,
      voucher: {
        id: voucher.id,
      },
    };

    try {
      await CompanyBenefitVoucherService.create(benefitVoucher);
      toast.success('Voucher Added!');
    } catch (error) {
      showError(error);
    }

    refreshVouchers();
  };

  const verifyBenefit = () => {
    if (moment(benefit.start).isAfter(moment(benefit.end))) {
      toast.error('End Date should be after Start date');

      return false;
    }

    return true;
  };

  const updateBenefit = async () => {
    const verified = verifyBenefit();

    if (verified) {
      try {
        const benefitObject: CompanyBenefit = {
          id: benefit.id,
          description: benefit.description,
        };

        if (benefit.statusId !== 2) {
          benefitObject.start = benefit.start;
        }

        benefitObject.end = benefit.end;

        const benefitResponse = await CompanyBenefitService.patch(
          benefit.id,
          benefitObject,
        );

        setBenefit(benefitResponse.data.data);

        toast.success('Benefit successfully updated');
      } catch (error) {
        showError(error);
      }
    }
  };

  const closeConfirmDialog = () => {
    setShowConfirm(false);
  };

  const activateBenefit = async () => {
    setIsSubmitting(true);
    try {
      await CompanyBenefitService.patch(benefit.id, {
        id: benefit.id,
        statusId: 2,
      });
      toast.success('Benefit successfully activated');
    } catch (error) {
      showError(error);
    }
    closeConfirmDialog();
    setIsSubmitting(false);
  };

  const confirmActivate = () => {
    setShowConfirm(true);
  };

  return (
    <>
      <Dialog open={showConfirm} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Benefit Activation</DialogTitle>
        <DialogContent>
          Are you sure you want to activate this benefit? This will apply
          benefits to all employees associated with this company.
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={activateBenefit}
            disabled={isSubmitting}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={classes.paper}>
        {benefit.id && (
          <CompanyBenefitEditForm benefit={benefit} setBenefit={setBenefit} />
        )}
        <div className={classes.buttons}>
          <Link to={`/companies/${benefit.companyId}`} style={{ marginRight: 30, marginTop: 30 }}>
            &laquo; Back to Company
          </Link>
          <Button
            variant='contained'
            color='secondary'
            onClick={cancel}
            className={classes.button}
          >
            Cancel
          </Button>
          {benefit.statusId === 1 && (
            <Button
              variant='contained'
              onClick={confirmActivate}
              color='primary'
              className={classes.button}
            >
              Activate
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={updateBenefit}
            className={classes.button}
          >
            Save
          </Button>
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant='h6' gutterBottom>
          Add Voucher Code
        </Typography>
        <AddCompanyBenefitVouchers setVoucher={setVoucher} />
        <br />
        <Button
          id='close'
          variant='contained'
          color='secondary'
          onClick={cancel}
        >
          Cancel
        </Button>{' '}
        <Button
          id='save'
          variant='contained'
          color='primary'
          onClick={saveVoucherCode}
        >
          Add
        </Button>{' '}
      </Paper>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={CompanyBenefitVoucherService.getByFilter}
        params={() => [{
          key: 'benefitId',
          value: match.params.benefitId.toString(),
        }]}
        alwaysApplyParams
        hideSearchBar
        deleteAction={deleteRowVoucher}
      />
    </>
  );
}

export default withRouter(CompanyBenefitEdit);
