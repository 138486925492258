import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { Profile } from 'us-web-services';

interface Props {
  profile: Profile;
  setProfile(profile: Profile): void;
}

export default function ProfileEditForm(props: Props) {
  const { profile, setProfile } = props;

  const [street, setStreet] = useState(profile.address.street);
  const [street2, setStreet2] = useState(profile.address.street2 || '');
  const [city, setCity] = useState(profile.address.city);
  const [state, setState] = useState(profile.address.state);
  const [zip, setZip] = useState(profile.address.zip);

  const [phone, setPhone] = useState(profile.phone);

  const updateStreet = event => {
    setStreet(event.target.value);
    profile.address.street = event.target.value;
    setProfile(profile);
  };

  const updateStreet2 = event => {
    setStreet2(event.target.value);
    profile.address.street2 = event.target.value;
    setProfile(profile);
  };

  const updateCity = event => {
    setCity(event.target.value);
    profile.address.city = event.target.value;
    setProfile(profile);
  };

  const updateState = event => {
    setState(event.target.value);
    profile.address.state = event.target.value;
    setProfile(profile);
  };

  const updateZip = event => {
    setZip(event.target.value);
    profile.address.zip = event.target.value;
    setProfile(profile);
  };

  const updatePhone = event => {
    setPhone(event.target.value);
    profile.phone = event.target.value;
    setProfile(profile);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='street'
            name='street'
            label='Street'
            fullWidth
            autoComplete='street'
            value={street}
            onChange={updateStreet}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='street2'
            name='street2'
            label='Street 2'
            fullWidth
            autoComplete='street2'
            value={street2}
            onChange={updateStreet2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='city'
            name='city'
            label='City'
            fullWidth
            autoComplete='city'
            value={city}
            onChange={updateCity}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='state'
            name='state'
            label='State'
            autoComplete='state'
            value={state}
            onChange={updateState}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='zip'
            name='zip'
            label='Zip'
            fullWidth
            autoComplete='zip'
            value={zip}
            onChange={updateZip}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='phone'
            name='phone'
            label='Phone'
            fullWidth
            autoComplete='phone'
            value={phone}
            onChange={updatePhone}
          />
        </Grid>
      </Grid>
    </>
  );
}
