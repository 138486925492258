import axios, { AxiosRequestConfig } from 'axios';
import { GoogleConnection } from '../../types/social/GoogleConnection';
import { PagedResponse } from '../../types/api';

const URL = '/v2/google-connection';

class GoogleConnectionService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<GoogleConnection>> {
    return axios.get(URL, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { GoogleConnectionService };
