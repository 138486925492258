import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/zip-codes';

class ZipCodeService {
  static get(id: number, config?: AxiosRequestConfig) {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(URL, config);
  }

  static patch(id: number, data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(URL, data, config);
  }
}

export { ZipCodeService };
