import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Link, withRouter } from 'react-router-dom';

import { AdminNoteService, AdminScammerNotificationService, Job, JobService, UserService } from 'us-web-services';
import { Toast } from '../../constants';
import useAuthentication from '../../store/useAuthentication';
import DisplayService from '../../util/DisplayService';
import { Table, TableInstance } from '../common/Table';
import { PageStyles } from '..';

function JobModeration() {
  const ONE_HOUR = 60;
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const tableRef = React.useRef<TableInstance>();
  const [jobs, setJobs] = React.useState<Job[]>([]);
  const [approving, setApproving] = React.useState(true);
  const [dialogText, setDialogText] = React.useState('');
  const [blockedUserIds, setBlockedUserIds] = React.useState([]);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [isModerating, setIsModerating] = React.useState(false);

  const { classes } = PageStyles();

  const filterJobs = async (config) => {
    let response;

    try {
      response = await JobService.getByFilter(config);
    } catch (error) {
      response = {
        data: {
          data: [],
          meta: {
            size: 0,
          },
        },
      };
    }

    return response;
  };

  const params = (globalFilter: string) => {
    const filterParams = [
      {
        key: 'moderated',
        value: false,
      },
      {
        key: 'userId',
        value: adminUser.id,
      },
      {
        key: 'sort',
        value: 'userCreated',
      },
    ];

    if (globalFilter) {
      const parentId = Number.parseInt(globalFilter, 10);

      if (parentId) {
        filterParams.push({
          key: 'parentId',
          value: parentId,
        });
      }
    }

    return filterParams;
  };

  const refreshJobs = () => tableRef.current?.fetchData();

  const showJobError = (error, jobId: number) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      `Error updating job ${jobId}`,
    );

    toast.error(displayedError.message, { duration: Toast.DISPLAY_TIME_ERROR });
  };

  const showNoteError = (error, userId: number) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      `Error adding note to user ${userId}`,
    );

    toast.error(displayedError.message, { duration: Toast.DISPLAY_TIME_ERROR });
  };

  const showBlockError = (error, userId: number) => {
    let blockError;

    if (error.response?.data?.errors[0]?.code === 'user.deactivate') {
      blockError =
        <span>
          <Link
            rel='noreferrer'
            target='_BLANK'
            to={`/users/lookup?id=${userId}`}
          >
            User {userId}
          </Link> has active bookings and was not blocked.
        </span>;
    } else {
      blockError = DisplayService.getErrorResponse(
        error,
        `Could not block user ${userId}`,
      );
    }
    toast.error(blockError, { duration: Toast.DISPLAY_TIME_LONG });
  };

  const showNotifyError = (error, userId: number) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      `Could not notify caregivers about blocked user ${userId}`,
    );

    toast.error(displayedError.message, { duration: Toast.DISPLAY_TIME_ERROR });
  };

  const getScamNotificationCount = async (userId: number) => {
    try {
      const response = (await AdminScammerNotificationService.count({
        id: userId,
      })).data;

      return response.data.count;
    } catch (error) {
      return 0;
    }
  };

  const sendScamNotifications = async (userId: number) => {
    try {
      await AdminScammerNotificationService.send({
        id: userId,
      });
    } catch (error) {
      showNotifyError(error, userId);
    }
  };

  const updateJob = async targetJob => {
    try {
      await JobService.patch(targetJob.row.id, {
        id: targetJob.row.id,
        booking: {
          description: targetJob.values.Description,
        },
      });
    } catch (error) {
      showJobError(error, targetJob.row.id);
    } finally {
      refreshJobs();
    }
  };

  const closeConfirmDialog = () => {
    setShowConfirm(false);
  };

  const openApproveModal = (currentJob: Job) => {
    setApproving(true);
    setDialogText('Approve this job?');
    setJobs([currentJob]);
    setShowConfirm(true);
  };

  const openApproveModalBulk = selectedJobs => {
    setApproving(true);
    setDialogText(`Approve ${selectedJobs.length > 1 ? `these ${selectedJobs.length} jobs` : 'this job'}?`);
    setJobs(selectedJobs);
    setShowConfirm(true);
  };

  const openBlockModal = async (currentJob: Job) => {
    const newNotificationCount = await getScamNotificationCount(currentJob.booking.parent.id);

    let newText;

    if (newNotificationCount) {
      newText = `Close this job, block this user, and notify ${newNotificationCount} caregiver`
        + `${newNotificationCount !== 1 ? 's' : ''} that this user has been removed?`;
    } else {
      newText = 'Close this job and block this user?';
    }

    setApproving(false);
    setBlockedUserIds([currentJob.booking.parent.id]);
    setNotificationCount(newNotificationCount);
    setDialogText(newText);
    setJobs([currentJob]);
    setShowConfirm(true);
  };

  const openBlockModalBulk = async (selectedJobs: Job[]) => {
    const blockUserIds = [];

    let newNotificationCount = 0;

    await Promise.all(selectedJobs.map(async bulkJob => {
      const userId = bulkJob.booking.parent.id;

      if (!blockUserIds.includes(userId)) {
        blockUserIds.push(userId);
        const appendCount = await getScamNotificationCount(userId);

        newNotificationCount += appendCount;
      }
    }));

    let newText;

    if (newNotificationCount) {
      newText = `Close ${selectedJobs.length} job${selectedJobs.length !== 1 ? 's' : ''},
       block ${blockUserIds.length} user${blockUserIds.length !== 1 ? 's' : ''},
       and notify ${newNotificationCount} caregiver${newNotificationCount !== 1 ? 's' : ''}
       that ${blockUserIds.length === 1 ? 'this user has' : 'these users have'} been removed?`;
    } else {
      newText = `Close ${selectedJobs.length} job${selectedJobs.length !== 1 ? 's' : ''} and
       block ${blockUserIds.length} user${blockUserIds.length !== 1 ? 's' : ''}?`;
    }

    setApproving(false);
    setBlockedUserIds(blockUserIds);
    setNotificationCount(newNotificationCount);
    setDialogText(newText);
    setJobs(selectedJobs);
    setShowConfirm(true);
  };

  const addNote = async (userId, comment) => {
    const noteData = {
      user: {
        id: userId,
      },
      createdBy: {
        id: adminUser.id,
      },
      comment,
    };

    try {
      await AdminNoteService.create(noteData);
    } catch (error) {
      showNoteError(error, userId);
    }
  };

  const moderateJob = async (targetJob: Job): Promise<void> => {
    const moderatedJob: Job = {
      id: targetJob?.id,
      moderated: true,
    };

    if (!approving) {
      moderatedJob.status = 'closed_invalid';
    }

    try {
      await JobService.patch(moderatedJob.id, moderatedJob);
    } catch (error) {
      showJobError(error, moderatedJob.id);
    }
  };

  const moderateJobs = async () => {
    setIsModerating(true);

    let blockFails = 0;

    try {
      await Promise.all(jobs.map(async bulkJob => {
        await moderateJob(bulkJob);
      }));

      if (!approving) {
        await Promise.all(blockedUserIds.map(async userId => {
          try {
            await UserService.patch(userId, {
              id: userId,
              active: false,
            });
            await addNote(userId, 'Scammer - blocked via Job Board Mod, added to TOS doc');
            await sendScamNotifications(userId);
          } catch (error) {
            blockFails += 1;
            showBlockError(error, userId);
          }
        }));
      }

      let successMessage =
        `${jobs.length === 1 ? 'Job' : `${jobs.length} jobs`} ${approving ? 'approved' : 'closed'}`;

      if (!approving) {
        if (blockFails > 0) {
          const blockedUsers = blockedUserIds.length - blockFails;

          let blockFailNote = '';

          if (blockedUsers > 0) {
            blockFailNote += `${blockedUsers} user${blockedUsers !== 1 ? 's' : ''} blocked and `
              + 'affected caregivers notified. ';
          }
          blockFailNote += `${blockFails} user${blockFails !== 1 ? 's need' : ' needs'} active bookings handled.`;
          toast.error(blockFailNote, { duration: Toast.DISPLAY_TIME_ERROR });
        } else if (notificationCount > 0) {
          successMessage += `, ${blockedUserIds.length === 1 ? 'user' : `${blockedUserIds.length} users`} blocked`
            + `, and ${notificationCount} caregiver${notificationCount !== 1 ? 's' : ''} notified.`;
        } else {
          successMessage += ` and ${blockedUserIds.length === 1 ? 'user' : `${blockedUserIds.length} users`} blocked.`;
        }
      }

      toast.success(successMessage, { duration: Toast.DISPLAY_TIME_SUCCESS });
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      toast.error('Unknown error occurred while moderating jobs.', { duration: Toast.DISPLAY_TIME_ERROR });
    } finally {
      setJobs([]);
      setBlockedUserIds([]);
      setNotificationCount(0);
      setIsModerating(false);
      setShowConfirm(false);
      refreshJobs();
    }
  };

  const withinFirstHour = (currentJob: Job) => currentJob.createdWithin <= ONE_HOUR;

  const renderModerationButtons = (rowData: any) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Button
        size='small'
        disabled={isModerating}
        variant='contained'
        color='success'
        onClick={() => openApproveModal(rowData?.row?.original)}
      >
        Approve
      </Button>
      {(withinFirstHour(rowData) || rowData.scammerKeywordDetected) && (
        <Button
          size='small'
          disabled={isModerating}
          variant='contained'
          color='error'
          onClick={() => openBlockModal(rowData?.row?.original)}
        >
          Block
        </Button>
      )}
    </div>
  );

  const renderParentLink = ({ cell }) => (
    <a
      href={`/users/lookup?id=${cell?.row?.original?.booking.parent.id}`}
      target='_blank'
      rel='noreferrer'
    >
      {cell?.row?.original?.booking.parent.firstName}{' '}
      {cell?.row?.original?.booking.parent.lastName}
    </a>
  );

  const renderLocation = ({ cell }) => (
    <>
      {cell?.row?.original?.booking.address.city},{' '}
      {cell?.row?.original?.booking.address.state}
    </>
  );

  const renderRate = ({ cell }) => {
    let rowRate = cell?.row?.original?.booking.rate;

    if (!rowRate) {
      rowRate = 'negotiable';
    } else {
      rowRate = cell?.row?.original?.booking.paymentFrequency.id === 1
        ? `$${rowRate}/hr`
        : `$${rowRate} daily`;
    }

    return (
      <div style={{ display: 'inline-flex' }}>
        {rowRate}
      </div>
    );
  };

  const renderWithinFirstHour = ({ cell }) => (
    <>{withinFirstHour(cell?.row?.original) ? 'Yes' : 'No'}</>
  );

  const getSuppressedForKeyword = ({ cell }) => (
    <>{cell?.row?.original?.scammerKeywordDetected ? 'Yes' : 'No'}</>
  );

  const COLUMNS = useMemo(
    () => [
      {
        id: 'jobs',
        header: 'Job Moderation',
        columns: [
          {
            header: 'Moderate',
            Cell: renderModerationButtons,
            enableEditing: false,
            size: 80,
          },
          {
            header: 'Parent',
            Cell: renderParentLink,
            enableEditing: false,
            size: 30,
            maxSize: 30,
          },
          {
            header: 'User ID',
            accessorFn: row => row?.booking?.parent?.id,
            enableEditing: false,
            size: 10,
          },
          {
            header: 'Location',
            Cell: renderLocation,
            enableEditing: false,
            size: 20,
          },
          {
            header: 'Status',
            accessorKey: 'status',
            enableEditing: false,
            size: 60,
          },
          {
            header: 'Rate',
            Cell: renderRate,
            enableEditing: false,
            size: 10,
          },
          {
            header: 'Description',
            accessorFn: row => row?.booking?.description,
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            },
            size: 300,
          },
          {
            header: '1st Hour',
            Cell: renderWithinFirstHour,
            enableEditing: false,
            size: 10,
          },
          {
            header: 'Suppressed for keyword',
            Cell: getSuppressedForKeyword,
            enableEditing: false,
            size: 10,
          },
        ],
      },
    ],
    [],
  );

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Job Action</DialogTitle>
        <DialogContent>{dialogText}</DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color='secondary'>
            Cancel
          </Button>
          <Button autoFocus disabled={isModerating} onClick={moderateJobs}>
            {approving ? 'Yes'
              : `Block${notificationCount ? ` and Notify Caregiver${notificationCount !== 1 ? 's' : ''}` : ''}`}
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        params={params}
        retrieveData={filterJobs}
        editAction={updateJob}
        pageSize={50}
        alwaysApplyParams
        enableInlineEditing
        enableRowSelection
        renderTopToolbarCustomActions={({ table }) => {
          const handleBulkBlock = () => {
            openBlockModalBulk(table.getSelectedRowModel().flatRows.map(row => row?.original));
          };

          const handleBulkApprove = () => {
            openApproveModalBulk(table.getSelectedRowModel().flatRows.map(row => row?.original));
          };

          return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color='success'
                disabled={isModerating || (!table.getIsSomePageRowsSelected() && !table.getIsAllRowsSelected())}
                onClick={handleBulkApprove}
                variant='contained'
              >
                Approve
              </Button>
              <Button
                color='error'
                disabled={isModerating || (!table.getIsSomePageRowsSelected() && !table.getIsAllRowsSelected())}
                onClick={handleBulkBlock}
                variant='contained'
              >
                Block
              </Button>
            </div>
          );
        }}

      />
    </Paper>
  );
}

export default withRouter(JobModeration);
