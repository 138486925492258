import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Partner, PromoService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';
import DisplayService from '../../util/DisplayService';
import PartnerForm from './PartnerForm';

function PartnerEdit(props) {
  const { history, match } = props;
  const { classes } = PageStyles();
  const [partner, setPartner] = useState<Partner>({});

  useEffect(() => {
    const getPartner = async () => {
      if (match.params.id > 0) {
        const response = await PromoService.get(match.params.id);
        const { data } = response.data;

        setPartner(data);
      }
    };

    getPartner();
  }, [match.params.id]);

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the partner.',
    );

    toast.error(displayedError.message);
  };

  const updatePartner = async () => {
    const data = {
      ...partner,
    };

    await PromoService.patch(data).catch(showError);
    const message = 'Partner updated successfully';

    toast.success(message);
    history.push('/partners');
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Edit Partner
      </Typography>
      <Grid container spacing={3}>
        {partner.id && (
          <PartnerForm partner={partner} setPartner={setPartner} mode='edit' />
        )}
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={updatePartner}
          className={classes.button}
        >
          Save Partner
        </Button>
      </div>{' '}
    </Paper>
  );
}

export default withRouter(PartnerEdit);
