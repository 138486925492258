import axios, { AxiosRequestConfig } from 'axios';
import { Verification } from '../types/Verification';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/user-verification';

class UserVerificationService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Verification>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Verification>> {
    return axios.get(URL, config);
  }

  static post(
    id: number,
    data: Verification,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Verification>> {
    return axios.post(`${URL}/${id}`, data, config);
  }
}

export { UserVerificationService };
