import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/videos';

class VideoService {
  static getByFilter(config: AxiosRequestConfig) {
    return axios.get(URL, config);
  }
}

export { VideoService };
