import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { ParentSignupVerticalType } from '../types/ParentSignupVerticalType';

const URL = '/v2/parent-signup-vertical-types';

class ParentSignupVerticalTypeService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupVerticalType>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<ParentSignupVerticalType>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: ParentSignupVerticalType,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupVerticalType>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: ParentSignupVerticalType,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupVerticalType>> {
    return axios.post(URL, data, config);
  }

  static delete(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentSignupVerticalType>> {
    return axios.delete(`${URL}/${id}`, config);
  }
}
export { ParentSignupVerticalTypeService };
