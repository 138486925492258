import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormLabel from '@mui/material/FormLabel';
import { CompanyBenefit } from 'us-web-services';
import DateUtil from '../../../util/DateUtil';

interface Props {
  benefit: CompanyBenefit;
  setBenefit(benefit: CompanyBenefit): void;
}

export default function CompanyBenefitEditForm(props: Props) {
  const { benefit, setBenefit } = props;
  const [start, setStart] = useState(benefit.start);
  const [end, setEnd] = useState(benefit.end);
  const [description, setDescription] = useState(benefit.description);

  const updateStart = date => {
    benefit.start = date.format(DateUtil.midnightDateFormat);
    setStart(date);
    setBenefit(benefit);
  };

  const updateEnd = date => {
    benefit.end = date.format(DateUtil.midnightDateFormat);
    setEnd(date);
    setBenefit(benefit);
  };

  const updateDescription = event => {
    benefit.description = event.target.value;
    setDescription(event.target.value);
    setBenefit(benefit);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Benefit Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormLabel component='legend'>Start</FormLabel>
          <DatePicker
            disabled={benefit.statusId === 2}
            inputFormat={DateUtil.dateFormatSlashes}
            onChange={updateStart}
            value={start}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component='legend'>End</FormLabel>
          <DatePicker
            inputFormat={DateUtil.dateFormatSlashes}
            onChange={updateEnd}
            value={end}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='description'
            name='description'
            label='Description'
            value={description}
            onChange={updateDescription}
            fullWidth
            multiline
            minRows='5'
          />
        </Grid>
      </Grid>
    </>
  );
}
