import axios, { AxiosRequestConfig } from 'axios';
import { Affiliation } from '../types/Affiliation';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/affiliations';

class AffiliationService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Affiliation>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Affiliation>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: Affiliation,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Affiliation>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: Affiliation,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Affiliation>> {
    return axios.post(URL, data, config);
  }
}

export { AffiliationService };
