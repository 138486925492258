import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { CitySeoContent } from '../types/CitySeoContent';

const CITY_SEO_URL = '/v2/city-seo-pages';

class CitySeoContentService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CitySeoContent>> {
    return axios.get(`${CITY_SEO_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<CitySeoContent>> {
    return axios.get(CITY_SEO_URL, config);
  }

  static patch(
    id: number,
    data: CitySeoContent,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CitySeoContent>> {
    return axios.patch(`${CITY_SEO_URL}/${id}`, data, config);
  }

  static create(
    data: CitySeoContent,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CitySeoContent>> {
    return axios.post(`${CITY_SEO_URL}`, data, config);
  }
}

export { CitySeoContentService };
