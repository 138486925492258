import React, { useEffect, useMemo, useState } from 'react';
import { CreateModal, Table, TableInstance } from 'src/components/common/Table';
import { MenuItem, Paper } from '@mui/material';

import { Company, CompanyPartner, CompanyPartnerService, PartnerService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';

interface Props {
  company: Company;
}

function CompanyPartners(props: Props) {
  const { company } = props;
  const [partners, setPartners] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const tableRef = React.useRef<TableInstance>();

  const { classes } = PageStyles();

  const refreshCompanyPartners = () => tableRef.current?.fetchData();

  const addPartner = async partner => {
    const domainData: CompanyPartner = {
      company: {
        id: company.id,
        partners: [],
      },
      partner: {
        id: partner.id,
      },
    };

    await CompanyPartnerService.create(domainData);
    refreshCompanyPartners();
  };

  const deletePartner = async partner => {
    await CompanyPartnerService.delete(partner.id);
    refreshCompanyPartners();
  };

  useEffect(() => {
    const getPartners = async () => {
      const result = (await PartnerService.getByFilter()).data;

      setPartners(result.data);
    };

    getPartners();
  }, []);

  const COLUMNS = useMemo(
    () => [
      {
        id: 'partners',
        header: 'Partners',
        columns: [
          {
            header: 'Partner',
            accessorFn: rowData => rowData?.partner?.name,
            size: 50,
            muiTableBodyCellEditTextFieldProps: {
              select: true,
              children: partners.map(item => (
                <MenuItem key={item.name} value={item}>
                  {item.id} - {item.name}
                </MenuItem>
              )),
            },
          },
        ],
      },
    ],
    [partners],
  );

  const params = () => [
    {
      key: 'companyId',
      value: company.id.toString(),
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={CompanyPartnerService.getByFilter}
        params={params}
        alwaysApplyParams
        addAction={() => setCreateModalOpen(true)}
        deleteAction={deletePartner}
        hideSearchBar
      />
      <CreateModal
        columns={COLUMNS}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={addPartner}
        title='Add Partner'
        buttonText='Add'
      />
    </Paper>
  );
}

export default CompanyPartners;
