import axios, { AxiosRequestConfig } from 'axios';
import { AdminNote , DataResponse, PagedResponse } from '../types';

const ADMIN_NOTE_URL = '/v2/user-notes';

class AdminNoteService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<AdminNote>> {
    return axios.get(ADMIN_NOTE_URL, config);
  }

  static create(
    data: AdminNote,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminNote>> {
    return axios.post(ADMIN_NOTE_URL, data, config);
  }

  static update(
    id: number,
    data: AdminNote,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminNote>> {
    return axios.put(`${ADMIN_NOTE_URL}/${id}`, data, config);
  }
}

export { AdminNoteService };
