import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, SitterDashboardData } from '../types';

const URL = '/v2/sitter-dashboard-data';

class SitterDashboardDataService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterDashboardData>> {
    return axios.get(`${URL}/${id}`, config);
  }
}

export { SitterDashboardDataService };
