import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { Sweetener } from '../types/Sweetener';

const URL = '/v2/booking-sweeteners';

class SweetenerService {
  static getBookingSweeteners(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Sweetener>> {
    return axios.get(URL, config);
  }
}

export { SweetenerService };
