import React, { useState } from 'react';
import {
  Button,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';

import { UserVerificationService, Verification } from 'us-web-services';
import { UserVerificationConstants } from '../../constants';
import ModalStyles from '../../styles/ModalStyles';

interface Props {
  identityCheck: Verification;
  onUpdate: () => void;
  setIdentityCheck: any;
  userId: number;
}

function IdentityCheckEdit(props: Props) {
  const { identityCheck, setIdentityCheck, userId, onUpdate } = props;
  const { classes } = ModalStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(identityCheck.status);
  const [attempts, setAttempts] = useState(identityCheck.attempts);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateStatus = async event => {
    const { value } = event.target;

    setStatus(value);
    identityCheck.status = value;
    setIdentityCheck(identityCheck);
  };

  const updateAttempts = async event => {
    const value = parseInt(event.target.value, 10);

    setAttempts(value);
    identityCheck.attempts = value;
    setIdentityCheck(identityCheck);
  };

  const saveIdentityCheck = async () => {
    const data = {
      id: identityCheck.id,
      userId,
      attempts,
      status,
      updatedBy: 'user',
      updatedAgent: 'admin',
    };

    const response = (await UserVerificationService.post(identityCheck.id, data)).data;

    setIdentityCheck(response.data);

    onUpdate();
    handleClose();
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        id='openModal'
        variant='contained'
        color='primary'
        onClick={showModal}
      >
        Edit Identity Check
      </Button>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id='identity-check-modal-title'>Edit Identity Check</h2>
            <FormLabel>Status</FormLabel>
            <br />
            <RadioGroup
              aria-label='Status'
              name='status'
              value={`${status || ''}`}
              onChange={updateStatus}
            >
              {Object.values(UserVerificationConstants.STATUSES).map(
                verificationStatus => (
                  <FormControlLabel
                    key={verificationStatus.code}
                    control={<Radio />}
                    label={verificationStatus.label}
                    value={`${verificationStatus.code}`}
                  />
                ),
              )}
            </RadioGroup>
            <br />
            <TextField
              id='attempts'
              name='attempts'
              label='Attempts'
              fullWidth
              autoComplete='attempts'
              value={attempts}
              type='number'
              onChange={updateAttempts}
            />
            <br />
            <br />
            <Button
              id='close'
              variant='contained'
              color='secondary'
              onClick={handleClose}
            >
              Close
            </Button>{' '}
            <Button
              id='save'
              variant='contained'
              color='primary'
              onClick={saveIdentityCheck}
            >
              Save
            </Button>{' '}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default IdentityCheckEdit;
