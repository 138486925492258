import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { SpecialExperience } from '../types/SpecialExperience';

const URL = '/v2/special-experiences';

class SpecialExperiencesService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<SpecialExperience>> {
    return axios.get(URL, config);
  }
}

export { SpecialExperiencesService };
