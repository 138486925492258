import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Press } from 'us-web-services';
import DateUtil from '../../util/DateUtil';

interface Props {
  press: Press;
  setPress(press: Press): void;
}

export default function PressEditForm(props: Props) {
  const { press, setPress } = props;
  const [title, setTitle] = useState(press.title);
  const [url, setUrl] = useState(press.url);
  const [publisher, setPublisher] = useState(press.publisher);
  const [pubDate, setPubDate] = useState(press.pubDate);

  const updateTitle = event => {
    setTitle(event.target.value);
    press.title = event.target.value;
    setPress(press);
  };

  const updateUrl = event => {
    setUrl(event.target.value);
    press.url = event.target.value;
    setPress(press);
  };

  const updatePublisher = event => {
    setPublisher(event.target.value);
    press.publisher = event.target.value;
    setPress(press);
  };

  const updatePubDate = moment => {
    const newPubDate = DateUtil.getDateTimeString(moment.set({ seconds: 0 }));

    press.pubDate = newPubDate;
    setPress(press);
    setPubDate(newPubDate);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='title'
          name='title'
          label='Title'
          fullWidth
          value={title}
          onChange={updateTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='url'
          name='url'
          label='URL'
          fullWidth
          value={url}
          onChange={updateUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='publisher'
          name='publisher'
          label='Publisher'
          fullWidth
          value={publisher}
          onChange={updatePublisher}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          inputFormat={DateUtil.dateTimeReadable}
          renderInput={pickerProps => (
            <TextField
              {...pickerProps}
              name='pubDate'
              id='pubDate'
              label='Published Date'
              fullWidth
            />
          )}
          value={pubDate}
          onChange={updatePubDate}
        />
      </Grid>
    </Grid>
  );
}
