import React, { useState } from 'react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';

import { SchoolService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function SchoolMerge(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [schoolInput, setSchoolInput] = useState(null);
  const [selectedSchoolInto, setSelectedSchoolInto] = useState(null);
  const [selectedSchoolFrom, setSelectedSchoolFrom] = useState([]);

  const getSchools = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (schoolInput) {
      contentConfig.params.name = schoolInput;
    }

    const schoolResponse = (await SchoolService.getByFilter(contentConfig)).data;
    const schools = schoolResponse.data;
    const options = [];

    schools.map(data =>
      options.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return options;
  };

  const updateSchoolInput = newValue => {
    setSchoolInput(newValue);
  };

  const updateSchoolFrom = value => {
    setSelectedSchoolFrom(value);
  };

  const navigateToList = () => {
    history.push('/schools');
  };

  const saveSuccess = (schoolInto, totalSchools) => {
    toast.success(
      `Successfully merged ${totalSchools} schools into ${schoolInto}`,
    );
    navigateToList();
  };

  const mergeSchools = async () => {
    if (!selectedSchoolInto) {
      toast.error('Please pick a School to Merge Into');

      return;
    }

    if (!selectedSchoolFrom.length) {
      toast.error('Please pick Schools to Merge From');

      return;
    }

    const schoolMergeFromIds = [];

    selectedSchoolFrom.forEach(x => {
      schoolMergeFromIds.push(x.id);
    });

    selectedSchoolInto.mergeFrom = schoolMergeFromIds;

    try {
      await SchoolService.patch(selectedSchoolInto.id, selectedSchoolInto);
      saveSuccess(selectedSchoolInto.name, schoolMergeFromIds.length);
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Merge Schools
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge Into</InputLabel>
          <AsyncSelect
            id='schoolInto'
            required
            loadOptions={getSchools}
            defaultOptions
            onInputChange={updateSchoolInput}
            onChange={setSelectedSchoolInto}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge From</InputLabel>
          <AsyncSelect
            isMulti
            id='schoolFrom'
            required
            loadOptions={getSchools}
            defaultOptions
            onInputChange={updateSchoolInput}
            onChange={updateSchoolFrom}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeSchools}
          className={classes.button}
        >
          Submit
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(SchoolMerge);
