import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardContent, Typography } from '@mui/material';

import { ParentAffiliationService,
  ParentLocalGroupsService,
  ParentSchoolsService,
  ParentService,
  SavedSearchService,
  SitterNotificationSettingsService,
  SitterService,
  UserService } from 'us-web-services';
import SettingToggle from '../common/SettingToggle';
import useUser from '../../store/useUser';
import DisplayService from '../../util/DisplayService';

interface Props {
  userId: number;
}

function UserNotifications(props: Props) {
  const { userId } = props;

  const [userState, userActions] = useUser();
  const [parentSavedSearches, setParentSavedSearches] = useState([]);
  const [sitterNotificationSettings, setSitterNotificationSettings] = useState(
    {
      availabilityRemindText: undefined,
      interviewRequestText: undefined,
      jobRequestText: undefined,
      weeklySummaryMail: undefined,
    },
  );

  const sitterNotifications = {
    availabilityRemindText: 'Availability reminder texts',
    interviewRequestText: 'Interview request texts',
    jobRequestText: 'Job request texts',
    weeklySummaryMail: 'Weekly summary emails',
  };

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the notification setting.',
    );

    toast.error(displayedError.message);
  };

  const toggleSavedSearchNotification = async (isChecked: boolean, id: number) => {
    const toggleParams = { id, notificationType: { id: isChecked ? 2 : 1 } };

    await SavedSearchService.patch(id, toggleParams);

    const searchParams = `?active=true&parentId=${userId}`;
    const searchesResponse = (await SavedSearchService.getByFilter(searchParams)).data;

    setParentSavedSearches(searchesResponse.data);
  };

  const toggleSitterNotificationSetting = async (isChecked: boolean, code: string) => {
    const params = { id: userId };

    params[code] = isChecked;

    const sitterSetting = (await SitterNotificationSettingsService.patch(userId, params)).data;

    setSitterNotificationSettings(sitterSetting.data);
  };

  const toggleFriendRecommendationEmail = async (isChecked: boolean, id: number) => {
    const params = {
      id,
      friendRecommendationId: isChecked ? 2 : 1,
    };

    const parentResponse = (await ParentService.patch(userId, params)).data;

    userActions.setProfile(parentResponse.data);
  };

  const toggleJobBoardEmail = async enabled => {
    const params = {
      id: userId,
      jobPostMessageEnabled: enabled,
    };

    const sitterResponse = await SitterService.patch(userId, params);
    const { data } = sitterResponse.data;

    userActions.setProfile(data);
  };

  const toggleNewsletterEmail = async (isChecked: boolean, id: number) => {
    try {
      const userResponse = await UserService.patch(id, {
        emailNewsletter: isChecked,
        id,
      });
      const { data } = userResponse.data;

      userActions.setUser(data);
    } catch (error) {
      showError(error);
    }
  };

  const toggleAffiliationNotification = async (enabled: boolean, affiliationId: number) => {
    try {
      await ParentAffiliationService.patch(affiliationId, {
        id: affiliationId,
        recommendationNotificationId: enabled ? 2 : 1,
      });

      const parentResponse = await ParentService.get(userId);
      const { data } = parentResponse.data;

      userActions.setProfile(data);
    } catch (error) {
      showError(error);
    }
  };

  const toggleLocalGroupNotification = async (enabled, localGroupId) => {
    try {
      await ParentLocalGroupsService.patch(localGroupId, {
        id: localGroupId,
        recommendationNotificationId: enabled ? 2 : 1,
      });

      const parentResponse = await ParentService.get(userId);
      const { data } = parentResponse.data;

      userActions.setProfile(data);
    } catch (error) {
      showError(error);
    }
  };

  const toggleSchoolNotification = async (isChecked: boolean, id: number) => {
    try {
      await ParentSchoolsService.patch(id, {
        id,
        recommendationNotificationId: isChecked ? 2 : 1,
      });

      const parentResponse = await ParentService.get(userId);
      const { data } = parentResponse.data;

      userActions.setProfile(data);
    } catch (error) {
      showError(error);
    }
  };

  const unsubscribeAll = async () => {
    try {
      if (userState.user.emailNewsletter) {
        toggleNewsletterEmail(false, userId);
      }

      if (userState.user.sitter) {
        if (userState.profile.jobPostMessageEnabled) {
          toggleJobBoardEmail(false);
        }

        if (sitterNotificationSettings.weeklySummaryMail) {
          toggleSitterNotificationSetting(false, 'weeklySummaryMail');
        }
      }

      if (userState.user.parent) {
        if (userState.profile.friendRecommendationId === 2) {
          toggleFriendRecommendationEmail(false, userId);
        }
        userState.profile.affiliations.map(affiliation => {
          if (affiliation.recommendationNotificationId === 2) {
            return toggleAffiliationNotification(false, affiliation.id);
          }

          return null;
        });
        userState.profile.localGroups.map(localGroup => {
          if (localGroup.recommendationNotificationId === 2) {
            return toggleLocalGroupNotification(false, localGroup.id);
          }

          return null;
        });
        userState.profile.schools.map(school => {
          if (school.recommendationNotificationId === 2) {
            return toggleSchoolNotification(false, school.id);
          }

          return null;
        });
        if (parentSavedSearches.length) {
          parentSavedSearches.map(search => {
            if (search.notificationType.id === 2) {
              return toggleSavedSearchNotification(false, search.id);
            }

            return null;
          });
        }
      }
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error updating the user notification settings.',
      );

      toast.error(displayedError.message);
    }

    toast.success('All non-transactional user notifications disabled.');
  };

  useEffect(() => {
    const getParentSavedSearches = async () => {
      const params = `?active=true&parentId=${userId}`;
      const searchesResponse = await SavedSearchService.getByFilter(params);

      setParentSavedSearches(searchesResponse.data.data);
    };

    const getSitterNotificationSettings = async () => {
      const settingParams = { id: userId };

      const settingsResponse =
        (await SitterNotificationSettingsService.getByFilter(userId, { params: settingParams })).data;

      setSitterNotificationSettings(settingsResponse.data);
    };

    if (userState.user.parent) {
      getParentSavedSearches();
    }

    if (userState.user.sitter) {
      getSitterNotificationSettings();
    }
  }, [
    setSitterNotificationSettings,
    userId,
    userState.user.id,
    userState.user.parent,
    userState.user.sitter,
  ]);

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Typography component='h5' variant='h5' style={{ marginBottom: 5 }}>
          User Notifications
        </Typography>
        <br />
        <Button
          id='unsubscribe-all'
          variant='contained'
          color='primary'
          onClick={unsubscribeAll}
          style={{ marginBottom: 15 }}
        >
          Unsubscribe All
        </Button>
        <SettingToggle
          id={userId}
          isChecked={userState.user.emailNewsletter || false}
          label='Newsletter emails'
          toggleFunction={toggleNewsletterEmail}
        />
        {userState.user.parent && (
          <SettingToggle
            id={userId}
            isChecked={userState.profile.friendRecommendationId === 2}
            label='Friend-recommended sitter emails'
            toggleFunction={toggleFriendRecommendationEmail}
          />
        )}
        {userState.user.parent &&
          userState.profile.affiliations &&
          userState.profile.affiliations.map(affiliation => (
            <SettingToggle
              id={affiliation.id}
              isChecked={
                affiliation.recommendationNotificationId === 2 || false
              }
              key={`affiliation-${affiliation.id}`}
              label={affiliation.affiliation.name}
              toggleFunction={toggleAffiliationNotification}
            />
          ))}
        {userState.user.parent &&
          userState.profile.localGroups &&
          userState.profile.localGroups.map(localGroup => (
            <SettingToggle
              id={localGroup.id}
              isChecked={localGroup.recommendationNotificationId === 2 || false}
              key={`localGroup-${localGroup.id}`}
              label={localGroup.localGroup.name}
              toggleFunction={toggleLocalGroupNotification}
            />
          ))}
        {userState.user.parent &&
          userState.profile.schools &&
          userState.profile.schools.map(school => (
            <SettingToggle
              id={school.id}
              isChecked={school.recommendationNotificationId === 2 || false}
              key={`school-${school.id}`}
              label={school.school.name}
              toggleFunction={toggleSchoolNotification}
            />
          ))}
        {userState.user.parent &&
          parentSavedSearches.length > 0 &&
          parentSavedSearches.map(search => (
            <SettingToggle
              id={search.id}
              isChecked={search.notificationType.id === 2 || false}
              key={`saved-search-${search.id}`}
              label={search.title}
              toggleFunction={toggleSavedSearchNotification}
            />
          ))}
        {userState.user.sitter && (
          <SettingToggle
            id='jobBoardEmail'
            isChecked={userState.profile.jobPostMessageEnabled || false}
            label='Job Board post alerts'
            toggleFunction={toggleJobBoardEmail}
          />
        )}
        {userState.user.sitter && (
          <SettingToggle
            id='weeklySummaryMail'
            isChecked={sitterNotificationSettings.weeklySummaryMail || false}
            label={sitterNotifications.weeklySummaryMail}
            toggleFunction={toggleSitterNotificationSetting}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default UserNotifications;
