import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';

import { LocalGroup, LocationService, LocationRegionsService } from 'us-web-services';

interface Props {
  localGroup: LocalGroup;
  setLocalGroup: (localGroup: LocalGroup) => void;
}

export default function LocalGroupEditForm(props: Props) {
  const { localGroup, setLocalGroup } = props;

  const [name, setName] = useState(localGroup.name);
  const [alias, setAlias] = useState(localGroup.alias);
  const [cityState, setCityState] = useState(localGroup.city);
  const [location, setLocation] = useState(localGroup.location);
  const [locationInput, setLocationInput] = useState(null);
  const [region, setRegion] = useState(localGroup.region.name);
  const [regionInput, setRegionInput] = useState(null);

  const getLocations = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (locationInput) {
      contentConfig.params.name = locationInput;
    }

    const locationsResponse = (await LocationService.getByFilter(contentConfig)).data;
    const locationsData = locationsResponse.data;
    const locationOptions = [];

    locationsData.map(locationData =>
      locationOptions.push({
        id: locationData.id,
        name: locationData.name,
        value: locationData.id,
        label: `${locationData.name}, ${locationData.state.code}`,
        state: locationData.state,
        region: locationData.region,
      }),
    );

    return locationOptions;
  };

  const getRegions = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (regionInput) {
      contentConfig.params.name = regionInput;
    }

    const regionResponse = await LocationRegionsService.getByFilter(
      contentConfig,
    );

    const regionData = regionResponse.data.data;

    const regionOptions = [];

    regionData.map(data =>
      regionOptions.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return regionOptions;
  };

  const updateName = event => {
    setName(event.target.value);
    localGroup.name = event.target.value;
    setLocalGroup(localGroup);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
    localGroup.alias = event.target.value;
    setLocalGroup(localGroup);
  };

  const updateLocationInput = newValue => {
    setLocationInput(newValue);
  };

  const updateRegionInput = newValue => {
    setRegionInput(newValue);
  };

  const updateCityState = value => {
    setCityState(value);
    localGroup.city = value;
    setLocalGroup(localGroup);
  };

  const updateLocation = value => {
    setLocation(value);
    localGroup.location = value;
    setLocalGroup(localGroup);
  };

  const updateRegion = value => {
    setRegion(value);
    localGroup.region = value;
    setLocalGroup(localGroup);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          onChange={updateName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='alias'
          name='alias'
          label='Alias'
          fullWidth
          value={alias}
          onChange={updateAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          City and State
        </Typography>
        <AsyncSelect
          id='citystate'
          required
          loadOptions={getLocations}
          defaultOptions
          defaultInputValue={`${cityState.name}, ${cityState.state.code}`}
          onInputChange={updateLocationInput}
          onChange={updateCityState}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          Location
        </Typography>
        <AsyncSelect
          id='location'
          required
          loadOptions={getLocations}
          defaultOptions
          defaultInputValue={`${location.name}, ${location.state.code}`}
          onInputChange={updateLocationInput}
          onChange={updateLocation}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          Region
        </Typography>
        <AsyncSelect
          id='region'
          required
          loadOptions={getRegions}
          defaultOptions
          defaultInputValue={region}
          onInputChange={updateRegionInput}
          onChange={updateRegion}
        />
      </Grid>
    </Grid>
  );
}
