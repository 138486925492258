import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';

interface Props {
  id: number | string;
  isChecked: boolean;
  label: string;
  toggleFunction: (isChecked: boolean, id: number | string) => void;
}

function SettingToggle(props: Props) {
  const { id, isChecked, label, toggleFunction } = props;

  const toggleEvent = () => {
    toggleFunction(!isChecked, id);
  };

  return (
    <Grid item xs={6}>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={toggleEvent} />}
        label={label}
      />
    </Grid>
  );
}

export default SettingToggle;
