import React, { useMemo } from 'react';

import { UserDeviceService } from 'us-web-services';
import { Table } from '../common/Table';

interface Props {
  userId: number;
}

function UserDevices(props: Props) {
  const { userId } = props;

  const COLUMNS = useMemo(
    () => [
      {
        id: 'devices',
        header: 'User Devices',
        columns: [
          {
            header: 'Device',
            accessorFn: rowData => `[${rowData.type}] ${rowData.manufacturer}`,
          },
          {
            header: 'Model',
            accessorKey: 'model',
          },
          {
            header: 'OS Version',
            accessorKey: 'osVersion',
          },
          {
            header: 'App Version',
            accessorKey: 'appVersion',
          },
          {
            header: 'Active',
            accessorKey: 'active',
          },
        ],
      },
    ],
    [],
  );

  const params = (globalFilter: string) => [
    {
      key: 'name',
      value: globalFilter,
    },
    {
      key: 'userId',
      value: userId.toString(),
    },
    {
      key: 'sort',
      value: 'created',
    },
    {
      key: 'created.dir',
      value: 'DESC',
    },
  ];

  return (
    <Table
      columns={COLUMNS}
      retrieveData={UserDeviceService.findByFilter}
      params={params}
      alwaysApplyParams
    />
  );
}

export default UserDevices;
