import { makeStyles } from 'tss-react/mui';

const ModalStyles = makeStyles()(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '1px solid #000',
    outline: 'none',
    padding: theme.spacing(2),
  },
}));

export default ModalStyles;
