import React from 'react';
import {
  Button,
} from '@mui/material';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { CheckrPayoutOrder } from 'us-web-services';

const useStyles = makeStyles<
void,
'focusVisible' | 'imageBackdrop' | 'imageMarked' | 'imageTitle'
>()((theme, _params, classes) => ({
  card: {
    display: 'flex',
  },
  orderLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  media: {
    width: 181,
    backgroundSize: 'cover',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '45%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    [`&:hover, &.${classes.focusVisible}`]: {
      zIndex: 1,
      [`& .${classes.imageBackdrop}`]: {
        opacity: 0.15,
      },
      [`& .${classes.imageMarked}`]: {
        opacity: 0,
      },
      [`& .${classes.imageTitle}`]: {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)} 
    ${theme.spacing(4)} calc(${theme.spacing(1)} + 6px)`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

interface Props extends RouteComponentProps {
  order: CheckrPayoutOrder;
  reversePayout: () => void;
}

function CheckrPayoutOrderCard(props: Props) {
  const { order, reversePayout } = props;
  const { classes } = useStyles();

  return (
    <>
      Order ID: { order.order.id }<br />
      Created: {moment(order.order.created).format('LLL')}<br />
      Updated: {moment(order.order.updated).format('LLL')}<br />
      { order.order.reason ? `Reason: ${order.order.reason}<br />` : '' }
      Status: { order.order.status }<br /><br />

      { order.order.itemBooking &&
        <>
          Booking:&nbsp;
          <a href={`/bookings/lookup?id=${order.bookingId}`} rel='noreferrer' target='_blank'>
            {order.bookingId}
          </a>
          <br />
          Total: ${ order.order.itemBooking.total }<br />
          Tip: ${ order.order.itemBooking.tip }<br /><br />
        </>
      }

      Payee:&nbsp;
      <a href={`/users/lookup?id=${order.payeeId}`} rel='noreferrer' target='_blank'>
        {order.payeeId}
      </a>
      <br />
      Amount: ${ order.order.totalOut }<br />
      Checkr Payout: { order.payoutId }<br />
      { order.repayDate ? <><b>{`Reversed: ${moment(order.repayDate).format('LLL')}`}</b><br /></> :
      <Button
        className={classes.buttons}
        color='error'
        onClick={reversePayout}
        style={{ marginTop: 25 }}
        variant='contained'
      >
        Reverse Payout
      </Button>
      }
    </>
  );
}

export default withRouter(CheckrPayoutOrderCard);
