import axios, { AxiosRequestConfig } from 'axios';
import { CheckrRequest } from '../types/CheckrRequest';
import { DataResponse } from '../types/api';

const URL = '/v2/checkr-requests';

class CheckrRequestService {
  static create(
    data: CheckrRequest,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CheckrRequest>> {
    return axios.post(URL, data, config);
  }

  static update(
    id: number,
    data: CheckrRequest,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<CheckrRequest>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { CheckrRequestService };
