import React, { useEffect, useState } from 'react';
import { AppBar, Grid, Paper, Toolbar, Typography } from '@mui/material';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UserState } from 'us-web-services';
import useUser from '../../../store/useUser';
import UserSuggestions from '../UserSuggestions';
import PlanLookup from './PlanLookup';
import LookupTabs from '../LookupTabs';

function UserPlans(props: RouteComponentProps) {
  const { location } = props;
  const userCache = useUser()[0];
  const searchParams = queryString.parse(location.search);
  const [userState, setUserState] = useState<UserState>({
    user: {},
    profile: {},
  });

  useEffect(() => {
    setUserState(userCache);
  }, [userCache, userCache.user.id, userCache.profile.id, searchParams.id]);

  return (
    <Paper>
      <AppBar
        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        position='static'
        color='default'
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                }}
              >
                <UserSuggestions />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div style={{ margin: '40px 16px' }}>
        {userState.user.id && userState.profile.id ? (
          <>
            <LookupTabs user={userState.user} />
            <PlanLookup />
          </>
        ) : (
          <Typography
            color='textPrimary'
            align='center'
            component='h5'
            variant='h5'
            style={{ padding: 20 }}
          >
            No user found
          </Typography>
        )}
      </div>
    </Paper>
  );
}

export default withRouter(UserPlans);
