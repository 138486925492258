import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';

import { WinbackCode } from 'us-web-services';
import DateUtil from '../../util/DateUtil';

interface Props {
  winbackCode: WinbackCode;
  setWinbackCode: (winbackCode: WinbackCode) => void;
}

export default function WinbackCodeForm(props: Props) {
  const { winbackCode, setWinbackCode } = props;
  const [code, setCode] = useState(winbackCode.code);
  const [description, setDescription] = useState(winbackCode.description);
  const [start, setStart] = useState(moment(winbackCode.start));
  const [end, setEnd] = useState(moment(winbackCode.end));

  const updateCode = event => {
    setCode(event.target.value);
    winbackCode.code = event.target.value;
    setWinbackCode(winbackCode);
  };

  const updateDescription = event => {
    setDescription(event.target.value);
    winbackCode.description = event.target.value;
    setWinbackCode(winbackCode);
  };

  const updateStart = date => {
    setStart(date);
    winbackCode.start = date.format(DateUtil.midnightDateFormat);
    setWinbackCode(winbackCode);
  };

  const updateEnd = date => {
    setEnd(date);
    winbackCode.end = date.format(DateUtil.midnightDateFormat);
    setWinbackCode(winbackCode);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='code'
          name='code'
          label='Code'
          fullWidth
          value={code}
          onChange={updateCode}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='description'
          name='description'
          label='Description'
          fullWidth
          value={description}
          onChange={updateDescription}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel component='legend'>Start</FormLabel>
        <DatePicker
          inputFormat={DateUtil.dateReadable}
          onChange={updateStart}
          value={start}
          renderInput={pickerProps => <TextField {...pickerProps} />}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel component='legend'>End</FormLabel>
        <DatePicker
          inputFormat={DateUtil.dateReadable}
          onChange={updateEnd}
          value={end}
          renderInput={pickerProps => <TextField {...pickerProps} />}
        />
      </Grid>
    </Grid>
  );
}
