/* eslint-disable react/jsx-pascal-case */
import { Box, IconButton } from '@mui/material';
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { Add, Delete, Edit } from '@mui/icons-material';
import toast from 'react-hot-toast';

interface Column {
  id: string;
  header: string;
  columns: {
    header: string;
    accessorKey?: string;
    accessorFn?: (any) => string;
    size?: number;
  }[];
}

interface Param {
  key: string;
  value: string;
}

export type TableInstance = {
  fetchData: () => void;
};

interface Props {
  columns: Column[];
  alwaysApplyParams?: boolean;
  hideSearchBar?: boolean;
  enableInlineEditing?: boolean;
  enableRowSelection?: boolean;
  enableSorting?: boolean;
  pageSize?: number;
  params?: (query: string, sorting?: any) => Param[];
  retrieveData?: (config: any) => any;
  addAction?: (rowData?: any) => void;
  editAction?: (rowData: any) => void;
  deleteAction?: (rowData: any) => void
  renderTopToolbarCustomActions?: ({ table }: {
    table: MRT_TableInstance<any>;
  }) => React.ReactNode
}

const Table = React.forwardRef(
  (
    {
      columns,
      pageSize = 10,
      params,
      retrieveData,
      addAction,
      editAction,
      enableInlineEditing,
      enableRowSelection,
      enableSorting,
      deleteAction,
      alwaysApplyParams,
      hideSearchBar,
      renderTopToolbarCustomActions,
    }: Props,
    ref: React.MutableRefObject<TableInstance>,
  ): JSX.Element => {
    // data and fetching state
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [sorting, setSorting] = useState(undefined);

    // table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize,
    });

    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      }

      const paramList = new URLSearchParams();

      paramList.append('limit', pagination.pageSize.toString());
      paramList.append(
        'page',
        (pagination.pageIndex + 1).toString(),
      );
      const config = {
        params: paramList,
      };

      if ((globalFilter && params) || alwaysApplyParams) {
        const variableParams = params(globalFilter, sorting);

        variableParams.forEach((item: Param) => {
          config.params.append(item.key, item.value);
        });
      }

      try {
        const response = await retrieveData(config);

        setData(response.data.data);
        setRowCount(response.data.meta.size);
      } catch (error) {
        setIsError(true);
        toast.error('There was an error loading the data.');

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    useEffect(() => {
      fetchData();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    React.useImperativeHandle(ref, () => ({
      fetchData,
    }));

    const handleSaveRowEdits = ({ exitEditingMode, row, values }) => {
      editAction({ row, values });
      exitEditingMode();
    };

    const handleSortingChange = (sortingData: any) => {
      const { id, desc } = sortingData()[0];

      setSorting({ id, desc });
    };

    return (
      <MaterialReactTable
        columns={columns}
        data={data}
        getRowId={(row: any) => row.id}
        enableRowActions={!!editAction || !!deleteAction}
        renderToolbarInternalActions={({ table }) => (
          <>
            {/* built-in buttons (must pass in table prop for them to work!) */}
            {params && !hideSearchBar && (
              <MRT_ToggleGlobalFilterButton table={table} />
            )}
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_FullScreenToggleButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            {addAction && (
              <IconButton onClick={addAction}>
                <Add />
              </IconButton>
            )}
          </>
        )}
        renderRowActions={({ row, table }) => (
          <Box style={{ display: 'inline-flex', flexDirection: 'row' }}>
            {editAction && !enableInlineEditing && (
              <IconButton
                onClick={() => {
                  editAction(row.original);
                  fetchData();
                }}
              >
                <Edit />
              </IconButton>
            )}
            {enableInlineEditing && editAction && (
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            )}
            {deleteAction && (
              <IconButton
                onClick={() => {
                  deleteAction(row.original);
                  fetchData();
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        )}
        manualFiltering
        manualPagination
        manualSorting
        enableEditing={enableInlineEditing}
        enableRowSelection={enableRowSelection}
        onEditingRowSave={handleSaveRowEdits}
        enableSorting={!!enableSorting}
        enableColumnFilters={false}
        onSortingChange={handleSortingChange}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        rowCount={rowCount}
        state={{
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'auto',
          },
        }}
        defaultColumn={{
          minSize: 10,
          size: 100,
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 30,
          },
          'mrt-row-select': {
            size: 20,
          },
        }}
      />
    );
  },
);

export default Table;
