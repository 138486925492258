import React, { useMemo } from 'react';
import { Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ZipCodeService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../../common/Table';

function ZipCodeLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'zipCodes',
        header: 'Zip Codes',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Zip Code',
            accessorKey: 'zipCode',
          },
          {
            header: 'City',
            accessorKey: 'city',
          },
          {
            header: 'State',
            accessorKey: 'state',
          },
          {
            header: 'Time Zone',
            accessorKey: 'timeZone',
          },
          {
            header: 'Latitude',
            accessorKey: 'latitude',
          },
          {
            header: 'Longitude',
            accessorKey: 'longitude',
          },
        ],
      },
    ],
    [],
  );

  const addZipCode = () => {
    history.push('/zip-code-create');
  };

  const editZipCode = rowData => {
    history.push(`/zip-codes/${rowData.id}`);
  };

  const params = (globalFilter: string) => [
    {
      key: 'zipCode',
      value: globalFilter,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={ZipCodeService.getByFilter}
        params={params}
        editAction={editZipCode}
        addAction={addZipCode}
      />
    </Paper>
  );
}

export default withRouter(ZipCodeLookup);
