import React, { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';

import {
  OrderService,
} from 'us-web-services';
import { Table, TableInstance } from 'src/components/common/Table';
import useUser from '../../store/useUser';
import DateUtil from '../../util/DateUtil';
import OrderItemVoucher from './OrderItemVoucher';

interface Props {
  open: boolean;
}

function RenewalOrderLookup(props: Props) {
  const { open } = props;
  const userCache = useUser()[0];
  const tableRef = React.useRef<TableInstance>();

  const COLUMNS_ORDERS = useMemo(
    () => [
      {
        id: 'orders',
        header: 'Orders',
        columns: [
          {
            header: 'ID',
            accessorKey: 'id',
            enableEditing: false,
          },
          {
            header: 'Status',
            accessorKey: 'status',
            enableEditing: false,
          },
          {
            header: 'Total In',
            accessorKey: 'totalIn',
            enableEditing: false,
          },
          {
            header: 'Total Out',
            accessorKey: 'totalOut',
            enableEditing: false,
          },
          {
            header: 'Fee',
            accessorKey: 'fee',
            enableEditing: false,
          },
          {
            header: 'Created At',
            accessorFn: rowData =>
              DateUtil.getReadableDateTime(rowData.created),
            enableEditing: false,
          },
          {
            header: 'Total',
            accessorKey: 'total',
            enableEditing: false,
          },
          {
            header: 'Discount',
            accessorKey: 'discount',
            enableEditing: false,
          },
          {
            header: 'Platform Fee',
            accessorKey: 'platformFee',
            enableEditing: false,
          },
          {
            header: 'Purchased Item Id',
            accessorKey: 'purchasedItemId',
            enableEditing: false,
          },
          {
            header: 'Reason',
            accessorKey: 'reason',
            enableEditing: false,
          },
          {
            header: 'Payee Id',
            accessorKey: 'payeeId',
            enableEditing: false,
          },
        ],
      },
    ],
    [],
  );

  const paramsOrders = () => [
    {
      key: 'userId',
      value: userCache?.user?.id?.toString(),
    },
  ];

  return (
    <Paper>
      {open ? (
        <>
          <Table
            columns={COLUMNS_ORDERS}
            ref={tableRef}
            retrieveData={OrderService.getByFilter}
            params={paramsOrders}
            alwaysApplyParams
          />
          <OrderItemVoucher />
        </>
      ) : (
        <Typography
          color='textPrimary'
          align='center'
          component='h5'
          variant='h5'
          style={{ padding: 20 }}
        />
      )}
    </Paper>
  );
}

export default RenewalOrderLookup;
