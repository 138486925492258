import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../../types/api';
import { SitterCheckrPayUrl } from '../../types/checkrpay/SitterCheckrPayUrl';

const URL = '/v2/sitter-checkrpay-url';

class SitterCheckrPayUrlService {
  static create(
    data: SitterCheckrPayUrl,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<SitterCheckrPayUrl>> {
    return axios.post(URL, data, config);
  }
}

export { SitterCheckrPayUrlService };
