import axios, { AxiosRequestConfig } from 'axios';

const API_URL = '/v2/parent-schools';

class ParentSchoolsService {
  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(API_URL, data, config);
  }

  static patch(id: any, data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${API_URL}/${id}`, data, config);
  }
}

export { ParentSchoolsService };
