import axios, { AxiosRequestConfig } from 'axios';
import { Login } from '../types/authentication/Login';
import { DataResponse } from '../types/api';
import { AuthToken } from '../types/authentication/AuthToken';

const ADMIN_LOGIN_URL = '/v2/public/authenticate/admin';
const EMAIL_LOGIN_URL = '/v2/public/authenticate/email';
const SSO_LOGIN_URL = '/v2/public/sso';

class AuthenticationService {
  static admin(
    data: Login,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AuthToken>> {
    return axios.post(ADMIN_LOGIN_URL, data, config);
  }

  static email(
    data: Login,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AuthToken>> {
    return axios.post(EMAIL_LOGIN_URL, data, config);
  }

  static sso(data: any, config?: AxiosRequestConfig) {
    return axios.post(SSO_LOGIN_URL, data, config);
  }
}

export { AuthenticationService };
