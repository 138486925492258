import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { City } from '../types/City';

const CITIES_URL = '/v2/cities';

class CityService {
  static findById(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<City>> {
    return axios.get(`${CITIES_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<City>> {
    return axios.get(CITIES_URL, config);
  }
}

export { CityService };
