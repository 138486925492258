import axios, { AxiosRequestConfig } from 'axios';

const PROMO_URL = '/v2/promos';
const PROMO_LOGOS_URL = '/v2/promo-logos';

class PromoService {
  static get(id: any, config?: AxiosRequestConfig) {
    return axios.get(`${PROMO_URL}/${id}`, config);
  }

  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(PROMO_URL, config);
  }

  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(PROMO_URL, data, config);
  }

  static patch(data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${PROMO_URL}/${data.id}`, data, config);
  }

  static uploadLogo(data: any) {
    return axios.post(PROMO_LOGOS_URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export { PromoService };
