import axios, { AxiosRequestConfig } from 'axios';
import { Sitter } from '../types/Sitter';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/sitters';
const INTERNAL_URL = '/internal/sitters';
const APPROVAL_EMAIL_URL = '/v2/sitter-approval-email';
const AVAILABILTIES_URL = '/v2/sitter-availabilities';
const HIDDEN_REASONS_URL = '/v2/sitter-hidden-reasons';
const PROFILE_STATUS_URL = '/v2/sitter-profile-status';
const CERTIFICATIONS_URL = '/v2/certifications';
const SCHEDULES_URL = '/v2/sitter-schedules';

class SitterService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Sitter>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Sitter>> {
    return axios.get(URL, config);
  }

  static getInternal(config: AxiosRequestConfig = {}) {
    if (typeof window === 'undefined') {
      config.baseURL = `http://${process.env.US_SERVICES_HOST}:${process.env.US_SERVICES_PORT}`;
    }

    return config.params.sitterId
      ? axios.get(`${INTERNAL_URL}/${config.params.sitterId}`, config)
      : axios.get(INTERNAL_URL, config);
  }

  static getAvailabilities(config?: AxiosRequestConfig) {
    return axios.get(AVAILABILTIES_URL, config);
  }

  static getCertifications(id: number, config?: AxiosRequestConfig) {
    return axios.get(`${CERTIFICATIONS_URL}/${id}`, config);
  }

  static getHiddenReasons(config?: AxiosRequestConfig) {
    return axios.get(HIDDEN_REASONS_URL, config);
  }

  static getProfileStatus(id: number, config?: AxiosRequestConfig) {
    return axios.get(`${PROFILE_STATUS_URL}/${id}`, config);
  }

  static getSchedules(config?: AxiosRequestConfig) {
    return axios.get(SCHEDULES_URL, config);
  }

  static getStats(id: number, config?: AxiosRequestConfig) {
    return axios.get(`${URL}/${id}/stats`, config);
  }

  static patch(
    id: number,
    data: Sitter,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Sitter>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  // TODO: create the somewhat complex type for the availabilityData parameter
  /* eslint-disable @typescript-eslint/no-explicit-any */
  static patchAvailabilities(availabilityData: any, config?: AxiosRequestConfig) {
    return axios.patch(AVAILABILTIES_URL, config, availabilityData);
  }

  static patchStats(sitterId: number, statsId: number, config?: AxiosRequestConfig) {
    return axios.patch(`${URL}/${sitterId}/stats/${statsId}`, config);
  }

  static sendApprovalEmail(sitterId: number, config?: AxiosRequestConfig) {
    return axios.post(`${APPROVAL_EMAIL_URL}/${sitterId}`, config);
  }
}

export { SitterService };
