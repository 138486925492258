import axios, { AxiosRequestConfig } from 'axios';

const VOUCHER_CODE_URL = '/v2/voucher-codes';

class VoucherCodeService {
  static get(id: any, config?: AxiosRequestConfig) {
    return axios.get(`${VOUCHER_CODE_URL}/${id}`, config);
  }

  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(`${VOUCHER_CODE_URL}`, config);
  }

  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(VOUCHER_CODE_URL, data, config);
  }

  static update(data: any, config?: AxiosRequestConfig) {
    return axios.post(`${VOUCHER_CODE_URL}/${data.id}`, data, config);
  }

  static patch(data: { id: any }, config?: AxiosRequestConfig) {
    return axios.patch(`${VOUCHER_CODE_URL}/${data.id}`, data, config);
  }
}

export { VoucherCodeService };
