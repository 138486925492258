import React from 'react';
import useStore from './useStore';

import * as actions from './actions/authentication';
import { StorageNames } from '../constants';

const localUser = localStorage.getItem(StorageNames.US_USER_OBJECT);
const user = localUser ? JSON.parse(localUser) : {};

const initialState = {
  authentication: {
    isAuthenticated: false,
    user: user?.id ? user : {},
    token: localStorage.getItem(StorageNames.US_USER_AUTH_TOKEN),
    refreshToken: localStorage.getItem(StorageNames.US_USER_REFRESH_TOKEN),
  },
};

const useAuthentication = useStore(React, initialState, {
  authentication: actions,
});

export default useAuthentication;
