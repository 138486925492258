import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../types/api';
import { LocationRegion } from '../types/LocationRegion';

const API_URL = '/v2/location-regions';

class LocationRegionsService {
  static findById(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<LocationRegion>> {
    return axios.get(`${API_URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<LocationRegion>> {
    return axios.get(`${API_URL}`, config);
  }
}

export { LocationRegionsService };
