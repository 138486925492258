import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { Referral, ReferralOffer } from '../types/referral/Referral';

const OFFER_URL_PUBLIC = '/v2/public/received-referral-offer?senderId=';
const OFFER_URL_USER = '/v2/received-referral-offer';
const REFERRALS_URL = '/v2/referrals';
const USERS_URL = '/v2/users';

class ReferralOfferService {
  static create(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ReferralOffer>> {
    return axios.post(`${USERS_URL}/${id}/referral-offer`, config);
  }

  static get(
    senderId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ReferralOffer>> {
    return axios.get(
      `${OFFER_URL_USER}?senderId=${senderId}&userId=${userId}`,
      config
    );
  }

  static getPublicOffer(
    senderId: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ReferralOffer>> {
    return axios.get(`${OFFER_URL_PUBLIC}${senderId}`, config);
  }

  static redeem(
    data: Referral,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Referral>> {
    return axios.post(REFERRALS_URL, data, config);
  }
}

export { ReferralOfferService };
