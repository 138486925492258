import { useEffect, useReducer, useState } from 'react';
import moment from 'moment';

import {
  PartnerService,
  VoucherBookingType,
  VoucherCode,
  VoucherCodePartner,
  VoucherCodeService,
} from 'us-web-services';
import DisplayService from '../../../util/DisplayService';
import DateUtil from '../../../util/DateUtil';

function useViewModel(voucherId?: number) {
  const [sideEffect, setSideEffect] = useState<SideEffect>({ type: 'onLoad' });

  const reducer = (uiModel: UiModel, action: ActionType) => {
    switch (action.type) {
      case 'type':
        return { ...uiModel, type: action.value };
      case 'code':
        return { ...uiModel, code: action.value };
      case 'description':
        return { ...uiModel, description: action.value };
      case 'numberOfCodes':
        return { ...uiModel, numberOfCodes: action.value };
      case 'value':
        return { ...uiModel, value: action.value };
      case 'maxRedemptions':
        return { ...uiModel, maxRedemptions: action.value };
      case 'userType':
        return { ...uiModel, userType: action.value };
      case 'percentDiscount':
        return { ...uiModel, percentDiscount: action.value };
      case 'firstTimeBooking':
        return { ...uiModel, firstTimeBooking: action.value };
      case 'babysittingFee':
        return { ...uiModel, babysittingFee: action.value };
      case 'partners':
        uiModel.partners.forEach(item => {
          if (item.id === action.id) {
            item.selected = action.value;
          }
        });

        return { ...uiModel };
      case 'verticalType':
        uiModel.verticalTypes.forEach(item => {
          if (item.id === action.id) {
            item.selected = action.value;
          }
        });

        return { ...uiModel };
      case 'setupFee':
        return { ...uiModel, setupFee: action.value };
      case 'setupFeeFrequency':
        uiModel.setupFeeFrequencies.forEach(item => {
          if (item.id === action.id) {
            item.selected = action.value;
          }
        });

        return { ...uiModel };
      case 'installmentFee':
        return { ...uiModel, installmentFee: action.value };
      case 'installmentFeeFrequency':
        uiModel.installmentFeeFrequencies.forEach(item => {
          if (item.id === action.id) {
            item.selected = action.value;
          }
        });

        return { ...uiModel };
      case 'activeFrom':
        return { ...uiModel, activeFrom: action.value };
      case 'redeemBy':
        return { ...uiModel, redeemBy: action.value };
      case 'useBy':
        return { ...uiModel, useBy: action.value };
      case 'comment':
        return { ...uiModel, comment: action.value };
      case 'update':
        return { ...action.value };
      default:
        return uiModel;
    }
  };

  const defaultUiModel = (): UiModel => ({
    edit: false,
    active: true,
    pageTitle: 'Add Voucher',
    type: 'general',
    code: '',
    numberOfCodes: '',
    description: '',
    value: '',
    maxRedemptions: '',
    userType: -1,
    userTypes: [
      { id: -1, label: 'All Users' },
      { id: 5, label: 'Parents Only' },
      { id: 4, label: 'Sitters Only' },
    ],
    percentDiscount: false,
    firstTimeBooking: false,
    babysittingFee: false,
    verticalTypes: [
      { id: 1, label: 'Child care', selected: false },
      { id: 2, label: 'Pet care', selected: false },
      { id: 3, label: 'Senior care', selected: false },
      { id: 4, label: 'Household services', selected: false },
    ],
    setupFee: false,
    setupFeeFrequencies: [
      { id: 7, label: 'Monthly', selected: false },
      { id: 5, label: 'Annual', selected: false },
      { id: 16, label: 'Quarterly', selected: false },
      { id: 15, label: 'Access Pass', selected: false },
    ],
    installmentFee: false,
    installmentFeeFrequencies: [
      { id: 11, label: 'Monthly', selected: false },
      { id: 9, label: 'Annual', selected: false },
      { id: 14, label: 'Quarterly', selected: false },
    ],
    activeFrom: null,
    redeemBy: null,
    useBy: null,
    comment: '',
    error: '',
  });

  function getUiModelFromEntity(entity: VoucherCode): UiModel {
    const dto: UiModel = defaultUiModel();

    dto.edit = true;
    dto.active = entity.active;
    dto.id = entity.id;
    dto.pageTitle = `Voucher Code: ${entity.code}`;
    dto.type = entity.type;
    dto.code = entity.code;
    dto.percentDiscount = entity.percentDiscount;
    dto.description = entity.description;
    dto.value = entity.value?.toString(10);
    dto.userType = entity.roleId;
    if (entity.roleId != null) {
      if (entity.roleId === 5) {
        dto.pageSubtitle = 'UserType: Parents Only';
      } else if (entity.roleId === 4) {
        dto.pageSubtitle = 'UserType: Sitters Only';
      } else {
        dto.pageSubtitle = 'UserType: All Users';
      }
    }
    dto.maxRedemptions = entity.useLimit?.toString(10);
    dto.firstTimeBooking = entity.firstTimeBooking;
    dto.babysittingFee = entity.babysittingFee;
    entity.bookingTypes?.forEach(item => {
      const index = dto.verticalTypes.findIndex(
        types => types.id === item.bookingTypeId,
      );

      if (index !== -1) {
        dto.verticalTypes[index].selected = true;
      }
    });

    entity.voucherCodeFees?.forEach(item => {
      const setupIndex = dto.setupFeeFrequencies.findIndex(
        types => types.id === item.voucherFeeId,
      );
      const installmentIndex = dto.installmentFeeFrequencies.findIndex(
        types => types.id === item.voucherFeeId,
      );

      if (dto.setupFeeFrequencies[setupIndex] != null) {
        dto.setupFee = true;
        dto.setupFeeFrequencies[setupIndex].selected = true;
      }
      if (dto.installmentFeeFrequencies[installmentIndex] != null) {
        dto.installmentFee = true;
        dto.installmentFeeFrequencies[installmentIndex].selected = true;
      }
    });

    if (entity.begins != null) {
      dto.activeFrom = moment(entity.begins, DateUtil.dateFormat);
    }

    if (entity.useBy != null) {
      dto.useBy = moment(entity.useBy, DateUtil.dateFormat);
    }

    if (entity.expires != null) {
      dto.redeemBy = moment(entity.expires, DateUtil.dateFormat);
    }

    dto.comment = entity.comment;

    return dto;
  }

  function getEntityFromUiModel(uiModel: UiModel): VoucherCode {
    const entity: VoucherCode = {};

    entity.id = uiModel.id;
    entity.active = true;
    entity.code = uiModel.code;
    entity.type = uiModel.type;
    entity.description = uiModel.description;
    entity.comment = uiModel.comment;
    entity.firstTimeBooking = uiModel.firstTimeBooking;
    entity.percentDiscount = uiModel.percentDiscount;

    if (uiModel.value != null && uiModel.value.length > 0) {
      entity.value = parseInt(uiModel.value, 10);
    }

    if (uiModel.maxRedemptions != null && uiModel.maxRedemptions.length > 0) {
      entity.useLimit = parseInt(uiModel.maxRedemptions, 10);
    } else {
      entity.useLimit = 0;
    }

    if (uiModel.userType != null && uiModel.userType > 0) {
      entity.roleId = uiModel.userType;
    }

    if (uiModel.babysittingFee) {
      entity.babysittingFee = true;
      const bookingTypes: Array<VoucherBookingType> = [];

      uiModel.verticalTypes.forEach(item => {
        if (item.selected) {
          bookingTypes.push({
            bookingTypeId: item.id,
          });
        }
      });
      entity.bookingTypes = bookingTypes;
    }

    const partners: Array<VoucherCodePartner> = [];

    uiModel.partners.forEach(item => {
      if (item.selected) {
        partners.push({
          partner: {
            id: item.id,
          },
        });
      }
    });
    entity.partners = partners;

    if (uiModel.setupFee) {
      uiModel.setupFeeFrequencies.forEach(item => {
        if (item.selected) {
          if (item.id === 7) {
            entity.monthlySetup = true;
          } else if (item.id === 5) {
            entity.annualSetup = true;
          } else if (item.id === 15) {
            entity.accessPassSetup = true;
          } else if (item.id === 16) {
            entity.quarterlySetup = true;
          }
        }
      });
    }

    if (uiModel.installmentFee) {
      uiModel.installmentFeeFrequencies.forEach(item => {
        if (item.selected) {
          switch (item.id) {
            case 11:
              entity.monthlyInstallment = true;
              break;
            case 9:
              entity.annualInstallment = true;
              break;
            case 14:
              entity.quarterlyInstallment = true;
              break;
            default:
              break;
          }
        }
      });
    }

    if (uiModel.activeFrom != null) {
      entity.begins = DateUtil.getDateMidnightString(uiModel.activeFrom);
    }

    if (uiModel.useBy != null) {
      entity.useBy = DateUtil.getDateMidnightString(uiModel.useBy);
    }

    if (uiModel.redeemBy != null) {
      entity.expires = DateUtil.getDateMidnightString(uiModel.redeemBy);
    }

    return entity;
  }

  const [uiModel, dispatch] = useReducer(reducer, defaultUiModel());

  const createVoucherCalls = (voucher: VoucherCode) => {
    const numberOfCodes =
      uiModel.numberOfCodes != null && parseInt(uiModel.numberOfCodes, 10);
    const promises: Promise<any>[] = [];

    if (numberOfCodes > 0) {
      voucher.codePrefix = voucher.code ? `${voucher.code}-` : null;
      voucher.code = null;
      for (let i = 0; i < numberOfCodes; i += 1) {
        promises.push(VoucherCodeService.create(voucher));
      }
    } else {
      promises.push(VoucherCodeService.create(voucher));
    }

    return promises;
  };

  const updateVoucher = async () => {
    const voucher: VoucherCode = getEntityFromUiModel(uiModel);

    try {
      if (voucher.id != null) {
        await VoucherCodeService.update(voucher);
      } else {
        await Promise.all(createVoucherCalls(voucher));
      }

      setSideEffect({
        type: 'route',
        value: '/vouchers',
      });
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error creating the voucher code(s).',
      );

      setSideEffect({ type: 'error', value: displayedError.message });
    }
  };

  function validate(): string {
    let error = '';

    if (
      uiModel.activeFrom != null &&
      uiModel.useBy !== null &&
      uiModel.useBy.isBefore(uiModel.activeFrom)
    ) {
      error = 'Use by cannot be before voucher is active';
    }

    if (
      uiModel.activeFrom !== null &&
      uiModel.redeemBy !== null &&
      uiModel.redeemBy.isBefore(uiModel.activeFrom)
    ) {
      error = 'Redeem by cannot be before voucher is active';
    }

    return error;
  }

  const update = () => {
    const error = validate();

    if (error.length > 0) {
      setSideEffect({
        type: 'error',
        value: error,
      });
    } else {
      updateVoucher();
    }
  };

  const deactivateVoucher = async () => {
    const entity: VoucherCode = getEntityFromUiModel(uiModel);
    const params = {
      id: entity.id,
      active: false,
    };

    try {
      await VoucherCodeService.patch(params);
      setSideEffect({
        type: 'route',
        value: '/vouchers',
      });
    } catch (error) {
      const displayedError = DisplayService.getErrorResponse(
        error,
        'There was an error deactivating the voucher code.',
      );

      setSideEffect({
        type: 'error',
        value: displayedError.message,
      });
    }
  };

  useEffect(() => {
    const getPartners = async (model, entity) => {
      await PartnerService.getByFilter({}).then(partnerResponse => {
        model.partners = [];
        partnerResponse.data.data.forEach(partner => {
          let selected = false;

          if (entity) {
            const index = entity.partners.findIndex(
              item => item.partner.id === partner.id,
            );

            selected = index !== -1;
          }

          model.partners.push({
            id: partner.id,
            label: partner.name,
            selected,
          });
        });
      });
    };

    if (voucherId != null && voucherId > 0) {
      const getVoucher = async () => {
        try {
          const response = await VoucherCodeService.get(voucherId);
          const entity = response.data.data;
          const existingModel = getUiModelFromEntity(entity);

          await getPartners(existingModel, entity);

          dispatch({
            type: 'update',
            value: existingModel,
          });
        } catch (error) {
          setSideEffect({ type: 'route', value: '/voucher-not-found' });
        }
      };

      getVoucher();
    } else {
      const getDefault = async () => {
        const existingModel = defaultUiModel();

        await getPartners(existingModel, null);

        dispatch({
          type: 'update',
          value: existingModel,
        });
      };

      getDefault();
    }
  }, []);

  return { uiModel, dispatch, update, deactivateVoucher, sideEffect };
}

export default useViewModel;

interface UiModel {
  edit: boolean;
  active?: boolean;
  id?: number;
  type?: string;
  code?: string;
  numberOfCodes?: string;
  description?: string;
  value?: string;
  maxRedemptions?: string;
  partners?: Array<SelectableItem>;
  userType?: number;
  userTypes?: Array<KeyItem>;
  percentDiscount?: boolean;
  firstTimeBooking?: boolean;
  babysittingFee?: boolean;
  verticalTypes?: Array<SelectableItem>;
  setupFee?: boolean;
  setupFeeFrequencies?: Array<SelectableItem>;
  installmentFee?: boolean;
  installmentFeeFrequencies?: Array<SelectableItem>;
  activeFrom?: moment.Moment;
  redeemBy?: moment.Moment;
  useBy?: moment.Moment;
  comment?: string;
  error?: string;
  pageTitle?: string;
  pageSubtitle?: string;
}

interface KeyItem {
  id: number;
  label: string;
}

interface SelectableItem extends KeyItem {
  selected: boolean;
}

type ActionType =
  | { type: 'type'; value: string }
  | { type: 'code'; value: string }
  | { type: 'numberOfCodes'; value: string }
  | { type: 'description'; value: string }
  | { type: 'value'; value: string }
  | { type: 'maxRedemptions'; value: string }
  | { type: 'userType'; value: number }
  | { type: 'percentDiscount'; value: boolean }
  | { type: 'firstTimeBooking'; value: boolean }
  | { type: 'babysittingFee'; value: boolean }
  | { type: 'partners'; value: boolean; id: number }
  | { type: 'verticalType'; value: boolean; id: number }
  | { type: 'setupFee'; value: boolean }
  | { type: 'setupFeeFrequency'; value: boolean; id: number }
  | { type: 'installmentFee'; value: boolean }
  | { type: 'installmentFeeFrequency'; value: boolean; id: number }
  | { type: 'activeFrom'; value: moment.Moment }
  | { type: 'redeemBy'; value: moment.Moment }
  | { type: 'useBy'; value: moment.Moment }
  | { type: 'comment'; value: string }
  | { type: 'update'; value: UiModel };

type SideEffect =
  | { type: 'error'; value: string }
  | { type: 'route'; value: string }
  | { type: 'onLoad' };
