import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { TeachingSkill } from '../types/TeachingSkill';

const URL = '/v2/teaching-skills';

class TeachingSkillsService {
  static getByFilter(config?: AxiosRequestConfig): Promise<PagedResponse<TeachingSkill>> {
    return axios.get(URL, config);
  }
}

export { TeachingSkillsService };
