import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { NpsPrompt } from '../types/NpsPrompt';

const URL = '/v2/nps-prompts';

class NpsPromptService {
  static update(
    id: number,
    data: NpsPrompt,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<NpsPrompt>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}
export { NpsPromptService };
