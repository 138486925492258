import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';

import { School, SchoolService, SitterService } from 'us-web-services';
import SchoolEditForm from './SchoolEditForm';
import PageStyles from '../../styles/PageStyles';
import useAuthentication from '../../store/useAuthentication';

function SchoolEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const adminUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { history, match } = props;
  const [school, setSchool] = useState<School>({});
  const [sitterCount, setSitterCount] = useState(0);

  useEffect(() => {
    if (match.params.id) {
      const getData = async () => {
        const schoolId = Number.parseInt(match.params.id, 10);
        const response = await SchoolService.get(schoolId);

        if (response.data.data.id) {
          setSchool(response.data.data);
        } else {
          setSchool({
            id: null,
          });
        }

        const sitterConfig = {
          params: {
            limit: 1,
            schoolId,
            userId: adminUser.id,
          },
        };

        const sitterResponse = await SitterService.getByFilter(sitterConfig);

        setSitterCount(sitterResponse.data.meta.size);
      };

      getData();
    }
  }, [match.params.id, adminUser.id]);

  const navigateToList = () => {
    history.push('/schools');
  };

  const saveSuccess = () => {
    toast.success(`${school.name} updated successfully`);
    navigateToList();
  };

  const saveSchool = async () => {
    try {
      if (school.id) {
        await SchoolService.patch(school.id, school);
      } else {
        await SchoolService.create(school);
      }
      saveSuccess();
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        {school.name} (sitters: {sitterCount})
      </Typography>
      {school.id ? (
        <SchoolEditForm school={school} setSchool={setSchool} />
      ) : (
        'School not found'
      )}{' '}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveSchool}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(SchoolEdit);
