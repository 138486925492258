import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import toast from 'react-hot-toast';

import { CsvParserService } from 'us-web-services';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import PageStyles from '../../../styles/PageStyles';
import DisplayService from '../../../util/DisplayService';

function BatchMailchimp() {
  const [updateCount, setUpdateCount] = useState(0);
  const [file, setFile] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(null);
  const fileInput = React.useRef<HTMLInputElement>();
  const { classes } = PageStyles();

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const processFile = async () => {
    if (fileInput.current.files[0]) {
      setUploadSuccessful(null);
      setFile(fileInput.current.files[0]);
      const reader = new window.FileReader();

      reader.onload = e => {
        const data = CsvParserService.parse(e.target.result.toString(), ',', false);

        setUpdateCount(data.length);
      };

      reader.readAsText(fileInput.current.files[0]);
    } else {
      toast.error('Uploaded file does not exist');
    }
  };

  const resetAfterUpload = () => {
    setFile(null);
    setUpdateCount(0);
    setUploadSuccessful(true);
  };

  const showError = useCallback(error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an unknown error replacing the safe list.',
      false,
    );

    displayedError.autoDismiss = false;
    toast.error(displayedError.message);
    setUploadSuccessful(false);
  }, []);

  const uploadFile = async url => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);
      setShowConfirm(false);
      setUploading(true);

      await axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(resetAfterUpload)
        .catch(showError)
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const subscribeUsers = () => {
    const url = '/v2/batch-mailchimp';

    uploadFile(url);
  };

  const unsubscribeUsers = () => {
    const url = '/v2/batch-mailchimp/unsubscribe';

    uploadFile(url);
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Ready to upload?</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to update {updateCount} subscriptions?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirm} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={subscribeUsers}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            Subscribe Users
          </Button>
          <Button
            onClick={unsubscribeUsers}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            UnSubscribe Users
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Update Mailchimp Subscriptions
      </Typography>
      <p>
        Upload a .csv file of user IDs (one per line) to <b>update</b> user MailChimp subscriptions.
      </p>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <input
            id='csv-file-mailchimp'
            type='file'
            ref={fileInput}
            onChange={processFile}
          />
        </Grid>
        <Grid item lg={4}>
          {updateCount > 0 && (
            <Box>
              Update {updateCount} {updateCount === 1 ? 'user' : 'users'}
            </Box>
          )}
        </Grid>
        <Grid item lg={4}>
          {updateCount > 0 && (
            <Button
              color='primary'
              disabled={uploading}
              onClick={openConfirm}
              variant='contained'
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          )}
          {uploadSuccessful === true && (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faCheckCircle}
              />
              <span>Upload successful!</span>
            </div>
          )}
          {uploadSuccessful === false && (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faTimesCircle}
              />
              <span>Error occurred</span>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withRouter(BatchMailchimp);
