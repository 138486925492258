import React, { useState } from 'react';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Location, LocationService, SchoolService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function SchoolCreate(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [location, setLocation] = useState<Location>(null);
  const [locationInput, setLocationInput] = useState(null);

  const getLocations = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (locationInput) {
      contentConfig.params.name = locationInput;
    }

    const locationOptions = [];

    try {
      const locationsResponse = (await LocationService.getByFilter(contentConfig)).data;
      const locationsData = locationsResponse.data;

      locationsData.map(locationData =>
        locationOptions.push({
          id: locationData.id,
          value: locationData.id,
          name: locationData.name,
          label: `${locationData.name}, ${locationData.state.code}`,
          state: locationData.state,
          region: locationData.region,
        }),
      );
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }

    return locationOptions;
  };

  const updateName = event => {
    setName(event.target.value);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
  };

  const updateLocationInput = newValue => {
    setLocationInput(newValue);
  };

  const navigateToList = () => {
    history.push('/schools');
  };

  const saveSuccess = school => {
    toast.success(`${school.name} created successfully`);
    navigateToList();
  };

  const findByAlias = async schoolAlias => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        alias: schoolAlias,
      },
    };

    const response = await SchoolService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const showError = errText => {
    toast.error(errText);
  };

  const isInputValid = async () => {
    let isValid = true;

    if (!name) {
      showError('School name is required');
      isValid = false;
    }

    if (!location) {
      showError('Please pick a location');
      isValid = false;
    }

    if (await findByAlias(alias)) {
      showError('Alias already exists');
      isValid = false;
    }

    return isValid;
  };

  const createSchool = async () => {
    if (await isInputValid()) {
      const school = {
        name,
        alias,
        location: {
          id: location.id,
          name: location.name,
          state: location.state,
          region: location.region,
        },
        city: location.name,
        state: location.state.code,
      };

      try {
        await SchoolService.create(school);
        saveSuccess(school);
      } catch (e) {
        toast.error(e.response.data.errors[0].message);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Add School
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id='name'
            name='name'
            label='Name'
            required
            fullWidth
            value={name}
            onChange={updateName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='alias'
            name='alias'
            label='Alias'
            fullWidth
            value={alias}
            onChange={updateAlias}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' color='textPrimary'>
            Location
          </Typography>
          <AsyncSelect
            id='location'
            required
            loadOptions={getLocations}
            defaultOptions
            onInputChange={updateLocationInput}
            onChange={setLocation}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={createSchool}
          className={classes.button}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(SchoolCreate);
