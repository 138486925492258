import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardContent,
  Modal,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

import { UserVoucher, UserVoucherService } from 'us-web-services';
import CardStyles from '../../styles/CardStyles';
import DisplayService from '../../util/DisplayService';

function UserVouchers(props) {
  const { userId } = props;
  const { classes } = CardStyles();
  const [userVouchers, setUserVouchers] = useState<UserVoucher[]>([]);
  const [userVoucher, setUserVoucher] = useState('');

  const showError = (error, defaultMessage) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      defaultMessage,
    );

    toast.error(displayedError.message);
  };

  const getVouchers = useCallback(async () => {
    const voucherFilter = {
      params: {
        userId,
        limit: 100,
      },
    };

    try {
      const vouchersResponse = (await UserVoucherService.getByFilter(voucherFilter)).data;

      setUserVouchers(vouchersResponse.data);
    } catch (error) {
      showError(error, 'Vouchers could not be loaded.');
      setUserVouchers([]);
    }
  }, [userId]);

  const addVoucher = async () => {
    if (userVoucher) {
      const voucherParams = {
        userId,
        voucher: {
          code: userVoucher,
        },
      };

      try {
        await UserVoucherService.create(voucherParams);
        setUserVoucher('');
        getVouchers();
      } catch (error) {
        showError(
          error,
          'Voucher not added. Please verify the voucher code you are using.',
        );
      }
    }
  };
  const getValue = (value, isPercent) =>
    isPercent ? `${value}%` : `$${value}`;
  const isExpired = dateString => moment(dateString).isBefore(new Date());
  const isInactive = voucher =>
    !voucher.active || isExpired(voucher.validUntil) || !voucher.currentValue;
  const updateVoucher = event => {
    setUserVoucher(event.target.value);
  };

  const DeactivateButton = deactivateProps => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const deactivateVoucher = async () => {
      const params = {
        id: deactivateProps.voucher.id,
        userId,
        active: false,
      };

      try {
        await UserVoucherService.patch(params.id, params);
        getVouchers();
      } catch (error) {
        showError(error, 'Failed to deactivate voucher');
      }
    };

    return (
      <>
        <Modal
          aria-labelledby='deactivate-voucher-modal'
          aria-describedby='Confirm voucher deactivation'
          open={open}
          onClose={handleClose}
        >
          <div className={classes.paper}>
            <h2 id='modal-title'>Confirm Voucher Deactivation</h2>
            <p>
              Are you sure you want to deactivate this voucher for this user?
              You cannot undo this.
            </p>
            <Button color='primary' onClick={deactivateVoucher}>
              Confirm
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </Modal>
        <Button color='primary' onClick={showModal}>
          <HighlightOff />
        </Button>
      </>
    );
  };

  useEffect(() => {
    getVouchers();
  }, [getVouchers]);

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Typography component='h5' variant='h5' style={{ marginBottom: 15 }}>
          User Vouchers
        </Typography>
        <TextField
          id='add-voucher-field'
          label='Voucher Code'
          onChange={updateVoucher}
          style={{ marginRight: 15 }}
          value={userVoucher}
        />
        <Button
          id='add-voucher-button'
          color='primary'
          disabled={!userVoucher}
          onClick={addVoucher}
          style={{ padding: 15 }}
          variant='contained'
        >
          Add Voucher
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Deactivate</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Original Value</TableCell>
              <TableCell>Remaining Value</TableCell>
              <TableCell>Usage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userVouchers.map(voucher => (
              <TableRow key={voucher.id}>
                <TableCell>
                  {!isInactive(voucher) ? (
                    <DeactivateButton voucher={voucher} />
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>{voucher.voucher.code}</TableCell>
                <TableCell>{voucher.voucher.description}</TableCell>
                <TableCell>
                  {getValue(voucher.total, voucher.voucher.percentDiscount)}
                </TableCell>
                <TableCell>
                  {getValue(
                    voucher.currentValue,
                    voucher.voucher.percentDiscount,
                  )}
                </TableCell>
                <TableCell style={{ color: 'red' }}>
                  {isInactive(voucher) ? 'Used/Expired/Deactivated' : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default UserVouchers;
