import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { Affiliation, CityService, LocationRegionsService } from 'us-web-services';

interface Props {
  affiliation: Affiliation;
  setAffiliation(affiliation: Affiliation): void;
}

export default function AffiliationEditForm(props: Props) {
  const { affiliation, setAffiliation } = props;
  const [name, setName] = useState(affiliation.name);
  const [alias, setAlias] = useState(affiliation.alias);
  const [cityState, setCityState] = useState({
    name: affiliation.city,
    state: affiliation.state,
  });
  const [region, setRegion] = useState(affiliation.region);
  const [cityInput, setCityInput] = useState(null);
  const [regionInput, setRegionInput] = useState(null);

  const getCities = async () => {
    const paramList = new URLSearchParams();

    paramList.append('limit', '25');
    paramList.append('page', '1');

    if (cityInput) {
      paramList.append('q', `name.startsWith('${cityInput}')`);
    }

    const config = {
      params: paramList,
    };

    const citiesResponse = await CityService.getByFilter(config);

    const citiesData = citiesResponse.data.data;

    const cityOptions = [];

    citiesData.map(cityData =>
      cityOptions.push({
        id: cityData.id,
        name: cityData.name,
        value: cityData.id,
        label: `${cityData.name}, ${cityData.state}`,
        state: cityData.state,
      }),
    );

    return cityOptions;
  };

  const getRegions = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (regionInput) {
      contentConfig.params.name = regionInput;
    }

    const regionResponse = await LocationRegionsService.getByFilter(
      contentConfig,
    );

    const regionData = regionResponse.data.data;

    const regionOptions = [];

    regionData.map(data =>
      regionOptions.push({
        id: data.id,
        name: data.name,
        value: data.id,
        label: data.name,
      }),
    );

    return regionOptions;
  };

  const updateName = event => {
    setName(event.target.value);
    affiliation.name = event.target.value;
    setAffiliation(affiliation);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
    affiliation.alias = event.target.value;
    setAffiliation(affiliation);
  };

  const updateCityState = value => {
    setCityState(value);
    affiliation.city = value.name;
    affiliation.state = value.state;
    setAffiliation(affiliation);
  };

  const updateRegion = value => {
    setRegion(value);
    affiliation.region = value;
    setAffiliation(affiliation);
  };

  const updateCityInput = newValue => {
    setCityInput(newValue);
  };

  const updateRegionInput = newValue => {
    setRegionInput(newValue);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          onChange={updateName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='alias'
          name='alias'
          label='Alias'
          fullWidth
          value={alias}
          onChange={updateAlias}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          City and State
        </Typography>
        <AsyncSelect
          id='citystate'
          required
          loadOptions={getCities}
          defaultOptions
          defaultInputValue={`${cityState.name}, ${cityState.state}`}
          onInputChange={updateCityInput}
          onChange={updateCityState}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle1' color='textPrimary'>
          Region
        </Typography>
        <AsyncSelect
          id='region'
          required
          loadOptions={getRegions}
          defaultOptions
          defaultInputValue={region.name}
          onInputChange={updateRegionInput}
          onChange={updateRegion}
        />
      </Grid>
    </Grid>
  );
}
