import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types';
import { HeicData } from '../types/api/HeicData';

const URL = '/heic-convert'

class HeicConvertService {
  static convert(
    data: FormData,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<HeicData>> {
    return axios.post(URL, data, config);
  }

  static isHeic(buffer: Uint8Array): boolean {
    if (!buffer || buffer.length < 24) {
      return false;
    }

    return buffer[20] === 0x68 && buffer[21] === 0x65 && buffer[22] === 0x69 && buffer[23] === 0x63;
  }
}

export { HeicConvertService };
