export interface SitterProfileStatusItem {
  complete?: boolean;
  label?: string;
  subLabel?: string;
  url?: string;
}

export interface SitterProfileStatusMessage {
  title?: string;
  body?: string;
  url?: string;
}

export enum HiddenReasonType {
  UNHIDDEN = 0,
  PENDING_APPROVAL = 2,
  PHOTO = 4,
  SOLICITOR = 5,
  DECISION = 6,
  INCOMPLETE_PROFILE = 7,
  READY_FOR_REVIEW = 14
}

export interface HiddenReason {
  id?: number;
  type?: HiddenReasonType;
}

export interface SitterProfileStatus {
  sitterId?: number;
  backgroundCheckPurchased?: SitterProfileStatusItem;
  backgroundCheckCleared?: SitterProfileStatusItem;
  rate?: SitterProfileStatusItem;
  about?: SitterProfileStatusItem;
  video?: SitterProfileStatusItem;
  bankAccount?: SitterProfileStatusItem;
  picture?: SitterProfileStatusItem;
  essentialProfileComplete?: boolean;
  hiddenReason?: HiddenReason;
  profileStatusMessage?: SitterProfileStatusMessage;
}
