import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/video-moderation';

class VideoModerationService {
  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(URL, config);
  }

  static patch(data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${URL}/${data.id}`, data, config);
  }
}

export { VideoModerationService };
