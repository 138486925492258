import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { toast } from 'react-hot-toast';

import {
  FacebookConnection,
  FacebookConnectionService,
  GoogleConnection,
  GoogleConnectionService,
  LoginAccount,
  LoginAccountService,
  User,
} from 'us-web-services';
import DateUtil from '../../util/DateUtil';
import UserConnectionConfirmModal from './UserConnectionConfirmModal';
import DisplayService from '../../util/DisplayService';

interface Props {
  user: User;
}

function UserSocialConnections(props: Props) {
  const { user } = props;
  const [removeFacebookModal, setRemoveFacebookModal] = useState(false);
  const [removeGoogleModal, setRemoveGoogleModal] = useState(false);
  const [facebookConnection, setFacebookConnection] = useState<FacebookConnection>({});
  const [googleConnection, setGoogleConnection] = useState<GoogleConnection>({});
  const [facebookLoginAccount, setFacebookLoginAccount] = useState<LoginAccount>({});
  const [googleLoginAccount, setGoogleLoginAccount] = useState<LoginAccount>({});

  const getLoginAccounts = useCallback(async () => {
    const contentConfig = {
      params: {
        userId: user.id,
      },
    };
    const loginAccountResponse = await LoginAccountService.getByFilter(
      contentConfig,
    );

    loginAccountResponse.data.data.forEach(loginAccount => {
      if (loginAccount.loginMethod === 'facebook') {
        setFacebookLoginAccount(loginAccount);
      } else if (loginAccount.loginMethod === 'google') {
        setGoogleLoginAccount(loginAccount);
      }
    });
  }, [user.id]);

  const getFacebookConnection = useCallback(async () => {
    const contentConfig = {
      params: {
        userId: user.id,
      },
    };
    const facebookConnectionResponse =
      await FacebookConnectionService.getByFilter(contentConfig);

    setFacebookConnection(facebookConnectionResponse.data.data[0]);
  }, [user.id]);

  const getGoogleConnection = useCallback(async () => {
    const contentConfig = {
      params: {
        userId: user.id,
      },
    };
    const googleConnectionResponse = await GoogleConnectionService.getByFilter(
      contentConfig,
    );

    setGoogleConnection(googleConnectionResponse.data.data[0]);
  }, [user.id]);

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error removing this connection.',
    );

    toast.error(displayedError.message);
  };

  const confirmRemoveFacebook = async () => {
    try {
      await FacebookConnectionService.delete(user.id);

      setRemoveFacebookModal(false);
      toast.success('Facebook connection removed');
      setFacebookConnection({});
    } catch (error) {
      showError(error);
    }
  };

  const confirmRemoveGoogle = async () => {
    try {
      await GoogleConnectionService.delete(
        googleConnection.googleUser.id,
      );

      setRemoveGoogleModal(false);
      toast.success('Google connection removed');
      setGoogleConnection({});
    } catch (error) {
      showError(error);
    }
  };

  const openRemoveGoogleModal = () => setRemoveGoogleModal(true);
  const openRemoveFacebookModal = () => setRemoveFacebookModal(true);

  useEffect(() => {
    getLoginAccounts();
    if (user.facebookId) {
      getFacebookConnection();
    }
    if (user.googleUserId) {
      getGoogleConnection();
    }
  }, [
    user.facebookId,
    user.googleUserId,
    getFacebookConnection,
    getGoogleConnection,
    getLoginAccounts,
  ]);

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Typography component='h5' variant='h5' style={{ marginBottom: 15 }}>
          Social Connections
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Platform</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Connect Date</TableCell>
              <TableCell>Initial Name</TableCell>
              <TableCell>Initial Contacts</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {facebookConnection.userId ? (
              <TableRow key='facebook'>
                <TableCell>
                  <a
                    href={`https://www.facebook.com/${user.facebookId}`}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: 16 }}
                      icon={faFacebook}
                    />
                  </a>
                </TableCell>
                <TableCell>
                  {moment(facebookConnection.facebookUser.expireDate).isBefore(
                    moment(),
                  )
                    ? 'Token Expired'
                    : 'Token Active'}
                </TableCell>
                <TableCell>
                  {DateUtil.getReadableDateTime(facebookLoginAccount.created)}
                </TableCell>
                <TableCell>{facebookLoginAccount.name}</TableCell>
                <TableCell>{facebookLoginAccount.connectionCount}</TableCell>
                <TableCell>
                  <UserConnectionConfirmModal
                    connection='facebook'
                    confirmAction={confirmRemoveFacebook}
                    open={removeFacebookModal}
                    setOpen={setRemoveFacebookModal}
                  />
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={openRemoveFacebookModal}
                  >
                    Disconnect
                  </Button>
                </TableCell>
              </TableRow>
            ) : null}
            {googleConnection.userId ? (
              <TableRow key='google'>
                <TableCell>
                  <FontAwesomeIcon style={{ fontSize: 16 }} icon={faGoogle} />
                </TableCell>
                <TableCell>
                  {moment(googleConnection.googleUser.expireDate).isBefore(
                    moment(),
                  )
                    ? 'Token Expired'
                    : 'Token Active'}
                </TableCell>
                <TableCell>
                  {DateUtil.getReadableDateTime(googleLoginAccount.created)}
                </TableCell>
                <TableCell>{googleLoginAccount.name}</TableCell>
                <TableCell>{googleLoginAccount.connectionCount}</TableCell>
                <TableCell>
                  <UserConnectionConfirmModal
                    connection='google'
                    confirmAction={confirmRemoveGoogle}
                    open={removeGoogleModal}
                    setOpen={setRemoveGoogleModal}
                  />
                  <Button
                    size='small'
                    variant='outlined'
                    color='secondary'
                    onClick={openRemoveGoogleModal}
                  >
                    Disconnect
                  </Button>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default UserSocialConnections;
