import React from 'react';
import { Button, Paper, Grid, TextField, Typography } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import { College, CollegeService } from 'us-web-services';
import StatesConstant from 'src/constants/States.constant';
import useInput from '../../hooks/useInput';
import PageStyles from '../../styles/PageStyles';

function CollegeCreate(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const name = useInput('');
  const alias = useInput('');
  const urlAlias = useInput('');
  const street = useInput('');
  const city = useInput('');
  const state = useInput('');
  const zip = useInput('');
  const latitude = useInput('');
  const longitude = useInput('');

  const navigateToList = () => {
    history.push('/colleges');
  };

  const saveSuccess = (college: College) => {
    toast.success(`${college.name} created successfully`);
    navigateToList();
  };

  const findByName = async (collegeName: string) => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        name: collegeName,
      },
    };

    const response = await CollegeService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const findByAlias = async (collegeAlias: string) => {
    const contentConfig = {
      params: {
        limit: 1,
        page: 1,
        alias: collegeAlias,
      },
    };

    const response = await CollegeService.getByFilter(contentConfig);

    return response.data.data.length;
  };

  const showError = (errText: string) => {
    toast.error(errText);
  };

  const isInputValid = async () => {
    let isValid = true;

    if (!name.value) {
      showError('College name is required');
      isValid = false;
    }

    if (await findByName(name.value)) {
      showError('Name already exists');
      isValid = false;
    }

    if (await findByAlias(alias.value)) {
      showError('Alias already exists');
      isValid = false;
    }

    if (!StatesConstant.some(s => s.code === state.value)) {
      showError('State is invalid');
      isValid = false;
    }

    const zipRegex = /^\d{5}$/;

    if (!zipRegex.test(zip.value)) {
      showError('Zip is invalid');
      isValid = false;
    }

    if (+latitude.value > 90 || +latitude.value < -90) {
      showError('Latitude is invalid');
      isValid = false;
    }
    if (+longitude.value > 180 || +longitude.value < -180) {
      showError('Longitude is invalid');
      isValid = false;
    }

    return isValid;
  };

  const createCollege = async () => {
    if (await isInputValid()) {
      const college: College = {
        name: name.value,
        alias: alias.value,
        urlAlias: urlAlias.value,
        street: street.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
        latitude: latitude.value,
        longitude: longitude.value,
      };

      try {
        await CollegeService.create(college);
        saveSuccess(college);
      } catch (e) {
        toast.error(e.response.data.errors[0].message);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Add College
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField id='name' name='name' label='Name' fullWidth {...name} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='alias'
            name='alias'
            label='Alias'
            fullWidth
            {...alias}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='urlAlias'
            name='urlAlias'
            label='URL Alias'
            fullWidth
            {...urlAlias}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='street'
            name='street'
            label='Street'
            fullWidth
            {...street}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField id='city' name='city' label='City' fullWidth {...city} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='state'
            name='state'
            label='State'
            fullWidth
            {...state}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField id='zip' name='zip' label='Zip' fullWidth {...zip} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='latitude'
            name='latitude'
            label='Latitude'
            fullWidth
            {...latitude}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='longitude'
            name='longitude'
            label='Longitude'
            fullWidth
            {...longitude}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={createCollege}
          className={classes.button}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(CollegeCreate);
