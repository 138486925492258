import React, { useState } from 'react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import InputLabel from '@mui/material/InputLabel';
import { AffiliationService } from 'us-web-services';
import PageStyles from '../../styles/PageStyles';

function AffiliationMerge(props: RouteComponentProps) {
  const { classes } = PageStyles();
  const { history } = props;
  const [affiliationInput, setAffiliationInput] = useState(null);
  const [selectedAffInto, setSelectedAffInto] = useState(null);
  const [selectedAffFrom, setSelectedAffFrom] = useState([]);

  const getAffiliations = async () => {
    const contentConfig = {
      params: {
        limit: 25,
        name: undefined,
        page: 1,
        sort: 'id',
        'id.dir': 'asc',
      },
    };

    if (affiliationInput) {
      contentConfig.params.name = affiliationInput;
    }

    const affResponse = await AffiliationService.getByFilter(contentConfig);

    const affiliations = affResponse.data.data;

    const options = [];

    affiliations.map(affData =>
      options.push({
        id: affData.id,
        name: affData.name,
        value: affData.id,
        label: affData.name,
      }),
    );

    return options;
  };

  const updateAffiliationInput = newValue => {
    setAffiliationInput(newValue);
  };

  const updateAffiliationFrom = value => {
    setSelectedAffFrom(value);
  };

  const navigateToList = () => {
    history.push('/affiliations');
  };

  const saveSuccess = (affiliationInto, totalAffiliations) => {
    toast.success(
      `Successfully merged ${totalAffiliations} affiliations into ${affiliationInto}`,
    );
    navigateToList();
  };

  const mergeAffiliations = async () => {
    if (!selectedAffInto) {
      toast.error('Please pick an Affiliation to Merge Into');

      return;
    }

    if (!selectedAffFrom.length) {
      toast.error('Please pick Affiliations to Merge From');

      return;
    }

    const affMergeFromIds = [];

    selectedAffFrom.forEach(x => {
      affMergeFromIds.push(x.id);
    });

    selectedAffInto.mergeFrom = affMergeFromIds;

    try {
      await AffiliationService.patch(selectedAffInto.id, selectedAffInto);
      saveSuccess(selectedAffInto.name, affMergeFromIds.length);
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h6' gutterBottom>
        Merge Affiliations
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge Into</InputLabel>
          <AsyncSelect
            id='affInto'
            required
            loadOptions={getAffiliations}
            defaultOptions
            onInputChange={updateAffiliationInput}
            onChange={setSelectedAffInto}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id='type-label'>Merge From</InputLabel>
          <AsyncSelect
            isMulti
            id='affFrom'
            required
            loadOptions={getAffiliations}
            defaultOptions
            onInputChange={updateAffiliationInput}
            onChange={updateAffiliationFrom}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={navigateToList}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={mergeAffiliations}
          className={classes.button}
        >
          Submit
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(AffiliationMerge);
