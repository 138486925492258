import axios, { AxiosRequestConfig } from 'axios';

const API_URL = '/v2/schools';

class SchoolService {
  static get(id: any, config?: AxiosRequestConfig) {
    return axios.get(`${API_URL}/${id}`, config);
  }

  static getByFilter(config?: AxiosRequestConfig) {
    return axios.get(API_URL, config);
  }

  static patch(id: any, data: any, config?: AxiosRequestConfig) {
    return axios.patch(`${API_URL}/${id}`, data, config);
  }

  static create(data: any, config?: AxiosRequestConfig) {
    return axios.post(API_URL, data, config);
  }
}

export { SchoolService };
