import React, { useEffect, useState } from 'react';
import { Button, Fade, Modal, TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import { UserPlan } from 'us-web-services';
import DateUtil from '../../util/DateUtil';
import ModalStyles from '../../styles/ModalStyles';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  plan: UserPlan,
  updatePlan: (plan: any, planData: any) => void;
}

function PlanEdit(props: Props) {
  const { open, setOpen, plan, updatePlan } = props;
  const { classes } = ModalStyles();
  const [start, setStart] = useState(moment(plan.start));
  const [end, setEnd] = useState(moment(plan.end));

  const handleClose = () => {
    setOpen(false);
  };

  const savePlan = async () => {
    const planData = {
      id: plan.id,
      start: start.format(DateUtil.midnightDateFormat),
      end: end.format(DateUtil.midnightDateFormat),
    };

    updatePlan(plan, planData);
  };

  useEffect(() => {
    setStart(moment(plan.start));
    setEnd(moment(plan.end));
  }, [plan.id, plan.end, plan.start]);

  return (
    <Modal
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={classes.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id='plan-edit-modal-title'>Edit Plan Dates</h2>
          <FormLabel component='legend'>Start Date</FormLabel>
          <br />
          <DatePicker
            inputFormat={DateUtil.dateReadable}
            onChange={setStart}
            value={start}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
          <br /> <br />
          <FormLabel component='legend'>End Date</FormLabel>
          <br />
          <DatePicker
            inputFormat={DateUtil.dateReadable}
            onChange={setEnd}
            value={end}
            renderInput={pickerProps => <TextField {...pickerProps} />}
          />
          <br /> <br />
          <Button
            id='close'
            color='secondary'
            onClick={handleClose}
            variant='contained'
          >
            Close
          </Button>{' '}
          <Button
            id='save'
            color='primary'
            onClick={savePlan}
            variant='contained'
          >
            Save
          </Button>{' '}
        </div>
      </Fade>
    </Modal>
  );
}

export default PlanEdit;
