import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { AdminNoteService } from 'us-web-services';
import { CreateModal, Table } from '../common/Table';
import { TableInstance } from '../common/Table/Table';

interface Props {
  userId: number;
  adminUserId: number;
}

function UserAdminNotes(props: Props) {
  const { adminUserId, userId } = props;
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const tableRef = React.useRef<TableInstance>();
  const COLUMNS = useMemo(
    () => [
      {
        id: 'adminNotes',
        header: 'Admin Notes',
        columns: [
          {
            header: 'Note',
            accessorKey: 'comment',
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            },
          },
          {
            header: 'Created',
            accessorFn: rowData => {
              const createdDate = moment.unix(rowData.created).format('LLL');

              return rowData && rowData.created ? createdDate : '';
            },
            enableEditing: false,
          },
          {
            header: 'Created By',
            accessorKey: 'createdBy.name',
            enableEditing: false,
          },
        ],
      },
    ],
    [],
  );

  const updateNotes = () => tableRef.current?.fetchData();

  const addNote = async values => {
    const noteData = {
      user: {
        id: userId,
      },
      createdBy: {
        id: adminUserId,
      },
      comment: values.comment,
    };

    await AdminNoteService.create(noteData);
    updateNotes();
  };

  const editNote = async ({ row, values }) => {
    await AdminNoteService.update(row.original.id, {
      id: row.original.id,
      comment: values.comment,
    });
    updateNotes();
  };

  const params = () => [
    {
      key: 'userId',
      value: userId.toString(),
    },
  ];

  return (
    <div style={{ paddingTop: 10 }}>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={AdminNoteService.getByFilter}
        editAction={editNote}
        enableInlineEditing
        params={params}
        alwaysApplyParams
        addAction={() => setCreateModalOpen(true)}
      />
      <CreateModal
        columns={COLUMNS}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={addNote}
        title='Add Note'
        buttonText='Add'
      />
    </div>
  );
}

export default UserAdminNotes;
