import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { ParentRateSuggestion } from '../types';

const INTERNAL_URL = '/internal/parent-rate-suggestion';
const URL = '/v2/parent-rate-suggestion';

class ParentRateSuggestionService {
  static get(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentRateSuggestion>> {
    return axios.get(URL, config);
  }

  static getInternal(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ParentRateSuggestion>> {
    return axios.get(
      `http://${process.env.US_SERVICES_HOST}:${process.env.US_SERVICES_PORT}${INTERNAL_URL}`,
      config,
    );
  }
}

export { ParentRateSuggestionService };
