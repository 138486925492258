import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { MasterServiceAreaPlanPrice } from '../../types/master_service_area/MasterServiceAreaPlanPrice';

const URL = '/v2/master-service-area-plan-prices';

class MasterServiceAreaPlanPriceService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPlanPrice>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<MasterServiceAreaPlanPrice>> {
    return axios.get(URL, config);
  }

  static create(
    data: MasterServiceAreaPlanPrice,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPlanPrice>> {
    return axios.post(URL, data, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static patch(
    id: number,
    data: MasterServiceAreaPlanPrice,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<MasterServiceAreaPlanPrice>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { MasterServiceAreaPlanPriceService };
