import Paper from '@mui/material/Paper';
import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PromoService } from 'us-web-services';
import PageStyles from 'src/styles/PageStyles';
import { Table } from '../common/Table';

function PartnerLookup(props: RouteComponentProps) {
  const { history } = props;
  const { classes } = PageStyles();

  const COLUMNS = useMemo(
    () => [
      {
        id: 'partners',
        header: 'Partners',
        columns: [
          {
            header: 'ID',
            size: 20,
            accessorKey: 'id',
          },
          {
            header: 'Active',
            accessorKey: 'active',
          },
          {
            header: 'URL Alias',
            accessorKey: 'urlAlias',
          },
          {
            header: 'header',
            accessorKey: 'header',
          },
          {
            header: 'Logo',
            accessorFn: row => row.logoUrl,
            Cell: ({ cell }) => (
              <img alt='' src={cell.getValue()} style={{ width: 50 }} />
            ),
          },
        ],
      },
    ],
    [],
  );

  const addPartner = () => {
    history.push('/partner-create');
  };

  const editPartner = partner => {
    history.push(`/partners/${partner.id}`);
  };

  const params = (globalFilter: string) => [
    {
      key: 'q',
      value: `alias.startsWith('${globalFilter}')`,
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Table
        columns={COLUMNS}
        retrieveData={PromoService.getByFilter}
        params={params}
        editAction={editPartner}
        addAction={addPartner}
      />
    </Paper>
  );
}

export default withRouter(PartnerLookup);
