import axios, { AxiosRequestConfig } from 'axios';
import { Company } from '../../types/company/Company';
import { DataResponse, PagedResponse } from '../../types/api';

const URL = '/v2/companies';
const URL_COMPANY_TERMINATE = '/v2/admin/company-termination';

class CompanyService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Company>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Company>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: Company,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Company>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: Company,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Company>> {
    return axios.post(URL, data, config);
  }

  static delete(data: Company, config?: AxiosRequestConfig) {
    return axios.post(URL_COMPANY_TERMINATE, data, config);
  }
}

export { CompanyService };
