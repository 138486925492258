import React, { useEffect, useState } from 'react';
import { Checkbox, FormLabel, Grid, TextField } from '@mui/material';
import moment from 'moment';
import Select from 'react-select';

import { MasterServiceArea, MasterServiceAreaTier, MasterServiceAreaTierService } from 'us-web-services';
import DateUtil from '../../util/DateUtil';

interface Props {
  masterServiceArea: MasterServiceArea;
  setMasterServiceArea: (masterServiceArea: MasterServiceArea) => void;
}

export default function MasterServiceAreaForm(props: Props) {
  const { masterServiceArea, setMasterServiceArea } = props;

  const [name, setName] = useState(masterServiceArea.name);
  const [enableParentTest, setEnableParentTest] = useState<boolean>(
    !!masterServiceArea.parentPriceTestEnabled,
  );
  const [enableSitterTest, setEnableSitterTest] = useState<boolean>(
    !!masterServiceArea.sitterPriceTestEnabled,
  );
  const [tiers, setTiers] = useState<MasterServiceAreaTier[]>([]);

  const updateName = event => {
    setName(event.target.value);
    masterServiceArea.name = event.target.value;
    setMasterServiceArea(masterServiceArea);
  };

  useEffect(() => {
    const loadTiers = async () => {
      const response = (await MasterServiceAreaTierService.getByFilter()).data;

      if (response.data) {
        setTiers(response.data);
      }
    };

    loadTiers();
  }, []);

  const onTierSelect = selectedTier => {
    masterServiceArea.masterServiceAreaTier = selectedTier;
    setMasterServiceArea(masterServiceArea);
  };

  const updateEnableParentTest = () => {
    const result = !enableParentTest;

    if (result) {
      masterServiceArea.parentPriceTestEnabled = DateUtil.getDateTimeString(
        moment(),
      );
    } else {
      masterServiceArea.parentPriceTestEnabled = null;
    }

    setMasterServiceArea(masterServiceArea);
    setEnableParentTest(result);
  };

  const updateEnableSitterTest = () => {
    const result = !enableSitterTest;

    if (result) {
      masterServiceArea.sitterPriceTestEnabled = DateUtil.getDateTimeString(
        moment(),
      );
    } else {
      masterServiceArea.sitterPriceTestEnabled = null;
    }

    setMasterServiceArea(masterServiceArea);
    setEnableSitterTest(result);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id='name'
          name='name'
          label='Name'
          fullWidth
          value={name}
          inputProps={{
            maxLength: 60,
          }}
          onChange={updateName}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel component='legend'>Enable Parent A/B price test?</FormLabel>
        <Checkbox
          checked={enableParentTest}
          onChange={updateEnableParentTest}
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel component='legend'>Enable Sitter A/B price test?</FormLabel>
        <Checkbox
          checked={enableSitterTest}
          onChange={updateEnableSitterTest}
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
      </Grid>
      {tiers.length ? (
        <Grid item xs={12}>
          <FormLabel component='legend' style={{ marginBottom: 10 }}>
            Tier
          </FormLabel>
          <Select
            options={tiers}
            defaultValue={masterServiceArea.masterServiceAreaTier}
            onChange={onTierSelect}
            styles={{
              // @ts-expect-error CSS overloads are valid here
              menu: provided => ({
                ...provided,
                zIndex: 100,
              }),
            }}
            getOptionValue={data => data.id.toString()}
            getOptionLabel={data => data.name}
          />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
}
