import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../../types/api';
import { FacebookConnection } from '../../types/social/FacebookConnection';

const URL = '/v2/facebook-connection';

class FacebookConnectionService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<FacebookConnection>> {
    return axios.get(URL, config);
  }

  static create(data: FacebookConnection, config?: AxiosRequestConfig) {
    return axios.post(URL, data, config);
  }
  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { FacebookConnectionService };
