import axios, { AxiosRequestConfig } from 'axios';

const URL = '/v2/admin/remove-pii';

class UserPIIRemovalService {
  static findById(id: any, config?: AxiosRequestConfig) {
    return axios.get(`${URL}/${id}`, config);
  }

  static delete(id: any, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }
}

export { UserPIIRemovalService };
