import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../types/api';
import { ShareLink } from '../types/ShareLink';

const URL = '/v2/share-link';

class ShareLinkService {
  static post(
    data: ShareLink,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<ShareLink>> {
    return axios.post(URL, data, config);
  }
}
export { ShareLinkService };
